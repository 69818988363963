import {Tab, Tabs, Typography, Box} from "@material-ui/core";
import {ShowTypeLabel} from "./ShowTypeLabel";
import React from "react";
import AddIcon from '@material-ui/icons/Add';

export function TabsHorizontal(props) {
    const {rooms, tabHorizontal, setTabHorizontal, group, createNewRoom} = props;
    // const group = objectText[objectShow];
    return <Tabs
            value={tabHorizontal}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            style={{background: "#f0f0f0",}}
            onChange={(e, v) => setTabHorizontal(v)}
        >
            {
            createNewRoom && <Tab
                value={'new'}
                label={
                    <Box display="flex" pt={1.75}>
                        <Box pr={1}>
                            <AddIcon />
                        </Box>
                        <Typography varint="body2">
                            Добавить
                        </Typography>
                    </Box>
                }
            />
            }
            {rooms
                // .filter(el => tabGroup && el[objectShow] === tabGroup)
                .map(el => <Tab
                    value={el.guid}
                    // label={ShowTypeLabel(el.guid, el.type, el.lastDate, el.countNotShow)}
                    label={<ShowTypeLabel
                        guid={el[group.secondGroup]}
                        room={el}
                        type={group.api2}
                        lastDate={el.lastDate}
                        countNotShow={Number(el.notRead)}
                    />}

                    // onClick={changeTab(el.guid)}
                />)
            }

        </Tabs>
}