import React from 'react';

import {Box, Paper, Typography} from '@material-ui/core';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import {getCookie, setCookie} from 'libs/cookie';

import RequestForm from 'components/RequestForm';
import {RequestFormDebugContext} from 'components/RequestFormDebug';
import {MeContext} from "meContext";
import asArray from "libs/asArray";
import useExplainCols from "components/useExplainCols";

const defaultProps = {
    onFormDataAction:  values => values,
    canDeleteItem:     values => values,
    actionAddTitle:    'Добавить',
    actionUpdateTitle: 'Сохранить',
    FormComponent:     RequestForm,
    handleChange:      (values) => console.log(values),
};

export default function FilterTableParams(props) {

    const {
        dbId,
        open,
        onSuccess,
        handleSubmit,
        initialValues,
        topColsBlocks,
        subFormHandleChange,
        onFormDataAction,
        FormComponent,
        path,
        apiName,
        handleChange,
    } = {...defaultProps, ...props};

    let values;
    try {
        const saved = apiName
            ? getCookie('filter.'.concat(apiName))
            : '';
        values = JSON.parse(saved) || initialValues || {};
    } catch (e) {
        values = initialValues || {};
    }
    // const [values, setValues] = React.useState(initialValues);


    const me = React.useContext(MeContext);

    const Form = subFormHandleChange
        ? 'div'
        : 'form';

    const cols = useExplainCols(props.cols, {}, values, {me, debug_FilterTableParams: true});

    const filterCols = asArray(cols)
        .filter(i => i.filter)
        .map(i => ({
            ...i,
            ...i.filter,
        }));

    if (!filterCols.length) {
        return null;
    }

    return (
        <FormDataContextProvider
            initial={values}
            onFormDataAction={onFormDataAction}
            handleSubmit={handleSubmit}
            cols={filterCols}
            dbId={dbId}
            onSuccess={onSuccess}
            debug={'FilterTableParams'}
        >
            <AdminShow>[FilterTableParams]</AdminShow>
            <FormDataContextConsumer>{context => {
                // console.log('handleChange subValues FormDataContextConsumer', context);
                if (JSON.stringify(initialValues) !== JSON.stringify(context.state.values)) {
                    // setValues(values);
                    console.log('filter.'.concat(apiName), JSON.stringify(context.state.values), JSON.stringify(initialValues));
                    setCookie('filter.'.concat(apiName), JSON.stringify(context.state.values));
                    handleChange(context.state.values);
                }
            }}</FormDataContextConsumer>
            <AdminShow>[FilterTableParams]</AdminShow>
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            <Paper>
                <Form>
                    <Box p={3}>
                        <Typography variant={'h6'}>Фильтр значений списка</Typography>
                        <FormComponent
                            open={open}
                            // values={values}
                            // handleChange={handleChange}
                            // setState={setState}
                            // handleSuggest={handleSuggest}
                            // errors={errors}
                            // submitting={submitting}
                            topColsBlocks={topColsBlocks}
                            // cols={filterCols}
                            path={path + '/edit'}
                        />
                    </Box>
                </Form>
            </Paper>
            <RequestFormDebugContext/>
        </FormDataContextProvider>
    );
}
