import React from "react";
import { Link } from "react-router-dom";
import { config} from 'config'

import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";


export const KanbanCardRoutePoints = ({ card }) => {

  const {timeClipping} = config;

  const textLoadingPoint = "погрузка";
  const textUnloadingPoint = "разгрузка";
  const textLoadingUnloadingPoint = `${textLoadingPoint} и ${textUnloadingPoint}`;

  const [dataAdress, setdataAdress] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [loadPoint, setLoadPoint] = React.useState({
    address : "Точка не задана",
    name: "prod",
    point: 1
  })
  const [unLoadPoint, setUnLoadPoint] = React.useState({
    address : "Точка не задана",
    name: "prod",
    point: -1
  })

  const formatDate = (date) => {
    return date
    ? date.toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    : "--.--.----, --:--"
  } 

  // const getValueFromApiId = async (api) => {
  //   const value = await fetch(api)
  //     .then((res) => res.json())
  //     .catch((err) => {
  //       return err;
  //     });
  //   return value;
  // };


  React.useEffect(() => {
    const points = [];
    // Ниже функция для генерации фейковых значений
    // const emptyFun = () => {
    //   const count = Math.floor(Math.random() * 10);
    //   const adress = [
    //     "test-empty",
    //     "город Москва, Вознесенский переулок, дом 21, строение № 3, корпус 1, еще что-то еще что-то еще что-то ",
    //     "город Москва, Печатников переулок, дом 12",
    //     "город Москва, улица Маросейка, дом 11/4, строение 3 строение № 3, корпус 1, еще что-то еще что-то",
    //     "город Москва, Вознесенский переулок, дом 22",
    //     "город Москва, улица Каретный Ряд, дом 2  строение № 3, корпус 1, еще что-то еще что-то еще что-то еще что-то еще что-то еще что-то",
    //     "город Москва, Коровинское шоссе, дом 24А строение № 3, корпус 1, еще что-то",
    //     "город Москва, Зеленоградская улица, дом 33Б",
    //     "город Москва, Анадырский проезд, дом 79, строение 1  строение № 3, корпус 1, еще что-то",
    //     "город Москва, Псковская улица, дом 12, корпус 3",
    //   ];
    //   return { address: adress[count], name: "test" };
    // };
   
    if (card.routePlacesInfo.length == 1) {
        let point = 1;
        const cardData = card.routePlacesInfo.shift();
        const date = new Date(cardData.dateFro) || ''
        if (cardData.unloadCargos.length && cardData.loadCargos.length) {
          point = 0;
        } else if (cardData.unloadCargos.length && !cardData.loadCargos.length) {
          point = -1;
        }
        console.log(cardData.pointName);
        const itemPoint = {
          address: cardData.pointName,
          name: "prod",
          point,
          date: date > timeClipping ? date : '',
        };
        const itemPointEmpty = {
          address : "Точка не задана",
          name: "empty",
          point,
        } 
        setdataAdress((e) => [...e, itemPoint, itemPointEmpty]);
      
    } else if (card.routePlacesInfo.length > 1) {
      card.routePlacesInfo.map((r) => {
        let point = 1;
        const date = new Date(r.dateFrom)|| ''
        if ((r.isLoadind && r.isUnloading )||(r.unloadCargos.length && r.loadCargos.length)) {
          point = 0;
        } else if ((!r.isLoadind && r.isUnloading ) || (r.unloadCargos.length && !r.loadCargos.length)) {
          point = -1;
        }
        const itemPoint = {
          address: r.pointName,
          name: "prod",
          point,
          date: r.dateFrom && date > timeClipping ? date : '',
        };
        function byField(field) {
          return (a, b) => (a[field] > b[field] ? 1 : -1);
        }
        setdataAdress((e) => [...e, itemPoint]);
        setdataAdress((e) => [...e.sort(byField("date"))]);
      });
    }else {
      const itemPointEmpty = {
        address : "Точка не задана",
        name: "empty",
        point: 1
      } 
      setdataAdress((e) => [...e, itemPointEmpty, itemPointEmpty]);
    
    } 

    console.log(dataAdress)
  }, []);

  React.useEffect(()=>{
    const load = dataAdress.filter(e => e.name !==  'empty' && e.point !== -1)
    if(load.length){
      setLoadPoint(load[0]);
    }
    const unload =  dataAdress.filter(e => e.name !==  'empty' && e.point !== 1)
    if(unload.length){
      setUnLoadPoint(unload[unload.length -1])
    }
  }, [dataAdress])

  return (
    <>
      <div className="react-kanban-card__description">
        {dataAdress.length ? (
          <>
              <div className="react-kanban-card__route">
              <small title={textLoadingPoint}>
                <img
                  src="kanbanIcons/place_of_loading.svg"
                  className="react-kanban-card_icon"
                  width="20px"
                />
              </small> 
                <small
                  className="react-kanban-card__route_text"
                  title={loadPoint.address}
                >
                  <span className="react-kanban-card__route_text_green">
                    {formatDate(loadPoint.date)}
                  </span>
                  {loadPoint.address}
                </small>
              </div>
              {dataAdress.length > 2 && 
                <div
                className="react-kanban-card__route_arrow_button"
                onClick={() => setOpen(card.title)}
              >
                <Avatar
                  style={{
                    width: 20,
                    height: 20,
                    background: "#3b71af",
                    fontSize: "15px",
                  }}
                  className="react-kanban-card_icon"
                >
                  {dataAdress.length - 2}
                </Avatar>
                <small
                  className="react-kanban-card__route_text_button"
                  title={"дополнительные точки машрута"}
                >
                  {"дополнительные точки маршрута"}
                </small>
              </div>
              }
              
              <div className="react-kanban-card__route_arrow">
              <smal title={textUnloadingPoint}>
                <img
                  src="kanbanIcons/place_of_unloading.svg"
                  className="react-kanban-card_icon"
                  width="20px"
                />
              </smal>
                <small
                  className="react-kanban-card__route_text"
                  title={unLoadPoint.address}
                >
                  <span className="react-kanban-card__route_text_blue">
                    {formatDate(unLoadPoint.date)}
                  </span>
                  {unLoadPoint.address}
                </small>
              </div>
              <Dialog
                open={open === card.title ? true : false}
                onClose={() => setOpen(false)}
              >
                <DialogTitle>
                  <span>
                    <Link
                      to={card.url||''}
                      className={
                        "react-kanban-link react-kanban-card__title_modadl"
                      }
                    >
                      <span>Маршрут {card.title}</span>
                    </Link>
                  </span>
                </DialogTitle>
                <DialogContent>
                  {dataAdress.map((item, key) => {
                    let classBlock =
                      "react-kanban-card__route_arrow_modal_up_down";
                    if (key === 0) {
                      classBlock = "react-kanban-card__route_modal";
                    } else if (key === dataAdress.length - 1) {
                      classBlock = "react-kanban-card__route_arrow_modal";
                    }
                    switch (item.point) {
                      case 1:
                        return (
                          <div className={classBlock} key={key}>
                            <small title={textLoadingPoint}>
                            <img
                              src="kanbanIcons/place_of_loading.svg"
                              className="react-kanban-card_icon"
                              width="20px"
                            />
                            </small>
                            <small
                              className="react-kanban-card__route_text"
                              title={item.address + key}
                            >
                              <span className="react-kanban-card__route_text_green">
                                {formatDate(item.date)}
                              </span>
                              {item.address}
                            </small>
                          </div>
                        );
                      case 0:
                        return (
                          <div className={classBlock} key={key}>
                            <div className="react-kanban-card__route_from_and_to">
                              <small title={textLoadingUnloadingPoint}>
                              <img
                                src="kanbanIcons/place_of_loading.svg"
                                className="react-kanban-card_icon"
                                width="20px"
                                style={{
                                  marginLeft: "-13px",
                                  marginRight: "4px",
                                }}
                              />
                              </small>
                              <small title={textLoadingUnloadingPoint}>
                                <img
                                  src="kanbanIcons/place_of_unloading.svg"
                                  className="react-kanban-card_icon"
                                  width="20px"
                                  style={{ marginRight: "8px" }}
                                />
                              </small>
                            </div>
                            <small
                              className="react-kanban-card__route_text"
                              title={item.address}
                            >
                              <span className="react-kanban-card__route_from_and_to_text">
                              {formatDate(item.date)}
                              </span>
                              {item.address}
                            </small>
                          </div>
                        );
                      case -1:
                        return (
                          <div className={classBlock} key={key}>
                            <small title={textUnloadingPoint}>
                              <img
                                src="kanbanIcons/place_of_unloading.svg"
                                className="react-kanban-card_icon"
                                width="20px"
                              />
                            </small>
                            <small
                              className="react-kanban-card__route_text"
                              title={item.address}
                            >
                              <span className="react-kanban-card__route_text_blue">
                              {formatDate(item.date)}
                              </span>
                              {item.address}
                            </small>
                          </div>
                        );
                    }
                  })}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Закрыть
                  </Button>
                </DialogActions>
              </Dialog>
            </>
        ) : (
          <div className="react-kanban-card_loading">
            <CircularProgress color="primary" />
          </div>
        )}
      </div>
    </>
  );
};
