/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from "../Autocomplete";
import {config} from 'config';
import asArray from 'libs/asArray';
import React from 'react';
import {ApiDataLoad} from "components/ApiDataLoad";

const icon        = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

// tagSizeSmall
const useStyles = makeStyles(() => (
    {
        tag: {
            maxWidth: '75%',
        },
    }
));

export default function FormApiAutocompleteCheckbox(props) {
    const {
        label,
        value,
        api,
        apiFilter,
        placeholder,
        onChange,
        variant,
        item,
    } = {
        variant: 'outlined',
        ...props,
    };

    const {
        fieldShow
    } = {
        fieldShow: 'name',
        ...item
    }
    // return JSON.stringify({label,
    //     value,
    //     api,
    //     placeholder});
    
    const [options, setOptions] = React.useState([]);
    const loading               = options.length === 0;
    React.useEffect(() => {
        let active = true;
        if(!api && props.item.options){
            setOptions(props.item.options)
            return () => {
                active = false;
            };
        }
        if (!loading) {
            return undefined;
        }
        
        (
            async () => {
                try {
                    const response = await ApiDataLoad(config.apiPrefix + api, {...apiFilter});
                    // const response = await request
                    //     .get(config.apiPrefix + api)
                    //     .set('accept', 'application/json')
                    //     // todo: .query({name: inputValue, ...apiFilter});
                    //     .query({...apiFilter});
    
                    if (active) {
                        setOptions(response);
                    }
                } catch (e) {
                    setOptions([{name: 'HTTP Error: '+e.message}]);
                }
            }
        )();
        
        return () => {
            active = false;
        };
    }, [loading, api,apiFilter]);
    
    const onChangeLocal = (event, value) => {
        console.log('onChangeLocal', value.map(i => i.guid));
        onChange(null, value.map(i => i.guid));
    };
    // tagSizeSmall
    const classes       = useStyles();
    return (
        <Autocomplete
            multiple
            disabled={item.disabled}
            options={options}
            classes={{tag: classes.tag}}
            limitTags={2}
            ChipProps={{
                size: 'small',
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option[fieldShow]}
            value={options && options.filter(i => asArray(value).includes(i.guid))}
            onChange={onChangeLocal}
            noOptionsText={'Нет вариантов'}
            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option[fieldShow]}
                </React.Fragment>
            )}
            style={{width: '100%'}}
            renderInput={(params) => (
                <TextField {...params}
                           fullWidth
                           variant={variant}
                           margin={'dense'}
                           InputLabelProps={{shrink: true}}
                           label={label}
                           placeholder={placeholder}/>
            )}
            clearText={config.toolTipClearIconText}
        />
    );
}
