import {FieldFormat} from 'config/FieldFormat';
import PageCargoTypes from 'page/PageCargoTypes';
import {
    ShowApiLink,
    ColObjectName,
} from 'components/showItems';
import {ROLES} from 'config/roles';

import {CargoTypesIcon} from 'components/icons';

const mapSuggestions = fieldName => (data, oldData) => {
    return data.useCustom
        ? {
            ...oldData,
            [fieldName]: data[data.useCustom],
        }
        : {
            okpd2: data.kod
          
        }
};

export default {
    listTitle:     'Типы грузов',
    apiName:       'cargo_types',
    itemName:      'Типы груза',
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title:     'Типы грузов',
        path:      '/cargo_types',
        Component: PageCargoTypes,
        roles:     [ROLES.ROLE_USER],
        icon:      CargoTypesIcon,
    },
    topColsBlocks: [
        {
            num:   1,
            title: 'Информация',
            cols:  1,
        },
        {
            num:   2,
            title: 'Грузовое место',
            cols:  1,
        },
        {
            num:   3,
            title: 'Упаковка',
            cols:  1,
        },
    ],
    cols:          [
        {
            title: 'Наименование',
            // object: ShowApiLink('cargo_types'),
            field: 'name',
            cols:  2,
            topColsBlock: 1,
            required:   true,
        },
        {
            title:      'Логист. Ед. Изм.',
            helperText: 'Логистическая Единица Измерения',
            api:        'measures',
            object:     ColObjectName,
            format:     FieldFormat.FORMAT_SELECT_API,
            field:      'measure',
            required:   true,
            cols:       2,
            topColsBlock: 1,
        },
        {
            title:  'Длина грузового места, м',
            field:  'cargoPlaceLength',
            format: FieldFormat.FORMAT_NUMBER,
            cols:   6,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:  'Ширина грузового места, м',
            field:  'cargoPlaceWidth',
            format: FieldFormat.FORMAT_NUMBER,
            cols:   6,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:  'Высота грузового места, м',
            field:  'cargoPlaceHeight',
            format: FieldFormat.FORMAT_NUMBER,
            cols:   6,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:  'Объем грузового места, м3',
            type:   'float',
            field:  'cargoPlaceValue',
            format: FieldFormat.FORMAT_FLOAT,
            cols:   6,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:  'Вес места, кг',
            type:   'float',
            field:  'cargoPlaceWeight',
            format: FieldFormat.FORMAT_FLOAT,
            cols:   6,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:  'Объем упаковки, м3',
            field:  'measureValue',
            format: FieldFormat.FORMAT_NUMBER,
            cols:   6,
            topColsBlock: 3,
            minValue: 0
        },
        {
            title:  'Вес упаковки, кг',
            field:  'measureWeight',
            format: FieldFormat.FORMAT_NUMBER,
            cols:   6,
            topColsBlock: 3,
            minValue: 0
        },
        {
            title:  'Виды продукции (ОКПД 2)',
            field:  'okpd2',
            format: FieldFormat.FORMAT_SELECT_DADATA,
            mapSuggestions: mapSuggestions('okpd2'),
            helperText:     'Наименование груза',
            api:            'search/cargo',
            cols:   6,
            topColsBlock: 3,
        },
    ],
};
