import {ROLES} from 'config/roles';
// import Component from 'page/PageQueues';
import {
    DynamicFeed as MenuIcon,
} from '@material-ui/icons';
import {config} from './index';
import PageApiLoader from 'components/PageApiLoader3';
import React from 'react';
import {FieldFormat} from 'config/FieldFormat';
import {ApiDataLoadLookup, ShowDate, ColObjectName, ColObjectSimpleData} from 'components/showItems';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import RvHookupIcon from '@material-ui/icons/RvHookup';

export default {
    listTitle:     'Очереди распределения',
    itemName:      'Очередь распределения',
    apiName:       'doc_queues',
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title:     'Очереди распределения',
        path:      '/doc_queue',
        Component: props => <PageApiLoader
            {...props}
            {...config.queue}
            canEdit={true}
            canAdd={true}
        />,
        roles:     [ROLES.ROLE_USER],
        icon:      MenuIcon,
    },
    initialValues: () => (
        {
            docNumber: 'Присвоится автоматически',
            status:    'Открыт',
            docDate:   new Date(),
            _CREATE:   {
                newBidding: {
                    apiName:     'biddings',
                    parentField: {
                        docLinkedGuid: 'guid',
                        docLinkedName: '_sub_name',
                        docDate:       'docDate',
                    },
                },
            },
        }
    ),
    topColsBlocks: [
        {
            num:    1,
            title:  'Информация об очереди',
            cols:   2,
            border: true,
        },
        {
            num:    2,
            title:  'Характеристики очереди',
            cols:   2,
            border: true,
        },
        {
            num:    3,
            title:  'Правила очереди',
            cols:   1,
            border: true,
        },
    ],
    colsInTab:     true,
    cols:          [

        {
            title:        'Номер документа',
            field:        'docNumber',
            disabled:     true,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Клиентский',
            helperText:   'Клиентский номер документа',
            field:        'clientDocNumber',
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Статус',
            helperText:   'Статус документов',
            field:        'status',
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'enum_queue_statuses',
            object:       ColObjectName,
            cols:         2,
            topColsBlock: 1,
        },


        {
            title:        'Комментарий',
            field:        'comment',
            cols:         1,
            topColsBlock: 1,
        },
        {
            title:        'Дата c',
            helperText:   'Дата начала действия очереди',
            field:        'dateStart',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         1,
            topColsBlock: 2,
        },
        {
            title:        'Дата до',
            helperText:   'Дата окончание действия очереди',
            field:        'dateEnd',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         1,
            topColsBlock: 2,
        },

        {
            title:        'Когда будет пересчитана очередь',
            field:        'dateNextRecalc',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         2,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:        'Скрывать заявки попавшие в очередь',
            field:        'isHideAllOrders',
            format:       FieldFormat.FORMAT_CHECKBOX,
            cols:         2,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:        'Стартовый',
            helperText:   'Стартовый этап жизни заявки',
            field:        'startPhase',
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            required:     true,
            api:          'enum_queue_phases',
            cols:         4,
            topColsBlock: 3,
            hidden: true,
        },


        {
            title:        'Критерии',
            helperText:   'Какие заявки сюда попадают (общий критерий попадания в очередь)',
            field:        'orderCommonCriteries',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'queue_criterias',
            cols:         2,
            topColsBlock: 3,
        },
        {
            title:        'Шаблон',
            helperText:   'Шаблон пересчета категории перевозчика',
            field:        'ratingRulesPattern',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'rules_patterns',
            cols:         4,
            topColsBlock: 3,
        },

    ],
    subItems:      {
        style: 'tabs',
        list:  [
            {
                listTitle: 'Категории перевозчиков',
                field:     'carrierCategories',
                icon:      <EmojiEventsIcon/>,
                cols:      async () => {
                    const [
                              lookupCarrier,
                              lookupCategorys,
                              lookuptypedTransport,
                              lookupPlace,
                          ] = await Promise.all(
                        [
                            ApiDataLoadLookup('/api/legals', 'guid', false),
                            ApiDataLoadLookup('/api/categories', 'guid', false),
                            ApiDataLoadLookup('/api/typed_transports', 'guid', false),
                            ApiDataLoadLookup('/api/fias_address_trees', 'guid', false),
                        ],
                    );


                    return [
                        {
                            title:         'Перевозчик',
                            api:           'legals',
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'carrier',
                            object:     ColObjectName,

                        },
                        {
                            title:         'Категории перевозчиков',
                            api:           'categories',
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'category',
                            object:        ColObjectName,

                        },
                        {
                            title:         'Вид транспорта',
                            api:           'typed_transports',
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'typedTransport',
                            object:        ColObjectName,
                        },
                        {
                            title:         'Погрузка',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                            field:         'fromPoint',
                        },
                        {
                            title:         'Разгрузка',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                            field:         'toDirection',
                     
                        },
                    ];
                },
            },
            {
                listTitle: 'Этапы жизни заявки',
                field:     'phaseTransferRules',
                icon:          <RvHookupIcon/>,
                initialValues: () => ({
                    phaseDuration:      60,
                    orderPercent:       100,
                    priceBuynowPercent: 0,
                    stepBidSumm:        0,
                    stepBidPercent:     0,
                    accessLevel:        'ВсемПеревозчикам',
                }),
                cols:          async () => {
                    const [
                              lookupPhase,
                              lookupAcessLeval,
                          ] = await Promise.all([
                        ApiDataLoadLookup('/api/enum_queue_phases', 'guid', false),
                        ApiDataLoadLookup('/api/enum_access_levels', 'guid', false),
                    ]);

                    return [
                        {
                            title:         'Этап', // todo api: EnumQueuePhase
                            api:           'enum_queue_phases',
                            style:         {width: "250px"},
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'phase',
                            object:        ColObjectName,
                        },
                        {
                            title:         'Следующий этап',
                            api:           'enum_queue_phases',
                            style:         {width: "250px"},
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'nextPhase',
                            object:        ColObjectName,
                        },
                        {
                            title:  'Срок жизни (в мин)',
                            field:  'phaseDuration',
                            format: FieldFormat.FORMAT_NUMBER,
                            measureText : '(мин)',
                            object: ColObjectSimpleData,
                            minValue: 0,
                        },
                        {
                            title:  '% заявок',
                            field:  'orderPercent',
                            format: FieldFormat.FORMAT_NUMBER,
                            measureText: '%',
                            object: ColObjectSimpleData,
                            minValue: 0,
                            maxValue: 100
                        },
                        {
                            title:  'Купить сейчас(% от стоимости)',
                            field:  'priceBuynowPercent',
                            format: FieldFormat.FORMAT_NUMBER,
                            measureText:  '%',
                            object: ColObjectSimpleData,
                            minValue: 0,
                            maxValue: 100
                        },
                        {
                            title: 'Шаг ставки (сумма)',
                            format: FieldFormat.FORMAT_NUMBER,
                            field: 'stepBidSumm',
                            minValue: 0,
                        },
                        {
                            title:  'Шаг ставки %',
                            field:  'stepBidPercent',
                            format: FieldFormat.FORMAT_NUMBER,
                            measureText : '%',
                            object : ColObjectSimpleData,
                            minValue: 0,
                            maxValue: 100
                        },
                        {
                            title:         'Уровень доступа', // todo: accessLevel from other docs
                            api:           'enum_access_levels',
                            style:         {width: "290px"},
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'accessLevel',
                            object:        ColObjectName,
                        },

                    ];
                },
            },
        ],
    },
};
