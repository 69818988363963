import React from 'react';
import {FormDataContextProvider} from "FormDataContext";

const defProps = {
    topColsBlocks: [
        {}
    ]
}

/**
 * Компонент для работы со вложенным объектом в контексте
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function NestedFieldsForm(props) {
    const {cols, AfterComponent, item, field, ...otherProps} = props;
    const initial = item[field];
    return <FormDataContextProvider
        cols={cols}
        item={{field}}
        initial={initial}
        debug={'NestedFieldsForm'}
    >
        <AfterComponent {...otherProps}/>
    </FormDataContextProvider>
}

/**
 * Компонен для работы с отдельныым полями во вкладке
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TabForm(props) {
    const {cols, AfterComponent, item, ...otherProps} = props;
    console.log('TabFormTabForm',props)
    return <AfterComponent {...otherProps} item={item} topColsBlocks={[{fieldsShow: cols}]} />
}


const addDefaultProps = defProps => WrappedComponent => props => {
    return <WrappedComponent {...defProps} {...props} />;
}

export default addDefaultProps(defProps)(NestedFieldsForm)