import React from 'react';
import {config} from 'config';
import PageApiLoader from 'components/PageApiLoader3';

export default function Page(props) {
    return <PageApiLoader
        {...props}
        {...config.driver}
    />;
}
