import React from "react";
import PropTypes from "prop-types";
import {Grid, Typography} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";




function TabPanel(props) {
    const {children, value, index, ...other} = props;
    
    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

class ErrorMessage extends React.PureComponent {
    render() {
        const { title, description, extended } = this.props;
        return (
            <Grid
                container
                alignItems={`flex-center`}
                spacing={4}
                style={{
                    
                    background: '#fdeded',
                    borderRadius: '4px',
                    margin : '0 2%', 
                    width: '96%',
                    alignItems: 'center',
                }}
            >
                <Grid item style={{padding: '0'}}>
                    <ErrorIcon style={{ color: "#e51c23" }}/>
                </Grid>
                <Grid item style={{padding: '0', marginLeft: '16px'}}>
                    <Typography style={{ color: "#d50000" }} variant={"body2"}>
                        {title}
                    </Typography>
                </Grid>
                {extended &&
                <Grid item xs={12} style={{padding: '0'}}>
                    <Typography style={{ color: "#d50000" }} variant={"body2"}>
                        {description}
                    </Typography>
                </Grid>}
            </Grid>
        );
    }
}

ErrorMessage.propTypes = {
    /**
     * Error title
     */
    title:          PropTypes.string.isRequired,
    /**
     * Extend information about error
     */
    description:    PropTypes.string.isRequired,
    /**
     * Show extend information?
     */
    extended:       PropTypes.bool.isRequired,
};

ErrorMessage.defaultProps = {
    title:          "Произошла ошибка",
    description:    "",
    extended:       false,
};

export default ErrorMessage;