import React from 'react';

import {STATE} from 'libs/api';
import {MeContext} from 'meContext';

import {LoginForm} from "components/LoginForm";
import {useLoaderApiList} from "components/useLoaderApi";
import {LinearProgress, Snackbar} from "@material-ui/core";
import App from "App";
import url from 'url';
import {Alert} from "@material-ui/lab";
import WebSocketEvents from "./components/WebSocketEvents";
import ClosedForm from "components/ClosedForm";
import {ChatProvider} from "./components/Chat/ChatContext";

export default  function MeLoader(props){
    const [me, state, reload] = useLoaderApiList('me')
    const [options, setOptions] = React.useState({});
    window.enableDebug          = () => {
        setOptions({
                    ...me,
                    roles: [
                        ...me.roles,
                        'ROLE_DEBUG'
                    ],
                }
        );
    };

    // const reloadDefaultValue =(defaultSettings) => {
    //     setOptions(state => ({...state, defaultSettings}))
    // }

    switch (state) {
        case STATE.LOADED:
            return <MeContext.Provider value={{...me, ...options, setOptions, reload}}>
                <ChatProvider>
                    <App/>
                </ChatProvider>
            </MeContext.Provider>
        case STATE.LOADING:
            return <div>LOADING...<LinearProgress/></div>;
        case STATE.ERROR:
        default:
            return <LoginForm setOptions={reload}/>;
    }

}
