import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {FormControl, IconButton, InputAdornment, TextField} from '@material-ui/core';
import {Visibility, VisibilityOff,} from '@material-ui/icons';
import {FormDataContext, FormDataContextConsumer} from 'FormDataContext';
// import useCheckField from "components/useCheckField"
import EndAdornmentComponent from "../EndAdornmentComponent";

function MyComponent(props) {
    const { submitting, item, value, values, error, handleChange, required} = props;
    const { topColsBlock, disabled, field, title, format, check, checkfield, helperText,  mainField, nextfield, require, options, api, apiFilter, ...other } = item;

    const [showPassword, setShowPassword] = React.useState(false)


    const handleClickShowPassword = () => setShowPassword(e => !e)

    // const [errorCheck, loadState, ] = useCheckField(check, field)


    // const errorTextField = error || (!value.length && required) || '';


    return <React.Fragment>
        <AdminShow>[INPUT]</AdminShow>
        {/* <TextField
            disabled={disabled || submitting}
            error={true}
            label={title}
            value={value}
            required={helperText.length ? true : false}
            margin={'dense'}
            fullWidth
            variant={'outlined'}
            name={field}
            onChange={!error ? handleChange : error}
            error={!!error}
            helperText={helperText}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                    values: context.state.values,
                    setStateValues: context.setStateValues,
                })}</FormDataContextConsumer>,
                endAdornment: <FormDataContextConsumer>{context => item && item.endButtons && item.endButtons({
                    values: context.state.values,
                    setStateValues: context.setStateValues,
                })}</FormDataContextConsumer>,
            }}
            {...other}
        /> */}
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" fullWidth>
          <TextField
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            margin={'dense'}
            fullWidth
            variant={'outlined'}
            name={field}
            InputLabelProps={{ shrink: true }}
            // InputProps = {generatedInputProps(
            //         item,
            //         <InputAdornment position="end">
            //             <IconButton
            //                     aria-label="toggle password visibility"
            //                     onClick={handleClickShowPassword}
            //                     edge="end"
            //             >
            //                 {showPassword ? <Visibility/> : <VisibilityOff/>}
            //             </IconButton>
            //         </InputAdornment>
            //         )}
            InputProps={{
                startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                    values: context.state.values,
                    setStateValues: context.setStateValues,
                })}</FormDataContextConsumer>,
                endAdornment: EndAdornmentComponent(
                        item,
                        value,
                        error,
                        (<InputAdornment position="end">
                            <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>)
                        ),


            }}
            label={title}
            {...other}
            required={required}
          />
          </FormControl>
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error
        && prevProps.required === nextProps.required;

}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const { field, required } = props.item;
    const { state: { values, errors }, handleChange,  checkPassword, stateHelperText} = React.useContext(FormDataContext);
    const handleChangeLocal = (e) => {
        // console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };


    const value = values && values[field];
    const error = errors && errors[field];
     
    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        required={required}
        {...props}
    />;
}

export default MyComponentMemoUse;