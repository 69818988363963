import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Box
} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';

const defaultProps = {
    title:    'Требуется подтверждение',
    text:     'Подтвердите выполнение действия',
    confirm:  'Да',
    cancel:   'Нет',
    onCancel: () => {},
    /** @deprecated */
    confirmDisable : false,
    /** @deprecated */
    cancelDisable: false
};

function ConfirmDialog(props) {
    const {title, text, confirm, cancel, trigger, children, onCancel, maxWidth,  onConfirm, show, confirmDisable, cancelDisable, custemDialogContent, closeByMeansTitle } = {...defaultProps, ...props};
    
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };
    
    const handleClose = (e) => {
        setOpen(false);
        onCancel(e);
    };
    
    const handleSubmit = (e) => {
        setOpen(false);
        onConfirm(e);
    };
    
    return <React.Fragment>
        {!show && <span onClick={handleClickOpen}>{trigger || children}</span>}
        <Dialog open={Boolean(open || show)} onClose={handleClose}  maxWidth={maxWidth}>
            <DialogTitle onClose={handleClose}>
                {
                    closeByMeansTitle
                        ?
                        <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                            <Box>{title}</Box>
                            <Box>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        :<>{title}</>
                }
            </DialogTitle>
            {
                custemDialogContent ?
                    <DialogContent>
                        {custemDialogContent}
                        </DialogContent>

                        : <DialogContent>
                            <DialogContentText>{text}</DialogContentText>
                        </DialogContent>
            }

            <DialogActions>
                {
                    !cancelDisable
                        ? <Button onClick={handleClose} color="primary">
                            {cancel}
                        </Button>
                        : <></>
                }
                {
                    !confirmDisable
                        ? <Button onClick={handleSubmit} color="primary" autoFocus>
                            {confirm}
                        </Button>
                        : <></>
                }

            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

ConfirmDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    
    title:    PropTypes.string,
    text:     PropTypes.string,
    confirm:  PropTypes.string,
    cancel:   PropTypes.string,
    onCancel: PropTypes.func,
    trigger:  PropTypes.node,
    /** @deprecated */
    cancelDisable: PropTypes.bool,
};

export default ConfirmDialog;