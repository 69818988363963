import React from "react";
import {FieldFormat} from 'config/FieldFormat';
/**
 * @return {string}
 */

const typeNumber = 'number'
export function Dummy(props) {
    // return "D";
    const {column, row, noneValue, journal } = props;
    if(journal && row[column.field] === null){
        return noneValue || '---';
    }
    if (column && column.field) {
        if(row[column.field] && column.format && column.format === FieldFormat.FORMAT_NUMBER){
            row[column.field] = parseFloat(row[column.field]).toLocaleString()
        }
        if (row[column.field] && 'string' == typeof row[column.field]) {
            return row[column.field]
        } else if ('string' !== typeof row[column.field]) {
            return <pre>{JSON.stringify(row[column.field],null,1)}</pre>;
        } else {
            return noneValue || '---';
        }
    } else {
        return '.field not set';
    }
}
