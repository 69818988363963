import PageKanban from 'page/PageKanban';
import React from "react";
import Typography from "@material-ui/core/Typography";
import {Redirect} from 'react-router-dom';

// Свободные ()
// Назначенные заявки ()
// Внести транспорт и водителя ()
// Подать машину ()
// На погрузке ()
// В пути. Подтвердить доставку ()

export default  function MainPage() {


    return <React.Fragment>
        <Redirect to={{pathname: `/kanban`}}/>
        <PageKanban />
        </React.Fragment>
        ;

}
