import React from 'react';
import {CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import AdminShow from 'components/FieldFormat/AdminShow';
import FormEditCreateTabs from 'components/FormEditCreateTabs';
import RequestForm from 'components/RequestForm';

import API, {STATE} from 'libs/api';
import detectCols from 'libs/detectCols';

import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import useLoaderApi from "components/useLoaderApi";
import ErrorBoundary from "components/ErrorBoundary";
import {MeContext} from "meContext";
import {AddBreadCrumb} from "components/Menu";

const defaultProps = {
    cols:            detectCols,
    initialValues:   () => ({}),
    onElementUpdate: () => {},
    filter: {},
    itemName: 'объект',
    handleSubmit: apiName => (values, action) => {
        const api = new API();
        return api[action](apiName, values);
    },
};

function TableApiParamsLoader(props) {
    console.log('callfunction TableApiParamsLoader');

    
    let {guid, action, sublist} = useParams();

    const {
        apiName, itemName, subApi,
        backLink, path,
        initialValues,
        onElementUpdate,
        fullScreen,
        // loadState,
        cols,
        filter,
        handleSubmit,
        // item,
    } = {...defaultProps, ...props};

    const {me} = React.useContext(MeContext);

    const [colsField, setColsField] = React.useState(null)

    React.useEffect(()=>{
        //ToDo переделать реализацию, врменное решение.
        if(Array.isArray(cols)){
            setColsField(cols)

        }else if (typeof  cols == "function"){
            const resultCols = cols({}, {}, {});
            if(Array.isArray(resultCols)){
                setColsField(resultCols);
            }else{
                resultCols
                        .then(result => {
                            setColsField(result)
                        })
            }
        }


    }, [])

    const [item, loadState, forceReloadData] = useLoaderApi(apiName, guid,{},{
        initial: initialValues({menuRoot: props.menuRoot, item: {}, me, debug:'TableApiParamsLoader'}),
        onResponse: (item) => {
            const initial = initialValues({menuRoot: props.menuRoot, item: item, me});
            // console.log('call onResponse',item, initial,{...initial,...item})
            return {...item, ...filter}

        }
    });


    // console.log('callfunction TableApiParamsLoader',apiName,guid,{loadState,item});

    const history = useHistory();
    
    const openDialogEdit = state => () => {
        if (backLink) {
            console.log('use backLink');
            history.push(backLink);
        }
        else if (guid) {
            console.log('use push 1');
            history.push(
                state
                    ? `${path}/${guid}/edit`
                    : `${path}`,
            );
        }
        else {
            console.log('use push 2');
            history.push(`${path}`);
        }
    };
    
    // const api = new API();
    
    // const handleSubmit = apiName => (values, action) => {
    //     return api[action](apiName, values);
    // };



    if(loadState == STATE.LOADING || !colsField){
        return (
                <React.Fragment>
                    <AddBreadCrumb label={'Загрузка...'}/>
                    <CircularProgress/>
                    <AdminShow>[TableApiParamsLoader]</AdminShow>
                </React.Fragment>
        )

    }else if(loadState == STATE.LOADED && colsField){
        const label = item
                ? item.name
                : 'Новый';

        const showList = !action && !sublist;

        const newStatusDocument = item && !item.guid ? true : false

        return (
                <React.Fragment>
                    <AdminShow>[TableApiParamsLoader]</AdminShow>
                    <AddBreadCrumb label={label}/>

                    {fullScreen && <DialogEditCreate3
                            {...props}
                            dbId={'guid'}
                            itemName={itemName}
                            open={true}
                            handleSubmit={handleSubmit(apiName)}
                            handleClose={openDialogEdit(false)}
                            subApi={subApi}
                            tab={sublist||'edit'}
                            onSuccess={onElementUpdate}
                            initialValues={item}
                            item={item}
                            FormComponent={RequestForm}
                            cols={cols}
                            fullScreen={fullScreen}
                            path={path + '/' + guid}
                    />}
                    {!showList && !fullScreen && <FormEditCreateTabs
                            {...props}
                            dbId={'guid'}
                            itemName={itemName}
                            open={true}
                            handleSubmit={handleSubmit(apiName)}
                            handleClose={openDialogEdit(false)}
                            subApi={subApi}
                            tab={sublist||'edit'}
                            onSuccess={onElementUpdate}
                            initialValues={item}
                            item={item}
                            FormComponent={RequestForm}  //??? TODO: test not need?
                            cols={cols}
                            fullScreen={fullScreen}
                            path={path + '/' + (guid || 'new')}
                            colsField={colsField}
                            newStatusDocument={newStatusDocument}
                    />}

                </React.Fragment>
        );
    }else if(loadState == STATE.ERROR){
        return <React.Fragment>
            <AdminShow>[TableApiParamsLoader:Error]</AdminShow>
            <ErrorBoundary info={'STATE.ERROR'}>
                {console.log('STATE.ERROR',item,apiName, guid)}
                <Alert severity="error">Error: {item}</Alert>
            </ErrorBoundary>
        </React.Fragment>;
    }else{
        return <React.Fragment>
            <AdminShow>[TableApiParamsLoader:Error]</AdminShow>
            <ErrorBoundary info={'STATE.ERROR'}>
                {console.log('STATE.ERROR',item,apiName, guid)}
                <Alert severity="error">Default: {item}</Alert>
            </ErrorBoundary>
        </React.Fragment>;
    }
}

TableApiParamsLoader.propTypes = {
    apiName: PropTypes.string.isRequired,
};
export default TableApiParamsLoader;

// export default HOCLoadItem({from: 'TableApiParamsLoader'})(
//     HOCExplainCols()(
//         TableApiParamsLoader,
//     ),
// );

