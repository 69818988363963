import React from 'react';
import {ApiDataLoad} from "components/ApiDataLoad";

import ErrorMessage from 'components/ErrorMessage';

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function SubFormEditCreate(props) {
    
    const handleSuggest = item => suggest => {
        console.log('handleSuggest', suggest, suggest.data);
        if (suggest.data && item.mapSuggestions) {
            let newValues = item.mapSuggestions(suggest.data);
            this.setState({
                values:      {
                    ...this.state.values,
                    ...newValues,
                },
                submitError: false,
            });
        }
    };
    
    const handleChangeOld = item => (event, newValue) => {
        console.log('handleChangeOld', item, event, newValue);
        const {subFormHandleChange} = props;
        
        if (!newValue && (
            !event || !event.target
        )) {
            console.log('old no event: '
//          , typeof event, JSON.stringify(event),!event,!event.target);
                , typeof event, event, newValue);
            return false;
        }
        
        const {field}          = item;
        const {values} = props;
        //delete errors[field];
        // cl(event.target);
        let value;
        if (newValue) {
            value = newValue;
        }
        else {
            value = 'checkbox' === event.target.type
                ? event.target.checked
                : event.target.value;
        }
        
        let newValues = {
            ...values,
            [field]: value,
        };
        // console.log('subFormHandleChange ',subFormHandleChange );
        onChange(null, newValues);
        if (subFormHandleChange) {
            subFormHandleChange(newValues);
        }
        
    };
    
    const [state, setState] = React.useState({
        values: {},
        errors: {},
    });
    
    const {
              cols,
              initialValues,
              FormComponent,
              subErrors,
              onChange,
              values,
          } = props;
    const {
              // values,
              errors,
              submitting,
              submitError,
          } = state;
    //
    // React.useEffect(() => {
    //     if ('string' === typeof values) {
    //         ApiDataLoad(values).then(data => {
    //                 onChange(null, data);
    //             },
    //         );
    //     }
    // }, [values, onChange]);
    //
    // React.useEffect(() => {
    //     setState({
    //         values:      initialValues,
    //         errors:      subErrors || {},
    //         submitError: false,
    //     });
    //     // if (!isEqual(prevProps.initialValues, initialValues)) {
    //     //   console.log('componentDidUpdate',initialValues);
    //     //   this.setState({
    //     //     values: initialValues,
    //     //     errors: {},
    //     //     submitError: false,
    //     //   })
    //     // }
    // }, [initialValues, subErrors]);

    return (
        <div>
            <FormComponent
                open={true}
                // values={values}
                // initialValues={initialValues}
                // handleChange={handleChangeOld}
                // handleSuggest={handleSuggest}
                // errors={errors}
                // submitting={submitting}
                // cols={cols}
                debug={false}
                subForm={props.subForm}
                itemProps={props.itemProps}
            />
            {submitError && <ErrorMessage description={submitError} extended={true}/>}
        </div>
    );
}

// DialogEditCreate.defaultProps = {
//     dbId: "id",
//     TitleComponent,
//     ContentComponent,
//     ActionsComponent,
//
// };

