import React from "react";
import {FormDataContext} from "FormDataContext";
import {MeContext} from "meContext"
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {DeleteIcon, SaveIcon} from "components/icons.jsx";
import {withStyles} from "@material-ui/core/styles";
import ConfirmDialog from "components/ConfirmDialog";
import LinearProgress from "components/LinearProgress";
import {ObjectDetect} from "components/ObjectDetect";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {getCloneItems} from "libs/cloneItems"
import Alert from '@material-ui/lab/Alert';
import {ActionButtonCancel} from "components/ActionButtonCancel";
import {ActionButtonRequired} from "./ActionButtonRequired";


const text = {
    titleModalWindowDelete : 'Удалить'
}

const checkStatusDocments = (stateValues, roles, flagNotChange, cols) => {
    let flag = false
    let message = <></>
    const arrayMessage = []
    if(roles.includes('ROLE_DEBUG')){
        return {flag, message} 
    }
    if(stateValues.clone){
        return {flag, message} 
    }
    if(flagNotChange ){
        for(const item of flagNotChange){
            if(item.values.find(el => el === stateValues[item.field])){
                const value = stateValues[item.field];
                const col = cols.find(c => c.field === item.field)
                arrayMessage.push(<Typography variant="body2">{col.title}</Typography>)
                arrayMessage.push(<Box mx={1}>
                    <ObjectDetect noneValue={'---'} rows={{[item.field]: value}} field={item.field} item={{[item.field]: value}} row={col}/>
                </Box>)
                flag = true
            }
        }
    }
    if(arrayMessage.length){
        message = <Box>
            <Box display={'flex'} alignItems="center">
                {arrayMessage.map(el => el)}
            </Box>
            <Typography variant="body2">Редактирование документа заблокировано</Typography>
        </Box>
    } 

    return {flag, message} 
}

function SaveAndContinue(props) {
    const {state, handleSubmitForm, values, isRequired, isUpdate} = React.useContext(FormDataContext);
    const {flagBlockingChange} = props;
    const {submitting} = state;

    return <Button
        variant="contained" size="small"
        color={'primary'}
        onClick={event => handleSubmitForm(event, values, {continue: true})}
        disabled={!isRequired || isRequired.length || submitting || flagBlockingChange}
        startIcon={<SaveIcon/>}
    >
        {isUpdate
                ? 'Сохранить и продолжить'
                : 'Добавить и продолжить'}
    </Button>;
}

function SaveAndContinueOne(props) {
    const {state, handleSubmitForm, values, isRequired, isUpdate} = React.useContext(FormDataContext);
    const {flagBlockingChange} = props;
    const {submitting} = state;

    return <Button
            variant="contained" size="small"
            color={'primary'}
            onClick={event => handleSubmitForm(event, values, {continue: true})}
            disabled={!isRequired || isRequired.length || submitting || flagBlockingChange}
            startIcon={<SaveIcon/>}
    >
        Сохранить
    </Button>
}

function SaveForm(props) {
    const {state, handleSubmitForm, isRequired, isUpdate} = React.useContext(FormDataContext);
    const {flagBlockingChange} = props;
    const {submitting} = state;

    return <Button
        variant="contained" size="small"
        color={'primary'}
        onClick={event => handleSubmitForm(event, state.values)}
        disabled={!isRequired || isRequired.length || submitting || flagBlockingChange}
        startIcon={<SaveIcon/>}
    >
        {isUpdate
            ? 'Сохранить'
            : 'Добавить'}
    </Button>
}

function CloneForm(props) {
    const {state, handleSubmitForm, isRequired, isUpdate, setStateValues, changeIsUpdate} = React.useContext(FormDataContext);
    const {submitting} = state;
    const {cloneIems} = props


    const [confirm, setConfirm ] = React.useState(false)

    const handlerClone = () => {
        {
            changeIsUpdate();
                setStateValues (values => {
                    return {
                        ...values,
                        ...getCloneItems(values, cloneIems)
                    }
                })
        }
    }

    React.useEffect(()=> {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get("clone")) {
            handlerClone()
        }
    }, [])

    return (
        <>
            <ConfirmDialog
                show={confirm}
                title={'Подвердите действие'}
                text={`Копировать ${props.itemName} ${state.values.name}?`}
                onConfirm={() => {
                    handlerClone()
                    setConfirm(false)
                }}
                onCancel={() => {
                    setConfirm(false)
                }}
            />

            <Button
                variant="contained" size="small"
                color={'primary'}
                onClick={event => {
                    setConfirm(true)
                    //handleSubmitForm(event, state.values)
                }}
                disabled={!isRequired || isRequired.length || submitting}
                startIcon={<FileCopyIcon />}
            >
                {"Копировать"}
            </Button>
        </>
    )
}


function DeleteItemForm(props) {
    const {handleClose, canDelete, initialValues, dbId, itemName}
        = props;
    const {state, handleSubmitForm, isRequired, isUpdate} = React.useContext(FormDataContext);
    const {submitting} = state;

    const ButtonStyled = withStyles(() => (
        {
            root: {
                color:           "white",
                backgroundColor: "red",
            },
        }
    ))(Button);

    return <ConfirmDialog
        onConfirm={e => handleSubmitForm(e, {...state.values, isDeleted: true})}
        title = {`${text.titleModalWindowDelete} ${itemName}`}
    >
        <ButtonStyled
            variant="contained"
            size="small"
            disabled={submitting}
            startIcon={<DeleteIcon/>}
        >
            Удалить
        </ButtonStyled>
    </ConfirmDialog>
}

export function ActionButtons(props) {
    const defaultProps = {
        itemName : '',
        cloneIems: '',
        flagNotChange: false
    };

    const {handleClose, canDelete, itemName, initialValues, dbId, cloneIems, flagNotChange, url, canAdd,}
        = {...defaultProps, ...props};
    const {state, handleSubmitForm, values, requireFields, isUpdate} = React.useContext(FormDataContext);
    const me = React.useContext(MeContext)
    const {submitting} = state;
    let actionButtons = []


    if(!canAdd && !canDelete){
        actionButtons = [
            SaveAndContinueOne
        ]
    }else{
        actionButtons = [
            SaveForm,
            isUpdate && SaveAndContinue,
            isUpdate && cloneIems && CloneForm,
            ActionButtonCancel,
            isUpdate && canDelete && DeleteItemForm,
            ActionButtonRequired
        ]
    };

    const [flagBlockingChange, setFlagBlockingChange] = React.useState({
        flag: false,
        message: '' 
    })

    React.useEffect(()=>{
       typeof props.cols === "function" && Promise.resolve(props.cols(props, state.values, {}, {}))
       .then(data => {
            const {flag, message} = checkStatusDocments(state.values, me.roles, flagNotChange, data)
            setFlagBlockingChange({
                flag,
                message
            })
       }) 
        
    },[state.values.clone])

    return <div>
        <Grid container spacing={2}>
            {actionButtons
                .map((ActionButton, key) => ActionButton && <Grid item key={key}>
                    <ActionButton handleClose={handleClose} itemName={itemName} cloneIems={cloneIems} flagBlockingChange={flagBlockingChange.flag}/>
                </Grid>)}
        </Grid>
        {flagBlockingChange.flag
            && <Box mt={1}>
                <Alert severity="warning">{flagBlockingChange.message}</Alert>
            </Box>
        }
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <LinearProgress show={submitting}/>
            </Grid>
        </Grid>
    </div>;
}
