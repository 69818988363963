import React from 'react';

export default function renderFiasAddress(lookup, field, name = 'name') {
    // return console.log;
    return rowData => {
        const value = rowData[field]
            && lookup[rowData[field]]
            && lookup[rowData[field]][name];

        console.log(rowData[field]);

        const point_route = rowData['point_route_name'];
        return <React.Fragment>
            {value || (
                rowData[field] ? `? ${rowData[field]}` : `Нет данных`
            )}
            {point_route && <div>
                <b>{point_route.name}</b>
            </div>}
        </React.Fragment>;
    };
};