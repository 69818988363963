import React from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';

import {Alert} from '@material-ui/lab';
import useLoaderApi, {useLoaderApiList} from 'components/useLoaderApi';
import {asGUID} from 'libs/asGUID';
import {STATE} from 'libs/api';
import FieldFormatDate from 'components/FieldFormat/Date';
import SelectApi from 'components/FieldFormat/SelectApi';
import {EmailSend, ExelIcon, HtmlIcon, PdfIcon} from 'components/Icons';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {ATTACHED_FILE_TYPE} from 'config/userFiles';
import {FormDataContext} from 'FormDataContext';
import API from '../libs/api';
import MaterialTable from 'material-table';
import {config} from 'config'
import {TMSTableAction} from "./MTableExtend/TMSTableAction";
import IconButton from "@material-ui/core/IconButton";
import AttachFileOutlinedIcon  from '@material-ui/icons/AttachFileOutlined';
import LoadStateShow from "./LoadStateShow";
import  CheckAttach from "./CheckAttachPrintedForms"
import  ValidateDownload from "./ValidateDownloadPrintedForms"
import asArray from "libs/asArray";

const BoxContainerStyle = withStyles(() => (
    {
        root: {
            padding: "1rem 0 0 1rem",
        },
    }
))(Box)

const BoxContentStyle = withStyles(() => (
    {
        root: {
            padding: "1rem",
        },
    }
))(Box)




const useStyles = makeStyles((theme) => ({
    pBox: {
        padding: "1rem 0 0 1rem",
    },
    p1:   {
        padding: "1rem",
    },
    pR_1: {
        paddingRight: "1.9rem",
    }
}))






const DownloadFile = (props) => {
    console.log('DownloadFile', props);

    // const {apiName, guid, obj, type, getParameters, children} = props;
    const {action, data, disabled, size, rowData} = props;
    const {tooltip, type} = action;
    const {link, guid, format, title, searchName} = data;
    const hidden = !format.includes(type);
    const [download, setDownload] = React.useState(false);
    const {state: {values}} = React.useContext(FormDataContext);
    if (hidden) {
        return null
    }
    const onOpen = (e) => {
        setDownload(true);
        e.stopPropagation();
    }

    const onClose = (e) => {
        setDownload(false);
        e.stopPropagation();
    }

    

    const objGetParametrs = {
        signatoryDocTypeDocRequest:       values.signatoryDocTypeDocRequest,
        signatoryDocRequest:              asGUID(values.signatoryDocRequest),
        signatoryFacsimileDocRequest:     asGUID(values.signatoryFacsimileDocRequest),
        signatoryPostFacsimileDocRequest: asGUID(values.signatoryPostFacsimileDocRequest),
        cargoCostDocTypeDocRequest:       values.cargoCostDocTypeDocRequest,
        UPDDocTypeDocRequest:             values.UPDDocTypeDocRequest
    }

    const button = <IconButton
        size={size}
        color="inherit"
        disabled={disabled}
        onClick={onOpen}
    >
        <action.icon/>
    </IconButton>

    if(type === "attach"){
        return (
                <CheckAttach
                        {...props}
                        objGetParametrs={objGetParametrs}
                        onClose={onClose}
                        download={download}
                        onOpen={onOpen}
                       />

        )
    };
    return (<>
        {download && <ValidateDownload apiName={link} guid={guid} type={type} obj={objGetParametrs} onClose={onClose}/>}
        {tooltip
            ? <Tooltip title={tooltip}>{button}</Tooltip>
            : button
        }
    </>)
}


export default function Page(props) {

    const {cols} = props;
    const classes = useStyles();
    const {listTitle, itemValues: {documents, carrierAgreement}} = props
    const listDocuments = documents;

    const {state: {values, errors}, handleChange, changeValueState, setStateValues} = React.useContext(FormDataContext);

    const {
        signatoryDocTypeDocRequest,
        signatoryDocRequest,
        signatoryDateEndDocRequest,
        cargoCostDocTypeDocRequest,
        UPDDocTypeDocRequest,
        docDate,
    } = values;

    const [attachFilesList, setAttachFilesList] = React.useState({
        attachFilesList : null,
        loadStateAttachFilesList : STATE.LOADING

    })

    // const [attachFilesList, loadStateAttachFilesList,] = useLoaderApiList(
    //         'attached_files',
    //         {
    //             objLinkedName: "doc_requests",
    //             objLinkedGuid: values.guid
    //         },
    //         {
    //             onResponse : data => {
    //
    //                 return data.filter(el => el.otherData).map(el => {el.otherData = JSON.parse(el.otherData); return el})
    //             }
    //         })

    const [searchDataSignatory, setSearchDataSignatory] = React.useState(signatoryDateEndDocRequest && new Date(signatoryDateEndDocRequest).getTime() < new Date(docDate).getTime());


    const [init, setInit] = React.useState(false);


    React.useEffect(() => {
        setInit(true)
        new API()
                .get(
                        'attached_files',
                        {
                            objLinkedName: "doc_requests",
                            objLinkedGuid: values.guid
                        }
                ).
        then(data => {
            setAttachFilesList({
                attachFilesList:  data.filter(el => el.otherData).map(el => {el.otherData = JSON.parse(el.otherData); return el}),
                loadStateAttachFilesList: STATE.LOADED
            })
        })
                .catch(err => {
                    console.log(err)
                    setAttachFilesList({
                        attachFilesList: null,
                        loadStateAttachFilesList: STATE.ERROR
                    })
                });
    }, [])
//signatoryDocType
    React.useEffect(() => {
        const api = new API
        init && api.get(`contact_people/${asGUID(signatoryDocRequest)}`)
            .then(result => {
                setStateValues(values => {
                    return {
                        ...values,
                        signatoryDateStartDocRequest: result.signatoryDateStart || null,
                        signatoryDateEndDocRequest:   result.signatoryDateEnd || null,
                        signatoryDocTypeDocRequest:   result.signatoryDocType || '',
                    };
                })
            })
        // !signatoryDocRequest && changeValueState('signatoryDocTypeDocRequest', null)
        !signatoryDocRequest && setStateValues(values => {
            return {
                ...values,
                signatoryDocRequest:          null,
                signatoryDateStartDocRequest: null,
                signatoryDateEndDocRequest:   null,
                signatoryDocTypeDocRequest:   '',
            };
        });
    }, [signatoryDocRequest])


    React.useEffect(() => {
        setSearchDataSignatory(signatoryDateEndDocRequest && new Date(signatoryDateEndDocRequest).getTime() < new Date(docDate).getTime())
    }, [signatoryDateEndDocRequest, docDate])


    const [item, loadState] = useLoaderApi('contracts', carrierAgreement);

    const fixLocalization = (config, item) => {
        return {
            ...config,
            body: {
                ...config.body,
                emptyDataSourceMessage: !item.type ?  `Список пуст,так как в договоре ${item.name} не заполнено поле "Тип договора".` : config.body.emptyDataSourceMessage
            }
        }
    }


    switch (loadState) {
        case STATE.LOADED:
            const typeContract = item && item.type  && item.type !== '' ? item.type.toLowerCase() : '';
            return (
                <BoxContainerStyle>
                    {/* <Typography variant='body2'>{listTitle}</Typography> */}

                    <Box ml={1}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <SelectApi
                                    item={{
                                        api:       "contact_people",
                                        field:     "signatoryDocRequest",
                                        title:     "Подписант",
                                        apiFilter: (rowData) => (
                                            {
                                                legal: rowData.customer,
                                            }
                                        ),
                                    }}
                                    parent={{}}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box mt={1}>
                                    <TextField variant="outlined" InputLabelProps={{
                                        shrink: true,
                                    }} label="Документ для права подписи" size="small"
                                               value={signatoryDocTypeDocRequest || ''} fullWidth onChange={e => {
                                        handleChange({field: "signatoryDocTypeDocRequest"})('', e.currentTarget.value)
                                    }}/>
                                </Box>

                            </Grid>

                            <Grid item md={6} xs={12}>
                                <SelectApi
                                    item={{
                                        api:           "attached_files",
                                        field:         "signatoryFacsimileDocRequest",
                                        title:         "Факсимиле подписи",
                                        textSecondary: (props) => props.item && props.item.description,
                                        apiFilter:     (rowData) => (
                                            {
                                                'objLinkedGuid': asGUID(rowData.signatoryDocRequest),
                                                'objLinkedName': 'contact_persons',
                                                'documentType':  ATTACHED_FILE_TYPE.FACSIMILE
                                            }
                                        ),
                                    }}
                                    parent={{}}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SelectApi
                                    item={{
                                        api:           "attached_files",
                                        field:         "signatoryPostFacsimileDocRequest",
                                        title:         "Факсимиле печати",
                                        textSecondary: (props) => props.item && props.item.description,
                                        apiFilter:     (rowData) => (
                                            {
                                                'objLinkedGuid': asGUID(rowData.customer),
                                                'objLinkedName': 'legals',
                                                'documentType':  ATTACHED_FILE_TYPE.POST_FACSIMILE
                                            }
                                        ),
                                    }}
                                    parent={{}}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <FieldFormatDate item={{
                                    title: 'С какого числа действует документ для права подписи',
                                    field: 'signatoryDateStartDocRequest'
                                }}/>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FieldFormatDate item={{
                                    title: 'По какое число действует документ для права подписи',
                                    field: 'signatoryDateEndDocRequest'
                                }}/>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField variant="outlined" InputLabelProps={{
                                    shrink: true,
                                }} label="Стоимость груза" size="small" value={cargoCostDocTypeDocRequest || ''}
                                           fullWidth onChange={e => {
                                    handleChange({field: "cargoCostDocTypeDocRequest"})('', e.currentTarget.value)
                                }}/>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField variant="outlined" InputLabelProps={{
                                    shrink: true,
                                }} label="УПД" size="small" value={UPDDocTypeDocRequest || ''} fullWidth
                                           onChange={e => {
                                               handleChange({field: "UPDDocTypeDocRequest"})('', e.currentTarget.value)
                                           }}/>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box p={1} py={2}>
                        {searchDataSignatory ?
                            <Alert severity="error">{
                                "Дата действия документа для права подписи истекла"
                            }</Alert>
                            :
                            <></>
                        }
                    </Box>

                    <Box p={1}>
                        <LoadStateShow state={attachFilesList.loadStateAttachFilesList} error={''}>
                            <MaterialTable
                                    {...config.MaterialTable}
                                    title="Список документов"
                                    columns={[
                                        {
                                            title: 'Документ',
                                            field: 'title',
                                        },
                                    ]}
                                    data={typeContract
                                    ? asArray(listDocuments).filter(el => el.type
                                                    .find(it => typeContract.includes(it)
                                                    )
                                            )
                                            : []
                                    }
                                    actions={[
                                        {
                                            component: DownloadFile,
                                            tooltip:   'Открыть html документ (в новом окне)',
                                            icon:      HtmlIcon,
                                            type:      'html'
                                        },
                                        {
                                            component: DownloadFile,
                                            tooltip:   'Скачать документ в формате xls',
                                            icon:      ExelIcon,
                                            type:      'xls'
                                        },
                                        {
                                            component: DownloadFile,
                                            tooltip:   'Скачать в формате pdf',
                                            icon:      PdfIcon,
                                            type:      'pdf'
                                        },
                                        {
                                            component: DownloadFile,
                                            tooltip:   'Отправить на email',
                                            icon:      EmailSend,
                                            type:      'email'
                                        },
                                        {
                                            component: DownloadFile,
                                            tooltip:   'Добавить к заявке',
                                            icon:      AttachFileOutlinedIcon,
                                            type:      'attach',
                                            attachFilesList: attachFilesList.attachFilesList
                                        },
                                    ]}
                                    components={{
                                        Action: TMSTableAction
                                    }}

                                    options={config.MaterialTable.options}
                                    localization={
                                        fixLocalization(config.MaterialTable.localization, item)
                                    }
                            />
                        </LoadStateShow>

                    </Box>
                </BoxContainerStyle>


            );
        case STATE.ERROR:
            return (
                <BoxContainerStyle>
                    <Typography variant='h5'>{listTitle}</Typography>
                    <BoxContentStyle>
                        <Typography varuiant="body2">Ошибка загрузки документов</Typography>
                    </BoxContentStyle>
                </BoxContainerStyle>

            )
        default:
            return (
                <BoxContainerStyle>
                    <Typography variant='h5'>{listTitle}</Typography>
                    <BoxContentStyle>
                        <Typography varuiant="body2">Загрузка документов</Typography>
                        <CircularProgress/>
                    </BoxContentStyle>
                </BoxContainerStyle>
            )

    }

}