import {DeliveryIcon, DeliveryRequestIcon, ServiceIcon} from 'components/icons';
import {
    ColObjectName,
    ShowCurrency,
    ShowDate,
    ColObjectSimpleData,
    ColObjectNumber,
    ColObjectNameOptions
} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import Component from 'page/DeliveryRequest';
import React from 'react';
import optionsNDS from "./optionsNDS";
import {FORM_ACTION, FormDataContext} from "../FormDataContext";
import ConfirmDialog from "../components/ConfirmDialog";

const ndsDefault = 1

const checkStatus = (status) => {
    if (!status) return  true;
    const checkStatusDocumentArray = [
        "Стратегический",
        "Эталонный"
    ]
    const check = checkStatusDocumentArray.filter(el => status.includes(el))
    if(check.length){
        return  true
    }
    return  false
}

const checkCarriers = (items) => {
    if(items && items.carriers  && items.carriers.length){
        return  true
    }
    if(items
            && items.priceForDeliveries
            && items.priceForDeliveries.length
            && items.priceForDeliveries.filter(el => el.carrier && el.carrier.length).length ){
        return  true
    }
    if(items
            && items.priceForServices
            && items.priceForServices.length
            && items.priceForServices.filter(el => el.carrier && el.carrier.length).length ){
        return  true
    }
    return false
}

const ShowModalClearCarriers = props => {
    const {setState, handleChange} = React.useContext(FormDataContext);
    return (
            <ConfirmDialog
                    show={true}
                    title={"Смена статуса"}
                    text={"При сменене статуса будут очищены выбранные перевозчики"}
                    confirm={"Продолжить"}
                    cancel={"Отмена"}
                    onConfirm={(el) => {
                        setState(state => {
                            state.values.carriers = null;
                            const priceForDeliveries = state.values.priceForDeliveries;
                            const priceForServices = state.values.priceForServices;
                            if(priceForDeliveries && priceForDeliveries.length){
                                state.values.priceForDeliveries = priceForDeliveries.map(el => ({...el, carrier: null}));
                                state.values.priceForServices = priceForServices.map(el => ({...el, carrier: null}));
                            }

                            return state
                        })
                        handleChange({ field: "_showModalClearCarriers"})(el, false)
                    }}
                    onCancel={(el) => {
                        setState(state => {
                            state.values.status = state.values._historyStatus;
                            return state
                        })
                        handleChange({ field: "_showModalClearCarriers"})(el, false)
                    }}

            />
    )
}

export default {
    listTitle:     'Стоимость доставки',
    apiName:       'doc_prices',
    itemName:      'Стоимость доставки',
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title: 'Стоимость доставки',
        path:  '/delivery_request',
        Component,
        roles: [ROLES.ROLE_USER],
        icon:  DeliveryRequestIcon,
    },
    topColsBlocks: [
        {
            num:   1,
            title: 'Информация о документе',
            cols:  2,
            border: true
        },
        {
            num:   2,
            title: 'Параметры',
            cols:  2,
            border: true
        },
    ],
    initialValues: () => (
        {
            docNumber: 'Присвоится автоматически',
            status:    'Черновик',
            docDate:   new Date(),
            ndsRateString: 'БезНДС',
            ndsRateValue: ndsDefault

        }
    ),
    onFormDataAction: (values, props) => {
        const {old, action, value, field} = props;
        let _fix = {};
        let ndsRateValue = values.ndsRateValue || 1;
        switch (action){
            case FORM_ACTION.INIT:
                const nds = optionsNDS.find(o => o.rate === values.ndsRateValue);
                _fix.ndsRateString = nds ? nds.id : optionsNDS.find(o => o.rate === ndsDefault).id;
                _fix._historyStatus = values.status;
                break;
            case FORM_ACTION.CHANGE:
                if(field == "status"){
                    if(checkStatus(value) && checkCarriers(values)){
                        _fix._showModalClearCarriers = true
                    }else{
                        _fix._historyStatus = value
                    }
                }
                if(field == "ndsRateString") {
                    const nds = optionsNDS.find(o => o.id === value);
                    _fix.ndsRateValue = nds ? nds.rate : ndsRateValue;
                }
                break;
            case FORM_ACTION.SUBMIT:

                break;
        }
        return {...values, ..._fix}

    },
    colsInTab:     true,
    cols:  (props, items) => {
        return [
            {
                title:        'Номер документа',
                field:        'docNumber',
                hidden:       {xsUp: true},
                disabled:     true,
                cols:         2,
                topColsBlock: 1,
            },
            {
                title:        'Номер документа клиента',
                field:        'clientDocNumber',
                cols:         2,
                topColsBlock: 1,
                // hidden:       true,
            },
            {
                title:        'Дата документа/начало действия',
                field:        'docDate',
                object:       ShowDate,
                format:       FieldFormat.FORMAT_DATETIME_LOCAL,
                cols:         2,
                topColsBlock: 1,
            },
            {
                title:        'Статус',
                field:        'status',
                required:     true,
                topColsBlock: 1,
                format:       FieldFormat.FORMAT_SELECT_API,
                api:          'enum_delivery_request_statuses',
                object:       ColObjectName,
                cols:         2,
            },
            {
                title:        'Комментарий',
                field:        'comment',
                topColsBlock: 1,
            },
            {
                title:        'Валюта',
                object:       ShowCurrency,
                format:       FieldFormat.FORMAT_SELECT_API,
                api:          'currencies',
                field:        'currency',
                cols:         2,
                topColsBlock: 2,
            },
            {
                title:        'Ставка НДС',
                field:        'ndsRateString',
                format:       FieldFormat.FORMAT_SELECT,
                options:      optionsNDS,
                object:       ColObjectNameOptions,
                cols:         2,
                topColsBlock: 2,
                // hidden: true
            },
            {
                title:        'Заказчик перевозки',
                api:          'legals',
                apiFilter: () => (
                        {isCarrier: false}
                ),
                field:        'customer',
                format:       FieldFormat.FORMAT_SELECT_API,
                object:       ColObjectName,
                topColsBlock: 2,
            },


            {
                title:        'Перевозчики',
                field:        'carriers',
                api:          'legals',
                multiple:     true,
                format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                topColsBlock: 2,
                object:       ColObjectName,
                disabled:   checkStatus(items.status),
                apiFilter:    () => (
                // hidden:       true,
                        {'exists[contragent]': true}
                ),
            },
            {
                format: items && items._showModalClearCarriers ? FieldFormat.FORMAT_CUSTOM : FieldFormat.FORMAT_NONE,
                custom: ShowModalClearCarriers,
                hidden: true,
                topColsBlock: 1,
            }
        ]
    },

    subItems: {
        style: 'tabs',
        list:  [
            {
                listTitle: 'Доставка',
                field:     'priceForDeliveries',
                icon:      <DeliveryIcon/>,
                cols:  (props) => {
                    return [
                        {
                            title:         'Из точки маршрута',
                            field:         'fromPoint',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                        },
                        {
                            title:         'до Направления',
                            field:         'toDirection',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                        },
                        {
                            title: 'Стоимость',
                            field: 'servicePrice',
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            object: ColObjectNumber

                        },
                        {
                            title: 'Время для поиска',
                            field: 'timeForSearch',
                            format: FieldFormat.FORMAT_NUMBER,
                            object: ColObjectSimpleData,
                            measureText: '(мин)',
                            minValue: 0
                        },
                        {
                            title: 'Время для доставки',
                            field: 'timeForDelivery',
                            format: FieldFormat.FORMAT_NUMBER,
                            object: ColObjectSimpleData,
                            measureText: '(мин)',
                            minValue: 0
                        },
                        {
                            title:  'Вид транспорта',
                            field:  'typedTransports',
                            multiple:     true,
                            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                            api:          'typed_transports',
                            object:       ColObjectName,
                            // hidden:       true,
                            required: true,
                        },
                        {
                            title: 'Перевозчик',
                            field: 'carrier',
                            api: 'legals',
                            apiFilter: () => (
                                    { 'exists[contragent]': true }
                            ),
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName,
                            disabled: checkStatus(props.item.status),
                        },
                    ]
                }
            },
            {
                listTitle: 'Услуги ',
                field:     'priceForServices',
                icon:      <ServiceIcon/>,
                cols: (props) => {
                    return [
                        {
                            title: 'Услуга',
                            field: 'service',
                            api: 'services',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName,
                        },
                        {
                            title: 'Стоимость',
                            field: 'servicePrice',
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            object: ColObjectNumber

                        },
                        {
                            title: 'Перевозчик',
                            field: 'carrier',
                            api: 'legals',
                            apiFilter: () => (
                                    { 'exists[contragent]': true }
                            ),
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName,
                            disabled: checkStatus(props.item.status),
                        },
                    ]
                }
            },
            /*{
                listTitle: 'Для перевозчиков',
                field:     'carriers',
                cols:      async () => {
                    const [
                              lookupCarrier,
                          ] = await Promise.all([
                        ApiDataLoadLookup('/api/legals', 'guid', 'name'),
                    ]);

                    return [
                        {
                            title:  'Перевозчик',
                            field:  'carrier',
                            lookup: lookupCarrier
                        },
                    ];
                },
            }*/
        ],
    },
};
