import {config} from 'config';
import React from "react";
import FormEditCreateTabs from "../components/FormEditCreateTabs";
import HOCLoadItem from "../components/HOCLoadItem";
import LoadStateShow from "../components/LoadStateShow";
import request from "superagent";
import {MeContext} from "../meContext";

const Page  = (props) => {

    const {item, loadState} = props

    const {reload} = React.useContext(MeContext)

    const handleSubmit = (values, action) => {
        return request
                .post(`/api/profile`)
                .set('accept', 'application/json')
                .send(values)
                .then(result => {
                    if( result.status && result.status === 200){
                        console.log("push2", result)
                        reload()
                    }
                    return result
                })

    }

    return  (
            <LoadStateShow  state={loadState} error={"Ошибка работы сервиса"}>
                <FormEditCreateTabs
                        {...props}
                        {...config.settingsProfile}
                        handleSubmit={handleSubmit}
                        canEdit={true}
                        canAdd={false}
                        canDelete={false}
                        open={true}
                        initialValues={item.defaultSettings}

                />

            </LoadStateShow>
    )

}

export default  HOCLoadItem({path: '/api/profile'})(Page)