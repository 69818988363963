import React from "react";
import {FormDataContext} from "../FormDataContext";
import IconButton from "@material-ui/core/IconButton";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    Grid, Tooltip,
    Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import  ValidateDownload from "./ValidateDownloadPrintedForms";
import {withStyles} from "@material-ui/core/styles";
import DialogWindow from  './CheckAttachPrintedFormsDialogWindow'

const TypographyBold = withStyles(() => (
        {
            root: {
                color: 'black',
            }
        }
))(Typography)

const TypographyMarginBottom_1 = withStyles(() => (
        {
            root: {
                color: "black",
                marginBottom: "0.5rem"
            }
        }
))(Typography)

const CheckAttachPrintedForms = (props) => {
    const {action, data, disabled, size, rowData, onOpen, download, objGetParametrs, onClose} = props
    const {tooltip, type} = action;
    const {link, guid, searchName} = data;
    const {state: {values}} = React.useContext(FormDataContext);
    const [flagCheck, setFlagCheck] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [continueAction, setContinueAction] = React.useState(false);
    const {attachFilesList} = action;

    React.useEffect(()=>{

        setFlagCheck(attachFilesList.filter(el => el.otherData.formType === searchName).shift())
    }, [attachFilesList]);

    const onClick = (e) => {
        if(flagCheck){
            setModal(true)
            return
        }
        onOpen(e)
    };

    const onConfirm = (e) => {
        setModal(false)
        setContinueAction(true)
        onOpen(e)
    };

    const button = <IconButton
            size={size}
            color="inherit"
            disabled={disabled}
            onClick={onClick}
    >
        <action.icon/>
    </IconButton>


    return (
            <>
                <>
                    {download && !flagCheck  && <ValidateDownload apiName={link} guid={guid} type={type} obj={objGetParametrs} onClose={onClose}/>}
                    {flagCheck && <DialogWindow
                            title={"Подтвердить замену документа"}
                            onConfirm={onConfirm}
                            onCancel={() => {setModal(false); }}
                            text={<>
                                <Grid container spacing={2} direction="column">
                                    <Grid item container xs={12}>
                                        <Grid xs={3}>
                                            <Typography>
                                                Документ
                                            </Typography>
                                        </Grid>
                                        <Grid xs={9}>
                                            <TypographyMarginBottom_1>{`${rowData.title}`}</TypographyMarginBottom_1>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <Grid xs={3}>
                                            <Typography>
                                                Имя файла
                                            </Typography>
                                        </Grid>
                                        <Grid xs={9}>
                                            <TypographyMarginBottom_1>{`${flagCheck.name}`}</TypographyMarginBottom_1>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                            <TypographyBold>Уже прикреплён к заявке на вкладке "Файлы".</TypographyBold>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <TypographyBold>Заменить?</TypographyBold>
                                    </Grid>
                                </Grid>
                            </>}
                            show={modal}
                    />}
                    {tooltip
                            ? <Tooltip title={tooltip}>{button}</Tooltip>
                            : button
                    }
                    {download && continueAction  && <ValidateDownload apiName={link} guid={guid} type={type} obj={objGetParametrs} onClose={onClose}/>}
                </>
            </>
    )

}

export  default CheckAttachPrintedForms