import React from 'react';
import AdminShow from 'components/FieldFormat/AdminShow';
import {
    AddIcon,
} from 'components/icons';
import {SubFormEditCreate} from 'components/SubFormEditCreate';
import {config} from 'config';
import request from 'superagent';
import RequestForm from 'components/RequestForm';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';

export default function FormMultiElements(props) {
    const {api, label, onChange, apiFilter, item, errors} = props;
    const [data, setData]                                 = React.useState(null);
    
    const {subConfig} = item;
    const configSub   = config[subConfig];
    
    React.useEffect(() => {
        // запрос только если есть фильтры
        if (data || !apiFilter) {
            return;
        }
        (
            async () => {
                const response = await request.get(`/api/${api}`).set('accept', 'application/json').query(apiFilter);
                setData([...response.body]);
            }
        )();
    }, [data, apiFilter, api]);
    
    const subFormHandleChange = key => values => {
        // console.log('in subFormHandleChange', values);
        data[key] = values;
        setData(data);
        onChange(null, data);
        // onChange(null, {
        //     [values],
        // });
    };
    
    let showData = data || [{}];
    
    const addNew = () => {
        setData([...showData, {}]);
    };
    
    // return <pre>label:{JSON.stringify(configSub.col)}</pre>;
    return <React.Fragment>
        <Typography variant={'subtitle1'}>{label}</Typography>
        <Grid container spacing={3}>
            {showData.map((item, key) => <Grid key={key} item xs={12} md={4}>
                <Card>
                    <CardContent>
                        <AdminShow>SubFormEditCreate3</AdminShow>
                        <SubFormEditCreate
                            dbId={'guid'}
                            subFormHandleChange={subFormHandleChange(key)}
                            // handleSubmit={handleSubmit(apiName)}
                            // handleClose={openDialogEdit(false)}
                            // onSuccess={loadData}
                            initialValues={item}
                            subErrors={errors && errors[key]}
                            FormComponent={RequestForm}
                            cols={configSub.cols}
                        />
                    </CardContent>
                </Card>
            </Grid>)}
            {<Grid item xs={12} md={4}><Button
                aria-label="Добавить"
                title={'Добавить'}
                // variant={'contained'}
                // color={'primary'}
                onClick={addNew}
                startIcon={<AddIcon/>}
            >
                Добавить
            </Button></Grid>}
        </Grid>
    </React.Fragment>
        ;
    
}
