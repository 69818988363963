import {Badge} from '@material-ui/core';
import {FormatIndentDecrease, FormatIndentIncrease, Timeline, VisibilityOutlined} from '@material-ui/icons';
import {SubFormBiddings} from 'components/FieldFormat/SubFormBiddings';
import FormLoadSub  from 'components/FormLoadSub';
import {BiddingIcon, ServiceIcon} from 'components/icons';
import detailPanelRender2and1 from 'components/MTableExtend/detailPanelRender2and1';
import {
    ApiDataLoadLookup,
    ColObjectNameOther,
    ColObjectName,
    ColBoolean,
    ColObjectShowDateTime,
    ColObjectOptionsName,
    ColObjectSimpleData
} from 'components/showItems';
import TabRequestAccesses from 'components/TabRequestAccesses';
import bidding from 'config/bidding';
import {FieldFormat} from 'config/FieldFormat';
import React from 'react';
import {onFormDataActionNestedRoutePlace} from "config/docRequest_routePlaces";
import {asGUID} from 'libs/asGUID';

export default [
    {
        listTitle: 'Маршрут',
        field:     'routePlaces',
        icon:      <Timeline/>,
        onFormDataAction: onFormDataActionNestedRoutePlace,
        onFormDataAction: (values, props) => {
            console.log('onFormDataAction nested', values)
            return values;
        },
        detail:    [
            values => ({
                render: detailPanelRender2and1,
                colsSplit: 1,
                tooltip: 'Погрузка',
                icon:    () => <Badge badgeContent={(values.loadCargo||[]).length}
                                      color="primary"><FormatIndentIncrease/></Badge>,
                field:   'loadCargo',
                // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
                cols: async () => {
                    const [lookupCargo, lookupMeasure]
                              = await Promise.all([
                        ApiDataLoadLookup('/api/cargo_types', 'guid', false),
                        ApiDataLoadLookup('/api/measures', 'guid', false),
                    ]);

                    const getMeasure = api => {
                        const guid = api && api.substr(-36);
                        return lookupMeasure[guid]
                            ? 'Упаковка: ' + lookupMeasure[guid].name
                            : guid;
                    };

                    return [
                        {
                            title:         'Тип груза',
                            field:         'cargoType',
                            api:           'cargo_types',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName,
                            onRowChange:   (data, {field, value}) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = {...data};
                                if (cargo.measureValue) {
                                    // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.cargoValue = (data.qtyInMeasure * cargo.measureValue).toFixed(2);
                                }
                                if (cargo.measureWeight) {
                                    // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.cargoMass = (data.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                }
                                if(cargo.okpd2){
                                    update.cargoOkpd2 = cargo.okpd2
                                }

                                return update;
                            },
                        },
                        {
                            title: 'Упаковок',
                            field: 'qtyInMeasure',
                            format: FieldFormat.FORMAT_NUMBER,
                            object:        ColObjectSimpleData,
                            onRowChange:   (data, {field, value}) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = {...data};
                                if (cargo.measureValue) {
                                    // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.cargoValue = (value * cargo.measureValue).toFixed(2);
                                }
                                if (cargo.measureWeight) {
                                    // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.cargoMass = (value * cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                }
                                return update;
                            },
                        },
                        {
                            title: 'Мест',
                            field: 'qtyInCargoPlaces',
                            format: FieldFormat.FORMAT_NUMBER,
                            object: ColObjectSimpleData,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.cargoPlaceValue) {
                                    // - Объем = [Количество мест] * [ТипГруза].[ОбъемМеста]
                                    // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.cargoValue = (value * cargo.cargoPlaceValue).toFixed(2);
                                }
                                if (cargo.cargoPlaceWeight) {
                                    // - Вес = [Количество мест] * [ТипГруза].[ВесМеста]
                                    // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                    update.cargoMass = (value * cargo.cargoPlaceWeight).toFixed(2);
                                }
                                if (cargo.measureValue) {
                                    // - Упаковок = [ОБъем] / [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (update.cargoValue / cargo.measureValue).toFixed(2);
                                }
                                return update;
                            },
                        },
                        {
                            title: 'Объем, м^3',
                            field: 'cargoValue',
                            object: ColObjectSimpleData,
                            measureText: '(м^3)',
                            // editComponent: MTableTextField,
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.measureValue) {
                                    // 4. При изменении [Объем] считать:
                                    // - Упаковок = [Объем] / [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (value / cargo.measureValue).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [Объем] / [ТипГруза].[ОбъемМеста]
                                    // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (value / cargo.cargoPlaceValue).toFixed(2);
                                }
                                if (cargo.measureValue) {
                                    // - Вес = [Количество упаковок] * [ТипГруза].[ВесУпаковки]
                                    // если [ТипГруза].[ВесМеста] = 0 то не пересчитывать
                                    update.cargoMass = (update.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                } else if (0 === Number(cargo.measureWeight)) {
                                    // если [ТипГруза].[ВесУпаковки] = 0, то
                                    // Вес = [Количество мест] * [ТипГруза].[ВесМеста],
                                    update.cargoMass = (update.qtyInCargoPlaces * cargo.cargoPlaceWeight).toFixed(2);
                                }
                                return update;
                            },

                        },
                        {
                            title: 'Вес, кг',
                            field: 'cargoMass',
                            object: ColObjectSimpleData,
                            measureText: '(кг)',
                            // editComponent: MTableTextField,
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.measureWeight) {
                                    // 5. При изменении [Вес] считать:
                                    // - Упаковок = [Вес] / [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (value / cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceWeight) {
                                    // - Мест = [Вес] / [ТипГруза].[ВесМеста] // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (value / cargo.cargoPlaceWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Объем = [Количество упаковок] * [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемМеста] = 0 то не пересчитывать
                                    update.cargoValue = (update.qtyInMeasure * cargo.measureValue).toFixed(2);
                                } else if (0 === Number(cargo.measureValue)) {
                                    // если [ТипГруза].[ОбъемУпаковки] = 0,
                                    // то Вес = [Количество мест] * [ТипГруза].[ОбъемМеста],
                                    update.cargoValue = update.qtyInCargoPlaces * cargo.measureValue;


                                }
                                return update;
                            },
                        },
                    ];
                },
            }),
            values => ({
                tooltip: 'Разгрузка',
                render: detailPanelRender2and1,
                colsSplit: 1,
                field:   'unloadCargo',
                icon:    () => <Badge badgeContent={(values.unloadCargo||[]).length}
                                      color="primary"><FormatIndentDecrease/></Badge>,
                // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
                cols: async () => {
                    const [lookupCargo, lookupMeasure]
                              = await Promise.all([
                        ApiDataLoadLookup('/api/cargo_types', 'guid', false),
                        ApiDataLoadLookup('/api/measures', 'guid', false),
                    ]);

                    const getMeasure = api => {
                        const guid = api && api.substr(-36);
                        return lookupMeasure[guid]
                            ? 'Упаковка: ' + lookupMeasure[guid].name
                            : guid;
                    };

                    return [
                        {
                            title:         'Тип груза',
                            field:         'cargoType',
                            api:           'cargo_types',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName,
                            onRowChange:   (data, {field, value}) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = {...data};
                                if (cargo.measureValue) {
                                    // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.cargoValue = (data.qtyInMeasure * cargo.measureValue).toFixed(2);
                                }
                                if (cargo.measureWeight) {
                                    // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.cargoMass = (data.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                }
                                if(cargo.okpd2){
                                    update.cargoOkpd2 = cargo.okpd2
                                }

                                return update;
                            },
                        },
                        {
                            title: 'Упаковок',
                            field: 'qtyInMeasure',
                            format: FieldFormat.FORMAT_NUMBER,
                            object:        ColObjectSimpleData,
                            onRowChange:   (data, {field, value}) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = {...data};
                                if (cargo.measureValue) {
                                    // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.cargoValue = (value * cargo.measureValue).toFixed(2);
                                }
                                if (cargo.measureWeight) {
                                    // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.cargoMass = (value * cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                }
                                return update;
                            },
                        },
                        {
                            title: 'Мест',
                            field: 'qtyInCargoPlaces',
                            format: FieldFormat.FORMAT_NUMBER,
                            object: ColObjectSimpleData,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.cargoPlaceValue) {
                                    // - Объем = [Количество мест] * [ТипГруза].[ОбъемМеста]
                                    // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.cargoValue = (value * cargo.cargoPlaceValue).toFixed(2);
                                }
                                if (cargo.cargoPlaceWeight) {
                                    // - Вес = [Количество мест] * [ТипГруза].[ВесМеста]
                                    // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                    update.cargoMass = (value * cargo.cargoPlaceWeight).toFixed(2);
                                }
                                if (cargo.measureValue) {
                                    // - Упаковок = [ОБъем] / [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (update.cargoValue / cargo.measureValue).toFixed(2);
                                }
                                return update;
                            },
                        },
                        {
                            title: 'Объем, м^3',
                            field: 'cargoValue',
                            object: ColObjectSimpleData,
                            measureText: '(м^3)',
                            // editComponent: MTableTextField,
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.measureValue) {
                                    // 4. При изменении [Объем] считать:
                                    // - Упаковок = [Объем] / [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (value / cargo.measureValue).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Мест = [Объем] / [ТипГруза].[ОбъемМеста]
                                    // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (value / cargo.cargoPlaceValue).toFixed(2);
                                }
                                if (cargo.measureValue) {
                                    // - Вес = [Количество упаковок] * [ТипГруза].[ВесУпаковки]
                                    // если [ТипГруза].[ВесМеста] = 0 то не пересчитывать
                                    update.cargoMass = (update.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                } else if (0 === Number(cargo.measureWeight)) {
                                    // если [ТипГруза].[ВесУпаковки] = 0, то
                                    // Вес = [Количество мест] * [ТипГруза].[ВесМеста],
                                    update.cargoMass = (update.qtyInCargoPlaces * cargo.cargoPlaceWeight).toFixed(2);
                                }
                                return update;
                            },

                        },
                        {
                            title: 'Вес, кг',
                            field: 'cargoMass',
                            object: ColObjectSimpleData,
                            measureText: '(кг)',
                            // editComponent: MTableTextField,
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            onRowChange: (data, { field, value }) => {
                                const cargo = lookupCargo[asGUID(data.cargoType)];
                                if (!cargo) {
                                    return data;
                                }
                                let update = { ...data };
                                if (cargo.measureWeight) {
                                    // 5. При изменении [Вес] считать:
                                    // - Упаковок = [Вес] / [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                    update.qtyInMeasure = (value / cargo.measureWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceWeight) {
                                    // - Мест = [Вес] / [ТипГруза].[ВесМеста] // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                    update.qtyInCargoPlaces = (value / cargo.cargoPlaceWeight).toFixed(2);
                                }
                                if (cargo.cargoPlaceValue) {
                                    // - Объем = [Количество упаковок] * [ТипГруза].[ОбъемУпаковки]
                                    // если [ТипГруза].[ОбъемМеста] = 0 то не пересчитывать
                                    update.cargoValue = (update.qtyInMeasure * cargo.measureValue).toFixed(2);
                                } else if (0 === Number(cargo.measureValue)) {
                                    // если [ТипГруза].[ОбъемУпаковки] = 0,
                                    // то Вес = [Количество мест] * [ТипГруза].[ОбъемМеста],
                                    update.cargoValue = update.qtyInCargoPlaces * cargo.measureValue;


                                }
                                return update;
                            },
                        },
                    ];
                },
            }),
            values => ({
                render: detailPanelRender2and1,
                colsSplit: 1,
                field:   'services',
                tooltip: 'Услуги',
                icon:    () => <Badge badgeContent={(values.services||[]).length} color="primary"><ServiceIcon/></Badge>,
                // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
                cols: () => (
                    [
                       {
                           title:  'Услуга',
                           field:  'service',
                           api:    'services',
                           format: FieldFormat.FORMAT_SELECT_API,
                           object: ColObjectName,
                       },
                   ]
               ),
            }),
        ],
        cols:       (props, other) => {
           
            //const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
            //await sleep(2000); // need for correct draw table
            return [
                {
                    title:         'Адрес',
                    field:         'point',
                    api:           'fias_address_trees',
                    format: FieldFormat.FORMAT_FIAS_ADDRESS,
                    object:    ColObjectNameOther,
                },

                // {
                //     title:  'Дата прибытия',
                //     format: FieldFormat.FORMAT_DATETIME_LOCAL,
                //     field:  'dateFrom',
                //     object: ColObjectShowDateTime,
                // },
                {
                    title: 'Тип',
                    format:    FieldFormat.FORMAT_SELECT,
                    field:     'typePoint',
                    options: [
                        {
                        id:   'погрузка',
                        name: 'Погрузка',
                    },
                    {
                        id:   'разгрузка',
                        name: 'Разгрузка',
                    },
                    // {
                    //     id:   'загрузка-погрузка',
                    //     name: 'Загрузка и погрузка',
                    // },

                ],
                object: ColObjectOptionsName,
                },
                // {
                //     title:  'Дата убытия',
                //     format: FieldFormat.FORMAT_DATETIME_LOCAL,
                //     field:  'dateTo',
                //     object: ColObjectShowDateTime,
                // },

                {
                    title:    'key_id',
                    field:    'key_id',
                    editable: 'never',
                    hidden:   true,
                },
            ];
        },
    },
    {
        listTitle:      'Торги',
        Component:      FormLoadSub,
        helperTextForDisableButton : 'Для добавления новой торговой сессии необходимо сохранить текущий запрос стоимости.',
        cols:           {
            BiddingType: {
                title:   'Тип торгов',
                field:   'biddingType',
                format:  FieldFormat.FORMAT_SELECT,
                cols:    4,
                options: [
                    {
                        id:   'Запрос',
                        name: 'Запрос',
                    },
                ],
            },
        },
        AfterComponent: SubFormBiddings,
        item:           bidding,
        icon:           <BiddingIcon/>,
        canAdd:         true,
        field:          'biddings',
        api:            'biddings',
        apiFilter:      values => (
            {
                docLinkedGuid: values.guid || '00000000-0000-0000-0000-000000000000',
                docLinkedName: 'ЗапросСтоимости',
            }
        ),
    },
    {
        listTitle: 'Доступ',
        field:     'priceRequestAccesses',
        icon:      <VisibilityOutlined/>,
        // Component:      FormRequestSubApiLoader,
        // AfterComponent: SubFormLIstEdit,
        Component: TabRequestAccesses,
        cols: [
            {
                title: 'Перевозчик',
                // lookup:   lookupLegals,
                field: 'legal',
                api: 'legals',
                required: true,
                apiFilter: () => (
                    { 'exists[contragent]': true }
                ),
                format: FieldFormat.FORMAT_SELECT_API,
                object: ColObjectName,
            },
            {
                title: 'Видимая',
                field: 'visible',
                type: 'boolean',
                object: ColBoolean
            },
        ]
    },
];