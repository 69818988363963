import {ColContactInfo} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {FORM_ACTION} from 'FormDataContext';

export default {
    listTitle:        'Физические лица',
    apiName:          'individuals',
    onFormDataAction: (values, props) => {
        // console.log('individuals?', values, props);
        const { action, field } = props;
        const name = values.fioFull;
        switch (action) {
            case FORM_ACTION.INIT:
                const { drvLicenseCategories } = values;
                //todo: drvLicenseCategoriesFront
                const drvLicenseCategoriesFront = drvLicenseCategories && drvLicenseCategories.length ? drvLicenseCategories.split(',') : [];
                return { ...values, name, drvLicenseCategoriesFront };

            case FORM_ACTION.CHANGE:
                if (field === 'drvLicenseCategoriesFront') {
                    const { drvLicenseCategoriesFront } = values
                    const drvLicenseCategories = drvLicenseCategoriesFront ? drvLicenseCategoriesFront.join() : drvLicenseCategoriesFront
                    return { ...values, name, drvLicenseCategories }
                }
                return { ...values, name };
            default:
                return { ...values, name };
        }
    },
    cols:             async (props, value, {from, subForm}) => [
        // {field: 'edit', title: 'Редактировать', object: this.Edit, format:
        // RequestForm.FORMAT_NONE}, {field: 'guid', title: 'ID', format:
        // RequestForm.FORMAT_NONE},
        // {
        //     field:  'name',
        //     title:  'Имя',
        //     cols:   3,
        //     hidden: {mdDown: true},
        // },
        // {
        //     title:    'Фио',
        //     field:    'fio',
        //     cols:     3,
        //     hidden:   {mdDown: true},
        //     required: true,
        // },
        {
            title:  'Контакты',
            field:  'ContactInfo',
            object: ColContactInfo,
            format: FieldFormat.FORMAT_NONE,
            multi:  true,
            api:    'contact_infos',
            hidden: {xlDown: true},
        },
        {
            field: 'fioFull',
            cols:  3,
            title: 'Фио',
            required: true,
            hidden:   {mdDown: true},
        },
        {
            field:  'dayOfBirth',
            title:  'Дата рождения',
            format: FieldFormat.FORMAT_DATE,
            cols:   3,
            hidden: {mdDown: true},
        },
        {
            field:  'placeOfBirth',
            title:  'Место рождения',
            cols:   3,
            hidden: {mdDown: true},
        },
        {
            field:  'address',
            title:  'Адрес',
            cols:   3,
            hidden: {xsDown: true},
        },
        {
            field:  'passport',
            title:  'Паспорт',
            cols:   3,
            hidden: {xlDown: true},
        },
        {
            field:  'passportOrgan',
            title:  'Кем выдан паспорт',
            cols:   3,
            hidden: {xlDown: true},
        },
        {
            field:  'passportDate',
            title:  'Дата выдачи паспорта',
            format: FieldFormat.FORMAT_DATE,
            cols:   3,
            hidden: {xlDown: true},
        },
        {
            field:  'drvLicenseNumber',
            title:  'Водительские права',
            cols:   3,
            hidden: {xlDown: true},
            format: (subForm && subForm.sub === 'driver')
                        ? FieldFormat.FORMAT_INPUT
                        : FieldFormat.FORMAT_NONE,
        }, // 4
        {
             field: 'drvLicenseCategories',
             format: FieldFormat.FORMAT_NONE,
        },
        {
            field:  'drvLicenseCategoriesFront',
            title:  'Категория прав',
            cols:   3,
            hidden: {xlDown: true},
            format: (subForm && subForm.sub === 'driver')
                        ? FieldFormat.FORMAT_SELECT_API_CHECKBOX
                        : FieldFormat.FORMAT_NONE,
            options : [
                {
                    guid: 'A',
                    name: 'A' 
                },
                {
                    guid: 'B',
                    name: 'B' 
                },
                {
                    guid: 'C',
                    name: 'C' 
                },
                {
                    guid: 'D',
                    name: 'D' 
                },

            ],
        }, //2
        {
            field:  'drvLicenseDate',
            title:  'Дата выдачи прав',
            format: (subForm && subForm.sub === 'driver')
                        ? FieldFormat.FORMAT_DATE
                        : FieldFormat.FORMAT_NONE,
            cols:   3,
            hidden: {xlDown: true},
        }, //3
        {
            field:  'drvLicenseExpired',
            title:  'Срок окончания прав',
            format: (subForm && subForm.sub === 'driver')
                        ? FieldFormat.FORMAT_DATE
                        : FieldFormat.FORMAT_NONE,
            cols:   3,
            hidden: {xlDown: true},
        }, //3
        {
            title:        'ИНН',
            field:        'inn',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         3,
            hidden: {xlDown: true},
        },
        // {field: 'replicator', title: 'Репликатор', type: 'int'},
        // {
        //     field:  'isTest',
        //     title:  'Тестовое',
        //     object: ColBoolean,
        //     format: RequestForm.FORMAT_CHECKBOX,
        //     cols:   3,
        //     hidden: {xsDown: true},
        // },
        // {field: 'isHided', title: 'Скрыто', object: ColBoolean, format:
        // RequestForm.FORMAT_CHECKBOX, cols: 3}, {field: 'isDeleted', title:
        // 'Удалено', object: ColBoolean, format: RequestForm.FORMAT_CHECKBOX,
        // cols: 3}, {field: 'contactPerson', title: 'включено', object:
        // ColBoolean, format: RequestForm.FORMAT_CHECKBOX},
    ],
};