import AdminShow from 'components/FieldFormat/AdminShow';
import FormApiAutocomplete from 'components/FieldFormat/FormApiAutocomplete';
import FormApiAutocompleteOther from 'components/FieldFormat/FormApiAutocompleteOther';
import FormMultiElements from 'components/FormMultiElements';
import {FormDataContext} from 'FormDataContext';
import {apiSplit} from 'libs/apiSplit';
import React from 'react';

function MyComponent({item, value, values, error, handleChange, onLoadValue, apiFilter,setUserErrors, getOptionDisabled, textDisabled}) {
    const {title, api, required, helperText, hidden, ...other} = item;

    const otherField = 'otherField';

    // const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const FormApiComponent = otherField
        ? FormApiAutocompleteOther
        : FormApiAutocomplete;
    return <React.Fragment>
        <AdminShow>[SelectApi]</AdminShow>
        {item.multi
        ?
        <FormMultiElements
            item={item}
            label={title}
            value={value}
            api={api}
            apiFilter={apiFilter}
            required={required}
            error={!!error}
            errors={value}
            onChange={handleChange}
            InputLabelProps={{shrink: true}}
        />
        : <FormApiComponent
            {...other}
            item={item}
            setUserErrors={setUserErrors}
            label={title}
            value={value && apiSplit(value)}
            values={values}
            api={api}
            apiFilter={apiFilter}
            required={required}
            helperText={helperText}
            error={!!error}
            errors={error}
            onChange={handleChange}
            onLoadValue={onLoadValue}
            InputProps={{color: 'secondary'}}
            InputLabelProps={{shrink: true}}
            getOptionDisabled={getOptionDisabled}
            textDisabled={textDisabled}
        />}
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error
        && prevProps.values[prevProps.item.freeSoloField] === nextProps.values[nextProps.item.freeSoloField]
        && JSON.stringify(prevProps.apiFilter) === JSON.stringify(nextProps.apiFilter);
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {parent} = props;
    const {field,apiFilter,freeSoloField, getOptionDisabled, textDisabled}                                 = props.item;
    const {state: {values, errors}, handleChange, setStateValues, setUserErrors} = React.useContext(FormDataContext);

    const handleChangeLocal = (e) => {
        setStateValues(values => ({
            ...values,
            [field]: e.target.value,
            [freeSoloField]: e.target[freeSoloField],
            _items:   {
                ...values._items,
                [field]: e.target.item,
            },
        }));
        // Вызов для отработки расчетсв onFormDataAction и onRowChange
        return handleChange(props.item)(e, e.target.value);
    };

    const onLoadValue = (item) => {

        return setStateValues(values => ({
            ...values,
            _items:   {
                ...values._items,
                [field]: item,
            },
        }));
    };

    const apiFilterLocal = apiFilter && apiFilter(values, parent)
    const value = values && values[field];
    const error = values && errors && errors[field];

    return <MyComponentMemo
        value={value || ''}
        values={values}
        error={error || null}
        apiFilter={apiFilterLocal}
        handleChange={handleChangeLocal}
        onLoadValue={onLoadValue}
        setUserErrors={setUserErrors}
        getOptionDisabled={getOptionDisabled}
        textDisabled={textDisabled}
        {...props}
    />;
}

export default MyComponentMemoUse;