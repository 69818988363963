import {Button, Link} from "@material-ui/core";
import React from "react";
import Picture from "./Picture";
import {withStyles} from "@material-ui/core/styles";

const LinkStyled = withStyles(() => (
        {
            root: {
                cursor: "pointer",
                padding: "0.25rem"
            },
        }
))(Link);

const LinkActiveStyled = withStyles(() => (
        {
            root: {
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: 'underline',
                background: "#e9e4e4",
                padding: "0.25rem"
            },
        }
))(Link);


export const LinkMarcDown = (link, clickMarkDownLink, activeLink) =>  ({node, inline, className, ...props}) =>  {
    const {children, properties} = node;
    const value = children.shift().value
    if(properties.href && properties.href.includes('.WebHome')){
        const arrayLink = properties.href.split('.');
        arrayLink.shift();
        arrayLink.pop();
        const link = `${arrayLink.join('/')}/`
        return (
                <>
                    {
                        link === activeLink
                                ? <LinkActiveStyled
                                        onClick={clickMarkDownLink(link)}>{value}</LinkActiveStyled>
                                : <LinkStyled
                                        onClick={clickMarkDownLink(link)}>{value}</LinkStyled>
                    }
                </>

        )

    }
    if(value.includes('.WebHome')){
        const [title, linkComponent] = value.split('|');
        const [first, linkValue] = linkComponent.split('.');
        return (
                <LinkStyled
                        onClick={clickMarkDownLink(`${link}${linkValue}`)}>{title}</LinkStyled>
        )
    };
    return (
            <LinkStyled
                    onClick={clickMarkDownLink(properties.href)}>{value}</LinkStyled>
    )
}

export const PMarcDown = (link) => (props) =>{
    const {children} = props
    const [first] = children
    const value = `${first}`;
    if(value.includes('![[')){
        const [valueImg] = value.split('|')
        const soursImg = valueImg.replace('![[', '');
        return <Picture link={link} soursImg={soursImg} />
    }
    return <p>{first}</p>
}