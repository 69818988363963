import {CircularProgress, TextField} from '@material-ui/core';
import useLoaderApi from 'components/useLoaderApi';
import {FormDataContext} from 'FormDataContext';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormEditCreate from 'components/FormEditCreate';
import RequestForm from 'components/RequestForm';
import {FieldFormat} from 'config/FieldFormat';
import API, {STATE} from 'libs/api';
import ErrorBoundary from 'components/ErrorBoundary';
import {asGUID} from 'libs/asGUID';

import MenuIcon from '@material-ui/icons/Menu';
import EndAdornmentComponent from "../EndAdornmentComponent";

const useStyles = makeStyles((theme) => (
    {
        root:       {
            padding:         '0 4px',
            display:         'flex',
            alignItems:      'center',
            // width: 400,
            backgroundColor: 'transparent',
            position: 'relative'

        },
        disabledInput: {
            "& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: "pink !important"
            }
        },
        input:      {
            marginLeft: theme.spacing(1),
            flex:       1,
        },
        tiltle: {
            position: 'absolute',
            fontSize: "0.75em",
            color: "#949394",
            background: "#ffffff",
            padding: "0 9px",
            top: "-10px"
        },
        iconButton: {
            padding: 10,
        },
        divider:    {
            height: 28,
            margin: 4,
        },
    }
));

/**
 * @example for custom
 * {
        "country": "test",
        "useCustom": "country"
    }
 */
export const mapSuggestions = () => (data,val, {suggestion}) => {
    //todo: work fias
    let levelsLook    = [
        'country', 'region', 'area', 'city', 'settlement', 'street', 'house', 'flat',
    ];
    const levels = levelsLook.map(lvl => {

            return {
                level:            lvl,
                kladr_id:         data[lvl + '_kladr_id'] || '',
                fias_id:          data[lvl + '_fias_id'] || '',
                object_type:      data[lvl + '_type'] || '',
                object_type_full: data[lvl + '_type_full'],
                object:           lvl === 'house' && data['block']
                                    ? `${data[lvl]}  ${data['block_type']} ${data['block']}`
                                    : data[lvl] || '',
                object_with_type: data[lvl + '_with_type'],
                geo_lat:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lat']
                                      : 0,
                geo_lon:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lon']
                                      : 0,
            };
        })
        .filter(v => v)
        .filter(l => l.object !== '' );

    console.log('mapSuggestionslevels', levels, data)
    const last    = levels[levels.length - 1];
    const address = [ ...levels.map(o => {
        return o.object ?
         `${o.object_type} ${o.object}`
        : ''
        })]
        .filter( e => e !== '' )
        .join(', ');
    return {
        address:   address,
        name:      address,
        latitude:  data.geo_lat,
        longitude: data.geo_lon,
        jsonData: data,
        levels,
        ...last,
        _log:      console.log(data),
        fias_address_id: suggestion.fias_address_id,
        unrestricted_value: suggestion.unrestricted_value,
        custom : levels.length  === 1 ? true: false
    }


};

const api = new API();

const search = {
    cols: [
        {
            title:          'Поиск',
            field:          'address',
            hidden:         true,
            mapSuggestions: mapSuggestions('address'),
            format:         FieldFormat.FORMAT_SELECT_DADATA,
            freeSolo:       true,
            helperText:     'Поиск адреса точки маршрута',
            placeholder:    'Для автоматического заполнения начните вводить адрес',
            textSecondary: ({suggest}) => suggest && suggest.fias_address_id && suggest.unrestricted_value,
            api:            'search/address',
            cols:           1,
        }
    ],
};

const WrapperTextField = (props) => {
    // Данная конструкция нужна, чтоб выводить Tooltip.
    const {toolTip, value, ...other} = props
    return (
        toolTip && value
        ? <Tooltip title={value} interactive placement="top"><TextField value={value} {...other}/></Tooltip>
        : <TextField value={value} {...other}/>
    )
}

export default function FiasAddress(props) {
    console.log('FiasAddress', props)
    const {item} = props;
    const {title, required, helperText, hidden, toolTip, ...other} = item;

    const classes         = useStyles();
    const [open, setOpen] = React.useState(false);
    // const {state: {values, errors}, handleChange,setStateValues} = React.useContext(FormDataContext);

    const {field, fieldShow} = {
        fieldShow: 'name',
        ...item,
    };

    const {state: {values, errors}, handleChange, setState} = React.useContext(FormDataContext);

    const value = values && values[field];
    const error = errors && errors[field];

    const [loadedValue, loadState, forceReloadData] = useLoaderApi(item.api, asGUID(value));
    const loading = loadState === STATE.LOADING;
    const asDeletedStyle = loadedValue && loadedValue.isDeleted
        ? {
            style: {
                textDecoration: 'line-through',
            }
        }
        : {}


    // const value = props.value;
    // const {title, api, apiFilter, required, ...other} = props.columnDef;
    const handleOpen  = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let onSuccess               = ({body}) => {
        const field = props.item.field;
        // выставление полей для избранного
        setState(state => ({
            ...state,
            values: {
                ...state.values,
                [field + '_route_name']: body && body.unrestricted_value,
            }
        }));
        handleChange(props.item)(null, body.guid);
        forceReloadData();
        handleClose();
    };
    const FullScreenWrapperNone = 'div';
    // const FullScreenWrapper = 'div';
    return <ErrorBoundary info={'paper'}>
            <WrapperTextField
                toolTip = {true}
            onChange={handleChange}
            label={title}
            fullWidth
            variant="outlined"
            margin={'dense'}
            error={!!error}
            disabled={true || loading}
            required={item.required}
                className={item.required ? classes.disabledInput : ''}
            helperText={helperText}
                InputLabelProps={{ shrink: true }}
            value={loadedValue && loadedValue[fieldShow]}
            InputProps={{
                // ...props.InputProps,
                ...asDeletedStyle,
                endAdornment: (
                        <React.Fragment>
                            {loading && <CircularProgress
                                    color="inherit"
                                    size={20}
                            />}
                            {/*{props.InputProps.endAdornment}*/}
                            {/*{valueSelected && <ItemInfo item={item} itemLoaded={loadedValue}/>}*/}
                            <IconButton color="primary" size={"small"}
                                        aria-label="directions"
                                        onClick={handleOpen}
                            >
                                <MenuIcon />
                            </IconButton>
                        </React.Fragment>
                ),

            }}
            {...other}
        />

        {/*<Paper variant="outlined" className={classes.root}>*/}
        {/*    <div className={classes.input}>{loadedValue && loadedValue[fieldShow]}</div>*/}
        {/*    {loadState === STATE.LOADING && <CircularProgress color="inherit" size={16}/>}*/}
        {/*    <Divider className={classes.divider} orientation="vertical"/>*/}
        {/*    <IconButton color="primary"*/}
        {/*                className={classes.iconButton} aria-label="directions"*/}
        {/*                onClick={handleOpen}*/}
        {/*    >*/}
        {/*        <MenuIcon/>*/}
        {/*    </IconButton>*/}
        {/*</Paper>*/}

        <Dialog open={open} fullScreen={false} scroll={"paper"} maxWidth={"md"}>
            <FullScreenWrapperNone title={'Выбрать'} handleClose={handleClose}>
                <div style={{minWidth: "600px"}}>
                    <FormEditCreate
                        helpPrefix={'Для выбора значения, его нужно найти через поле поиска'}
                        itemName={item.title}
                        {...search}
                        handleSubmit={(values, action) => api[action](`fias_select`, values || {})}
                        handleClose={handleClose}
                        onSuccess={onSuccess}
                        FormComponent={RequestForm}
                        actionAddTitle={'Выбрать'}
                        noSend={true}
                    />
                </div>
            </FullScreenWrapperNone>
        </Dialog>
    </ErrorBoundary>;
}
;