import useLoaderApi, {useLoaderApiList} from "../useLoaderApi";
import Alert from "@material-ui/lab/Alert";
import {Avatar, Box, Grid, Tooltip, Typography} from "@material-ui/core";
import LoadStateShow from "../LoadStateShow";
import moment from "moment";
import React, {useContext, useRef} from "react";
import asArray from "../../libs/asArray";
import * as PropTypes from "prop-types";
import {ChatContext} from "./ChatContext";
import {MeContext} from "meContext";
import {useInView} from "react-intersection-observer";
import request from "superagent";
import useChatRoom from "./useChatRoom";
import {withStyles} from '@material-ui/core/styles'
import {asGUID} from 'libs/asGUID';

const BoxStyleBlue = withStyles(() => ({
    root: {
        background: "#e8f4fd"
    }
}))(Box)

const BoxStyleGreen = withStyles(() => ({
    root: {
        background: "#edf7ed"
    }
}))(Box)

const BoxStyleYellow = withStyles(() => ({
    root: {
        background: "#fff4e5"
    }
}))(Box)


const IconAuthor = ({picture, user}) => {
    return <Tooltip title={user} placement="top">
        <Avatar src={picture.includes('/img/') ? `/upload${picture}` : picture}/>
    </Tooltip>
}


const Message = (props) => {
    const {user, mess, onRead} = props
    const {markAsRead} = useContext(ChatContext);
    const me = React.useContext(MeContext);

    const onChangeView = (inView, entry) => {
        if (!mess.readAt && inView) {
            request
                .post(`/api/chat/read/${mess.guid}`)
                .set('accept', 'application/json')
                .send({})
                .then(resp => {
                    markAsRead(resp.body)
                    onRead(resp.body);
                }, console.error)
            ;
        }
    }

    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold:   1,
        triggerOnce: true,
        onChange:    onChangeView
    });
    if (user.guid === me.guid) {
        return (
            <Box display={"flex"} fullWidth p={0.25} justifyContent="flex-start" ref={ref}>
                <BoxStyleGreen p={2} borderRadius={8}>
                    <Box display={'flex'}>
                        <Box>
                            <IconAuthor picture={user.picture} user={user.name}/>
                        </Box>
                        <Box ml={1.3}>
                            {props.children}
                        </Box>

                    </Box>

                </BoxStyleGreen>

            </Box>

        )
    }

    if (user.guid !== me.guid && asGUID(user.clientProfile) === me.client_profile_guid) {
        return (
            <Box display={"flex"} fullWidth p={0.25} justifyContent="flex-end" ref={ref}>
                <BoxStyleYellow p={2} borderRadius={8}>
                    <Box display={'flex'}>
                        <Box>
                            <IconAuthor picture={user.picture} user={user.name}/>
                        </Box>
                        <Box ml={1.3}>
                            {props.children}
                        </Box>

                    </Box>

                </BoxStyleYellow>

            </Box>
        )
    }

    return (
        <Box display={"flex"} fullWidth p={0.25} justifyContent="flex-end" ref={ref}>
            <BoxStyleBlue p={2} borderRadius={8}>
                <Box display={'flex'}>
                    <Box>
                        <IconAuthor picture={user.picture} user={user.name}/>
                    </Box>
                    <Box ml={1.3}>
                        {props.children}
                    </Box>

                </Box>

            </BoxStyleBlue>

        </Box>

    )
}

const chekDate = (date) => {
    if (!date) {
        return '---'
    }

    if (!moment(date).isValid()) {
        return date
    }

    if ((Date.now() - (new Date(date).getTime())) >= 43200000) {
        return moment(date).format("DD.MM.YYYY HH:mm")
    }

    return moment(date).fromNow()
}


const TextMessage = ({mess, onRead}) => {

    const [user, loadState] = useLoaderApi('users', mess.author, {})
    return (
        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(user)}</>}>
            {user && <Message user={user} mess={mess} onRead={onRead}>
                <Typography variant='body2'>{user.name}, {chekDate(mess.createdAt)}</Typography>
                <Typography variant='body2'>
                    {mess.data}
                </Typography>
            </Message>}
            {/* <Alert
                severity={user.guid === me.guid ? 'success' : 'info'}
                icon={<IconAuthor picture={user.picture} user={user.name} />}
                ref={ref}
            >
                <Typography variant='body2'>{user.name}, {moment(mess.createdAt).fromNow()}</Typography>
                <Typography variant='body2'
                >{mess.data}</Typography>
            </Alert> */}
        </LoadStateShow>
    )

}


function ShowMessages(props) {
    const {room} = props;
    const [items, loadState, reload, setItems] = useLoaderApiList(`chat/${room}`, {})
    const {addEventListener, removeEventListener} = React.useContext(ChatContext)

    React.useEffect(() => {
        addEventListener(room, (data) => {
            setItems(old => [...old, data])
        })
        return () => {
            removeEventListener(room);
        }
    }, [room])

    const onRead = oldMess => newMess => {
        setItems(items => items && items
            .map(iMess => iMess.guid === oldMess.guid
                ? newMess
                : iMess
            )
        )
    }

    const scrollToBottom = el => {
        setTimeout(() => {
            el && el.scrollIntoView({behavior: "auto", block: "end"});
        }, 100)
    }

    return <div>
        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(items)}</>}>
            {asArray(items).map((m, i) => <Box mb={1}>
                <TextMessage mess={m} index={i} onRead={onRead(m)}/>
            </Box>)}
        </LoadStateShow>
        <div ref={scrollToBottom}/>
    </div>;
}


ShowMessages.propTypes = {room: PropTypes.any};

export default ShowMessages;