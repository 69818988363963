/**
 * Группировка значениц по полю
 * @param filed
 * @returns {function(*, *, *): boolean}
 */
export const onlyUniqueByField = (filed) => (value, index, self) => {

    return self.indexOf(self.find(val => val[filed] === value[filed])) === index;
}

export const groupUniqueByField = (filed) => (value, index, self) => {

    return self.indexOf(self.find(val => val[filed] === value[filed])) === index;
}