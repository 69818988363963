import React from "react";
import {config} from 'config';
import PageApiLoader from "components/PageApiLoader3";

export default function Tender(props) {
    console.log('callfunction Tender');
    return <PageApiLoader
        {...props}
        {...config.tender}
        canEdit={true}
        canAdd={true}
    />
}