import React from "react";
import {Tooltip, IconButton, Box} from '@material-ui/core';
import {withStyles} from "@material-ui/core";
import {config} from "../config";

import CloseIcon from '@material-ui/icons/Close';

const ToolTipStyle = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: "0.73rem",
        padding: "0.3rem",
        borderRadius: "0",
        border: "1px solid black",

    },
}))(Tooltip)

const ToolTipClearIcon = ({value, disabled, format, clearAll }) => {
    const style = {
        "select": {
            marginRight: "1.2rem"
        }
    }
    return (
            <Box style={style[format] || {}} >
                <ToolTipStyle title={config.toolTipClearIconText} placement="bottom-start" >
                    <IconButton aria-label={"delete"}
                                onClick={clearAll}
                                disabled={!value ||
                                disabled} size={"small"}
                    >
                        <CloseIcon fontSize={"small"}/>
                    </IconButton>
                </ToolTipStyle>
            </Box>

    )
}

export  default  ToolTipClearIcon