import {FieldFormat} from 'config/FieldFormat';
import React from "react";
import {
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from "@material-ui/core";

import API from 'libs/api';
import {connect} from "react-redux";
import system from "actions/system";
import {
    EditIcon,
    ListIcon,
} from "components/icons";

import moment from 'moment';
import 'moment/locale/ru';
import {
    ColBoolean
} from "components/showItems";
import PageSimpleShow from "components/PageSimpleShow";
import PageApiLoader3 from "components/PageApiLoader3";

const api = new API();

// /**
//  * @return {string}
//  */
// const ColEnabled = function ({item}) {
//     return item
//         ? 'Да'
//         : 'Нет'
// };

/**
 * @return {string}
 */
export const ColFields = function ({item}) {
    try {
        return (JSON.parse(item || '[]'))
            .length;
    } catch (e) {
        return 'error JSON.parse';
    }
    // .map((c,key) => <div key={key}>
    //         {c['tms']}={c['1c']}
    //     </div>
    // );
};

function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const icon = props.icon || <ListIcon/>;
    return (
        <React.Fragment>
            <Button onClick={handleClickOpen}>
                {icon}
            </Button>
            <Dialog
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={'xl'}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="caption" display="block" gutterBottom>
                        {props.children}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export const ColLog = function ({item, row}) {
    const last = moment(row.lastRunDate);
    return last.isBefore()
        ? <CustomizedDialogs
            title={'(' + last.fromNow() + ') ' + row.name}
            icon={<div>
                <ListIcon/>{last.fromNow()}
            </div>}
        >
            <h5>Запуск:{last.format()}</h5>
            <pre>{item}</pre>
        </CustomizedDialogs>
        : row.lastRunDate
    //     <div>{moment(row.lastUpdateDate,'YYYYMMDD').fromNow()}</div>
};

class SectionsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        this.update();
    }

    update = () => {
        this.props.loadData();
    };

    isItemCanDelete = () => {
        return true;
    };

    Edit = (props) => {
        const {row} = props;
        return <Tooltip title={`Изменить`}>
            <IconButton onClick={this.onEdit(row)}><EditIcon/></IconButton>
        </Tooltip>;
    };

    cols = [
        // {field: 'id', title: 'ID', format: RequestForm.FORMAT_NONE},
        {field: 'name', title: 'Название'},
        {field: 'className', title: 'Имя класса', hidden: {xlDown: true}},
        {field: 'query', title: 'Имя для запроса', hidden: {xlDown: true}},
        {
            field: 'lastLog',
            title: 'Журнал обновления',
            object: ColLog,
            hidden: {xsDown: true},
        },
        {field: 'itemsName', title: 'Имя списка в результате', hidden: {xlDown: true}},
        {
            field: 'fromReplicator',
            title: 'Репликатор',
            type: 'int',
            hidden: {xsDown: true},
        },
        {
            field: 'fields',
            title: 'Поля синхронизации',
            object: ColFields,
            type: 'json',
            format: FieldFormat.FORMAT_TEXTAREA,
            hidden: {mdDown: true},
        },
        {
            field: 'limitPerSync',
            title: 'Лимит при синхронизации',
            type: 'int',
            hidden: {mdDown: true},
        },
        {
            field: 'enabled', title: 'включено',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            hidden: {mdDown: true},
        },
        {
            field: 'changeData',
            title: 'обратная',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            hidden: {mdDown: true},
        },
    ];

    onEdit = row => () => {
        this.setState({open: true, values: row});

    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSubmit(values, action) {
        return api[action](`syncs`, values);
    }


    render() {
        return <PageApiLoader3
            {...this.props}
            cols={this.cols}
        />;
    }
}

const apiName = 'syncs';

const mapStateToProps = state =>
    ({
        list: state.system[apiName],
        apiName,
        canAdd: true,
        canEdit: true,
        fabStyle: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
        }
    });

const mapDispatchToProps = dispatch =>
    ({
        loadData: () => {
            dispatch(system.fetch(apiName));
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(SectionsTable);
