import AdminShow from 'components/FieldFormat/AdminShow';
import RequestFormDebug from 'components/RequestFormDebug';
import {FieldFormat} from 'config/FieldFormat';
import API from 'libs/api';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    List,
    ListItem,
    Typography
} from "@material-ui/core";
import {ApiCacheClear} from "components/ApiDataLoad";
import useExplainCols, {useExplainColsFake} from "./components/useExplainCols";
import {MeContext} from "meContext";
import useCheckField from "./components/useCheckField";
import asArray from "./libs/asArray";

const FormDataContext = React.createContext({state:{}});

const {Provider, Consumer} = FormDataContext;

const fixValuesTypes = (cols, values) => {
    cols.forEach(({field, type, format}) => {
        if (field && ('int' === type || format === FieldFormat.FORMAT_NUMBER)) {
            values[field] = !isNaN(values[field])
                ? Number(values[field])
                : 0;
        }
    });
    return values;
};

export const FORM_ACTION = {
    INIT:   'initial',
    CHANGE: 'change',
    SUBMIT: 'handleSubmit',
    SUGGEST: 'suggest',
};

function ShowApiErrors(props) {
    const {userErrors, setUserErrors} = props;

    return <Dialog
        open={Boolean(userErrors.length)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
            setUserErrors('clear');
        }}
    >
        <DialogTitle id="alert-dialog-title" style={{
            textAlign: 'center',

        }}>Ошибки в работе сервиса</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Typography variant="body1" align="center" style={{margin: "20px 0"}}>На данный момент наблюдается
                    нестабильность в работе сервиса Транспортного портала, просим Вас воспользоваться им чуть
                    позже</Typography>
                <Typography variant="h5" align="center">Просим Вас обратиться в техническую поддержку</Typography>
                <br/>
                <Typography variant="body2" align="center">
                    По телефону <Link href="tel:+74956604151">+7 (495) 660-41-51</Link>
                </Typography>
                <br/>
                <Typography variant="body2" align="center">По email <Link
                    href="mallto:b2b@foamline.com">b2b@foamline.com</Link></Typography>
                <br/>
                <Typography variant="overline" align="center">Список ошибок:</Typography>
                <List>
                    {
                        userErrors.map(e => <ListItem>{e}</ListItem>)
                    }
                </List>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {setUserErrors('clear')}} variant="contained" color="error">
                Нажмите если хотите продолжить работу в Транспортном портале, его функционал на данный моменет ограничен
            </Button>
        </DialogActions>
    </Dialog>
}

    ShowApiErrors.propTypes = {userErrors: PropTypes.any};

function detectArrayOfObject(values, field, value) {
    console.log('detectArrayOfObject', values, field, value, typeof value === 'number')
    console.log('detectArrayOfObject F', field, typeof field === 'number')
    if(typeof field === 'number'){
        values[field] = value;
        console.log('detectArrayOfObject 1', values)
        return values
    }
    console.log('detectArrayOfObject 2', {...values, [field]: value})
    return {...values, [field]: value};
}

function FormDataContextProviderPre(props) {
    // console.log('FormDataContextProviderPre props',props)
    const {
        initial,
        children,
        onFormDataAction: onFormDataActionPre,
        handleSubmit,
        dbId,
        onSuccess,
        debug,
        refreshItem,
        item,
        hideDebug,
        subForm,
        colsField,
        newStatusDocument
    } = props;
    console.log('callfunction FormDataContextProviderPre', debug, {refreshItem, initial})

    // const isUpdate = initial && initial[dbId];
    const me = React.useContext(MeContext)
    const {defaultSettings} = me

    const [isUpdate, setIsUpdate ] = React.useState(initial && initial[dbId])

    // React.useEffect(()=>{
    //     console.log('item_FormDataContextProviderPre on',props.debug,JSON.stringify(initial))
    //     // setAction(FORM_ACTION.CHANGE);
    //     return ()=> {
    //         console.log('item_FormDataContextProviderPre off',props.debug,JSON.stringify(initial))
    //     }
    //
    // }, [])
    //


    const onFormDataAction = (...args) => {
        const {value, item, action} = args[1] || {value: null, item: null, action: null}
        value && item && action && action != "init" && checkFieldAsync(value, item.check || [], item.field, args[0])
        //ToDo переделать реализацию, врменное решение.
        if(defaultSettings && action == FORM_ACTION.CHANGE  && !value && item && item.field && defaultSettings[item.field]){
            args[0][item.field] = defaultSettings[item.field]
        }
        if(defaultSettings && action == FORM_ACTION.INIT){
            const values = args[0]
            const key = Object.keys(defaultSettings)
            key.forEach(k => {
                if(args[1].colsField.find(c => c.field == k)){
                    if(!values[k]){
                        values[k] = defaultSettings[k]
                    }
                    if(values._statusDocument && values._statusDocument === "new"){
                        values[k] = defaultSettings[k]
                    }
                }
                if(newStatusDocument && values[k]){
                    values[k] = defaultSettings[k]
                }
            })
            args[0] = values

        }

        console.debug('call onFormDataAction', {args, onFormDataActionPre})
        if (!onFormDataActionPre) {
            console.debug('call onFormDataAction', {args, onFormDataActionPre})
            return args[0]
        }
        const rez = onFormDataActionPre(...args);
        console.debug('call onFormDataAction', {rez, args, onFormDataActionPre})
        return rez
    }

    const {handleChange: handleChangeTop, item: itemTop, values: valuesTop} = React.useContext(FormDataContext)

    const [state, setStateOrig] = React.useState(() => {
        const values = onFormDataAction(initial || {}, {action: FORM_ACTION.INIT, refreshItem, item, colsField: colsField || []}, newStatusDocument);
        if (handleChangeTop && props.item && values !== initial) {
            console.log('FormDataContextProviderPre update initial handleChangeTop', props.item, initial, values)
            handleChangeTop(props.item)(null, values);
        }

        return {
            errors:        {}, // Одна ошибка текстом
            arErrors:      {}, // Массив ошибок
            calc:          false,
            values,
            userErrors:    [],
            requireFields: [],
            checkField: [],
            initial,
        }
    });

    const checkFieldAsync = (value = '', check = [], field = '', values) => {
        new Promise(resolve => resolve(1))
                .then(() => {
                    value.length && setState(state => {
                        return {
                            ...state,
                            checkField: [...state.checkField, field]
                        }
                    })
                })
                .then(() => {
                    Promise.all(
                            asArray(check)
                                    .map(el => el(value, {values: values})))
                            .then(result => result.filter(v => v))
                            .catch(err => {
                                console.log(err);
                                return ["Ошибка сервиса"]
                            })
                            .then(filedError => {
                                const newArErrors = {
                                    ...state.arErrors,
                                    [field]: filedError
                                };
                                const newErrors = {
                                    ...errors,
                                    [field]: filedError.join(', '),
                                };
                                if (!filedError.length) {
                                    delete newArErrors[field];
                                    delete newErrors[field];
                                }

                                // console.log("push2", newArErrors)
                                setState(state => ({
                                    ...state,
                                    errors: newErrors,
                                    arErrors: newArErrors,
                                    checkField: [...state.checkField].filter(el => !el == field)
                                }))
                            });
                })

    }


    const handleChange = item => (event, newValue, setStateCallBack) => {

        setState((state) => {
            
            // console.log('handleChange event: ', event, newValue);
            console.log('onFormDataAction handleChange event: ', {debug, event, newValue,item});
            //const onFormDataAction = values => values;

            if (typeof newValue === 'undefined' && (
                !event || !event.target
            )) {
                console.log('handleChange no event: '
//          , typeof event, JSON.stringify(event),!event,!event.target);
                    , typeof event, event, newValue);
                return state;
            }
            const {field, format, api, parentField, parentList, onRowChange} = {
                onRowChange: values => values,
                ...item,
            };
            const {values, errors, arErrors}                              = state;
            // delete errors[field];
            // cl(event.target);
            let value;
            if (typeof newValue !== 'undefined' ) {
                value = newValue;
            }
            else {
                value = 'checkbox' === event.target.type
                    ? event.target.checked
                    : event.target.value;
            }
            console.log('fieldCreateName',{format, newValue, t: typeof newValue, item})
            if ([
                    FieldFormat.FORMAT_SELECT_API,
                    FieldFormat.FORMAT_SUB_FORM,
                    FieldFormat.FORMAT_SUB_TABLE,
                ].includes(format)
                // && 'string' === typeof values[field]
                && 'object' === typeof newValue
            ) {
                values[API.fieldCreateName] = {
                    ...values['_CREATE'],
                    [field]: {
                        apiName: api,
                        parentField,
                        parentList,
                    },
                };
            }
            
            // if (subFormHandleChange) {
            //     subFormHandleChange(newValues);
            // }
            // console.log('handleChange state: ', state.values, field, value);
            console.log('onFormDataAction handleChange event: ', {debug, event, values, field, value});
            if (typeof field === 'undefined') {
                console.log('no field handleChange', {debug, event, values, field, value})
                return state;
            }
            const actionInfo = {old: values, field, value, action: FORM_ACTION.CHANGE, item, refreshItem};
            const nextValues = onFormDataAction(
                onRowChange(detectArrayOfObject(values,field,value), actionInfo),
                actionInfo,
            );
            // const [errorCheck, loadState, ] = useCheckField(check, field)
            // const filedError = asArray(check)
            //     .map(checkFunc => checkFunc(value, {values}))
            //     .filter(v => v);
            //
            const nextState = {
                ...state,
                values:  nextValues,

                submitError: false,
            };
            console.log('onFormDataAction handleChange event: itemTop ', {nextValues, item, itemTop, itemProps: props.item});
            if(handleChangeTop && props.item) {
                handleChangeTop(props.item)(null, nextValues);
            }else{
                console.log('no handleChangeTop props.item', handleChangeTop, props.item)
            }
            return nextState;
        }, setStateCallBack);
    };
    
    // Убраны правки во вложенных объектах ????
    React.useEffect(() => {
        if(state.initial !== initial) {
            console.log('FormDataContextProviderPre update initial' + debug, {
                debug,
                item,
                initial,
                state,
            })

            // handleChange(item)(null, initial)
            setState(state => ({
                ...state,
                values: initial,
            }));
        }

    }, [initial])


    console.log('callfunction FormDataContextProviderPre state', state)
    const setState = props => {
        console.log('callfunction FormDataContextProviderPre setState', props)
        setStateOrig(props)
    }

    /** @deprecated requireFields Убрать двойное использование полей для проверки */
    const [requireFields, setRequireFields] = React.useState([])

    // todo: not call onFormDataAction every time
    // const [errors, setErrors] = React.useState({});
    const {values, errors, userErrors}  = state;

    const preCols = useExplainCols(
        props.cols,
        {...props, ...props.initial}, // для задачи #960
        values,
        {subForm, debug_FormDataContextProviderPre: debug || '???', me},
        null
    );

    if (!preCols) {
        console.log("!cols", cols, props.cols)
        return <center><CircularProgress title={`FormDataContextProvider no cols from ${props.debug}`}/></center>;
    }
    const cols = preCols.map(col => {
        let {required, check} = col;
        if(typeof required == 'function'){
            required =  required(values);
        }
        // if(required && !check){
        //     check = value => !value.length && 'Поле не заполнено';
        // }
        return {...col, required, check};
    });

    const listFieldsRequired = (cols||[]).filter(e => {
        if(typeof e.required == 'function'){
            return e.required(values)
        }
        return  e.required
    })


    // Проверка на не пустоту обязательных полей
    // TODO: FormDataContext.jsx:313 Uncaught TypeError: Cannot read properties of undefined (reading 'customer')
    console.log('state.values', debug, state, values)
    const isRequired = [
        ...listFieldsRequired.filter(col => !values || !values[col.field]),
        ...requireFields.filter(col => !values[col.field]),
        ...Object.values(state.arErrors).filter(v=>v)
    ];

    const checkField = [...state.checkField]

    const setStateCompare = fnOrState => {
        console.log('setStateCompare', fnOrState, typeof fnOrState)
        if( typeof fnOrState === 'function') {
            setState(oldState => {
                let newState = fnOrState(oldState);
                if(JSON.stringify(oldState) === JSON.stringify(newState)){
                    console.log('setStateCompare equal', fnOrState)
                }else{
                    console.error('setStateCompare !equal',
                        JSON.stringify(oldState.values.routePlaces,null,2),
                        JSON.stringify(newState.values.routePlaces,null,2)
                        )
                }
                return newState;
            });
        } else {
            setState(fnOrState);
        }
    }

    const handleSuccessSubmit = options => (data) => {
        ApiCacheClear();
        console.log('handleSuccessSubmit data', data);
        if (204 === data.statusCode) {
            setState(state => ({
                ...state,
                submitting: false,
                errors:     {},
            }));
        }
        console.log('handleSuccessSubmit', data);
        const {errors} = data.body || {};
        if (!errors) {
            console.log('onSuccess', onSuccess);
            onSuccess(data, options);
            // handleClose();
        }
        console.log(errors);
        setState(state => ({
            ...state,
            submitting: false,
            errors:     200 === data.statusCode
                            ? {...errors}
                            : {errors},
        }));
    };
    
    const handleErrorSubmit = (err) => setState({
        ...state,
        submitting:  false,
        submitError: err.message || err,
    });

    const changeIsUpdate = () => {
        setIsUpdate(value => !value)
    }


    const handleSubmitForm = (event, values, options) => {
        event.preventDefault();
        const isUpdate = initial && initial[dbId];
        
        setState({
            ...state,
            submitting:  true,
            submitError: false,
        });
        if (!handleSubmit) {
            console.error('handleSubmit not set');
        }
        handleSubmit(
            onFormDataAction(fixValuesTypes(cols, values), {action: FORM_ACTION.SUBMIT}),
            isUpdate && !values.clone
                ? 'put'
                : 'post',
        )
            .then(handleSuccessSubmit(options))
            .catch(handleErrorSubmit);
    };
    const setStateValues = func => {
        setState(state => {
            return {
                ...state,
                values: {
                    ...state.values,
                    ...onFormDataAction(func(state.values),{old:state.values,action: FORM_ACTION.CHANGE, refreshItem}),
                },
            };
        });
    };
    /** @deprecated update to setState */
    const setUserErrors =  (err) => {
        setState(state => {
            return {
                ...state,
                userErrors: err === 'clear'
                    ? []
                    : [...state.userErrors, err]
            }
        })
    }
    /** @deprecated update to setState */
    const changeValueState = (field, value) => {
        //Данный метод добавлен для измения поля пароля для страницы пользователй
        console.log('callfunction FormDataContextProviderPre changeValueState')
        setState(state => {
            return{
                ...state,
                values :{
                    ...state.values,
                    [field] : value
                }
            }
        })
    }  

    /** @deprecated Меть данные через определение cols */
    const putRequiredFields = (field) => {
        console.log('callfunction FormDataContextProviderPre putRequiredFields')
        //Данный метод добавлен для измения значений обязательных полей, если коллекции requireFields есть хоть один элемент, то отключается кнопка Сохранить/Добавить
        setRequireFields(field)
    }

    /** @deprecated update to setState */
    const deleteNodeState = (node) => {
        console.log('callfunction FormDataContextProviderPre deleteNodeState')
        //Данный метод создан чтоб удалять опредленные узлы из state.values, пример удалям узел посвященному новому тендеру.
        const newValues= state.values
        delete newValues[node]
        setState(state => {
            return{
                ...state,
                values : newValues
            }
        })
    }

    return <Provider value={{
        item, cols, state, values, valuesTop, errors,
        requireFields, handleChange, setState,
        handleChangeTop,
        setStateValues, changeIsUpdate, onFormDataAction, handleSubmitForm,
        setUserErrors, deleteNodeState, putRequiredFields, changeValueState, isRequired, checkField, isUpdate,
        debug,
        RequestFormDebug: () => <RequestFormDebug
            values={values}
            debug={debug + JSON.stringify({
                field: item ? item.field : 'none',
                parentField:itemTop && itemTop.field || 'none'
            })}
        />
    }}>
        {console.log('callfunction FormDataContextProviderPre Provider')}
        <AdminShow>[FormDataContextProvider:{debug}]</AdminShow>
        {/*<div>handleChangeTop: {handleChangeTop && props.item ? props.item.field: 'none'}</div>*/}
        {/*<div>Provider:[{debug + JSON.stringify({*/}
        {/*    field: item ? item.field : 'none',*/}
        {/*    parentField:itemTop && itemTop.field || 'none'*/}
        {/*})}]</div>*/}
        {children}
        <ShowApiErrors userErrors={state.userErrors} setUserError={setUserErrors}/>
        {!hideDebug && <RequestFormDebug
            values={values}
            debug={debug + JSON.stringify({
                field: item ? item.field : 'none',
                parentField:itemTop && itemTop.field || 'none'
            })}
        />}
    </Provider>;
}

function FormDataContextProvider(props) {
    return FormDataContextProviderPre({
        onFormDataAction: values => values,
        debug: 'noName',
        refreshItem: () => {},
        ...props,
    });
}

FormDataContextProvider.propTypes = {
    onFormDataAction: PropTypes.func,
    initial:          PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    debug:            PropTypes.string,
};

export {Provider, FormDataContext, FormDataContextProvider, Consumer as FormDataContextConsumer};