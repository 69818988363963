import {Paper, Typography} from '@material-ui/core';
import AdminShow from 'components/FieldFormat/AdminShow';
import TableApiParamsLoader from 'components/TableApiParamsLoader';
import React from 'react';
import {Redirect, Route, useParams, useRouteMatch} from 'react-router-dom';
import PageApiShow3 from './PageApiShow3';
import {FormDataContext} from "../FormDataContext";
import ErrorBoundary from "components/ErrorBoundary";

function RedirectEdit(props) {
    let {url}  = props;
    let {guid} = useParams();
    return <Redirect
        to={{
            pathname: `${url}/${guid}/edit`,
        }}
    />;
}


const guidMask = '\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}';

export default function PageApiLoader3(props) {
    console.log('callfunction PageApiLoader3');

    const [goList, setGoList] = React.useState(false);
    let {title}               = props;
    
    let {url} = useRouteMatch();
    
    function RedirectList({url}) {
        setGoList(false);
        
        return <Redirect to={{pathname: `${url}`}} push={true}/>;
    }

    return <React.Fragment>
        <AdminShow>[PageApiLoader3]</AdminShow>
        <Route exact={true} path={[
            `${url}/:guid(${guidMask})`,
            `${url}/:guid(new)`,
        ]}>
            <RedirectEdit url={url}/>
        </Route>
        <Route path={[
            `${url}/:action(new)/:sublist`,
            `${url}/:action(new)`,
            `${url}/:guid(${guidMask})/:action(edit)`,
            `${url}/:guid(${guidMask})/:sublist`,
            // `${url}/:guid(${guidMask})`,
        ]}>
            {goList && <RedirectList url={url}/>}
            <ErrorBoundary info={'TableApiParamsLoader'}>
            <TableApiParamsLoader
                {...props}
                url={url}
                onElementUpdate={() => setGoList(true)}
            />
            </ErrorBoundary>
        </Route>
        <Route path={url} exact={true}>
            <Typography variant={'h5'}>{title}</Typography>
            <Paper>
                <PageApiShow3
                    newTable
                    {...props}
                    url={url}
                />
            </Paper>
        </Route>
    </React.Fragment>;
    
}