import {MeContext} from 'meContext';
import React from 'react';

export default function AdminShow({alert, children}) {
    const {roles} = React.useContext(MeContext);
    const time    = new Date();

    if(window.showZones) {
        return children || null; // for <AdminShow/>
    }

    if (alert && roles && roles.includes('ROLE_DEBUG')) {
        return children;
    }

    return <React.Fragment>
        {/*{roles && roles.includes('ROLE_DEBUG') && children}*/}
        {/*{roles && roles.includes('ROLE_DEBUG') && time.toJSON()}*/}
    </React.Fragment>;
}
