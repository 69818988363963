import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {TextField, Box} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';
import {FormDataContextConsumer} from 'FormDataContext';
import TextMaskCustom from 'components/FieldFormat/TextMaskCustom.jsx'

import EndAdornmentComponent from "../EndAdornmentComponent";

const WrapperMyComponent = (props) => {
    // Данная конструкция нужна, чтоб выводить иконки актулизации перед полем.
    const {beforeFieldIcon, value} = props
    if(beforeFieldIcon){
        return (
                <Box
                        display='flex'
                        justifyContent="center"
                >
                    <Box pr={1} pt={2}>
                        {beforeFieldIcon(value)}
                    </Box>
                    {props.children}
                </Box>
        )
    }
    return (
            <Box>
                {props.children}
            </Box>
    )
}


function MyComponent(props) {
    const defaultItem = {check: []}
    const {submitting, item, value, values,  error, handleChange, InputPropsDefault}                                              = props;
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, mask, check, helperText, ...other} = {...defaultItem, ...item};

    return <React.Fragment>
        <AdminShow>[INPUT]</AdminShow>
        <TextField
                disabled={disabled || submitting}
                label={title}
                value={value}
                required={required}
                margin={'dense'}
                fullWidth
                variant={'outlined'}
                name={field}
                onChange={handleChange}
                error={!!error}
                helperText={error || helperText}
                InputLabelProps={{shrink: true}}
                inputProps={{mask: mask,}}
                InputProps={{
                    inputComponent: mask && TextMaskCustom,
                    startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                        values:         context.state.values,
                        setStateValues: context.setStateValues,
                    })}</FormDataContextConsumer>,
                    endAdornment: EndAdornmentComponent(item, value, error),
                    ...InputPropsDefault
                }}
                {...other}
        />
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
            && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field, beforeFieldIcon}  = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };

    const value = values && values[field];
    const error = errors && errors[field];
    return (
            <WrapperMyComponent beforeFieldIcon={beforeFieldIcon} value={value}>
                <MyComponentMemo
                        values={values}
                        value={value || ''}
                        error={error || null}
                        handleChange={handleChangeLocal}
                        {...props}
                />
            </WrapperMyComponent>
    );
}

export default MyComponentMemoUse;