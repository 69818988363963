import React from 'react';
import {IconButton, InputAdornment, InputLabel, OutlinedInput, FormControl, TextField} from '@material-ui/core';
import {PermPhoneMsg as Phone} from '@material-ui/icons';
import request from 'superagent';
import { FormDataContext } from 'FormDataContext';
import ConfirmDialog from 'components/ConfirmDialog';
import {makeStyles} from '@material-ui/core/styles';


 //Уникальный компонент, служит для отбражение телефонов в Заявка на Траспорт/Машруты/Этап машрута. Позволяет выбирать телефону у указанных контакных лиц, изменять их, дописывать или новые данные водить. 

const useStyles = makeStyles(() => ({
    helperText : {
        position: "absolute", 
        top: "44px", 
        fontSize: "10px"
    }
}))



function InputPhones(props) {
    const {telelephon, item, value, handleChange} = props
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, ...other} = item;
    const classes = useStyles()

    return (
        <FormControl
            style={{
                position: 'relative'
            }}
            fullWidth
            variant="outlined"
        >
            <OutlinedInput
                value={value}
                variant={'outlined'}
                margin={'dense'}
                onChange={handleChange}
                fullWidth
                endAdornment={
                    telelephon &&
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleChange}
                            onMouseDown={handleChange}
                            edge="end"
                        >
                                <Phone />
                        </IconButton>
                    </InputAdornment>
                }
            />
            {telelephon && <span className={classes.helperText}>Есть телефоны у выбранного контакта</span>}
        </FormControl>
    )
}

function MyComponent(props) {
    const {item} = props;
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, ...other} = item;
    const { state: { values }, handleChange, deleteNodeState } = React.useContext(FormDataContext);

    const [telelephon, setTelePhone] = React.useState('');
    const [confirm, setConfirm] = React.useState(false);
    const value = values && values[field]
    React.useEffect(() => {
        if (values.contact) {
            request.get(`/api/contact_infos?contactPerson=${values.contact}&contactKind=Телефон`).set('accept', 'application/json').query({})
            .then(response => {
                if (response.body && response.body.length){
                    const  resultPhone = []
                    response.body.map(item => {
                        resultPhone.push(item.fullInfo)                        
                    })
                    const phons = resultPhone.join(', ');
                    setTelePhone(phons)
                    //автоматически меняем телефоны если получаем новые и не меняли старый 
                    values[field] && values[field] !== phons ? setConfirm(true) : handleChange({field: field})('', phons)
                    handleChange({field: "hidenContactPhones"})('', phons)
                    return null
                }
                setTelePhone('');
            });
            return 
        }
            !value && deleteNodeState(field)
            setTelePhone(false)        
    }, [values.contact])

    React.useEffect(()=>{
        if(!values.contragent){
            deleteNodeState('contact')
        }
    }, [values.contragent])

    const handleChangeLocal = (e) => {
        if(telelephon && e.target.getAttribute('type') !== 'text'){
            setConfirm(true)
            return null
        }
        return handleChange(props.item)(e, e.target.value);
    };

    return (
        <>
            <ConfirmDialog
                show={confirm}
                title={'Смена телефона'}
                text={`Поменять телефон(ы) с ${values[field]} на ${telelephon}`}
                onConfirm={() => {
                    setConfirm(false)
                    handleChange(props.item)('', telelephon)
                }}
                onCancel={() => {
                    setConfirm(false)
                }}
            />
            <InputPhones
                telelephon={values[field] && values[field] != values['hidenContactPhones'] ? telelephon : false}
                value={value || ''}
                handleChange={handleChangeLocal}
                {...props}
            />
        </>
    )

}

export default MyComponent;