import React from "react";

import { SvgIcon } from '@material-ui/core';

const ZipIcon = () => {
    return (
        <SvgIcon viewBox={"0 0 56 56"}>
            <g>
                <path style={{fill: "#E9E9E0"}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                <polygon style={{fill: "#D9D7CA"}} points="37.5,0.151 37.5,12 49.349,12 	" />
                <g>
                    <rect x="26.5" y="42" style={{fill: "#C8BDB8"}} width="2" height="14" />
                    <polygon style={{fill: "#C8BDB8"}} points="30.5,23 30.5,21 28.5,21 28.5,19 26.5,19 26.5,21 24.5,21 24.5,23 26.5,23 26.5,25 
			24.5,25 24.5,27 26.5,27 26.5,29 24.5,29 24.5,31 26.5,31 26.5,34 28.5,34 28.5,31 30.5,31 30.5,29 28.5,29 28.5,27 30.5,27 
			30.5,25 28.5,25 28.5,23 		"/>
                </g>
                <g>
                    <rect x="23.5" y="34" style={{fill: "#CBB292"}} width="8" height="8" />
                    <path style={{fill: "#5E5F62"}} d="M32.5,43h-10V33h10V43z M24.5,41h6v-6h-6V41z" />
                </g>
                <rect x="23.5" y="36" style={{fill: "#5E5F62"}} width="8" height="2" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </SvgIcon>
    )
}
export { ZipIcon };