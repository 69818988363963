import React from "react";
import {TableCell} from "@material-ui/core";
import {FieldFormat} from "config/FieldFormat";
import ErrorBoundary from "components/ErrorBoundary";
import {FormDataContext} from "FormDataContext";
import {ObjectDetect} from "components/ObjectDetect";

/**
 * {
    "size": "medium",
    "icons": {
         ....

        "Retry": {}
    },
    "columnDef": {
        "title": "Контрагент",
        "api": "legals",
        "format": "select_api",
        "field": "contragent",
        "freeSolo": true,
        "freeSoloField": "contragentName",
        "freeSoloCanEdit": true,
        "style": {
            "minWidth": "17rem"
        },
        "tableData": {
            "columnOrder": 3,
            "groupSort": "asc",
            "width": "calc((100% - (0px)) / 7)",
            "initialWidth": "calc((100% - (0px)) / 7)",
            "additionalWidth": 0,
            "id": 3
        }
    },
    "value": "0ab94e5c-8029-4f1b-91d2-84bbf1fc0a38",
    "rowData": {
        "contactName": "",
        "contactPhones": "",
        "contragentName": "",
        "dateFrom": "2022-07-22T04:43:03+00:00",
        "dateFromText": "",
        "dateTo": "2022-07-22T04:43:03+00:00",
        "isLoading": false,
        "isUnloading": false,
        "guid": "1d4a805b-0978-11ed-a5b3-0242ac130004",
        "docRequest": "12e6f8f2-fd25-11ec-8d5f-0242ac130006",
        "services": [],
        "cargos": [],
        "point": null,
        "contact": null,
        "contragent": "0ab94e5c-8029-4f1b-91d2-84bbf1fc0a38",
        "tableData": {
            "id": 1
        }
    },
    "cellEditable": false,
    "scrollWidth": 1413
}
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function MTCellContext(props) {
    // console.log('MTCellContext', props)
    const {size, columnDef} = props
    const {object, format, parent} = columnDef;
    const {values} = React.useContext(FormDataContext)
    // if (format === FieldFormat.FORMAT_NONE) {
    //     // for hidden fields
    //     return <TableCell
    //         size={size}
    //     >object</TableCell>;
    // }

    if (!parent) {
        // console.error('parent not set')
    }

    return <TableCell
        size={size}
        // {...cellProps}
        // style={this.getStyle()}
        // align={cellAlignment}
        // onClick={this.handleClickCell}
    >
        <ErrorBoundary info={'MTCellContext'}>

            <ObjectDetect
                // rows={rows}
                field={columnDef.field}
                item={values}
                row={columnDef}
                // props={props}
            />

        </ErrorBoundary>
    </TableCell>
}