import React from "react";


const Carrier = ()=>{

    return (
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.572 17.73H23V12.434L16.407 5.83999H10.717V17.73H14.191M1.23096 1.03299H7.87396V17.763H5.11096L1.23096 1.03299Z" stroke="#363636" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.76392 4.27798H1.38892M16.8889 20.227C17.21 20.227 17.5279 20.1637 17.8246 20.0409C18.1212 19.918 18.3908 19.7379 18.6178 19.5109C18.8448 19.2838 19.0249 19.0143 19.1478 18.7176C19.2707 18.421 19.3339 18.1031 19.3339 17.782C19.3339 17.4609 19.2707 17.143 19.1478 16.8463C19.0249 16.5497 18.8448 16.2801 18.6178 16.0531C18.3908 15.8261 18.1212 15.646 17.8246 15.5231C17.5279 15.4002 17.21 15.337 16.8889 15.337C16.2405 15.337 15.6186 15.5946 15.16 16.0531C14.7015 16.5116 14.4439 17.1335 14.4439 17.782C14.4439 18.4304 14.7015 19.0523 15.16 19.5109C15.6186 19.9694 16.2405 20.227 16.8889 20.227ZM12.6279 20.167H5.15692H12.6279Z" stroke="#363636" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export {Carrier}