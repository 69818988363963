import React from "react";
import * as PropTypes from "prop-types";

function DoDownload(props) {
    // https://stackoverflow.com/questions/31214677/download-a-reactjs-object-as-a-file
    const {output, type = 'application/octet-binary', filename = 'filename.csv'} = props
    const blob = new Blob([output], {type});
    const fileDownloadUrl = URL.createObjectURL(blob);

    React.useEffect(() => {
        let tempLink = document.createElement('a');
        tempLink.href = fileDownloadUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();

    }, [])
    return null;
}

DoDownload.propTypes = {output: PropTypes.any};

export default DoDownload;