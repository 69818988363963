import React from 'react';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import {Badge, Box, CircularProgress, Tooltip} from '@material-ui/core';
import asArray from 'libs/asArray'
import {useHistory} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {useLoaderApiList} from "components/useLoaderApi";
import LoadStateShow from "components/LoadStateShow";


const GreyEmojiEventsOutlinedIcon = withStyles(()=>({
    root: {
        color: "#bdbdbd"
    }
}))(EmojiEventsOutlinedIcon)

const MyBox = withStyles(()=>({
    root: {
        paddingTop: "0.5rem"
    }
}))(Box)

export const CountBiddings = ({rowData}) => {

    const [count, loadState] = useLoaderApiList(
        `biddings`,
        {
            docLinkedGuid: rowData.guid,
            docLinkedName: "ЗапросСтоимости"
        },
        {
            onResponse: data => data.reduce((sum, current) => sum + asArray(current.bids).length, 0)
        }
    )

    const Body = ({ count }) => {
        switch (count) {
            case 0:
                return (
                    <Tooltip title={`Ставок нет`}>
                        <GreyEmojiEventsOutlinedIcon />
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip title={`Количество ставок ${count}`}>
                        <Badge badgeContent={count} color="primary">
                            <EmojiEventsIcon onClick={onClick} />
                        </Badge>
                    </Tooltip>
                )
        }
    }



    const history = useHistory();

    const onClick = () =>{
        history.push(`/price_request/${rowData.guid}/tab-2`)
        
    }

    

    return (
        <LoadStateShow state={loadState} error={' '}>
            <MyBox>
                <Body count={count} />
            </MyBox>
        </LoadStateShow>
    )


   

    if(rowData && rowData.countBidds){
        return (
            <Box mt={1}>
                  <Badge badgeContent={rowData.countBidds} color="primary">
                <EmojiEventsIcon />
            </Badge>
            </Box>
          
        )
    }
    return (
        <></>
    )
    
}