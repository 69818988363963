import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import wikiLinkPlugin from "remark-wiki-link";
import rehypeRaw from "rehype-raw";
import React from "react";
import  './style.css'
import {LinkMarcDown, PMarcDown} from './MarcDownComponents'

const MarcDown = ({link, item, clickMarkDownLink, activeLink }) => {

    return (
            <>
                <ReactMarkdown
                        children={item.content}
                        remarkPlugins={[remarkGfm, [wikiLinkPlugin, {aliasDivider: '[['}] ]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            a: LinkMarcDown (link, clickMarkDownLink, activeLink),
                            p: PMarcDown(link)
                        }
                        }
                />
            </>
    )
}

export default  MarcDown