import DialogEditCreate3 from 'components/DialogEditCreate3';
import RequestForm from 'components/RequestForm';
import {FieldFormat} from 'config/FieldFormat';
import React from 'react';

import {
    Avatar,
    Typography,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
} from '@material-ui/core';

import {
    useDispatch,
} from 'react-redux';

import {SYSTEM} from 'reducers/system';
import request from 'superagent';

const mapSuggestions = data => (
    {
        'switch_user': data,
    }
);

const config = {
    cols: [
        {
            title:          'Поиск пользователя',
            format:         FieldFormat.FORMAT_SELECT_DADATA,
            api:            'switch_users',
            freeSolo:       false,
            searchField:    'name',
            method:         'GET',
            textSecondary:  item => item && item.client,
            onResponse:     data => data,
            mapItem:        data => data,
            mapSuggestions: mapSuggestions,
        },
        {
            title:          'Поиск пользователя',
            format:         FieldFormat.FORMAT_CUSTOM,
            custom:         ({values}) => <Typography variant={'h6'}>
                {values.switch_user && <React.Fragment>
                    Сменить пользователя на <Chip size="small" avatar={<Avatar src={values.switch_user.picture}/>}
                                                  label={values.switch_user.username}/>
                    email:{values.switch_user.email}
                </React.Fragment>
                }
            </Typography>,
            api:            'switch_users',
            freeSolo:       false,
            searchField:    'name',
            method:         'GET',
            mapSuggestions: mapSuggestions,
        },
    ],
};

export function ReturnUser(props) {
    const dispatch     = useDispatch();
    const handleSubmit = async () => {
              try {
                  const response = await request
                      .get(`/api/me`)
                      .set('accept', 'application/json')
                      .query({'_switch_user': '_exit'});
                  if (response.body) {
                      dispatch({
                          type:     SYSTEM.RECEIVE,
                          endPoint: 'me',
                          payload:  {items: response.body},
                      });
                      window.location.reload();
                      
                  }
              }
              catch (e) {
                  console.error('Ошибка смены пользователя');
                  dispatch({
                      type:     SYSTEM.RECEIVE,
                      endPoint: 'me',
                      payload:  {items: {name: '', message: 'Ошибка смены пользователя'}},
                  });
                  onClose()
              }
          }
    ;
    const {onClose} = props;
    React.useEffect(() => {
        handleSubmit()
            .then(() => onClose());
    });
    return <React.Fragment>
        <Dialog open={true}>
            <DialogContent>
                <CircularProgress/>
            </DialogContent>
        </Dialog>
    </React.Fragment>;
}

export function SwitchUser(props) {
    const dispatch     = useDispatch();
    const {onClose}    = props;
    const handleSubmit = async values => {
              try {
                  const response = await request
                      .get(`/api/me`)
                      .set('accept', 'application/json')
                      .query({'_switch_user': values.switch_user.email});
                  if (response.body) {
                      dispatch({
                          type:     SYSTEM.RECEIVE,
                          endPoint: 'me',
                          payload:  {items: response.body},
                      });
                      onClose();
                      window.location.reload();
                  }
              }
              catch (e) {
                  console.error('Ошибка смены пользователя');
                  dispatch({
                      type:     SYSTEM.RECEIVE,
                      endPoint: 'me',
                      payload:  {items: {name: '', message: 'Ошибка смены пользователя'}},
                  });
              }
          }
    ;

    return <React.Fragment>
        <DialogEditCreate3
            title={'Сменить пользователя'}
            buttonSave={'Сменить'}
            dbId={'guid'}
            open={true}
            handleSubmit={handleSubmit}
            handleClose={onClose}
            onSuccess={() => {}}
            // initialValues={{...initialValues, ...item, ...props.filter}}
            FormComponent={RequestForm}
            {...config}
        />
    </React.Fragment>
        ;
}

