import React from "react";

import {
    Button,
    Box,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,


} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'components/ConfirmDialog';
import {config} from 'config/'

import {FieldFormat} from "../../config/FieldFormat";
import request from "superagent";
import {FORM_ACTION, FormDataContext, FormDataContextProvider} from "../../FormDataContext";
import FormFieldsShow from "../FormFieldsShow";
import RequestForm from "../RequestForm";
import {withStyles} from "@material-ui/core/styles";
import {ObjectDetect} from "components/ObjectDetect";
import useLoaderApi, {useLoaderApiList} from "components/useLoaderApi";
import  LoadStateShow from  "components/LoadStateShow"
import optionsNDS from 'config/optionsNDS';
import { v1 as uuidv1 } from 'uuid';
import {ColObjectName, ColObjectShowDateTime} from "../showItems";
import moment from 'moment';



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

function getSteps() {
    return ['Требования к транспорту', 'Стоимость заявки', 'Адрес доставки'];
  };

const CARBODY_TYPES_CLOSED = "73b5262f-735c-11ea-b813-005056a60abf";
const CARBODY_TYPES_TENT = "7b9922d9-735c-11ea-b813-005056a60abf";
const LOADING_TYPES_BACK = "509a107d-94ed-11ea-b813-005056a60abf";
const NDSRATESTRING_20 = 'НДС20';
const CURRENCY_RUB = 'bb88b5b8-48d3-11ea-b812-005056a60abf';

const INIT_DOC_REQUEST =  {
    carbodyTypes : [
        CARBODY_TYPES_CLOSED,
        CARBODY_TYPES_TENT
    ],
    loadingTypes : [
        LOADING_TYPES_BACK
    ],
    ndsRateString : NDSRATESTRING_20,
    currency: CURRENCY_RUB
}


const calculatorRatiosList = (value, sum) => parseFloat((parseInt(value) / parseFloat(sum)).toFixed(2))


const cargosRatiosList = (routePlaces) => {
    const [first] = routePlaces;
    if(!first){
        return []
    }
    const cargoList = first.cargos;
    // Вычесляем общую сумму всех грузов
    const cargoListAllMass = cargoList.reduce((sum, el) => (parseInt(el.cargoValue) || 0) + sum, 0);

    //Расчитываем коэфецент соотношения всех грузов в точке маршрута.
    return cargoList.map(el => calculatorRatiosList(el.cargoValue, cargoListAllMass));


};

const calculatorValue = (value, ratio) => (parseFloat(value) * ratio).toFixed(2);


const FinalList = ({values, cols, listCols}) => {

      const [listColsAll, setListColsAll] = React.useState([])
    React.useState(()=>{
        listCols.forEach(el => {
            setListColsAll(val => ([...val, ...el]))
        })
    }, [])
      return (<>
                  {
                      cols.filter(el => listColsAll.includes(el.field)).map(item => <Box py={1} display={"flex"} alignItems={"baseLine"}>
                          <Typography variant={"body2"}>{item.title}:</Typography>
                          <Box ml={1}>
                              <ObjectDetect noneValue={'---'} rows={{[item.field]: values[item.field]}} field={item.field} item={{[item.field]: values[item.field]}} row={item}/>
                          </Box>
                      </Box>)
                  }
              </>
      )
}



const Body = ({handleSubmit, listCols, cols}) => {
    const classes = useStyles();
    const {state, handleSubmitForm,  setStateValues, isRequired} = React.useContext(FormDataContext);

  const [activeStep, setActiveStep] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const steps = getSteps();

  const handleNext = (event) => {
    if(activeStep === steps.length - 1){
        setModal(true)
        return
    }
      setStateValues(values => ({...values, _step: activeStep+1}))
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
      setStateValues(values => ({...values, _step: activeStep-1}))
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  };

  const handleReset = () => {
      setStateValues(values => ({...values, _step: 0}))
    setActiveStep(0);
  };

  const topColsBlocks = config.docRequest.topColsBlocks

    topColsBlocks.map(el => {el.cols = 1})


  return (
    <Box className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === steps.length ? (
          <Box>
            <Typography className={classes.instructions}>Конец строки</Typography>
            <Button onClick={handleReset}>Сбросить</Button>
          </Box>
        ) : (
          <Box>
            <Box className={classes.instructions}>
                <FormFieldsShow
                        topColsBlocks={topColsBlocks}
                        // dbId={'guid'}
                        // itemName={itemName}
                        open={true}
                        // handleSubmit={handleSubmit(apiName)}
                        // handleClose={openDialogEdit(false)}
                        // subApi={subApi}
                        // tab={sublist||'edit'}
                        // onSuccess={addChatMessage}
                        initialValues={{}}
                        // item={item}
                        FormComponent={RequestForm}  //??? TODO: test not need?
                />
            </Box>
            <Box display={"flex"} justifyContent="flex-end">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Назад
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext} disabled={isRequired && isRequired.length}>
                {activeStep === steps.length - 1 ? 'Добавить заявку' : ' Следующий шаг'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmDialog
                show={modal}
                title={'Создать заявку'}
                onConfirm={(event) => {
                    setModal(false)
                    handleSubmitForm(event, state.values)
                }}
                custemDialogContent={<FinalList cols={cols} values={state.values} listCols={listCols}/> }
                onCancel={() => {
                    setModal(false)
                }}
            />
    </Box>
  );
}


const DialogBox = ({item}) => {
    const [cols, setCols] = React.useState([])
    const list = [
        ['transportValue', 'transportValueMax', 'transportMass', 'carbodyTypes', 'loadingTypes', 'typedTransport', ],
        [ 'currency', 'ndsRateString', 'sumDeliveryNds', "_customSumDeliver", 'paymentConditions'],
        ['pointLoad', 'dateFromTextLoad', 'point', 'dateFromText', 'contragent', '_contact', '_contactPhones']
    ]
    const customCols = [
        {
            title:        'Стоимость доставки без НДС',
            field:        '_customSumDeliver',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         1,
            topColsBlock: 3,
            hidden:       true
        },
        {
            title:        'Контактное  лицо',
            field:        '_contact',
            format:       FieldFormat.FORMAT_INPUT,
            required: true,
            cols:         1,
            topColsBlock: 6,
            hidden:       true
        },
        {
            title:        'Контактный  телефон',
            field:        '_contactPhones',
            format:       FieldFormat.FORMAT_INPUT,
            required: true,
            cols:         1,
            topColsBlock: 6,
            hidden:       true
        },
        {
            title:         'Адрес погрузки',
            field:         'pointLoad',
            cols:         1,
            topColsBlock: 5,
            disabled: true
        },
        {
            title:  'Дата погрузки',
            field: 'dateFromTextLoad',
            cols:         1,
            topColsBlock: 5,
            required: true,
            format: FieldFormat.FORMAT_DATETIME_LOCAL,
            // type:   'datetime',
            object: ColObjectShowDateTime,
            defaultSort : 'asc'

        },
    ]

    React.useEffect(()=>{
         let mainCols =  []
        Promise.all([
            config.docRequest.cols({}, {}),
            config.docRequest.subItems({})
        ]).
        then(result => {
            mainCols = [...result[0]];
            return result[1].list.find(el => el.field === 'routePlaces').cols({}, {});
        }).
        then( subCols => {
            const newSubCols  = subCols.map(el => {
                el.topColsBlock = 6
                el.cols = 1
                el.required = true
                return el
            })
            const newMainCols = mainCols.map(el => {
                el.cols = 1
                return el
            })


            setCols([customCols.shift(), ...newMainCols, ...newSubCols, ...customCols])
        })
        // console.log("push2", config.docRequest)
    }, [])

    const funCols = (props, items) => {
        const {_step} = items

        return cols.filter(el => list[_step].includes(el.field))
    }
    const onFormDataAction = (values, props) => {
        const {action, value, field} = props;
        const _fix = {}
        const calculationNds = (sum, typeNds) => {
            const nds = optionsNDS.find(o => o.id === values.ndsRateString);
            const rounding   = (value) =>  Math.round((Number(value) + Number.EPSILON) * 100) / 100
            return typeNds
                    ?
                    rounding (sum*nds.rate)
                    :
                    rounding (sum/nds.rate)
        }
        switch (action){
            case FORM_ACTION.INIT:
                break;
            case FORM_ACTION.CHANGE:
                    switch (field){
                        case '_customSumDeliver':
                            _fix.sumDeliveryNds = calculationNds(value, true)
                            break
                        case 'sumDeliveryNds':
                            _fix._customSumDeliver = calculationNds(value, false)
                            break
                        case "ndsRateString":
                            _fix.sumDeliveryNds = calculationNds(values._customSumDeliver, true);
                            _fix.ndsRateValue = optionsNDS.find(o => o.id === values.ndsRateString).rate;
                            break
                        case 'transportValue':
                            const routePlaces = [...values.routePlaces];
                            const [first] = routePlaces;
                            const cargoList =  first.cargos;
                            if(cargoList.length){
                                cargoList.map((el, i) => {
                                    el.cargoValue = calculatorValue(value, values._cargosRatiosList[i]);
                                });
                                _fix.routePlaces = routePlaces
                            }

                            if(value > values.transportValueMax){
                                _fix.transportValueMax = value;
                            }
                            break
                        case 'transportValueMax':
                            if(parseFloat(value) < parseFloat(values.transportValue)){
                                _fix.transportValueMax = values.transportValue;
                            }
                            break
                    }
                break;
            case FORM_ACTION.SUBMIT:
                    const routePlaces = values.routePlaces;
                    const [first] = routePlaces;

                    const cargos = first  ? first.cargos.map(el =>({...el, routePlaces: null, guid: uuidv1()})): [] ;
                    first.dateFromText = values.dateFromTextLoad;

                        const newPoint = {
                            dateFromText : values.dateFromText,
                            isLoading: false,
                            isUnloading: true,
                            docRequest: values.guid,
                            services: [],
                            cargos: cargos,
                            point: values.point,
                            contragent: values.contragent,
                            contactName: values['_contact'],
                            contactPhones: values['_contactPhones']
                        };
                        routePlaces.push(newPoint);
                        _fix.routePlaces = routePlaces;
                        _fix.sumDelivery = values._customSumDeliver;
                    break;
        }

        return {...values, ..._fix}
    }
    const handleSubmit = (values, action) => {
        return new Promise((resolve, reject) => {
            request.put(`/api/multi/doc_requests/${values.guid}`)
                    .set('accept', 'application/json')
                    .send(values)
                    .then(result => {
                        document.location.href = `/doc_requests/${values.guid}/edit`
                    })
        })
    }

    const pointValue = (values, index) => {
        const [first] = values.routePlaces;
        if(first){

            switch (index){
                case 0:
                    return `${first.point_name} (${first.point_route_name || ''})`
                case 1:
                    return  first.dateFromText

            }
        }

        return "---"
    }



    const initialValues = {
        _step: 0,
        ndsRateString:  item.ndsRateString ? item.ndsRateString :INIT_DOC_REQUEST.ndsRateString,
        currency: item.currency ? item.currency : INIT_DOC_REQUEST.currency,
        pointLoad: pointValue(item, 0 ),
        dateFromTextLoad: pointValue(item, 1),
        carbodyTypes: item.carbodyTypes && item.carbodyTypes.length ? item.carbodyTypes : INIT_DOC_REQUEST.carbodyTypes,
        loadingTypes : item.loadingTypes && item.loadingTypes.length ? item.loadingTypes :  INIT_DOC_REQUEST.loadingTypes,
        isAuto: true,
        _cargosRatiosList: cargosRatiosList([...item.routePlaces])
    }

    return (
            <Dialog onClose={() => {
            }} open={true} fullWidth={false} maxWidth={"md"}>
                <DialogTitle>
                    {"Добавить заявку на перевозку"}
                </DialogTitle>
                <DialogContent>
                    <FormDataContextProvider
                            cols={funCols}
                            initial={{
                                ...item,
                                ...initialValues,
                            }
                            }
                            debug={'NestedFieldsForm'}
                            onFormDataAction={onFormDataAction}
                            handleSubmit={handleSubmit}
                            onSuccess={() => {
                            }}
                    >
                        <Body handleSubmit={handleSubmit} cols={cols} listCols={list}/>
                    </FormDataContextProvider>

                </DialogContent>
            </Dialog>
    )
}

const SearchClientProfileLegal = (props) => {
    const {item} = props
    const [client, loadState] = useLoaderApiList('legals', {isCarrier : false})
    const [typedTransport, typedTransportLoadState] = useLoaderApiList('typed_transports', {}, {
        onResponse: (data) => {
           if(item.transportValueMax){
               const dataCar = data.filter(el => el.name.includes('Автомашина'))
               const dataCarSort = dataCar.sort((a, b) => parseInt(a.maxValue) - parseInt(b.maxValue))
               item.typedTransport = dataCarSort.find(el => parseInt(el.maxValue) >= parseInt(item.transportValueMax)).guid || ''
           }

            return data
    }
    })
    return (
            <LoadStateShow state={loadState === typedTransportLoadState ? loadState: 2} error={''}>
                {
                    client && typedTransportLoadState
                        ? <DialogBox item={{...item, contragent: client.length ? [...client].shift().guid : ''}} />
                            : <></>
                }

            </LoadStateShow>
    )
}



const FormB2B = ({guid}) => {
    const [item, loadState] = useLoaderApi('doc_requests', guid)
    return (
            <LoadStateShow state={loadState} error={''}>
                <SearchClientProfileLegal item={item} />
            </LoadStateShow>
    )
}

export default FormB2B