import {FieldFormat} from 'config/FieldFormat';
import React from 'react';
import {
    Fab,
    Menu,
    MenuItem,
} from '@material-ui/core';
import API from 'libs/api';

import {
    AddIcon,
} from 'components/icons';
import {MoreVert as MoreIcon} from '@material-ui/icons';



const api = new API();

function TableRowMenu({handleEdit, handleDelete}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const onClickEdit = () => {
        handleClose();
        handleEdit();
    };
    
    const onClickDelete = () => {
        handleClose();
        handleDelete();
    };
    
    return (
        <div>
            <MoreIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Open Menu
            </MoreIcon>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={onClickEdit}>Редактировать</MenuItem>
                <MenuItem disabled onClick={onClickDelete}>Удалить</MenuItem>
            </Menu>
        </div>
    );
}

class PageSimpleShow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open:     false,
            anchorEl: null,
        };
        this.cols  = [...this.props.cols];
        
        if (this.props.canEdit) {
            this.cols.unshift(
                {
                    field:  'edit',
                    title:  <MoreIcon/>,
                    object: this.Edit,
                    format: FieldFormat.FORMAT_NONE,
                },
            );
        }
        // const [anchorEl, setAnchorEl] = React.useState(null);
        // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
        // this.isMenuOpen = Boolean(this.state.anchorEl);
        this.handleMenuClose = () => {
            this.setState({anchorEl: null});
            this.handleMobileMenuClose();
        };
        
        this.handleMobileMenuClose = () => {
            this.setState({anchorEl: null});
        };
        
        this.handleMenuOpen = row => event => {
            this.setState({
                anchorEl: event.currentTarget,
                values:   row,
            });
        };
    }
    
    componentDidMount() {
        this.update();
    }
    
    update = () => {
        this.props.loadData();
    };
    
    isItemCanDelete = (item) => {
        return true;
    };
    
    Edit = (props) => {
        const {row} = props;
        return <TableRowMenu handleEdit={this.onEdit(row)}/>;
    };
    
    onEdit = row => () => {
        this.setState({open: true, values: row});
    };
    
    handleClose = () => {
        this.setState({open: false});
    };
    
    handleSubmit = (values, action) => {
        return api[action](this.props.apiName, values);
    };
    
    render() {

        
        const {canAdd, fabStyle} = this.props;
        return <React.Fragment>
            {/*<RowMenu />*/}
            TableSimpleShow
            {/*<TableSimpleShow*/}
            {/*    list={list}*/}
            {/*    cols={cols}*/}
            {/*/>*/}
            DialogEditCreate
            {/*<DialogEditCreate*/}
            {/*    dbId={"guid"}*/}
            {/*    itemName={itemName}*/}
            {/*    open={open}*/}
            {/*    handleSubmit={this.handleSubmit}*/}
            {/*    handleClose={this.handleClose}*/}
            {/*    onSuccess={this.update}*/}
            {/*    initialValues={values}*/}
            {/*    FormComponent={RequestForm}*/}
            {/*    canDelete={canDelete}*/}
            {/*    cols={cols}*/}
            {/*    canDeleteItem={this.isItemCanDelete}*/}
            {/*/>*/}
            {canAdd && <Fab
                color="primary"
                aria-label="Add"
                title={'Добавить'}
                style={fabStyle}
                onClick={this.onEdit({})}
            >
                <AddIcon/>
            </Fab>}
        </React.Fragment>;
    }
}

PageSimpleShow.defaultProps = {
    fabStyle: {
        position: 'absolute',
        bottom:   '10px',
        right:    '10px',
    },
};

export default PageSimpleShow;
