import SelectorTable from 'components/SelectorTable';
import React, {
    useEffect,
} from 'react';

import {
    useSelector,
    useDispatch,
} from 'react-redux';
import {
    Typography,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Button,
} from '@material-ui/core';
import system from 'actions/system';
import {Close as CloseIcon} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => (
    {
        appBar: {
            position: 'relative',
        },
        title:  {
            marginLeft: theme.spacing(2),
            flex:       1,
        },
    }
));

export default function Selector(props) {
    
    const classes = useStyles();
    
    let {
            apiName,
            handleClose,
        } = props;
    
    const list     = useSelector(state => state.system[apiName]);
    const dispatch = useDispatch();
    
    useEffect(() => {
        // if (!list) {
        dispatch(system.fetch(apiName));
        // }
    }, [dispatch, apiName]);
    
    const rows = props.rows
        || Object
            .keys((list && list.items && list.items[0]) || {})
            .map(i => (
                {title: i, field: i}
            ))
        || [];
    
    if (!props.rows) {
        console.log('Generated cols:', JSON.stringify(rows, null, 2));
    }
    return <React.Fragment>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Выбор элемента из списка
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                    Отмена
                </Button>
            </Toolbar>
        </AppBar>
        {/*<List>*/}
        {/*    <ListItem button>*/}
        {/*        <ListItemText primary="Phone ringtone" secondary="Titania" />*/}
        {/*    </ListItem>*/}
        {/*    <Divider />*/}
        {/*    <ListItem button>*/}
        {/*        <ListItemText primary="Default notification ringtone" secondary="Tethys" />*/}
        {/*    </ListItem>*/}
        {/*</List>*/}
        
        <Paper>
            <SelectorTable
                {...props}
                cols={rows}
                list={list}
            />
        </Paper>
    
    </React.Fragment>;
    
}