import {asGUID} from 'libs/asGUID';
import React from 'react';
import {ContactInfoIcon} from 'components/icons';
import {
    ApiDataLoadLookup,
    ColObjectName,
} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {
    Select,
    MenuItem,
} from '@material-ui/core';
import {FORM_ACTION, FormDataContext} from '../FormDataContext';
import renderFiasAddress from 'components/MTableExtend/renderFiasAddress';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';



const mapSuggestions = () => (data) => {
    //todo: work fias
    let levelsLook    = [
        'country', 'region', 'area', 'city', 'settlement', 'street', 'house', 'flat',
    ];
    const levels = levelsLook.map(lvl => {

            return {
                level:            lvl,
                kladr_id:         data[lvl + '_kladr_id'] || '',
                fias_id:          data[lvl + '_fias_id'] || '',
                object_type:      data[lvl + '_type'] || '',
                object_type_full: data[lvl + '_type_full'],
                object:           lvl === 'house' && data['block']
                                    ? `${data[lvl]}  ${data['block_type']} ${data['block']}`
                                    : data[lvl] || '',
                object_with_type: data[lvl + '_with_type'],
                geo_lat:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lat']
                                      : 0,
                geo_lon:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lon']
                                      : 0,
            };
        })
        .filter(v => v)
        .filter(l => l.object !== '' );

    const last    = levels[levels.length - 1];
    const address = [...levels.map(o => {
        return o.object ?
         `${o.object_type} ${o.object}`
        : ''
        })]
        .filter( e => e !== '' )
        .join(', ');

    return {
        info : address,

    };
};

const MyMTableEditField = (props) => {
    const {columnDef, rowData, onRowDataChange, ...propsOther} = props;
    const filterValue                                          = rowData['contactKind'];
    return (
        <Select
            {...propsOther}
            value={props.value === undefined
                ? ''
                : props.value.replace('/api/contact_types/', '')}
            onChange={event => props.onChange(event.target.value)}
            style={{
                fontSize: 13,
            }}
        >
            {Object
                .keys(columnDef.lookup)
                .filter(key => columnDef.lookup[key].type === filterValue)
                .map(key => (
                        <MenuItem key={key}
                                  value={key}>{columnDef.lookup[key].name}</MenuItem>
                    ),
                )}
        </Select>
    );

};

const infoAdd = (contactKind, isForeign) => {

    switch (contactKind){

        case 'Адрес':
            return {
                title: 'Адрес',
                field: 'info',
                api: 'search/address',
                mapSuggestions: mapSuggestions('address'),
                format: FieldFormat.FORMAT_SELECT_DADATA,
            }
        case  'Телефон' :
            return  {
                title: 'Телефон',
                field: 'info',
                format: FieldFormat.FORMAT_INPUT,
                mask: isForeign
                        ? `+${'0'.repeat(15)}`
                        : "+7(000) 000-00-00",
            }
        case null:
            return  {
                format: FieldFormat.FORMAT_SIMPLE_TEXT_BOX,
                message: 'Заполните вид контактной информации',
            }
        default:
            return  {
                title: 'Информация',
                field: 'info',
                format: FieldFormat.FORMAT_INPUT,
            }
    }
}


export default {
    listTitle:        'Контакты фирмы',
    apiName:          'contact_infos',
    itemName:         'Контактную информацию',
    icon:             <ContactPhoneOutlinedIcon/>,
    // filterParent: 'parentGuid',
    // breads:       [
    //     {
    //         apiName: 'legals',
    //         field:   'parentGuid',
    //     },
    // ],
    canEdit: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },
    canDelete: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },
    filterParent: (props) => {
        const {menuRoot, legal, _items} = props;
        const {_self} = _items || {};
        const {contragent} = _self || {};
        let filter = {
            'legal.isCarrier': menuRoot.id === 'carrier',
        };
        if (contragent) {
            filter['legal[]'] = [asGUID(contragent), legal];
        }
        return filter
    },
    menu: {
        icon: ContactInfoIcon,
    },
    onFormDataAction: (values, props) => {
        const {old, action, field, refreshItem} = props;
        switch (action) {
            case FORM_ACTION.CHANGE:
                const contactKindNew = values.contactKind
                    && asGUID(values.contactKind)
                const newName = values._items
                    ? [
                        values._items._self && values._items._self.name,
                        values._items.contactKind && values._items.contactKind.name,
                        values._items.contactType && values._items.contactType.name,
                    ].join(' ')
                    : values.name
                const newData =  {
                    ...values,
                    contactKind: contactKindNew,
                    contactType: old && old.contactKind === contactKindNew
                                     ? values.contactType
                                     : null,
                    name:        newName,
                };
               
                if (old && old.contactKind !== contactKindNew){
                    newData.info = '';
                    refreshItem && refreshItem(newData);
                }    
                return newData;
            default:
                return values;
        }
    },
    initialValues:    () => ({
        contactKind: null,
    }),
    cols:             (props, values) => {

        const isForeign = props && props._topProps && props._topProps.isForeign

        const {contactKind} = values

        return [
            {
                title:    'Вид',
                field:    'contactKind',
                required: true,
                format:   FieldFormat.FORMAT_SELECT_API,
                api:      'contact_type_kind',
                object: ColObjectName,
                cols: 2,
            },
            {
                title:         'Тип',
                field:         'contactType',
                api:           'contact_types',
                apiFilter:     values => (
                    {'type': values.contactKind && values.contactKind.replace('/api/contact_type_kind/', '')}
                ),
                object: ColObjectName,
                format: FieldFormat.FORMAT_SELECT_API,
                cols: 2,
            },
            infoAdd(contactKind, isForeign)
            // {
            //     "title": "comment",
            //     "field": "comment"
            // },
            // {
            //     "title": "info",
            //     "field": "info"
            // },
            // {
            //     title:        'Наименование',
            //     field:        'name',
            // },
        ];

    },
};
