import React from "react";
import LoadStateShow from "../LoadStateShow";
import {useLoaderApiList} from "../useLoaderApi"

const Picture = ({link, soursImg}) => {

    const [src, loadState] = useLoaderApiList(
            `help/attachment`,
            {
                link: link,
                file: soursImg
            },
            {
                allResponse: true,
                type: 'x-binary',
                returnAll: true,

            }

    )


    return (
            <LoadStateShow state={loadState} error={JSON.stringify(src)}>
                <div className="scroll-container">
                    <img src={`data:image/png;base64, ${src && src.text ? src.text : ''}`} alt={soursImg}/>
                </div>
            </LoadStateShow>
    )
}

export default Picture