import React from 'react';
import {FORM_ACTION, FormDataContext, FormDataContextProvider} from "../FormDataContext";
import {config} from 'config/'
import {ApiDataLoad} from "./ApiDataLoad";
import Body from "components/selfRegistrationBody";


const initialValues = {
    _step: 0,

}

const PageSelfRegistration = ({handlerSelfRegistration, setOptions}) => {
    const handleSubmit = (values, action) => {
        return new Promise((resolve, reject) => {
            ApiDataLoad('/api/registration/new_user', values, {method: "put"})
                    .then(data => data && setOptions(data))
        })
    }
    return (
            <>
                <FormDataContextProvider
                        cols={config.selfRegistration.cols}
                        initial={{
                            ...initialValues
                        }
                        }
                        debug={'NestedFieldsForm'}
                        onFormDataAction={config.selfRegistration.onFormDataAction}
                        handleSubmit={handleSubmit}
                        onSuccess={()=>{}}

                >
                    <Body
                            listCols={config.selfRegistration.listCols.createCols}
                            cols={[]}
                            topColsBlocks={config.selfRegistration.topColsBlocks}
                            handlerSelfRegistration={handlerSelfRegistration}
                            listTitle={config.selfRegistration.listTitle}
                    />
                </FormDataContextProvider>
            </>
    )



}

export default PageSelfRegistration