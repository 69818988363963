export default function detectCols(props, list, other) {
    const item = list && list[0] || {};
    const cols = props.cols
        || Object.keys(item).map(i => ({title: i, field: i}))
        || [];

    if (!props.cols || 'function' == typeof props.cols) {
        console.log('Generated cols:', JSON.stringify(Object.keys(item).map(i => ({title: i, field: i})), null, 2));
    }
    return cols;
};
