import useStyles from "../styles";
import MUIPaper from "@material-ui/core/Paper";
import React from "react";

export default function Paper(props){
    const classes = useStyles();
    return <MUIPaper className={classes.MuiPaper}>
        {props.children}
    </MUIPaper>;
};

