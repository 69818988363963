import useLoaderApi from "../useLoaderApi";
import {asGUID} from "../../libs/asGUID";
import LoadStateShow from "../LoadStateShow";
import {Badge, Box, Typography, Tooltip} from "@material-ui/core";
import React from "react";
import {RenderIconActuality} from 'config/legals'

export const CreateLabel = (guid, type, lastDate, countNotShow, el = {}) => {
    const [item, loadState] = useLoaderApi(type, asGUID(guid), {})
    // console.log("push2", guid, lastDate)
    if (countNotShow) {

        return (
            <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(item)}</>}>
                <Badge badgeContent={countNotShow} color={"error"}>
                    <Box m={1} display={"flex"} position={"relative"}>

                        <Typography variant='body2'>{item ? item.name : ''}</Typography>

                        {lastDate && <Box position={'absolute'} style={{top: "-20px", right: "-35px"}}>
                            <Typography variant='overline' style={{fontSize: "0.5rem"}}>{`${lastDate}`}</Typography>
                        </Box>}
                    </Box>
                </Badge>
            </LoadStateShow>
        )
    }
    return (
        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(item)}</>}>
            <Typography variant='body2'>{item ? item.name : ''}</Typography>
        </LoadStateShow>
    )

}


const NotGuid = ({guid}) => {
    return (
        <Tooltip title={guid || ''}>
            <Typography variant="body2">Неизвестный</Typography>
        </Tooltip>
    )

}


export const ShowTypeLabel = ({ guid, type, lastDate, countNotShow, room, el = {} }) => {
    const [item, loadState] = useLoaderApi(type, asGUID(guid), {}, { initial: {}, timeout: 0 });
    // return <Typography variant='body2'>TODO LOAD:{type} {guid}</Typography>
    return <LoadStateShow state={loadState} error2={<>ERROR: {JSON.stringify(item)}</>}>
        <Box pt={1}>
        <Badge badgeContent={countNotShow} color={"error"}>
            {/*<Box m={1} display={"flex"} position={"relative"}>*/}
            <Box display={"flex"} alignItems="center">
                {item && item.isCarrier && <RenderIconActuality name={item.actualityStateName}/>}
                <Typography variant='body1'>{item && item.name || <NotGuid guid={guid}/>}</Typography>
            </Box>


            {lastDate && <Box position={'absolute'} style={{ top: item && item.isCarrier ? "-12px" : "-18px" }}>
                <Typography variant='overline' style={{ fontSize: "0.5rem" }}>{`${lastDate}`}</Typography>
            </Box>}
            {/*</Box>*/}
        </Badge>
        </Box>
    </LoadStateShow>

}