import {
    ColBoolean,
    ColObjectName,
    ShowDate,
    ShowLegal,
} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import PageContractsLeagal from 'page/PageContractsLeagal';
import {
    ContractIcon,
} from 'components/icons';
import {ROLES} from 'config/roles';
import optionsNDS from './optionsNDS';

export default {
    listTitle: 'Договоры',
    apiName:   'contracts',
    itemName:  'Договор',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Договоры',
        path:      '/contracts',
        Component: PageContractsLeagal,
        roles:     [ROLES.ROLE_USER],
        icon:      ContractIcon,
    },
    cols:      ({path}) => [
        {
            title:     'Моя компания',
            field:     'legal',
            object:    ShowLegal,
            apiFilter: () => (
                {isCarrier : false,}
            ),
            disabled: true,
            format:    FieldFormat.FORMAT_SELECT_API,
            api:       'legals',
            cols:  2,
        },
        {
            title:     'Перевозчик',
            apiFilter: () => (
                    {isCarrier: true}
            ),
            object:    ShowLegal,
            field:     'contragent',
            format:    FieldFormat.FORMAT_SELECT_API,
            api:       'legals',
            required: true,
            cols:  2,
        },
        {
            title: 'Наименование',
            field: 'name',
            cols:  3,
        },
        {
            title: 'Номер договора',
            field: 'number',
            cols:  3,
        },
        {
            title:  'Действующий',
            field:  'actual',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            cols:   3,
        },
        {
            title:  'Дата',
            field:  'date',
            object: ShowDate,
            format: FieldFormat.FORMAT_DATE,
            cols:   3,
        },
        {
            title:  'Дата окончания',
            field:  'dateEnd',
            object: ShowDate,
            format: FieldFormat.FORMAT_DATE,
            cols:   3,
        },
        {
            title:   'Тип договора',
            field:   'type',
            cols:    3,
            format:  FieldFormat.FORMAT_SELECT,
            required: true,
            options: [
                {
                    id:   'Экспедиции',
                    name: 'Экспедиции',
                }, {
                    id:   'Договор перевозки',
                    name: 'Договор перевозки',
                },
            ],
        },
        {
            field:  'currency',
            title:  'Валюта',
            object: ColObjectName,
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'currencies',
            cols:   3,
        },
        {
            title:   'Ндс',
            field:   'nds',
            format:  FieldFormat.FORMAT_SELECT,
            options: optionsNDS,
            cols:    3,
        },

    ],
};
