import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {TabsHorizontal} from "./TabsHorizontal";
import {TabsVertical} from "./TabsVertical";
import ShowMessages from "./ShowMessages";


export const ChatBody = (props) => {
    const {
        objectText, rooms, tabHorizontal, setTabHorizontal, createNewMessage, setCreateNewMessage,
        objectShow,
        setObjectShow,
        group,
        tabGroup,

        setTabGroup,
    } = props;


    // const group = objectText[objectShow];

    return (<>

        </>
    )

}