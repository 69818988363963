import React from "react";
import {FormDataContext} from "../FormDataContext";
import {Badge, Typography} from "@material-ui/core";

export const TextTooltipCargo = () => {
    const {values} = React.useContext(FormDataContext);

    if(Array.isArray(values)){
        return <Typography variant={'h6'}>{`Грузы`}</Typography>
    }
    const text = values.isLoading
            ? 'погрузка'
            : 'разгрузка';

    return <span>{`Грузы (${text})`}</span>
}

export function IconContextCount(props) {
    const {field} = props;
    const {values} = React.useContext(FormDataContext);

    const Icon = ({isLoading}) => {
        const icon = isLoading
                ? "/kanbanIcons/place_of_loading.svg"
                : '/kanbanIcons/place_of_unloading.svg';
        return (
                <img
                        src={icon}
                        className=""
                        width="24px"
                />)
    }

    if(field.toLowerCase().includes('cargo')) {

        return (
                <Badge
                        badgeContent={(values[field] || []).length}
                        color="primary">
                    <Icon isLoading={values.isLoading}/>
                    <props.icon/>
                </Badge>
        )
    }
    return (
            <Badge
                    badgeContent={(values[field] || []).length}
                    color="primary">
                <props.icon/>
            </Badge>
    )
}