import React from "react";
import useLoaderApi from "./useLoaderApi";
import {STATE} from "../libs/api";
import DoDownload from "../libs/DoDownload";
import  ValidateDownloadPrintedFormsDialog from './ValidateDownloadPrintedFormsDialogContent'
import {Dialog} from "@material-ui/core";


const ValidateDownloadPrintedForms = (props) => {
    const {apiName, guid, obj, type, getParameters, children, onClose} = props;
    // const query = obj;
    const [query, setQuery] = React.useState(obj);

    const [checkArrayData, setCheckArrayData] = React.useState([]);
    const onError = error => {
        if (error.response.statusCode === 400) {
            error.response.body.text()
                    .then(JSON.parse)
                    .then(setCheckArrayData);
        }
    }

    const [item, loadState, forceReloadData] = useLoaderApi(apiName, `${guid}.${type}`, query, {
        onError,
        allResponse: true
    });
    const [confirmSendDocuments, setConfirmSendDocuments] = React.useState(false)

    const confirm = 'Продолжить';
    const cancel = 'Отмена';
    const sendEmail = type.includes("email");
    const attach = type.includes("attach");

    const handleSubmit = () => {
        if (sendEmail) {
            setConfirmSendDocuments(true)
        } else {
            setQuery({...query, forced: true});
        }
    }

    const onSend = emails => () => {
        setQuery({...query, forced: true, emails});
    }

    if(loadState === STATE.LOADED && !sendEmail && !attach){
        const [, filenameVar] = (item.headers['content-disposition'] ?? '').split(';')
        const [, filename] = filenameVar.split('=');
        return <DoDownload
                output={item.text || item.body}
                type={item.type}
                filename={decodeURIComponent(filename.replace( /^UTF-8''(.+)$/, '$1').replace(/\+/g, '%20'))}
        />

    }

    return (
            <Dialog open={true} maxWidth={"lg"} onClose={loadState !== STATE.LOADING ? () =>{} : onClose}>
                <ValidateDownloadPrintedFormsDialog
                        loadState={loadState}
                        confirmSendDocuments={confirmSendDocuments}
                        onSend={onSend}
                        checkArrayData={checkArrayData}
                        onClose={onClose}
                        confirm={confirm}
                        cancel={cancel}
                        sendEmail={sendEmail}
                        attach={attach}
                        handleSubmit={handleSubmit}
                />
            </Dialog>
    )


};
export default  ValidateDownloadPrintedForms