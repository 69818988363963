import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {TextField} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';
import {FormDataContextConsumer} from 'FormDataContext';
import TextMaskCustom from 'components/FieldFormat/TextMaskCustom.jsx'

function MyComponent(props) {
    const {submitting, item, value, error, handleChange, InputPropsDefault}                                              = props;
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, minValue, maxValue, mask, ...other} = item;
    const [focusElement, setFocusElement] = React.useState(false)

    //Временое решение до решение задачи связаного с контестом.
    const handlerFocus =  (event) => {
        setFocusElement(true)
    }
    const handlerBlur = (event) => {
        setFocusElement(false)
    }
    const valueRender = value => value
            ? parseFloat(value).toLocaleString()
            : 0

    return <React.Fragment>
        <AdminShow>[NUMBER_INPUT]</AdminShow>
        <TextField
                disabled={disabled || submitting}
                onBlurCapture={handlerBlur}
                onFocus={handlerFocus}
                label={title}
                value={focusElement ? value : valueRender(value)}
                required={required}
                margin={'dense'}
                fullWidth
                variant={'outlined'}
                name={field}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                InputLabelProps={{shrink: true}}
                inputProps={{mask: mask, min : minValue, max : maxValue }}
                InputProps={{
                    inputComponent: mask && TextMaskCustom,
                    startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                        values:         context.state.values,
                        setStateValues: context.setStateValues,
                    })}</FormDataContextConsumer>,
                    endAdornment: <FormDataContextConsumer>{context => item && item.endButtons && item.endButtons({
                        values:         context.state.values,
                        setStateValues: context.setStateValues,
                    })}</FormDataContextConsumer>,
                    ...InputPropsDefault
                }}
                {...other}
                type={focusElement ? 'number' : 'string'}
        />
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
            && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field, maxValue, minValue}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        if(e.target.value > maxValue  || e.target.value < minValue){
            return null
        }
        return handleChange(props.item)(e, e.target.value);
    };

    const value = values && values[field];
    const error = errors && errors[field];
    return <MyComponentMemo
            values={values}
            value={value || ''}
            error={error || null}
            handleChange={handleChangeLocal}
            {...props}
    />;
}

export default MyComponentMemoUse;
