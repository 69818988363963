import {Box, Button, Grid, Paper, Tab, Tabs, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import {CancelIcon, InfoIcon, SaveIcon} from 'components/icons';

import LinearProgress from 'components/LinearProgress';
import {AddBreadCrumb} from 'components/Menu';
import PageApiLoader3 from 'components/PageApiLoader3';
import FormDebug from 'components/RequestFormDebug';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import API from 'libs/api';
import React from 'react';
import {Link} from 'react-router-dom';



function ActionButtons(props) {
    const {actionAddTitle, actionUpdateTitle, canDeleteItem, handleClose, canDelete, initialValues, dbId} = props;

    const {state, handleSubmitForm} = React.useContext(FormDataContext);
    const {submitting}              = state;
    const isUpdate                  = initialValues && initialValues[dbId];

    const classes = useStyle();
    
    return <div>
        <Grid container>
            <Grid item>
                <Button
                    variant="contained" size="small"
                    color={'primary'}
                    onClick={e => handleSubmitForm(e, state.values)}
                    disabled={submitting}
                    startIcon={<SaveIcon/>}
                >
                    {isUpdate
                        ? actionUpdateTitle
                        : actionAddTitle}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="small"
                    disabled={submitting}
                    onClick={handleClose}
                    startIcon={<CancelIcon/>}
                    className={classes.mx_1}
                >
                    Отмена
                </Button>
            </Grid>
            <Grid item>
                {isUpdate && canDelete &&
                canDeleteItem(state.values) &&
                <ConfirmDialog
                    onConfirm={e => handleSubmitForm(e, {...state.values, isDeleted: true})}
                    trigger={<Button
                        color={'secondary'}
                        disabled={submitting}
                    >
                        Удалить
                    </Button>}
                />
                }
            </Grid>
            <Grid item xs={12}>
                <LinearProgress show={submitting}/>
            </Grid>
        </Grid>
    </div>;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function LinkTab(props) {
    const {value, path} = props;
    return (
        <Tab
            component={Link}
            to={path + '/' + value}
            // onClick={(event) => {
            //     event.preventDefault();
            // }}
            {...props}
        />
    );
}

const useStyle = makeStyles((theme) => (
    {
        root: {
            flexGrow:        1,
            backgroundColor: theme.palette.background.paper,
            display:         'flex',
            // height:          224,
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
        mx_1: {
            margin : '0 10px',
        }
    }
));

const defaultProps = {
    onFormDataAction:  values => values,
    canDeleteItem:     values => values,
    actionAddTitle:    'Добавить',
    actionUpdateTitle: 'Сохранить',
};

/**
 * @deprecated update to FormEditCreateTabs
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormEditCreate(props) {
    console.error('OLD usage of FormEditCreate')
    const {
              dbId,
              cols,
              open,
              onSuccess,
              handleSubmit,
              initialValues,
              itemName,
              topColsBlocks,
              subFormHandleChange,
              onFormDataAction,
              FormComponent,
              subApi,
              path,
              actionAddTitle,
              actionUpdateTitle,
              helpPrefix,
          } = {...defaultProps, ...props};

    const [tab, setTab] = React.useState();
    const isUpdate      = initialValues && initialValues[dbId];

    const handleChangeTab = (event, tab) => {
        setTab(tab);
    };

    const Form = subFormHandleChange
        ? 'div'
        : 'form';

    const classes = useStyle();

    return (
        <FormDataContextProvider
            initial={initialValues}
            onFormDataAction={onFormDataAction}
            handleSubmit={handleSubmit}
            cols={cols}
            dbId={dbId}
            onSuccess={onSuccess}
            debug={'FormEditCreate'}
        >
            <AdminShow>[FormEditCreate]</AdminShow>
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            <Paper>
                <Form>
                    <Box p={3}>
                        {!subFormHandleChange &&
                        <ActionButtons {...defaultProps}
                                       {...props}
                            // submitting={submitting}
                            // handleDelete={handleDelete}
                            // handleSubmit={handleSubmitLocal}
                            // values={values}
                                       isUpdate={isUpdate}
                        />}
                        {helpPrefix && 
                        <Typography variant={'caption'} display="block" style={{marginTop: '0.3rem'}}>{helpPrefix}</Typography>
                        }
                        {itemName && <Typography variant={'h6'}>{isUpdate
                            ? actionUpdateTitle
                            : actionAddTitle} {itemName}
                        </Typography>}
                        <div className={classes.root}>
                            <Grid container>
                                {subApi && <Grid item xs={2}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={tab || 'edit'}
                                        onChange={handleChangeTab}
                                        className={classes.tabs}
                                    >
                                        <LinkTab value="edit" label="Основное" path={path} icon={InfoIcon}/>
                                        {initialValues.guid && subApi && subApi.map(
                                            ({item}, key) => <LinkTab key={key} value={item.apiName} path={path}
                                                                      label={item.listTitle}
                                                                      icon={item.menu && item.menu.icon}
                                            />,
                                        )}
                                    </Tabs>
                                </Grid>}
                                <Grid item xs={subApi
                                    ? 10
                                    : 12}>
                                    <TabPanel value={tab || 'edit'} index={'edit'}>
                                        <AddBreadCrumb label={'Редактировать'}/>
                                        <FormComponent
                                            {...props}
                                            open={open}
                                            // values={values}
                                            // handleChange={handleChange}
                                            // setState={setState}
                                            // handleSuggest={handleSuggest}
                                            // errors={errors}
                                            // submitting={submitting}
                                            topColsBlocks={topColsBlocks}
                                            cols={false}
                                            path={path + '/edit'}
                                        />
                                    </TabPanel>
                                    {subApi && subApi.map(
                                        ({item}, key) => <TabPanel key={key} value={tab}
                                                                   index={item.apiName}>
                                            {/*TODO replace PageApiLoader3*/}
                                            <AddBreadCrumb label={item.listTitle}/>
                                            <PageApiLoader3
                                                canEdit={true}
                                                canAdd={true}
                                                {...item}
                                                filter={{
                                                    legal: initialValues.guid,
                                                    _filter: 1,
                                                }}
                                                parent={{
                                                    [API.fieldCreateName]: [],
                                                    // legal: `/api/${apiName}/${values.guid}`,
                                                    // TODO: extend legal to parent?
                                                    legal:                 initialValues.guid,
                                                    _parent: 1,
                                                }}
                                                path={path + '/' + item.apiName}
                                                fullScreen={true}
                                            />
                                        </TabPanel>,
                                    )}
                                    <FormDataContextConsumer>{context => {
                                        const {submitError} = context.state;
                                        return submitError && <Alert severity="error">Ошибка: {submitError}</Alert>;
                                    }}</FormDataContextConsumer>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Form>
            </Paper>
            <FormDataContextConsumer>{context => <FormDebug
                values={context.state.values}
                errors={context.state.errors}/>
            }</FormDataContextConsumer>
        </FormDataContextProvider>
    );
}
