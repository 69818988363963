import React from "react";
import  LoadStateShow from  "components/LoadStateShow"
import {useLoaderApiList} from "../useLoaderApi";
import MarcDown from "./MarcDown";

const ContentHelp = ({link, changeLink, activeLink}) => {
    const [item, loadState, forceReloadData] = useLoaderApiList('help/page',
            {
                link: link,
            },
            {
                onResponse: data => {
                    return {
                        content: data.content || 'Раздел не заполнен.',
                        images: data.images || [],
                    }
                }
            }
    )

    const clickMarkDownLink = (link) => (e) => {
        e.preventDefault()
        // console.log("push2", link)
        if(link.includes("http://") || link.includes("https://") ){
            window.open(link);
            return
        };
        changeLink(link)
        forceReloadData && forceReloadData()
    }

    return (
            <>
                <LoadStateShow state={loadState} error={item}>

                    {
                        item && item.content && <MarcDown link={link} item={item} clickMarkDownLink={clickMarkDownLink} activeLink={activeLink}/>
                    }
                </LoadStateShow>
            </>
    )

}

export default  ContentHelp