import React from 'react';
import request from 'superagent';
import {
    CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText, Switch,
    TextField, Typography,
} from '@material-ui/core';

import Autocomplete from "./Autocomplete";
import TextMaskCustom from 'components/FieldFormat/TextMaskCustom.jsx'
import {
    debounce,
} from 'lodash';
import {useLoaderApiList} from "./useLoaderApi";
import {STATE} from "libs/api";
import asArray from "libs/asArray";
import ErrorBoundary from "./ErrorBoundary";
import {Star as StarIcon} from '@material-ui/icons';
import EndAdornmentComponent from "../components/EndAdornmentComponent";
import { withStyles } from "@material-ui/core/styles";
import {config} from "../config";


/** need migrate to FormApiAutocompleteOther */
export default function FormApiAutoSuggest(props) {
    const {textSecondary} = props.item || {};
    const defaultProps = {
        freeSolo:      true,
        searchField:   'inn',
        method:        'POST',
        freeSoloField: 'country',
        onResponse:    data => data,
        mapItem:       data => data,
        onChange:      data => data,
        renderOption:   (option) => {
            const secondary = textSecondary && textSecondary(option);
            return <ErrorBoundary info={'FormApiAutocompleteOther'}>
                {/*<ListItem ContainerProps={{style:{width:'100%'}}} dense={1}>*/}
                <div style={{position:'relative', width:'100%'}}>
                <ListItemText primary={option.name} secondary={secondary} />
                {secondary &&<ListItemSecondaryAction>
                    <StarIcon color={"secondary"}/>
                </ListItemSecondaryAction>}
                </div>
                {/*</ListItem>*/}
            </ErrorBoundary>
        }
    };
    const {
        api,
        label,
        value,
        valuesAll,
        onSuggest,
        placeholder,
        mask,
        apiFilter,
        required,
        helperText,
        freeSolo,
        searchField,
        method,
        freeSoloField,
        onResponse,
        mapItem,
        onChange,
        error,
        check,
        field,
        renderOption,
    } = {...defaultProps, ...props};

    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(value);

    const query = {[searchField]: inputValue};
    const onError = e => console.log('error', e);

    const [options, loadState] = useLoaderApiList(api, query, {
        initial: [],
        method,
        onResponse,
        onError,
        mapItem
    });

    console.log({options})

    const loading = loadState === STATE.LOADING;

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    const onChangeLocal = (event, value) => {
        console.log('fetch onChangeLocal', value);
        if (!value) {
            // eslint-disable-next-line
            onChange({
                target: {
                    value:           null,
                    guid:            null,
                    item:            null,
                    [freeSoloField]: '',
                },
            });
        }
        else if (value.suggest) {
            onSuggest(value && value.suggest);
        }
        else if (value.inputValue) {
            // onChange(null, value.inputValue);
            onSuggest({
                data: {
                    [freeSoloField]: value.inputValue,
                    useCustom:     freeSoloField,
                },
            });
        }
        else {
            onSuggest({data: value});
        }
    };
    /*
    const ValidationTextField = withStyles({
        root: {
            '& input:valid + div + fieldset':       {
                borderLeftWidth: 6,
                borderWidth:     2,
            },
            '& input:valid:focus + div + fieldset': {
                borderLeftWidth: 6,
                padding:         '4px !important', // override inline-style
            },
        },
    })(TextField);
    */




    const TextFieldComponent = (params)  => {
        const baseHelperText = 'Начните вводить для поиска'
        return(
                <TextField
                        {...params}
                        onChange={handleChange}
                        label={label}
                        error={!!error}
                        helperText={error || helperText || baseHelperText}
                        fullWidth
                        variant="outlined"
                        // variant={required ? "filled": "outlined"}
                        margin={'dense'}
                        // color={'secondary'}
                        required={Boolean(required)}
                        InputLabelProps={{shrink: true}}
                        placeholder={placeholder}
                        inputProps={{
                            ...params.inputProps,
                            mask: mask,
                            value: open
                                    ? inputValue
                                    : params.inputProps.value,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            inputComponent: mask && TextMaskCustom,
                            endAdornment: EndAdornmentComponent(
                                    props,
                                    value,
                                    error,
                                    <React.Fragment>
                                        {loading
                                                ? <CircularProgress
                                                        color="inherit"
                                                        size={20}/>
                                                : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                            ),

                        }

                        }

                />
        )

    };


    //todo: fix ValidationTextField
    return <React.Fragment>
        {/*<pre>{JSON.stringify(apiFilter)}</pre>*/}
        {/*<pre>config.get(subConfig):{JSON.stringify(config.get(subConfig))}</pre>*/}
        {/*{subConfig &&<pre>addNewElement:{JSON.stringify(addNewElement)}</pre>}*/}

        <Autocomplete
                freeSolo={Boolean(freeSolo)}
                open={open}
                onOpen={(e) => {
                    setOpen(true);
                    console.log('fetch onOpen',e)
                    setInputValue(value);
                    //setLoading(true);
                }}
                onClose={(e) => {
                    console.log('fetch onClose',e)
                    setOpen(false);
                    setInputValue(null);
                }}
                filterOptions={(options, params) => {
                    const filtered = options;

                    if (freeSolo && params.inputValue !== '') {
                        filtered.push({
                            // id: 'addid',
                            // guid: 'addguid',
                            inputValue: params.inputValue,
                            name:       `Использовать "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                getOptionSelected={(option, value) => value && (
                        option.name === value.name
                )}
                getOptionLabel={option => {
                    console.log('getOptionLabel', option)
                    return (option.name || option || '--')
                }}
                options={!loading
                        ? asArray(options)
                        : []}
                loading={loading}
                value={open
                        ? undefined
                        : {
                            name: value,
                        }}
                onChange={onChangeLocal}
                noOptionsText={'Нет вариантов'}
                renderInput={TextFieldComponent}

                // ListboxComponent={List}
                // ListboxProps={{style: {width:'100%',position:'relative'}}}
                renderOption={renderOption}
                clearText={config.toolTipClearIconText}
        />
    </React.Fragment>;
}
