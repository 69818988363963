import React from 'react';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import AdminShow from 'components/FieldFormat/AdminShow';
import FilterTableParams from 'components/FilterTableParams';

import RequestForm from 'components/RequestForm';
import TableMaterialTableShow from 'components/TableMaterialTableShow';
import API, {STATE} from 'libs/api';
import detectCols from 'libs/detectCols';

import {useHistory} from 'react-router-dom';
import request from 'superagent';
import  {useLoaderApiList} from "./useLoaderApi";
import ConfirmDialog from "components/ConfirmDialog";


const api = new API();


const defaultProps = {
    cols: detectCols,
    apiFilter: () => {}
};

const initialState = {
    open:     false,
    anchorEl: null,
    loadData: () => {},
};
/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageApiShow3(props) {
    const {
              canAdd,
              fabStyle,
              canDelete,
              itemName,
              newTable,
              listTitle,
              canEdit,
              topColsBlocks,
              apiName,
              url,
              filter,
              apiFilter,
              filterParent,
              parent,
              cols,
              handlerInitalData,
              onChange,
              ...other
          }
              = {...defaultProps, ...props};
    
    const history = useHistory();
    
    const [state, setState] = React.useState(initialState);
    
    const {open, values} = state;
    
    const filterParentResult = filterParent
        ? filterParent(parent)
        : {};
    const [filterList, onFilterChange] = React.useState({});
    const [confirmClone, setConfirmClone] = React.useState(false);

    const parentItem = parent && parent._items && parent._items._self;
    const query = {
        ...filter,
        ...(parent||{}).filter,
        ...filterParentResult,
        ...filterList,
        ...apiFilter(parentItem),  /// ???
        _PageApiShow3: 'filters'
    };

    const onResponse =  data => {
        // send loaded data to top form data
        onChange && onChange(null, data);
        return handlerData(data);
    }

    const [items, loadState, forceReloadData] = useLoaderApiList(apiName, query,{onResponse});
    const list = {
        items,
        isFetching: loadState === STATE.LOADING,
    }

    const isItemCanDelete = () => {
        return true;
    };
    
    const onEdit = row => () => {
        // or backLink?
        history.push(
            row
                ? `${url}/${row.guid}/edit`
                // :`/legals/${filter.parent_guid}/${apiName}/new`
                : `${url}/new`,
        );
    };

    const onClone = row => () => {
        // history.push(`${url}/${row.guid}/edit?clone=true`)
        setConfirmClone({guid: row.guid, name: row.name, title: props.listTitle})
    };

    const onDelete = row => {
        return request.put(`/api/multi/${apiName}/${row.guid || row.id}`)
            .set('accept', 'application/json')
            .send({...row, isDeleted: true});
    };
    
    const handleClose = () => {
        setState({open: false});
    };
    
    const handleSubmit = (values, action) => {
        return api[action](apiName, values);
    };

    const handlerData = ( data) => handlerInitalData  ? handlerInitalData(data) : data
   

    // Для удаление лишних заявок, созданных при копирование
    // list && list.items && list.items.length && list.items.map((e, i)=>{
    //     if(i <10){
    //         request.delete(`/api/doc_requests/${e.guid}`)
    //                         .set('accept', 'application/json')
    //                         .then(er => console.log('push2', er.body))
    //                         .catch(e => console.log(e))

    //     }
    // })

    return <React.Fragment>
        <AdminShow>[PageApiShow3]</AdminShow>
        <ConfirmDialog
                show={confirmClone}
                title={'Подвердите действие'}
                text={`Копировать ${confirmClone.title} ${confirmClone.name}?`}
                onConfirm={() => {
                    history.push(`${url}/${confirmClone.guid}/edit?clone=true`)

                }}
                onCancel={() => {
                    setConfirmClone(false)
                }}
            />
        <FilterTableParams apiName={apiName} cols={cols} handleChange={onFilterChange} initialValues={filterList}/>
        <TableMaterialTableShow
            list={list}
            cols={cols}
            listTitle={listTitle}
            newTable={true}
            canAdd={canAdd}
            onEdit={onEdit}
            onDelete={onDelete}
            onClone={onClone}
            loadData={forceReloadData}
            canEdit={canEdit}
            canDelete={canDelete}
            url={url}
            {...other}
        />
        
        <DialogEditCreate3
            dbId={'guid'}
            fullScreen
            itemName={itemName}
            open={open}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onSuccess={forceReloadData}
            initialValues={values}
            FormComponent={RequestForm}
            canDelete={canDelete}
            cols={cols}
            topColsBlocks={topColsBlocks}
            canDeleteItem={isItemCanDelete}
        />
    </React.Fragment>;
}
