import React from "react";
import API from "libs/api";
import clsx from "clsx";
import {
    Dialog,
    Grid,
    AppBar,
    Tabs,
    Tab,
    DialogTitle,
    Typography,
    DialogContent,
    TextField,
    FormControl,
    Link,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Box,
    IconButton,
    Button

} from "@material-ui/core"
import {Visibility, VisibilityOff} from "@material-ui/icons";
import ErrorMessage from "./ErrorMessage";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import PageSelfRegistration from "components/PageSelfRegistration";


const GoogleIcon = () => (
    <div style={{marginRight : '5px', marginTop: '5px'}}>
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
                <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                />
                <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"/>
                <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                />
                <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                />
                <path fill="none" d="M0 0h18v18H0z"/>
            </g>
        </svg>
    </div>
);

const B2BIcon = () => {
    return <div style={{marginRight : '5px', marginTop: '5px'}}>
        <img src={'https://b2b.foamline.com/favicon.ico'} />
    </div>
}



const useStyles = makeStyles((theme) => (
    {
        root:         {
            display:  'flex',
            flexWrap: 'wrap',
        },
        margin:       {
            // margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField:    {
            // width: '25ch',
        },
        logo : {
            display: 'block',
            margin: '2rem auto'
        },
        error_message: {
            background: '#fdeded',
            width: '96%',
            margin: '0 2%',
        },
        m_r_1 : {
            marginBottom : '1rem'
        },
        p_bottom_1: {
            paddingBottom: "1rem"
        }

    }
));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

export default function LoginForm(props) {
    console.log('callfunction LoginForm');
    const {setOptions} = props;
    const classes             = useStyles();
    const [values, setValues] = React.useState({
        login:        '',
        password:     '',
        showPassword: false,
        statusEnter: false,
        selfRegistration: false
    });

    const [tab, setTab] = React.useState(0);


    const handleChange            = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});

    };

    const handlerSelfRegistration = (flag) => ()=> {
        setValues(el => ({...el, selfRegistration: flag}))
    }
    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(values.login).toLowerCase());
    }
    const [useB2B, setUseB2B] = React.useState(false);

    React.useEffect(()=>{
        const email = validateEmail();
        if(!values.login.length && !values.password.length) {
            return setValues({...values, statusEnter : true })
        }
        if(!useB2B && values.login.length && !email) {
            return setValues({...values, error: 'Не верный формат почты', statusEnter : true })
        }
        if(email && !values.password.length && tab === 0) {
            return setValues({...values, error: 'Введите пароль' , statusEnter : true })
        }
        if(!values.login.length && values.password.length && tab === 0) {
            return setValues({...values, error: 'Введите почту', statusEnter : true })
        }
        if(email && tab === 1) {
            return setValues({...values, statusEnter: false, error: false })
        }
        setValues({...values, statusEnter: false, error: false })

    }, [values.login, values.password, tab, useB2B])



    const api = new API();

    const onError = (error) => {
        //error.rawResponse
        if (403 === error.status) {
            setValues({...values, error: 'Пользователь не найден'});
        }
        console.log('onError', error.status, JSON.stringify());
    };


    const handleSubmit = (values) => {
        setValues({...values, error: false});
        return api.post(useB2B ? 'auth-b2b': 'auth', {
                login:    values.login,
                password: values.password,
            },
            onError,
        ).then(
            date => date && setOptions(date.body)
        );
    };

    const handleRecover = (values) => {
        setValues({...values, error: false});
        return api.post('recover', {
                email:    values.login,
            },
            error => setValues({...values, error: error.response.body.error})
        ).then(
            date => date && setOptions(date.body),
        );
    };


    const toGoogleAuth = () => {
        window.location.replace(
            window.location.origin + '/connect/google?',
        );
    };

    const toB2BAuth = () => {
        setUseB2B(true);
    }

    const title = useB2B
        ? 'Введите Ваш логин и пароль B2B'
        : 'Введите Вашу почту и пароль'

    if(values.selfRegistration){

        return  (
                <Dialog

                        open={true}
                >
                    <Grid>
                        <img src="/logo_rus.svg" className={classes.logo}/>
                    </Grid>
                    <Grid>
                        <Grid item xs={12}>
                            <PageSelfRegistration
                                    handlerSelfRegistration={handlerSelfRegistration(false)}
                                    setOptions={setOptions}
                            />
                        </Grid>

                    </Grid>
                    <Box m={1}>
                        {/*<Button onClick={handlerSelfRegistration(false)}  fullWidth>*/}
                        {/*    Вернуться*/}
                        {/*</Button>*/}

                    </Box>
                </Dialog>
        )
    }

    return <Dialog

        open={true}
    >
        <Grid>
            <img src="/logo_rus.svg" className={classes.logo}/>
        </Grid>
        <AppBar position="static" color="default">
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"

            >
                <Tab label="Авторизация"/>
                <Tab label="Забыли пароль?"/>
            </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
            <DialogTitle>
                <Typography>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={2}
                        className={classes.m_r_1}
                    >

                        <Grid item xs={12}>
                            <TextField
                                label={useB2B ?'Логин b2b.foamline.com':'Почта для входа'}
                                value={values.login}
                                onChange={handleChange('login')}
                                variant={'outlined'}
                                type={'email'}
                                required
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                className={clsx(classes.margin, classes.textField)}
                                fullWidth
                                variant="outlined"
                            >
                                <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                                <OutlinedInput
                                    label="Пароль"
                                    type={values.showPassword
                                        ? 'text'
                                        : 'password'}
                                    value={values.password}
                                    required
                                    onChange={handleChange('password')}
                                    fullWidth
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword
                                                    ? <Visibility/>
                                                    : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Alert style={values.error?{}:{visibility:"hidden"}} severity="error">{values.error}</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                title={'Войти в систему'}
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={() => handleSubmit(values)}
                                disabled={values.statusEnter}
                                // onClick={addNew}
                            >
                                Войти
                            </Button>
                        </Grid>
                        {useB2B && <Grid item xs={12}>
                            <Button
                                title={'Отмена'}
                                variant={'contained'}
                                color={'default'}
                                fullWidth
                                onClick={() => setUseB2B(false)}
                            >
                                Отмена
                            </Button>
                        </Grid>}

                        {useB2B || <>
                            <Grid item xs={6} className={classes.p_bottom_1}>
                                <Button
                                    title={'Вход через google'}
                                    variant={'contained'}
                                    color={'default'}
                                    justify="center"
                                    alignItems="center"
                                    fullWidth
                                    onClick={toGoogleAuth}
                                >
                                    <GoogleIcon/> Вход google
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={classes.p_bottom_1}>
                                <Button
                                    title={'Вход через B2B'}
                                    variant={'contained'}
                                    color={'default'}
                                    justify="center"
                                    alignItems="center"
                                    fullWidth
                                    onClick={toB2BAuth}
                                >
                                    <B2BIcon/> Вход B2B
                                </Button>
                            </Grid>
                        </>}
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" fullWidth  onClick={handlerSelfRegistration(true)}>
                                Еще не зарегистрированы?
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                Для начала работы на портале свяжитесь с нами:
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Link variant="body2" mr={1} href="tel:+74956604150">+7(495) 660-41-50 доб 01694</Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Link variant="body2" href="mailto:transport@foamline.com"> transport@foamline.com</Link>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

        </TabPanel>
        <TabPanel value={tab} index={1}>
            <DialogTitle>
                <Typography>Укажите почту которую указали при регистрации</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleRecover}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={2}
                        className={classes.m_r_1}
                    >
                        <Grid item xs={12}>
                            <TextField
                                label={'Почта для восстановления'}
                                value={values.login}
                                onChange={handleChange('login')}
                                variant={'outlined'}
                                required
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Alert style={values.error?{}:{visibility:"hidden"}} severity="error">{values.error}</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                title={'Запросить восстановление'}
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={() => handleRecover(values)}
                                disabled={values.statusEnter}
                            >
                                Восстановить
                            </Button>
                        </Grid>

                    </Grid>

                </form>


            </DialogContent>


        </TabPanel>
    </Dialog>;
}

export {LoginForm}