export const getCloneItems = (values, cloneIems) => {
    const {handlerData} = cloneIems
    cloneIems.cloneOblect = values.guid
    if(cloneIems.deleteInField){
         for(const key in cloneIems.deleteInField){
             const result = [...values[key]]
             result.map(item => {
                 cloneIems.deleteInField[key].map(el => {
                     delete item[el]
                 })
             })
             cloneIems[key] = result

         }
    }
    const result = handlerData(cloneIems)
  
    return result
} 