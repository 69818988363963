import {ExposureOutlined as MenuIcon} from '@material-ui/icons';
import {ColObjectName, ColObjectNameOther, ColObjectShowDateTime, ShowCurrency, ShowDate} from 'components/showItems';
import {ROLES} from 'config/roles';
import asArray from 'libs/asArray';
import {ShowLoadingDate, ShowLoadingPlacesFrom, ShowUnloadingPlacesFrom} from 'libs/dateLoadUnload';
import Component from 'page/Tender';
import {FORM_ACTION} from '../FormDataContext';
import {FieldFormat} from './FieldFormat';
import tenderSubItems from './tenderSubItems';
import {asGUID} from "../libs/asGUID";

let STATUS_DRAFT = '2113fe80-55d2-11ed-b81e-005056a60abf';

export default {
    listTitle: 'Тендеры',
    itemName: 'тендер',
    apiName:   'doc_tenders',
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Тендеры',
        path:      '/tenders',
        Component,
        roles:     [ROLES.ROLE_USER],
        icon:      MenuIcon,
    },
    topColsBlocks: [
        {
            num:   1,
            title: 'Информация о тендере',
            cols:  2,
            border: true
        },
        {
            num:   2,
            title: 'Требования к транспорту',
            cols:  2,
            border: true
        },
        {
            num:   3,
            title: 'Финасовые условия',
            cols:  2,
            border: true
        },
        {
            num:   4,
            title: 'Параметры перевозки ',
            cols:  2,
            border: true
        },

        {
            num:   5,
            title: 'Победитель',
            cols:  2,
            border: true
        },
        // {
        //     num:   4,
        //     title: 'Информация о перевозчике',
        //     cols:  2,
        // },
    ],
    initialValues: () => (
        {
            docNumber: 'Присвоится автоматически',
            status:    STATUS_DRAFT,
            docRequestsQty: 1,
            _sub_name: 'Тендер',
            _date_now:           new Date(),
            docDate:   new Date(),
            dateRequestsTo: new Date(),
            dateRequestsFrom: new Date(),

            _CREATE:   {
                newBidding: {
                    apiName:     'biddings',
                    parentField: {
                        docLinkedGuid:  'guid',
                        docLinkedName: '_sub_name',
                        docDate:       '_date_now',
                    },
                },
            },
        }
    ),
    onFormDataAction: (values, props) => {
        const {old, action, value, field, refreshItem} = props;
        let _fix       = {};
        switch (action) {
            case FORM_ACTION.INIT:
                _fix.routePlaces = [
                    ...asArray(values.routePlaces).map(({cargos, ...i}) => ({
                        ...i,
                        loadCargo:   asArray(cargos).filter(i => !i.isUnload),
                        unloadCargo: asArray(cargos).filter(i => i.isUnload),
                    })),
                ];
                // console.log('onFormDataAction', {_fix});
                break;

            case FORM_ACTION.SUBMIT:
                console.log('FORM_ACTION.SUBMIT', {_fix, values})
                    _fix.winnerGuid = asGUID(values.winnerGuid)
                _fix.routePlaces = [
                    ...asArray(values.routePlaces).map(({loadCargo, unloadCargo, ...i}) => ({
                        ...i,
                        cargos: [
                            ...asArray(loadCargo).map(i => ({...i, isUnload: false})),
                            ...asArray(unloadCargo).map(i => ({...i, isUnload: true})),
                        ],
                    })),
                ];
                break;
        }

        return {
            ...values,
            ..._fix,
        };
    },
    colsInTab:     true,
    cols:          [
        {
            title:        'Номер документа',
            field:        'docNumber',
            hidden:       {xsUp: true},
            disabled:     true,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Валюта',
            object:       ShowCurrency,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'currencies',
            field:        'currency',
            cols:         1,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Условия оплаты',
            field:        'paymentConditions',
            format:       FieldFormat.FORMAT_INPUT,
            cols:         1,
            topColsBlock: 3,
            hidden:       true
        },
        {
            title:        'Дата погрузки',
            field:        'routePlacesInfo',
            object:       ShowLoadingDate,
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Откуда',
            field:        'routePlacesInfo',
            object:       ShowLoadingPlacesFrom,
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Куда',
            field:        'routePlacesInfo',
            object:       ShowUnloadingPlacesFrom,
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Номер документа клиента',
            field:        'clientDocNumber',
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Статус',
            field:        'status',
            required:     true,
            topColsBlock: 1,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'doc_tender_statuses',
            object:       ColObjectName,
            cols:         2,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'status[]',
            },
        },
        {
            title:        'Заказчик перевозки',
            api:          'legals',
            apiFilter: () => (
                {isCarrier: false}
            ),
            field:        'customer',
            format:       FieldFormat.FORMAT_SELECT_API,
            topColsBlock: 1,
            cols:         2,
            hidden:       true,
            required:     true,
        },
        {
            title:     'Контактное лицо',
            api:       'contact_people',
            apiFilter: (rowData) => (
                {
                    legal: rowData && rowData.customer,
                }
            ),
            // lookup: lookupContact,
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectNameOther,
            field:        'customerContact',
            topColsBlock: 1,
            cols:         2,
            hidden:       true,
            required:     true,
        },
        {
            title:        'Вид транспорта',
            field:        'typedTransports',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            api:          'typed_transports',
            object:       ColObjectName,
            cols:         2,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:        'Тип Кузова',
            api:          'carbody_types',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            field:        'carbodyTypes',
            topColsBlock: 2,
            cols:         2,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'carbodyTypes[]',
            },
        },
        {
            title:        'Грузоподъемность, кг',
            field:        'transportMass',
            required:     true,
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         1,
            topColsBlock: 2,
            minValue: 0
        },


        {
            title:        'Количество перевозок',
            field:        'docRequestsQty',
            required:     true,
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         1,
            topColsBlock: 4,
        },
        {
            title:        'Объем от, м^3',
            field:        'transportValue',
            required:     true,
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Объем до, м^3',
            field:        'transportValueMax',
            required:     true,
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Дата Начала Перевозок',
            field:        'dateRequestsFrom',
            required:     true,
            format:       FieldFormat.FORMAT_DATE,
            object:       ShowDate,
            cols:         2,
            topColsBlock: 4,
        },
        {
            title:        'Дата Окончания Перевозок',
            field:        'dateRequestsTo',
            required:     true,
            format:       FieldFormat.FORMAT_DATE,
            object:       ShowDate,
            cols:         2,
            topColsBlock: 4,
        },
        {
            title:        'Выигранная ставка',
            field:        'winnerPrice',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 5,
        },
        {
            title:        'Перевозчик',
            field:        'winnerGuid',
            api:          'legals',
            apiFilter: () => (
                    {isCarrier: false}
            ),
            format:       FieldFormat.FORMAT_SELECT_API,
            cols:         2,
            topColsBlock: 5,
        },
       
        // {
        //     title:    'Грузы',
        //     field:    'cargos',
        //     api:      'cargos',
        //     object:   ColObjectName,
        //     multiple: true,
        //     hidden:   true,
        // },
        {
            title:        'Тип загрузки',
            api:          'loading_types',
            field:        'loadingTypes',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },
        {
            title:        'Классы опасности',
            api:          'danger_classes',
            field:        'dangerClasses',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },
        // {
        //     title:        'Уровень видимости документа',
        //     field:        'accessLevel',
        //     format:       FieldFormat.FORMAT_SELECT_API,
        //     api:          'enum_access_levels',
        //     cols:         2,
        //     topColsBlock: 2,
        //     hidden:   true,
        // },
        // {
        //     title: 'userAuthor',
        //     field: 'userAuthor',
        // },
        {
            title:        'Комментарий',
            field:        'comment',
            hidden:       true,
            topColsBlock: 1,
        },
    ],
    subItems:  tenderSubItems
};
