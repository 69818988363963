import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContext} from 'FormDataContext';
import React from 'react';

export default function(props) {
    const {submitting, item} = props;
    const { field, enterType } = item;
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    const setState = (params) => {
        // todo: remove
        console.error('TODO: remove Call setState with params:', params);
    }
    if(enterType){
        item.type = enterType
    }  
    return <React.Fragment>
        <AdminShow>[Custom]</AdminShow>
        <item.custom
            item={item}
            submitting={submitting}
            name={field}
            value={values[field]}
            onChange={handleChange(item)}
            error={!!errors[field]}
            helperText={errors[field]}
            values={values}
            errors={errors}
            setState={setState}
            originalProp={props}
        />
    </React.Fragment>;
}