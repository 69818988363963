import React from 'react';
import FormApiAutoSuggest from 'components/FormApiAutoSuggest';
import {FORM_ACTION, FormDataContext} from 'FormDataContext';
import AdminShow from "./AdminShow";
import FormApiAutocompleteOther from "./FormApiAutocompleteOther";
import {apiSplit} from "../../libs/apiSplit";

export default function({item, parent}) {
    const defaultItem = {check: []}
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, check, ...other} = {...defaultItem, ...item};
    
    const {state, handleChange, setState, onFormDataAction} = React.useContext(FormDataContext);
    const {values, errors}                                  = state;
    
    const handleSuggest = item => suggest => {
        console.log('handleSuggest', suggest, suggest.data);
        if (suggest.data && item.mapSuggestions) {
            let newValues = onFormDataAction(
                item.mapSuggestions(suggest.data, values, {suggestion: suggest, setState}),
                {
                    action:  FORM_ACTION.SUGGEST,
                    old:     values,
                    suggest: suggest,
                    field,
                    item,
                    value:   suggest.data,
                },
            );
            setState(state => (
                {
                    ...state,
                    values:      {
                        ...state.values,
                        ...newValues,
                    },
                    submitError: false,
                }
            ));
        }
    };
    return <React.Fragment>
        <AdminShow>[FormApiAutoSuggest]</AdminShow>
        <FormApiAutoSuggest
            item={item}
            label={title}
            value={values[field]}
            api={api}
            field={field}
            valuesAll={values}
            required={required}
            apiFilter={apiFilter && apiFilter(values, parent)}
            error={errors[field]}
            onSuggest={handleSuggest(item)}
            onResponse={data => data.suggestions || []}
            check={check}
            mapItem={suggest => ({
                suggest,
                name: suggest.value,
            })}
            onChange={handleChange(item)}
            InputLabelProps={{shrink: true}}
            {...other}
        />
    </React.Fragment>;
}