import {STATE} from 'libs/api';
import detectCols from 'libs/detectCols';
import {MeContext} from 'meContext';
import React from 'react';
import {useParams} from 'react-router-dom';
import request from 'superagent';

// HOCLoadItem
/**
 * @depricated
 * @param params
 * @returns {function(*): function(*): *}
 */
export default (params) => WrappedComponent => props => {
    const {multiple, loaded, query, path, field} = {field: 'item', ...params};
    const defaultProps = {
        cols:            detectCols,
        initialValues:   ({item}) => item,
        onElementUpdate: () => {},
        filter: {}
    };

    let {guid} = useParams();

    const {
              apiName,
              // guid,
              initialValues,
              filter,
          } = {...defaultProps, ...props};

    const [loadState, setLoadState] = React.useState(STATE.INIT);
    // const [loadState, setLoadState] = React.useState(
    //     guid || path
    //         ? STATE.INIT
    //         : STATE.LOADED,
    // );
    if(!filter){
        console.error('initialValues HOC no filter', WrappedComponent.name)
    }

    const [item, setItem] = React.useState(multiple ? [] : {});
    const me              = React.useContext(MeContext);

    console.log('initialValues loadState', apiName, loadState);
    React.useEffect(() => {
        if (STATE.INIT !== loadState) {
            return;
        }
        setLoadState(STATE.LOADING);
        const url = path || `/api/${apiName}/${guid}`;
        const loadP =  guid || path
            ? request
                .get(url)
                .set('accept', 'application/json')
                .query(query)
            : Promise.resolve({body:{}}) // emulate load empty object

        loadP
            .then(response => {
                // Заполнение объекта как начальное + загруженное
                const initial = initialValues({menuRoot: props.menuRoot, item: response.body, me});
                console.log('initialValues HOC step2', initial, response.body,filter);
                return Promise.all([
                    response.body,
                    initial,
                ]);
            })
            .then(([values, initial]) => {
                console.log('initialValues HOC step3', {values, initial, filter});
                // Original: initialValues={{...initialValues(props.parent), ...item, ...props.filter}}
                if (multiple) {
                    setItem(values.map(val => ({...initial, ...val, ...filter})));
                }
                else {
                    setItem({...initial, ...values, ...filter});
                }
                setLoadState(STATE.LOADED);
            })
            .catch(error => {
                console.log('TableApiParamsLoader', JSON.stringify(error));
                setItem(`${error.status} ${error.statusText} ${url}`);
                setLoadState(STATE.ERROR);
            });

    }, [loadState, apiName, guid, loaded]);

    const addProps = {
        loadState,
        [field]: item,
        refreshItem: (newValues = {}) => {
            //Временый костыль для изменений значений для field
            newValues ? setItem({...newValues}) : setItem(old => ({...old}))
            // setItem(old => ({...old}))
        },
    };
    return <WrappedComponent {...props} {...addProps} />;
};

// HOCLoadItem.propTypes = {
//     apiName:       PropTypes.string.isRequired,
//     guid:          PropTypes.string.isRequired,
//     initialValues: PropTypes.func,
// };

// export default HOCLoadItem;

