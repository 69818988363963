import React from "react";
import MTableAction from 'material-table/dist/components/m-table-action';
import {useCanOne} from "../useCanCheck";
import IconButton from "@material-ui/core/IconButton";

export const ButtonCanDo = (props) => {
    const {data, action} = props;
    const {canAction, onAction, icon} = {...action};

    const can = useCanOne(canAction, data, {}, false);
    return <IconButton disabled={!can} color="inherit" size={'small'} onClick={onAction && onAction(data)}>
        {icon}
    </IconButton>
}

export function TMSTableAction(props) {
    if (props.action.component) {
        const ActionButton = props.action.component || ButtonCanDo
        return <ActionButton {...props} rowData={props.data}/>;
    }
    return <MTableAction {...props} />
}
