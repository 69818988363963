import request from "superagent";

let cache = {}

function timeDiffLess(time, secDiff) {
    const now = new Date();
    return now - time < secDiff * 1000;
}
export const ApiCacheClear = () => {
    console.log('clear all cache');
    cache = {};
}



export const ApiDataLoad = (apiURI, query = {},opts={}) => {
    const {method, allResponse, type, returnAll} = {method: 'GET', ...opts};

    let key = method + apiURI + JSON.stringify(query);
    if (cache[key]
        && cache[key].time
        && timeDiffLess(cache[key].time,60)
        && cache[key].result
    ) {
        console.log('return from cache',key);
        return Promise.resolve(cache[key].result);
    }else{
        // console.log('no return from cache',key);
    }
    // const fetch = React.useMemo(  async (apiURI) =>{
    const fetch = async () => {
        if (!key) {
            return;
        }
        if(!cache[key]) {
            cache[key] = {time: new Date(), wait: []};
            // console.log('return from cache: create new key', key);
        }

        const result = new Promise((resolve,reject) => {
                cache[key].wait.push({resolve,reject});
                // console.log('return from cache: Promise', key, cache[key].wait);
            }
        );

        if(cache[key].wait.length === 1 ) {

            let load;
            switch(method.toUpperCase()){
                case 'POST':
                    load = request
                        .post(apiURI)
                        .set('accept', 'application/json')
                        .send(query);
                    break;
                case 'PUT':
                    load = request
                            .put(apiURI)
                            .set('accept', 'application/json')
                            .send(query);
                    break
                default:
                    load = request
                        .get(apiURI)
                        .set('accept', `application/${type ? type: 'json'}`)
                        .query(query);
            }
            if (allResponse && !returnAll) {
                load = load.responseType('blob')
            }
            load
                .then(response => {
                    cache[key].time   = new Date();
                    cache[key].result = allResponse ? response : response.body;
                    // console.log('return from cache: callback [wait]',key, cache[key].wait);
                    cache[key].wait = cache[key] && cache[key].wait && cache[key].wait.filter((callback, index) => {
                        callback.resolve(allResponse ? response : response.body)
                        return false;
                    });
                }).catch(e => {
                if (!cache[key]) {
                    return false
                }
                cache[key].wait = cache[key] && cache[key].wait && cache[key].wait.filter((callback, index) => {
                    callback.reject(e)
                    return false;
                });
            })

        }
        // console.log('return from cache: await result', key);
        return result;
        // return await waitResult();
    };
    let r = fetch();
    // console.log('return from cache: callback fetch', key,r);
    return r;
    // fetch(apiURI);
};

// window.ApiDataLoad = ApiDataLoad;
