import React from "react";
import {useLoaderApiList} from "../useLoaderApi";
import asArray from "../../libs/asArray";

const ChatContext = React.createContext({});

const {Provider, Consumer} = ChatContext;


const ChatProvider = ({children}) => {

    // const listeners = [];
    const [listeners, setListeners] = React.useState([]);

    const [rooms, loadStateRooms, reload, setRooms] = useLoaderApiList(
        `chat/rooms`, {}, {
            initial: [],
            noCache: true
        })

    const addEventListener = function (room, callback) {
        setListeners(listeners => {
            console.log('addEventListener', room)
            return {
                ...listeners,
                [room]: [
                    ...asArray(listeners.room),
                    callback
                ]
            }
        })
    };

    const addNewChat = (result) => {
        setRooms(rooms => ([
            result, ...rooms
        ]))
    }

    const removeEventListener = function (room, callback) {
        console.log('removeEventListener', room)
        if (!(room in listeners)) {
            return;
        }
        const stack = listeners[room];
        for (let i = 0, l = stack.length; i < l; i++) {
            if (stack[i] === callback) {
                stack.splice(i, 1);
                return;
            }
        }
    };

    const dispatchEvent = function (message) {
        const {room} = message;
        console.log('dispatchEvent', message, listeners)
        // todo: not set unread
        setRooms(rooms => rooms.map(i => ({
                ...i,
                notRead: i.guid === room
                             ? Number(i.notRead) + 1
                             : i.notRead
            })
        ));
        if (listeners[room]) {
            console.log('dispatchEvent', message, listeners)
            listeners[room].forEach(callback => {
                console.log('dispatchEvent call', callback, message)
                callback(message);
            })
        }
        return !message.defaultPrevented;
    }

    const markAsRead = (data) => {
        setRooms(rooms => rooms.map(room => ({
                ...room,
                notRead: room.guid === data.room && data
                             ? Math.max(0, room.notRead - 1)
                             : room.notRead
            })
        ));
    }

    React.useEffect(() => {
        //15000
        const reloadTimer = setInterval(reload, 15000000);
        return () => {
            clearInterval(reloadTimer);
        }
    }, [])

    return <Provider value={{
        addEventListener,
        removeEventListener,
        dispatchEvent,
        rooms,
        loadStateRooms,
        markAsRead,
        addNewChat,
    }}>
        {children}
    </Provider>
}

export {ChatProvider, Consumer as ChatConsumer, ChatContext};
