import {
    Badge,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableCell,
    TableHead,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {grey} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import {
    FormatIndentDecrease,
    FormatIndentIncrease,
    ListAlt as MenuIcon,
    ReportProblem,
    Timeline,
    VisibilityOutlined,
    Print as Documents,
    ContactSupport,
    CollectionsBookmarkOutlined,

} from '@material-ui/icons';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import {SubFormBiddings} from 'components/FieldFormat/SubFormBiddings';
import FormLoadSub, {SubApiReplace} from 'components/FormLoadSub';
import {BiddingIcon, CalculateIcon, ServiceIcon} from 'components/icons';
import ShowDateTime from 'components/ShowDateTime';
import API from '../libs/api';
import userFiles from 'config/userFiles'
import {config} from 'config';
import  AttachFileOutlinedIcon   from '@material-ui/icons/AttachFileOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';


import ShowMessages from "components/Chat/ShowMessages";
import PageChat from "components/PageChat"


import {
    ApiDataLoadLookup,
    ColObjectFullNameWithColor,
    ColObjectName,
    ColObjectNameOther,
    ShowDangerClass, 
    ColObjectShowDateTime,
    ColObjectNameOptions,
    RealApiObjectLoad,
    ShowCurrency,
    ShowDate,
    ColBoolean,
    ColBooleanSwitch
} from 'components/showItems';
import TabRequestAccesses from 'components/TabRequestAccesses';
import InvaitsPage from 'components/InvaitsPage'
import bidding from 'config/bidding';
import {FieldFormat} from 'config/FieldFormat';
import optionsNDS from 'config/optionsNDS';
import {ROLES} from 'config/roles';
import asArray from 'libs/asArray';
import {
    getMaxDate,
    getMinDate,
    ShowLoadingDate,
    ShowUnloadingDate,
    ShowLoadingPlacesFrom,
    ShowUnloadingPlacesFrom,
} from 'libs/dateLoadUnload';
import 'moment/locale/ru';
import PageDocRequests from 'page/PageDocRequests';
import React from 'react';
import request, { rawListeners } from 'superagent';
import detailPanelRender2and1 from '../components/MTableExtend/detailPanelRender2and1';
import {FormDataContext} from 'FormDataContext';
import {FORM_ACTION} from '../FormDataContext';
import {asGUID} from '../libs/asGUID';
import docRequest_routePlaces from './docRequest_routePlaces';
import PrintendForms from 'components/PrintendForms'
import {ApiDataLoad} from "components/ApiDataLoad";
import FormFieldsShow from "components/FormFieldsShow";
import NestedFieldsForm from "components/NestedFieldsForm";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



// TODO: need update to promise
// const lookupServices      = ApiDataLoadLookup('/api/services', 'guid', 'name');
//const lookupRoutePoints   = ApiDataLoadLookup('/api/route_points', 'guid', false);
// const lookupContactPeople = ApiDataLoadLookup('/api/contact_people', 'guid', 'name');
// const lookupLegals        = ApiDataLoadLookup('/api/legals?exists[contragent]=false', 'guid', 'name');
// const lookupCargoTypes    = ApiDataLoadLookup('/api/cargo_types', 'guid', 'name');

// const lookupServices      = ApiDataLoadLookup('/api/services', 'guid', 'name');
// const lookupRoutePoints   = ApiDataLoadLookup('/api/route_points', 'guid', 'address');
// const lookupContactPeople = ApiDataLoadLookup('/api/contact_people', 'guid', 'name');
// const lookupLegals        = ApiDataLoadLookup('/api/legals', 'guid', 'name');
// const lookupCargoTypes    = ApiDataLoadLookup('/api/cargo_types', 'guid', 'name');

const OWNER_TYPE_OPTIONS = [
    {
        id: 1,
        name: 'Собственность'
    },
    {
        id: 4,
        name: 'Совместная собственность супругов'
    },
    {
        id: 2,
        name: 'Аренда',
        agreement: true
    },
    {
        id: 3,
        name: 'Лизинг',
        agreement: true
    },
    {
        id: 5,
        name: 'Безвозмездное пользование',
        agreement: true
    },
];

export function generateKey(setRowDataKeyId) {
    let key = (
        [1e7] + -1e3 + -4e3 + -8e3 + -1e11
    ).replace(
        /[018]/g,
        c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
    );
    setRowDataKeyId(key);
    return key;
}

const reduceSum = (A, B) => Number(A) + Number(B);

/**
 * Расчет стоимости услуг
 * @param places
 * @returns {number}
 */
const calcSumServiceInPlace = (places) => {
    return Number(
        places && Array.isArray(places)
            ? places
                .map(place => (place.services || [])
                    .map(s => s.serviceCost)
                    .reduce(reduceSum, 0),
                )
                .reduce(reduceSum, 0)
            : 0,
    );
};


let STATUS_NEW = '3b1baecf-98fb-11ea-b814-005056a60abf';

/**
 * Сравнение двух объектов по ключам и значениея
 * TODO: вложенность
 */
function objectDiffFields(A, B) {
    return Object.keys({...A, ...B})
                 .filter((k, v) => {
                     return A[k] !== B[k];
                 });
}


const useStylesBootstrap = makeStyles((theme) => ({
    arrow:   {
        color: theme.palette.grey[500],
    },
    tooltip: {
        backgroundColor: theme.palette.grey[500],
    },
    _log:    console.log(theme.palette),
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

const handlerInitalData = (data) => {
    return data.map((el) => {
        //routePlacesInfo
        const item = {item: el.routePlacesInfo, formatTime : true}
        el.routePlacesInfo_dateFrom_loading= ShowLoadingDate(item)
        el.routePlacesInfo_pointNameLoading = ShowLoadingPlacesFrom(item)
        el.routePlacesInfo_pointNameUnloading = ShowUnloadingPlacesFrom(item)
        el.routePlacesInfo_dateFrom_unloading = ShowUnloadingDate(item)
        return el
    }) 
}
 
function CalcDelivery(props) {
    const {values, name, setStateValues, value} = props;

    // const title            = 'Нужно пересчитать стоимость перевозки';
    const [open, setOpen]     = React.useState(false);
    const [select, setSelect] = React.useState(false);

    const onSelect = (select, price) => {
        setOpen(false);
        setStateValues(values => {
            return {
                ...values,
                [name]:        undefined,
                sumDelivery:   price,
                tarifDocument: select.documentPrice,
                tarifCost:     price,
            };
        });
    };

    const apiName        = 'detect_price';
    const from           = getMinDate(values.routePlaces) || {};
    const to             = getMaxDate(values.routePlaces.filter(i => i !== from)) || {};
    const typedTransport = asGUID(values.typedTransport);
    const carrier        = asGUID(values.carrier);
    const currency       = asGUID(values.currency);

    const FiasShow = ({guid}) => guid
        ? <RealApiObjectLoad
            apiName={'fias_address_trees'}
            guid={guid}
            object={({item}) => <Chip size="small" variant="outlined" label={item.address}/>}
        />
        : <Chip size="small" icon={<ReportProblem/>} label="Не указано" variant="outlined"/>;

    const ApiShow = ({apiName, guid, required}) => guid
        ? <RealApiObjectLoad
            apiName={apiName}
            guid={guid}
            object={({item}) => <Chip size="small" variant="outlined" label={item.name}/>}
        />
        : <Chip size="small" icon={required && <ReportProblem/>} label="Не указано" variant="outlined"/>;

    const Currency = ({guid}) => <RealApiObjectLoad
        apiName={'currencies'}
        guid={guid}
        object={({item}) => <Chip size="small" variant="outlined" label={item.name}/>}
    />;

    const DocumentPrice = ({guid}) => <RealApiObjectLoad
        apiName={'doc_prices'}
        guid={guid}
        object={({item}) => <Chip size="small" variant="outlined" label={item.name}/>}
    />;

    React.useEffect(() => {
        if (!open) {
            return;
        }
        if (!from.point || !to.point || !typedTransport || !currency) {
            setSelect({required: 'no points'});
            return;
        }

        request
            .get(`/api/${apiName}`)
            .set('accept', 'application/json')
            .query({
                type:      'doc_request',
                from:      from.point,
                to:        to.point,
                startDate: from.dateFrom,
                typedTransport,
                carrier,
                currency,
            })
            .then(response => {
                if (response.body && response.body[0]) {
                    setSelect({found: true, ...response.body[0]});
                } else {
                    setSelect({notFound: response});
                }
            })
            .catch(error => {
                console.log('error', {error});
                setSelect({notFound: error});
            });


    }, [open]);

    const price = select.rate
        ? Math.round(100 * select.servicePrice * select.rate / select.rateMultiplier) / 100
        : select.servicePrice;

    return <>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Box style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                    <Typography>
                        Расчет стоимости перевозки
                    </Typography>
                    <IconButton>
                        <Close onClick={()=>setOpen(false)}/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Параметр расчета</b></TableCell>
                                <TableCell><b>Значение</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Из точки маршрута</TableCell>
                                <TableCell><FiasShow guid={from.point}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Дата приезда</TableCell>
                                <TableCell><ShowDateTime value={from.dateFrom}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>до точки маршрута</TableCell>
                                <TableCell><FiasShow guid={to.point}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Вид транспорта</TableCell>
                                <TableCell><ApiShow apiName="typed_transports"
                                                    required={true}
                                                    guid={typedTransport}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Перевозчик</TableCell>
                                <TableCell><ApiShow apiName="legals" guid={carrier}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Валюта</TableCell>
                                <TableCell><ApiShow apiName="currencies" required={true} guid={currency}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    {!select && <CircularProgress size={20}/>}
                                    {select && select.required &&
                                    <div>Не заполнены обязательные данные для расчета</div>}
                                    {select && select.notFound && <div>Не удалось выполнить расчёт</div>}
                                    {/*{select && select.found && <pre>{JSON.stringify(select,null,2)}</pre>}*/}
                                    {select && select.found && <div>
                                        {(select.rate) && <>
                                            <Typography>Пересчет из валюты
                                                <Currency guid={select.fromCurrency}/>
                                            </Typography>
                                            <Typography>
                                                Курс {select.rate} за {select.rateMultiplier}
                                            </Typography>
                                        </>}
                                        <Typography>
                                            Стоимость доставки: {select.servicePrice} <Currency guid={select.currency}/>
                                        </Typography>
                                        <Typography>Время для поиска: {select.timeForSearch}</Typography>
                                        <Typography>Время для доставки: {select.timeForDelivery}</Typography>
                                        <Typography>Документ: <DocumentPrice guid={select.documentPrice}/></Typography>
                                    </div>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContentText>
                <DialogActions>
                    {select && select.found &&
                    <Button onClick={() => onSelect(select, price)} color="primary" autoFocus variant={'contained'}>
                        Выбрать {price}
                    </Button>}
                </DialogActions>
            </DialogContent>

        </Dialog>
        <IconButton size={'small'} onClick={() => setOpen(true)}>
            <Tooltip title={'Рассчитать автоматически'}><CalculateIcon/></Tooltip>
        </IconButton>
    </>;
}


function HintTarifDocument({values}) {
    const hintTitle = values.tarifDocument
        ? 'По документу стоимость доставки' : null;
    return hintTitle
        ? <InputAdornment position="end">
            <BootstrapTooltip title={hintTitle}>
                <InfoIcon style={{color: grey[500]}}/>
            </BootstrapTooltip>
        </InputAdornment>
        : null;
}
const helper = {
    round2: value => Math.round((Number(value) + Number.EPSILON) * 100) / 100,
}

const subItems = [
    docRequest_routePlaces,

    ,
    {
        listTitle: 'Доступ',
        field: 'transportRequestAccesses',
        icon: <VisibilityOutlined />,
        // Component:      FormRequestSubApiLoader,
        // AfterComponent: SubFormLIstEdit,
        Component: TabRequestAccesses,
        cols: [
            {
                title: 'Перевозчик',
                // lookup:   lookupLegals,
                field: 'legal',
                api: 'legals',
                required: true,
                apiFilter: () => (
                    { 'exists[contragent]': true }
                ),
                format: FieldFormat.FORMAT_SELECT_API,
                object: ColObjectName,
            },
            {
                title: 'Видимая',
                field: 'visible',
                type: 'boolean',
                object: ColBoolean
            },
        ]

    }

];

export default {
    listTitle:        'Заявки на транспорт',
    apiName:          'doc_requests',
    itemName:         'Заявку на транспорт',
    roles:            [
        ROLES.ROLE_USER,
    ],
    showJournalUser : true,  
    flagNotChange: [
        {
            field: "status",
            values: [
                 "e7c669ba-98fb-11ea-b814-005056a60abf",
            ]
        }
    ],
    //нужно очищать поле carriersAccess чтоб сохранить копию заявки.
    //для сохрание клонированой завки нужно выставить поле clone чтоб отправить запорс методом post
    cloneIems: {
        carriersAccess: null,
        guid: null,
        name: null,
        clientDocNumber: null,
        signatoryDateEndDocRequest : null,
        signatoryDateStartDocRequest: null,
        signatoryDocRequest: null,
        signatoryDocTypeDocRequest: null,
        clone : true,
        replicator: null,
        OWNER_INN: 0,
        extraData: {OWNER_INN: 0},
        _needReCalcDelivery: true,
        tarifDocument: null,
        docNumber: 'Присвоится автоматически',
        status: STATUS_NEW,
        _sub_name: 'ЗаявкаНаТранспорт',
        docDate: new Date(),
        _date_now: new Date(),
        _CREATE: {
            newBidding: {
                apiName: 'biddings',
                parentField: {
                    docLinkedGuid: 'guid',
                    docLinkedName: '_sub_name',
                    docDate: '_date_now',
                },
            }
        },
        docQueue: null,
        carrier: null,
        carrierDriver: null,
        carrierAgreement: null,
        carrierTransports: null,
        OWNER_NAME: null,
        OWNER_INN: null,
        OWNER_ADDRESS: null,
        OWNER_PHONE: null,
        price: null,
        nds_rate: null,
        OWNER_TYPE: null,
        OWNER_INDIVIDUAL: null,
        manager_name: null,
        manager_position: null,
        order_number: null,
        order_date: null,
        source: null,
        //Для удаление определенных полей в объекте массиве объектов, нужно ввиде ключа указать поле масива, а поля для удаление прописать в виде массива значений
        deleteInField:
            {
                routePlaces : ['dateFromText', 'dateFrom', 'docRequest', 'guid']
            },
        //Обработчик для тонкой работы с данными.
        handlerData: (data) => {
            for (const key in data) {
                if (key === 'routePlaces') {
                    data[key].map(it => {
                        it.loadCargo && it.loadCargo.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.unloadCargo && it.unloadCargo.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.cargos && it.cargos.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.services && it.services.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                    })
                }
            }
            return data

        }

    },
    menu: {
        title:     'Заявки на транспорт',
        path:      '/doc_requests', // doc_order
        Component: PageDocRequests,
        icon:      MenuIcon,
        roles:     [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        hiddenIconDelete: true,
    },
    topColsBlocks:    [
        {
            num:  0,
            cols: 1,
        },
        {
            num:    1,
            title:  'Информация о заявке',
            cols:   2,
            border: true,
        },
        {
            num:    2,
            title:  'Требования к транспорту',
            cols:   2,
            border: true,
        },
        {
            num:    3,
            title:  'Стоимость заявки',
            cols:   2,
            border: true,
        },
        {
            num:    4,
            title:  'Информация о перевозчике',
            cols:   2,
            border: true,
        },
        {
            num:    5,
            title:  'Адрес погрузки',
            cols:   2,
            border: true,
        },
        {
            num:    6,
            title:  'Адрес доставки',
            cols:   2,
            border: true,
        },
        // {
        //     num:    5,
        //     title:  'Данные ТН',
        //     cols:   1,
        //     border: true,
        //     colsInTab: true,
        //     visibility: {
        //         key : 'status',
        //         array : ['e16aacea-98fb-11ea-b814-005056a60abf', '624414b9-73a4-11ec-b81c-005056a60abf', '00a9435c-1ba5-11ec-b81a-005056a60abf', 'e7c669ba-98fb-11ea-b814-005056a60abf'],
        //         helpertext : 'Выше перечислены guid  статусов Подписана, На погрузке, В пути, Выполнена'
        //     }
        // },
    ],
    initialValues:    () => (
        {
            _needReCalcDelivery: true,
            tarifDocument:       null,
            docNumber:           'Присвоится автоматически',
            status:              STATUS_NEW,
            _sub_name:           'ЗаявкаНаТранспорт',
            docDate:             new Date(),
            _date_now:           new Date(),
            routePlaces:         [],
            ndsRateString:       'НДС20',
            accessLevel:         'ВсемПеревозчикам',
            isAuto:              true,
            extraData:           {},
            _CREATE:             {
                newBidding: {
                    apiName:     'biddings',
                    parentField: {
                        docLinkedGuid: 'guid',
                        docLinkedName: '_sub_name',
                        docDate:       '_date_now',
                    },
                },
            },
        }
    ),
    onFormDataAction: (values, props) => {
        const {old, action, value, field, refreshItem} = props;
        // console.trace('onFormDataAction docRequest', {values, props});
        // console.log('onFormDataAction nested routePlaces docRequest', values, props)

        const sumServices  = calcSumServiceInPlace(values.routePlaces);
        let sumDelivery  = Number(values.sumDelivery) || 0;

        let ndsRateValue = values.ndsRateValue || 1.2;
        let sumDeliveryNds = Number(values.sumDeliveryNds) || helper.round2(sumDelivery * ndsRateValue);
        let sumServicesNds = helper.round2(sumServices * ndsRateValue);

        let _fix = {};

        if (!values.extraData) {
            _fix.extraData = {};
        }

        if (!values.routePlaces) {
            _fix.routePlaces = [];
        }

       


        switch (action) {
            case FORM_ACTION.INIT:
                const documents  = [
                    {
                        title: 'Договор Заявка экспедиция',
                        link: `printdocs/prn_broker_order`,
                        guid: values.guid,
                        type: ['экспедиц'],
                        searchName: 'broker_order',
                        format: ['xls', 'html', 'pdf', 'email', 'attach']
                    },
                    {
                        title: 'Договор Заявка',
                        link: `printdocs/prn_order`,
                        guid: values.guid,
                        type: ['перевозк'],
                        searchName: 'order',
                        format: ['xls', 'html', 'pdf', 'email', 'attach']
                    },
                    {
                        title: 'Заявка на перевозку',
                        link: `printdocs/prn_request`,
                        guid: values.guid,
                        type: ['перевозк'],
                        searchName: 'request',
                        format: ['xls', 'html', 'pdf', 'email', 'attach']
                    },
                    {
                        title: 'Поручение Экспедитору',
                        link: `printdocs/prn_broker_request`,
                        guid: values.guid,
                        type: ['экспедиц'],
                        searchName: 'broker_request',
                        format: ['xls', 'html', 'pdf', 'email', 'attach']
                    },
                    {
                        title: 'Доверенность',
                        link: `printdocs/prn_form_m2`,
                        guid: values.guid,
                        searchName: 'form_m2',
                        type: ['экспедиц', 'перевозк'],
                        format: ['xls', 'html', 'pdf', 'email', 'attach']
                    },
                    {
                        title: 'Транспортная накладная',
                        link: `printdocs/prn_tn`,
                        guid: values.guid,
                        type: ['перевозк'],
                        searchName: 'th',
                        format: ['xls', 'email', 'attach']
                    },
                    {
                        title: 'Транспортная накладная экспедиция',
                        link: `printdocs/prn_broker_tn`,
                        guid: values.guid,
                        type: ['экспедиц'],
                        searchName: 'broker_tn',
                        format: ['xls', 'email', 'attach']
                    },
                ]
                _fix.documents = documents
                asArray(_fix.routePlaces).map( item => {
                    if(item.isLoading){
                        item.typePoint = 'погрузка'
                    }
                    if(item.isUnloading){
                        item.typePoint = 'разгрузка'
                    }

                })
                // console.log('push2', _fix)
                // console.log('onFormDataAction', {_fix});
                const {routePlaces} = values
                if(routePlaces && routePlaces.length){
                    const routePlacesCargosMassAll = routePlaces.filter(el => el.cargos && el.cargos.length)
                    const sum = routePlacesCargosMassAll.reduce((current, el) =>
                            el.isLoading
                                ? el.cargos.reduce((sum, car) =>
                                    parseInt(car.cargoMass)
                                    + sum, 0)
                                : 0
                                + current,
                        0)
                    _fix.cargo_mass = sum
                }
                break;
            case FORM_ACTION.SUBMIT:
                console.log('FORM_ACTION.SUBMIT', {_fix, values});
                _fix.routePlaces = [
                    ...asArray(values.routePlaces).map(({loadCargo, unloadCargo, ...i}) => ({
                        ...i,
                        
                    })),
                ];
                break;
            case FORM_ACTION.CHANGE:
                sumDeliveryNds = Number(values.sumDeliveryNds);
                // console.log('objectDiffFields', objectDiffFields(values, old));
                const diffFields = objectDiffFields(values, old);
                field === 'OWNER_TYPE' && refreshItem(values)
                // console.log('CHANGE', field, values, props);
                if (old && 'sumDelivery' === field) {
                    _fix['tarifFrom']     = undefined;
                    _fix['tarifTo']       = undefined;
                    _fix['tarifDocument'] = undefined;
                    _fix['tarifCurrency'] = undefined;
                    _fix['tarifCost']     = undefined;
                }
                const changeFields = ['carrier', 'typedTransport', 'loadingPlaces', 'unloadingPlaces'];
                if (old && changeFields.filter(x => diffFields.includes(x)).length) {
                    _fix['_needReCalcDelivery'] = true;
                }
                switch (field) {
                    case 'ndsRateString':
                        //Установка текущего значения для рассчетов и значения для сохранения
                        let nds = optionsNDS.find(o => o.id === value);
                        ndsRateValue = nds ? nds.rate : 1.2;
                        // _fix['ndsRateValue'] = values['ndsRateValue'] = ndsRateValue;
                        sumDeliveryNds = helper.round2(sumDelivery * ndsRateValue);
                        break;
                    case 'sumDelivery':
                        sumDeliveryNds = helper.round2(sumDelivery * ndsRateValue);
                        _fix.sum_delivery_one_ton = helper.round2(sumDelivery*1000/values.cargo_mass )
                        break;
                    case 'routePlaces':
                        const {routePlaces} = values
                        values.routePlaces && values.routePlaces.map( item => {
                            if(item.typePoint === 'погрузка'){
                                item.isLoading = true
                                item.isUnloading = false
                            }
                            if(item.typePoint === 'разгрузка'){
                                item.isLoading = false
                                item.isUnloading = true
                            }
                            return item
                        })
                        //cargoMass
                        if(routePlaces && routePlaces.length){
                            const routePlacesCargosMassAll = routePlaces.filter(el => el.cargos && el.cargos.length)
                            const sum = routePlacesCargosMassAll.reduce((current, el) =>
                                    el.isLoading
                                        ? el.cargos.reduce((sum, car) =>
                                            parseInt(car.cargoMass)
                                            + sum, 0)
                                        : 0
                                    + current,
                                0)
                            _fix.cargo_mass = sum
                        }
                        break;
                    case 'sumDeliveryNds':
                        sumDelivery = helper.round2(sumDeliveryNds / ndsRateValue);
                        // _fix['sumDelivery'] = sumDelivery;
                        break
                    case 'sum_delivery_one_ton':
                        sumDelivery = helper.round2(values.cargo_mass * (parseInt(value)/ 1000))
                        sumDeliveryNds = helper.round2(sumDelivery * ndsRateValue);
                        break

                }

                break;
            default:
        }

        let sumTotal = helper.round2(sumDelivery + sumServices);
        let sumTotalNds = helper.round2(sumDeliveryNds + sumServicesNds);
        return {
            ...values,
            ..._fix,
            ndsRateValue,
            sumServices,
            sumServicesNds,
            sumDelivery,
            sumDeliveryNds,
            sumTotal,
            sumTotalNds,

        };
    },
    handlerInitalData : handlerInitalData,
    colsInTab:        true,
    cols:             async (props1, item) => {
        // HACK: Временное решение для запроса данных по Legal
        let legal = null;
        try {
            legal = item.carrier
                ? await ApiDataLoad('/api/legals/' + item.carrier) : null;
        }catch (e){
        }
       
        let transportMass = 0
        if(item){
            transportMass = parseInt(item.transportMass)
        }

    return [
        // {
        //     title:        '_needReCalcDelivery',
        //     field:        '_needReCalcDelivery',
        //     format:       FieldFormat.FORMAT_CUSTOM,
        //     custom:       props => <ReCalcDelivery {...props} />,
        //     cols:         2,
        //     topColsBlock: item && item._needReCalcDelivery ? 0 : -1,
        //     hidden:       true,
        // },
        {
            title:        'Номер документа',
            field:        'docNumber',
            hidden:       {xsUp: true},
            disabled:     true,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATE,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Номер документа клиента',
            field:        'clientDocNumber',
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Статус документа',
            api:          'doc_request_statuses',
            object:       ColObjectFullNameWithColor,
            format:       FieldFormat.FORMAT_SELECT_API,
            fieldShow:    'fullName',
            field:        'status',
            cols:         2,
            topColsBlock: 1,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'status[]',
            },
        },
        {
            title:        'Дата погрузки',
            field:        'routePlacesInfo_dateFrom_loading',
            format:       FieldFormat.FORMAT_NONE,
            object:       ColObjectShowDateTime,
            topColsBlock: -1,
        },
       
        {
            title:        'Дата разгрузки',
            field:        'routePlacesInfo_dateFrom_unloading',
            format:       FieldFormat.FORMAT_NONE,
            object:       ColObjectShowDateTime,
            topColsBlock: -1,
        },
       
        {
            title:        'Откуда',
            field:        'routePlacesInfo_pointNameLoading',
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Куда',
            field:        'routePlacesInfo_pointNameUnloading',
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        // {
        //     title:  'Маршрут',
        //     field:  'routePlaces',
        //     object: ShowUnloadingPlacesFrom,
        //     format: FieldFormat.FORMAT_NONE,
        //     topColsBlock: -1,
        // },
        

       
        {
            title:        'Сумма итого',
            type:         'float',
            field:        'sumTotal',
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Очередь распределения',
            api:          'doc_queues',
            field:        'docQueue',
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            cols:         2,
            hidden:       true,
            topColsBlock: 1,
        },
        {
            title:        'Автоматическое назначение',
            field:        'isAuto',
            format:       FieldFormat.FORMAT_CHECKBOX,
            cols:         2,
            hidden:       true,
            topColsBlock: 1,
            object:       ColBooleanSwitch
        },
        {
            title:        'Заказчик перевозки',
            api:          'legals',
            apiFilter: () => (
                {isCarrier: false}
            ),
            field:        'customer',
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            cols:         2,
            hidden:       true,
            required:     true,
            topColsBlock: 1,
        },
        {
            title:     'Контактное лицо',
            api:       'contact_people',
            apiFilter: (rowData) => (
                {
                    legal: rowData && rowData.customer,
                }
            ),
            // lookup: lookupContact,
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectNameOther,
            field:        'customerContact',
            cols:         2,
            hidden:       true,
            required:     true,
            topColsBlock: 1,
        },
        // {
        //     title:          'Контактый телефон',
        //     api:            'contact_infos',
        //     apiFilter: (rowData) => (
        //         {
        //             contactKind : "Телефон",
        //             contactPerson : rowData && rowData.customerContact
        //         }
        //     ),
        //     // lookup: lookupContact,
        //     format:       FieldFormat.FORMAT_SELECT_API,
        //     object:       ColObjectNameOther,
        //     field:        'customerContactPhone',
        //     fieldShow:    'info',
        //     cols:         1,
        //     hidden:       true,
        //     required:     true,
        //     topColsBlock: 1,
        // },
        {
            title:        'Комментарий',
            field:        'comment',
            hidden:       true,
            topColsBlock: 1,
        },
        {
            title:        'Объем от, м^3',
            field:        'transportValue',
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         4,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Объем до, м^3',
            field:        'transportValueMax',
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         4,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Грузоподъемность, кг',
            field:        'transportMass',
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Тип Кузова',
            api:          'carbody_types',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            multi:        true,
            field:        'carbodyTypes',
            topColsBlock: 2,
            cols:         2,
            filter:       {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'carbodyType[]',
            },
        },
        {
            title:        'Вид транспорта',
            field:        'typedTransport',
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'typed_transports',
            object:       ColObjectName,
            cols:         2,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:        'Тип загрузки',
            api:          'loading_types',
            field:        'loadingTypes',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            multi:        true,
            object:       ColObjectName,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },
        {
            title:        'Классы опасности',
            api:          'danger_classes',
            field:        'dangerClasses',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            object:       ShowDangerClass,
            multi:        true,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },

        {
            title:        'Валюта',
            object:       ShowCurrency,
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            api:          'currencies',
            field:        'currency',
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Ставка НДС',
            field:        'ndsRateString',
            format:       FieldFormat.FORMAT_SELECT,
            options:      optionsNDS,
            object:       ColObjectNameOptions, 
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Сумма доставки',
            field:        'sumDelivery',
            // type:         'float',
            // enterType:    'number',
            startButtons: ({values, setStateValues}) => [
                <CalcDelivery values={values} setStateValues={setStateValues}/>,
            ],
            endButtons:   ({values, setStateValues}) => [
                <HintTarifDocument values={values}/>,
            ],
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
            minValue: 0
        },
        {
            title:        'Сумма доставки с НДС',
            format:       FieldFormat.FORMAT_NUMBER,
            field:        'sumDeliveryNds',
            cols:         2,
            topColsBlock: 3,
            // disabled:     true,
            hidden:       true,
            minValue: 0
        },
        {
            title:        'Стоимость за тонну',
            type:         'float',
            field:        'sum_delivery_one_ton',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
            disabled: item && !item.cargo_mass,
            minValue: 0
        },
        {
            title:        'Масса груза, кг',
            type:         'float',
            field:        'cargo_mass',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
            disabled: true,
        },
        {
            title:        'Сумма услуг', // todo: поле не пересчиталось
            type:         'float',
            field:        'sumServices',
            cols:         2,
            readOnly:     true,
            disabled:     true,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Сумма услуг с НДС',
            type:         'float',
            field:        'sumServicesNds',
            cols:         2,
            readOnly:     true,
            topColsBlock: 3,
            disabled:     true,
            hidden:       true,
        },
        {
            title:        'Сумма итого',
            type:         'float',
            field:        'sumTotal',
            cols:         2,
            disabled:     true,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Сумма итого с НДС',
            type:         'float',
            field:        'sumTotalNds',
            cols:         2,
            disabled:     true,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Условия оплаты',
            field:        'paymentConditions',
            format:       FieldFormat.FORMAT_INPUT,
            cols:         1,
            topColsBlock: 3,
            hidden:       true
        },
        // {
        //     title:        'НДС коэффициент',
        //     type:         'float',
        //     field:        'ndsRateValue',
        //     cols:         2,
        //     topColsBlock: 1,
        //     disabled:     true,
        //     hidden:       true,
        // },
        {
            title:        'Перевозчик',
            api:          'legals',
            apiFilter:    () => (
                {'exists[contragent]': true}
            ),
            field:        'carrier',
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title: 'Договор',
            // apiFilter:    values => (
            //     {
            //         contragent: values.carrier || null,
            //     }
            // ),
            object:       ColObjectName,
            api:          'contracts',
            apiFilter:    values => (
                {
                    // 'exists[legal.contragent]': true,
                    contragent: values.carrier || null,
                }
            ),
            format:       FieldFormat.FORMAT_SELECT_API,
            field:        'carrierAgreement',
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title:        'Водитель',
            object:       ColObjectName,
            api:          'drivers',
            apiFilter:    values => (
                {
                    // 'exists[contragent]': true,
                    'legal[]':                     [
                        values.carrier,
                        legal ?  legal.contragent : null,
                    ],
                }
            ),
            field:        'carrierDriver',
            format:       FieldFormat.FORMAT_SELECT_API,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },

        {
            title:        'Звено 1',
            api:          'transports',
            apiFilter:    values => (
                {
                    'legal[]': [
                        values.carrier,
                        legal ? legal.contragent : null,
                    ],
                }
            ),
            field:        'carrierTransports',
            fieldIndex:   0,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title:        'Звено 2',
            api:          'transports',
            apiFilter:    values => (
                {
                    'legal[]': [
                        values.carrier,
                        legal ? legal.contragent : null,
                    ],
                }
            ),
            field:        'carrierTransports',
            fieldIndex:   1,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            hidden:       true,
            topColsBlock: 4,
        },
        {
            title:        'Звено 3',
            api:          'transports',
            apiFilter:    values => (
                {
                    'legal[]': [
                        values.carrier,
                        legal ? legal.contragent : null,
                    ],
                }
            ),
            field:        'carrierTransports',
            fieldIndex:   2,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            hidden:       true,
            topColsBlock: 4,
        },
        {
            cols:         4,
            topColsBlock: 1,
            format:       FieldFormat.FORMAT_CUSTOM,
            custom: function Custom({ values }) {
                const { setStateValues } = React.useContext(FormDataContext);
                const { guid, customer } = values
                React.useEffect(() => {
                    if (!guid) {
                        const api = new API
                        api.get(`contact_people?legal=${asGUID(customer)}`)
                            .then(result => {
                                const searchSignatory = result.find(item => item.mainSignatory) || false;

                                setStateValues(values => {
                                    return {
                                        ...values,
                                        signatoryDocTypeDocRequest : searchSignatory.signatoryDocType || null,
                                        signatoryDocRequest:   searchSignatory.guid || null,
                                        signatoryDateEndDocRequest: searchSignatory.signatoryDateEnd || null,
                                        signatoryDateStartDocRequest: searchSignatory.signatoryDateStart || null,
                                    };
                                });
                            })

                        }
                    }, [customer])

                    return (<></>)
            },
            hidden:       true,
        },

        // {
        //     title: 'loadingPlace',
        //     field: 'loadingPlace',
        // },
        // {
        //     title: 'unloadingPlace',
        //     field: 'unloadingPlace',
        // },
        // {
        //     title: 'cargo',
        //     field: 'cargo',
        // },
        // {
        //     title: 'dangerClass',
        //     field: 'dangerClass',
        // },
        // {
        //     title: 'services',
        //     field: 'services',
        // },
        // {
        //     title: 'clientProfile',
        //     field: 'clientProfile',
        // },
        // {
        //     title: 'userAuthor',
        //     field: 'userAuthor',
        // },
    ]},
    subItems: (item) => {


//  HACK: item.extraData Использоваться не должно. Видимо в коде ошибка и временно требуется использование
        const checkNewRequest =  item.docNumber !==  config.DOC_NUMBER_AUTO_DOC_REQUEST;
        
        const subItems = [
            {
                listTitle:      'Данные ТН',
                Component:      NestedFieldsForm,
                AfterComponent: FormFieldsShow,
                icon:           <AssignmentOutlinedIcon/>,
                canAdd:         true,
                field:          'extraData',
                cols: (item) => {

                    const OWNER_TYPE = item && item.OWNER_TYPE

                    const agreement = OWNER_TYPE && OWNER_TYPE_OPTIONS.find(item => item.id === OWNER_TYPE)

                    const OWNER_AGREEMENT_CHECK = agreement && agreement.agreement
                    return [
                        {
                            title: 'Наименование',
                            field: 'OWNER_NAME',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'ИНН',
                            field: 'OWNER_INN',
                            format: FieldFormat.FORMAT_NUMBER,
                            // mask:         '000000000000',
                            cols: 2,
                            hidden: true

                        },
                        {
                            title: 'Адрес',
                            field: 'OWNER_ADDRESS',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Телефон',
                            field: 'OWNER_PHONE',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Стоимость',
                            field: 'price',
                            format: FieldFormat.FORMAT_INPUT,
                            mask: /^(?=.*\d)\d*(?:\.\d{0,2})?$/,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Ставка НДС',
                            field: 'nds_rate',
                            format: FieldFormat.FORMAT_SELECT,
                            options: optionsNDS,
                            object: ColObjectNameOptions,
                            cols: 2,
                            hidden: true
                        },

                        {
                            title: 'Тип владения',
                            field: 'OWNER_TYPE',
                            format: FieldFormat.FORMAT_SELECT,
                            options: OWNER_TYPE_OPTIONS,
                            object: ColObjectNameOptions,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Юридическое лицо',
                            field: 'OWNER_INDIVIDUAL',
                            format: FieldFormat.FORMAT_CHECKBOX,
                            object: ColBooleanSwitch,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'ФИО менеджера',
                            field: 'manager_name',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Должность менеджера',
                            field: 'manager_position',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Номер заявки',
                            field: 'order_number',
                            format: FieldFormat.FORMAT_INPUT,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Дата заявки',
                            field: 'order_date',
                            format: FieldFormat.FORMAT_DATE,
                            object: ShowDate,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Источник',
                            field: 'source',
                            format: FieldFormat.FORMAT_NONE,
                            cols: 2,
                            hidden: true
                        },
                        {
                            title: 'Номер и дата договора владения',
                            field: 'OWNER_AGREEMENT',
                            format: OWNER_AGREEMENT_CHECK
                                ? FieldFormat.FORMAT_INPUT
                                : FieldFormat.FORMAT_NONE,
                            cols: 2,
                            hidden: true
                        },
                    ]
                }
            },
            docRequest_routePlaces,

            {
                listTitle: 'Этапы',
                Component: FormLoadSub,
                AfterComponent: SubFormBiddings,
                item: bidding,
                icon: <BiddingIcon />,
                canAdd: true,
                field: 'biddings',
                cols: {
                    BiddingType: {
                        title: 'Тип этапа',
                        field: 'biddingType',
                        format: FieldFormat.FORMAT_SELECT,
                        cols: 4,
                        options: [
                            {
                                id: 'Редукцион',
                                name: 'Редукцион',
                            },
                            {
                                id: 'Аукцион',
                                name: 'Аукцион',
                            },
                            {
                                id: 'Свободная',
                                name: 'Свободная',
                            },
                            {
                                id: 'Назначение',
                                name: 'Назначение',
                            },
                        ],
                    },
                    datetimeStart: {
                        title: "Старт этапа",


                    },
                    datetimeEnd: {
                        title: "Окончание этапа",
                    },

                },
                api: 'biddings',
                helperTextForDisableButton: 'Для добавления новой торговой сессии необходимо сохранить текущую заявку на транспорт.',
                apiFilter: values => (
                    {
                        docLinkedGuid: values.guid || '00000000-0000-0000-0000-000000000000',
                        docLinkedName: 'ЗаявкаНаТранспорт',
                    }
                ),
            },
            {
                listTitle: 'Доступ',
                field:     'transportRequestAccesses',
                icon:      <VisibilityOutlined/>,
                // Component:      FormRequestSubApiLoader,
                // AfterComponent: SubFormLIstEdit,
                Component: TabRequestAccesses,
                cols:      [
                    {
                        title: 'Перевозчик',
                        // lookup:   lookupLegals,
                        field:     'legal',
                        api:       'legals',
                        required:  true,
                        apiFilter: () => (
                            {'exists[contragent]': true}
                        ),
                        format:    FieldFormat.FORMAT_SELECT_API,
                        object:    ColObjectName,
                    },
                    {
                        title:  'Видимая',
                        field:  'visible',
                        type:   'boolean',
                        object: ColBoolean
                    },
                ],
            },
            checkNewRequest && {
                listTitle: 'Чаты',
                field:     'chats',
                icon:      <ChatOutlinedIcon/>,
                Component : PageChat,
                // Component:      FormRequestSubApiLoader,
                // AfterComponent: SubFormLIstEdit,
            },
        ];
        
        return {
            style: 'tabs',
            list: item && item.carrierAgreement
                ? [
                    ...subItems,
                    checkNewRequest && {
                        listTitle: 'Документы',
                        field: 'documents',
                        icon: <Documents />,
                        Component: PrintendForms,
                        cols: [
                            {

                                title: 'Право подписи',
                                field: 'signatoryDocTypeDocRequest',
                                format: FieldFormat.FORMAT_INPUT,
                                // api: 'contact_people', 
                                cols: 1,
                                topColsBlock: 1,
                                hidden: true,
                                // object:   ColObjectName,

                            }
                        ]
                    },
                    checkNewRequest && {
                        Component: SubApiReplace,
                        api:       'attached_files',
                        apiFilter: values => (
                            {
                                objLinkedName: "doc_requests",
                                objLinkedGuid: values.guid
                            }
                        ),
                        objLinkedName: "doc_requests",
                        objLinkedGuid: item.guid,
                        ...userFiles,
                        icon: <AttachFileOutlinedIcon/>
                    },
                ]
                : [...subItems, checkNewRequest && {
                    Component: SubApiReplace,
                    api:       'attached_files',
                    apiFilter: values => (
                        {
                            objLinkedName: "doc_requests",
                            objLinkedGuid: values.guid
                        }
                    ),
                    objLinkedName: "doc_requests",
                    objLinkedGuid: item.guid,

                    ...userFiles,
                    icon: <AttachFileOutlinedIcon/>
                },]
        }
    },

};