import {withStyles} from "@material-ui/core/styles";
import {Tab, Tabs} from "@material-ui/core";
import {onlyUniqueByField} from "./OnlyUniqueByField";
import {ShowTypeLabel} from "./ShowTypeLabel";
import React from "react";
import {reduceNotRead} from "./Chat";

const VerticalTabs = withStyles((theme) => ({
    root: {
        overflow: "auto",
    }
}))(Tabs)

export function TabsVertical(props) {
    const {rooms, tabGroup, setTabGroup, setTabHorizontal, objectShow, objectText, group} = props;
    // const group = objectText[objectShow];

    let setVTab = (e, v) => {
        setTabGroup(v);
        // setTabHorizontal(null);
    }


    return <VerticalTabs
        orientation="vertical"
        variant="scrollable"
        value={tabGroup}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        onChange={setVTab}
        // onChange={console.log}
    >
        {rooms
            .filter(onlyUniqueByField(objectShow))
            .map((el, key) => <Tab
                key={key}
                value={el[objectShow]}
                label={<ShowTypeLabel
                    guid={el[objectShow]} type={group.api}
                    lastDate={el.lastDate}
                    countNotShow={rooms
                        .filter(elNested => elNested[objectShow] === el[objectShow])
                        .reduce(reduceNotRead, 0)}
                />}
            />)
        }

    </VerticalTabs>;
}