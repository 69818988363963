import {FormDataContext} from 'FormDataContext';
import React from 'react';
import {ContactPeopleIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';
import {
    ShowIndividual,
} from 'components/showItems';
import {asGUID} from 'libs/asGUID';
import {SubApiReplace} from 'components/FormLoadSub';
import userFiles from 'config/userFiles'
import {ATTACHED_FILE_TYPE} from 'config/userFiles'
import EditIcon from '@material-ui/icons/Edit';
import AttachFileOutlinedIcon   from '@material-ui/icons/AttachFileOutlined';
import contactInfo from "config/contactInfo";
import FormFieldsShow from "components/FormFieldsShow";
import {TabForm} from "components/NestedFieldsForm";




export default {
    listTitle:     'Контактные лица',
    apiName:       'contact_people',
    itemName:      'Контактное лицо',
    topColsBlocks: [
        {
            num: 1,
        },
    ],
    canEdit: (rowData,{me}) => {
        console.log('canDelete',{rowData,me})
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },
    canDelete: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },

    filterParent: (props) => {
        const {menuRoot, legal, _items} = props;
        const {_self} = _items || {};
        const {contragent} = _self || {};
        let filter = {
            'legal.isCarrier': menuRoot.id === 'carrier',
        };
        if (contragent) {
            filter['legal[]'] = [asGUID(contragent), legal];
        }
        return filter
    },
    menu:          {
        icon: ContactPeopleIcon,
    },

    cols:            async (props,item) => { return[
        {
            title: 'ФИО',
            field: 'name',
            topColsBlock: 1,
        },
        {
            title:        'Должность',
            field:        'workPosition',
            topColsBlock: 1,
        },
        {
            title:        'Физ лицо',
            field:        'individual',
            api:          'individuals',
            subConfig:    'individual',
            format:       FieldFormat.FORMAT_SUB_FORM,
            object:       ShowIndividual,
            topColsBlock: 1,
            hidden:       true,
            sub: 'contactPerson',
        },
        {
            title:        'Комментарий',
            field:        'comment',
            topColsBlock: 1,
            format:       FieldFormat.FORMAT_TEXTAREA,
        },
        {
            title:        'Контакты',
            field:        'contacts',
            api:          'contact_infos',
            // todo: not use apiFilter for new objects
            apiFilter:    (item) => item && item.guid
                ? {
                    parent_guid: item.guid,
                }
                : {
                    parent_guid: 'new',
                    none:        console.log('item', item),
                }
            ,
            multi:        true,
            subConfig:    'contactInfo',
            format:       FieldFormat.FORMAT_SUB_TABLE,
            parentField:  {
                contactPerson: 'guid',
                name:          'name',
                comment:       'comment',
            },
            topColsBlock: 2,
            listTitle:     'Контактная информация',
            hidden:       true,
        },

    ]},
    subItems: async (item, props) => {
        const isForeign =props &&  props.topItem && props.topItem.isForeign;

        return item && item.guid &&  {
            style: 'tabs',
            list : [
                {
                    ...contactInfo,
                    listTitle: "Контактная информация",
                    Component: SubApiReplace,
                    field:        'contactInfos',
                    api:          'contact_infos',
                    parentField: 'contactPerson',
                    isForeign: isForeign,
                    apiFilter:    (item) => item && item.guid
                        ? {
                            parent_guid: item.guid,
                        }
                        : {
                            parent_guid: 'new',
                        }
                    ,
                },
                {
                    listTitle: "Документ права подписи",
                    icon: <EditIcon/>,
                    Component: TabForm,
                    AfterComponent: FormFieldsShow,
                    cols: [
                        {
                            title: 'Основной',
                            field: 'mainSignatory',
                            format: FieldFormat.FORMAT_CHECKBOX,
                            hidden: true,
                        },
                        {
                            title:        'Документ права подписи',
                            field:        'signatoryDocType',
                            format:       FieldFormat.FORMAT_INPUT,
                            hidden: true,

                        },
                        {
                            title: 'Факсимиле',
                            field: 'signaturyFacsimile',
                            api:       'attached_files',
                            apiFilter: (values) => ({

                                'objLinkedGuid': values.guid,
                                'objLinkedName': 'contact_persons',
                                'documentType' : ATTACHED_FILE_TYPE.FACSIMILE

                            }),
                            format:    FieldFormat.FORMAT_SELECT_API,
                            textSecondary: (props) => props.item && props.item.description,
                            hidden: true,
                        },
                        {
                            title:        'С какого числа действует документ',
                            field:        'signatoryDateStart',
                            format:       FieldFormat.FORMAT_DATE,
                            hidden: true,
                        },
                        {
                            title:        'По какое число действует документ',
                            field:        'signatoryDateEnd',
                            format:       FieldFormat.FORMAT_DATE,
                            hidden: true,
                        },
                    ]
                },
                {
                    field: 'files',
                    Component: SubApiReplace,
                    api: 'attached_files',
                    apiFilter: values => (
                        {
                            objLinkedName: 'contact_persons',
                            objLinkedGuid: values.guid
                        }
                    ),
                    objLinkedName: "contact_persons",
                    objLinkedGuid: item.guid,

                    ...userFiles,
                    icon: <AttachFileOutlinedIcon />,
                    hidden: true,
                }
            ]
        }

    },

    onFormDataAction: (values) => {
        const {individual, workPosition} = values
        let name = 'Новое контактное лицо'
        if (individual && individual.name && workPosition){
            name = `${individual.name}, ${workPosition}`
        }
        if(individual && individual.name && !workPosition ){
            name = individual.name
        }
        return {
            ...values,
            name
        };
    },
};