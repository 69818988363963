import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import FormEditCreateTabs from 'components/FormEditCreateTabs';
import Wrapper from 'components/FullScreenWrapper';
import RequestForm from 'components/RequestForm';
import {
    Dialog,
    DialogTitle,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const defaultProps = {
    dbId:             'guid',
    onFormDataAction: values => values,
    handleClose:      () => console.log('no handleClose'),
    handleSubmit:     async () => console.log('no handleSubmit'),
    canDeleteItem:    () => true,
    onSuccess:        () => console.error('no onSuccess'),
    open:             true,
    FormComponent:    RequestForm,
    cols:             [],
    maxWidth:         'md',
    fullWidth:        true,
    buttonSave:       'Добавить',
};
const useStyle = makeStyles(()=> ({
    p_x: {
        padding: '16px 0',
    },
    P_1 : {
        padding: '1rem',
    }
}));

export default function DialogEditCreate(props) {
    console.log('callfunction DialogEditCreate');
    const {
              open,
              fullScreen,
              title,
              initialValues,
              dbId,
              itemName,
              handleClose,
              handleSubmit,
              maxWidth,
              fullWidth,
              onSuccess,
              windowUsersetting
          } = {...defaultProps, ...props};

    const FullScreenWrapper = fullScreen
        ? Wrapper
        : React.Fragment;

    const isUpdate = initialValues && initialValues[dbId];

    const dialogTitle = title || (
        isUpdate
            ? 'Редактировать'
            : 'Добавить'
    ) + ' ' + itemName;
        
    const classes = useStyle()
    
    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            
            
        >
            <Box className={windowUsersetting ? classes.P_1 : ''}>
            <FullScreenWrapper title={fullScreen ? dialogTitle : null} handleClose={handleClose}>
                {!fullScreen && <DialogTitle className={windowUsersetting ? classes.p_x: ''}>{dialogTitle}</DialogTitle>}
                <ErrorBoundary info={'FormEditCreateTabs'}>
                    <AdminShow>[DialogEditCreate3]</AdminShow>
                    <FormEditCreateTabs
                        {...props}
                        dbId={'guid'}
                        itemName={itemName}
                        open={true}
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        onSuccess={onSuccess}
                        initialValues={initialValues}
                        FormComponent={RequestForm} /// ??? todo: test not need?
                        windowUsersetting={windowUsersetting}
                        // cols={cols}
                        // fullScreen={fullScreen}
                        // path={path + '/' + guid}
                    />
                </ErrorBoundary>


            </FullScreenWrapper>
            </Box>
        </Dialog>
    );
}
