import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import {FieldFormat} from 'config/FieldFormat';
import {SubApiReplace} from 'components/FormLoadSub';
import {ColBoolean, ColObjectName, ShowCurrency} from 'components/showItems';
import React from 'react';
import pageMembers from 'page/PageMembers';
import {ROLES} from 'config/roles';
import {MemberIcon} from 'components/icons';
import API from 'libs/api';
import {FORM_ACTION} from '../FormDataContext';
import NestedFieldsForm, {TabForm} from "components/NestedFieldsForm";
import FormFieldsShow from "components/FormFieldsShow";
import MessageIcon from '@material-ui/icons/Message';
import UserNotificationSettings from "components/UserNotificationSettings/UserNotificationSettings";
import request from 'superagent';
import contactInfo from "config/contactInfo";
import {fonts} from 'config/fontList'
import TuneIcon from '@material-ui/icons/Tune';

const api = new API() 


const fieldsPassword = (role) => {
    switch(role){
        case "checkReturnPasword":
            return FieldFormat.FORMAT_NONE
    }
}


const regForFieldPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/g

export default {
    listTitle: 'Пользователи',
    apiName:   'users',
    itemName:  'Пользователя',
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Пользователи',
        path:      '/users',
        Component: pageMembers,
        icon:      MemberIcon,
        roles:     [ROLES.ROLE_USER],
    },
    onFormDataAction: (values, props) => {
        const {action} = props;
        const _fix = {};
        switch (action) {
            case FORM_ACTION.INIT:
                if (values.settings && values.settings.font) {
                    _fix.font = values.settings.font;
                } else {
                    _fix.settings = { font: null };
                }
                break;
            case FORM_ACTION.SUBMIT:
                if (values.font) {
                    values.settings.font = values.font;
                }
                if(values.newPassword && values.checkPassword && (values.newPassword === values.checkPassword)){
                    values.plainPassword = values.newPassword;
                }
                if (values.user_notifications && values.channels) {
                    const result = []
                    values.user_notifications.filter(el => el.parentId).map(el => {
                        const user = {
                            event: el.id,
                        }
                        values.channels.map(channel => {
                            user[channel.code] = el[channel.code] ? "1" : '0'
                            user[`${channel.code}_data`] = el[`${channel.code}_data`]

                        })
                        result.push(user)
                    })
                    _fix.user_notifications_result = result
                }
                break;
        } 
        return {...values, ..._fix}
    },
    colsInTab: true,
    handleSubmit: apiName => (values, action) => {
        const api = new API;
        return new Promise((resolve, reject) => {
            if (values.user_notifications_result){
                request
                .put(`/api/user_notifications/${values.guid}`)
                .set('accept', 'application/json')
                .send(values.user_notifications_result)
                .catch(err => reject(err))
            }
            resolve(api[action](apiName, values))
        })
    },
    cols: [
        {
            field:      'picture',
            title:      'Фото',
            object:     ({row, item}) => <Chip size="small" avatar={<Avatar src={api.getUploadImg(item)}/>} label={row.username}/>,
            format: FieldFormat.FORMAT_NONE
        },
        {
            field:      'picture_data',
            title:      'Фото',
            helperText: 'Нажмите на иконку слева для обновления фотографии в учетной записи пользователя',
            hidden:     true,
            format: FieldFormat.FORMAT_FILE_ADD,
            framing: true,

        },
        {
            field: 'name',
            title: 'Наименование',
        },
        {
            field: 'username',
            title: 'ФИО',
            required: true,
        },
        {
            field:   'font',
            title:   'Шрифт в системе',
            format:  FieldFormat.FORMAT_SELECT,
            options: fonts.map(i => (
                    {
                        id:   i,
                        name: i,
                    }
            )),
        },
        {
            title: 'E-Mail',
            field: 'email',
            required: true,
            helperText: "Указанный почтовый ящик будет использоваться в качестве логина для авторизации на TMS портале"
        },
        {
            title: 'Новый пароль для входа(без шифрования)',
            field: 'newPassword',
            hidden: true,
            format: FieldFormat.FORMAT_PASSWORD,
            required: ({newPassword, checkPassword}) => !!(newPassword && newPassword.length || checkPassword && checkPassword.length),
            check : [
                (value) => value && value.length < 6 && 'Пароль должен содержать не менее 6 символов' ,
                (value) => value && !value.match(regForFieldPassword) && 'Пароль должен содержать латинские символы в нижнеи и верхнем регистре и цифры',
            ]
        },

        // TODO
        // {
        //     title:   'Роли',
        //     field:   'roles',
        //     format:  FieldFormat.FORMAT_SELECT_API_CHECKBOX,
        //     multi: true,
        //     options: [
        //         {
        //             id: 'ROLE_USER',
        //             name: 'Пользователь',
        //         },
        //     ],
        // },
        {
            title: 'Подтверждение пароля',
            field: 'checkPassword',
            hidden: true,
            format: FieldFormat.FORMAT_PASSWORD,
            required: ({newPassword}) => !!(newPassword && newPassword.length),
            check : [
                (value,  {values}) => value !== values.newPassword && 'Пароли должны совпадать',
            ]
        },
        {
            field:  'enabled',
            title:  'Активный',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            cols:   3,
        },
        // {
        //     field:  'isAdmin',
        //     title:  'Админ',
        //     object: ColBoolean,
        //     format: FieldFormat.FORMAT_CHECKBOX,
        //     cols:   3,
        // },
    ],
       subItems : (item) => {

           return item && item.guid && (
               {
                   style: 'tabs',
                   list: [
                       {
                           listTitle: "Настройки оповещений",
                           Component: TabForm,
                           AfterComponent: FormFieldsShow,
                           icon: <MessageIcon />,
                           canAdd: true,
                           field: 'extraData',
                           cols: [
                               {
                                   field: "events",
                                   format: FieldFormat.FORMAT_CUSTOM,
                                   custom: UserNotificationSettings


                               }
                           ]
                       },
                       {
                           ...contactInfo,
                           listTitle: "Контактная информация",
                           Component: SubApiReplace,
                           field: 'contactInfos',
                           parentField: 'user',
                           apiFilter: (item) => item && item.guid
                               ? {
                                   parent_guid: item.guid,
                               }
                               : {
                                   parent_guid: 'new',
                               }
                           ,
                       },
                       {
                           listTitle: 'Значения по умолчанию',
                           itemName: 'Значения по умолчанию',
                           icon: <TuneIcon/>,
                           Component: NestedFieldsForm,
                           AfterComponent: FormFieldsShow,
                           cols: (item) => {
                               return [
                                   {
                                       title: 'Валюта',
                                       object: ShowCurrency,
                                       format: FieldFormat.FORMAT_SELECT_API,
                                       api: 'currencies',
                                       field: 'currency',
                                       cols: 1,
                                       hidden: true,
                                   }
                               ]

                           }
                       }
                   ]
               }
           )
       }
};