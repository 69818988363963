import {ApiDataLoad} from "components/ApiDataLoad";
import React from 'react';
import {STATE} from "libs/api";
import * as PropTypes from "prop-types";

function useLoaderApiList(apiName, query = {}, opts = {}) {
    return useLoaderApi(apiName, null, query, {mode: 'list', ...opts});
}

useLoaderApiList.propTypes = {
    apiName: PropTypes.string,
    query:     PropTypes.object,
    opts:  PropTypes.shape({
        timeout: PropTypes.number,
        onError: PropTypes.func,
        prefix:  PropTypes.string,
        initial: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        method: PropTypes.string,
        mode: PropTypes.oneOf(['item','list']),
        onResponse: data => data,
    })
};

export {useLoaderApiList};

function doMapIdArray(item, mapItem) {
    if(mapItem && Array.isArray(item)){
        return item.map(mapItem);
    }
    return item;
}

export default function useLoaderApi(apiName, guid, query = {}, opts = {}) {
    const {timeout, onError, mapItem, prefix, initial, method, onResponse, allResponse, mode} = {
        timeout: 300,
        onError: () => {},
        prefix:  '/api/',
        initial: null,
        method: 'GET',
        mode: 'item',
        allResponse: false,
        onResponse: data => data,
        ...opts
    };
    let loadStateInitial = STATE.INIT;

    if(mode === 'item' && !guid){
        loadStateInitial = STATE.LOADED;
        // console.log('[error] call useLoaderApi, use useLoaderApiList apiName: ',apiName, loadStateInitial)
    }
    // console.log('useLoaderApi', apiName, guid, query)
    // const [item, setItem] = React.useState(initial && onResponse(initial));
    // const [loadState, setLoadState] = React.useState(loadStateInitial);
    // const [queryLoad, setQueryLoad] = React.useState(JSON.stringify([apiName, guid, query]));
    const [{item, loadState, queryLoad, lastState}, setState] = React.useState( () => (
        {
            item : initial && onResponse(initial),
            loadState: loadStateInitial,
            queryLoad: JSON.stringify([apiName, guid, query]),
            lastState: [],
        })
    );


    const setItem = (item) => {
        console.log('setItemitem', item)
        setState(oldState => ({
            ...oldState,
            item: typeof item === "function"
                    ? item(oldState.item)
                    : item
        }))
    }
    let timer = 0;

    const forceReloadData = () => {
        console.log('useLoaderApi forceReloadData', apiName, guid, query)
        timer = 0;
        //TODO: request.abort();
        setState(oldState => ({

            ...oldState,
            lastState: oldState.item,
            item:      initial,
            loadState: STATE.INIT
        }))
        // setItem(initial);
        // setLoadState(STATE.INIT);
    }

    // todo: use debounce
    React.useEffect(() => {
        if (![STATE.LOADED, STATE.ERROR].includes(loadState)) {
            return;
        }
        const newQuery = JSON.stringify([apiName, guid, query]);
        if (queryLoad !== newQuery) {
            console.log('useLoaderApi forceReloadData delay', {apiName, query, loadState,queryLoad,newQuery})
            // setQueryLoad();
            setState(oldState => ({
                ...oldState,
                queryLoad:      newQuery,
            }))

            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(forceReloadData, timeout);
        }
    }, [apiName, guid, query]);

// дозагрузка отсутствующего значения
    React.useEffect(() => {
        if (loadState !== STATE.INIT) {
            return;
        }

        setState(oldState => ({
            ...oldState,
            loadState: STATE.LOADING
        }))
        const url = guid
            ? `${prefix}${apiName}/${decodeURI(guid)}`
            : `${prefix}${apiName}`;

        ApiDataLoad(url, query,{method, allResponse, ...opts})
            .then(item => {
                setState(oldState => {
                    // console.log("push2", oldState)
                    return {
                        ...oldState,
                        queryLoad:      JSON.stringify([apiName, guid, query]),
                        item: doMapIdArray(onResponse(item), mapItem),
                        loadState: STATE.LOADED,
                    }
                })
            })
            .catch(error => {
                console.log(error);
                setState(oldState => ({
                    ...oldState,
                    item: error.message,
                    loadState: STATE.ERROR
                }))
                onError(error);
            });
    }, [apiName, guid, loadState]);
    // console.log("push2", lastState)

    // console.log('useLoaderApi return', apiName, guid, query)
    // TODO: remove loadState usage
    return [item, loadState, forceReloadData, setItem, lastState];
}