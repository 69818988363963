import React from 'react';
import request from 'superagent';
import {config} from '../config';
import {CircularProgress} from '@material-ui/core';
import PageApiLoader3 from "./PageApiLoader3";
import API, {STATE} from "libs/api";
import {useHistory, useRouteMatch} from "react-router-dom";
import {FormDataContextProvider} from "../FormDataContext";
import useLoaderApi, {useLoaderApiList} from "./useLoaderApi";
import Alert from "@material-ui/lab/Alert";
import {MeContext} from "../meContext";
import ErrorBoundary from "./ErrorBoundary";


export function SubApiReplace(props) {
    const {itemProps, item, itemValues, formProps, apiFilter, filter, parentField, onChange} =
        {
            parentField: 'legal',
            apiFilter: () => {},
            ...props
        };

    const initialValues = itemValues || item;
    const apiName = itemProps.apiName;
    // const url = 'props.url';
    let {url} = useRouteMatch();
    const history = useHistory();
    console.log('SubApiReplace', {props, itemProps, formProps}, useRouteMatch())
    const path = url;

    const onEdit = row => () => {
        // or backLink?
        history.push(
            row
                ? `${url}/${row.guid}/edit`
                // :`/legals/${filter.parent_guid}/${apiName}/new`
                : `${url}/new`,
        );
    };

    const onDelete = row => {
        return request.put(`/api/multi/${apiName}/${row.guid || row.id}`)
            .set('accept', 'application/json')
            .send({...row, isDeleted: true});
    };

    const apiFilterResult = apiFilter ? apiFilter(item) : {};

    return <>
        <PageApiLoader3
            // cols={[]}
            addMTableOptions={{
                search: false,
                paging: false,
            }}
            canAdd={true}
            canEdit={true}
            canDelete={props.canDelete}
            {...itemProps}
            onEdit={onEdit}
            onDelete={onDelete}
            onChange={onChange}
            filter={{
                [parentField]: initialValues.guid,
                _filter_SubApiReplace: 'SubApiReplace',
                ...filter,
                ...apiFilterResult,
            }}
            // filter={filter}
            parent={{
                menuRoot:              formProps.menuRoot || {},
                _items:                {_self: initialValues},
                parentItem: initialValues,
                [API.fieldCreateName]: [],
                // legal: `/api/${apiName}/${values.guid}`,
                // TODO: extend legal to parent?
                [parentField]: initialValues.guid,
            }}
            path={path}
            fullScreen={true}
        />
    </>
}

export default function FormLoadSub(props) {
    const {itemProps, item, itemValues} = props;
    const {AfterComponent, field, apiFilter, api} = {
        AfterComponent: SubApiReplace,
        apiFilter: () => {},
        ...itemProps
    };

    console.log('FormLoadSubprops', props, AfterComponent);
    // const [list, setList] = React.useState(null);
    const filter = apiFilter(itemValues);

    // React.useEffect(() => {
    //     if (list) {
    //         return;
    //     }
    //     request
    //         .get(config.apiPrefix + api)
    //         .set('accept', 'application/json')
    //         .query(filter)
    //         .then(response => setList(response.body));
    // }, [list]);


    // const list = props.item[field];
    // values;
    console.log('itemProps.item', itemProps.item)
    // console.log('itemProps.item cols', itemProps.item.cols)
    const {initialValues, apiName, cols, onFormDataAction} = itemProps.item || itemProps || {};
    const query = {};

    const {me} = React.useContext(MeContext);

    const [list, loadState, forceReloadData] = useLoaderApiList(apiName, filter, {}, {
        // initial: initialValues ?  initialValues({menuRoot: props.menuRoot, item: item, me, filter}) : {},
        onResponse: item => {
            const initial = initialValues && initialValues({menuRoot: props.menuRoot, item: item, me});
            console.log('call onResponse', item, initial, {...initial, ...item})
            return {...initial, ...item}
        }
    });
    console.log('loadState', loadState);

    switch (loadState) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress/>;
        case STATE.LOADED:
            console.log('FormDataContextProviderLOADED itemProps?',itemProps.item, itemProps.itemProps)
            return <ErrorBoundary info={'FormLoadSub'}>
                <FormDataContextProvider
                    initial={list}
                    onFormDataAction={onFormDataAction}
                    // handleSubmit={handleSubmit}
                    cols={cols}
                    item={itemProps.item}
                    // dbId={dbId}
                    // onSuccess={onSuccessLocal}
                    // refreshItem={refreshItem}
                    debug={'FormLoadSub'}
                >
                    <AfterComponent
                        {...props}
                        filter={filter}
                        {...itemProps.item}
                        list={{items: list}}
                        forceReloadData={forceReloadData}
                        /*cols={itemProps.cols}*/
                    />
                </FormDataContextProvider>
            </ErrorBoundary>
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {list} {`/api/${apiName}`}</Alert>;
    }
};