import React from "react";
import { 
    Tooltip,
    Box, 
    Typography
} from "@material-ui/core";

const CentralPart = ({name, color}) => {
    switch(name){
        case 'Проверен СБ ФЛ' : 

        return (
            <>
                
                <path
                        //  style="opacity:1;fill:#82c551;fill-opacity:1;fill:none;fill-width:0.1129801"
                        d="M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83V6.31l6-2.12 6 2.12v4.78zm-9.18-.5L7.4 12l3.54 3.54 5.66-5.66-1.41-1.41-4.24 4.24-2.13-2.12z"
                        id="path7078"
                        fill={color}
                    // connector-curvature="0" 
                    />
            </>
        )

        case 'Проверен СК' : 
        return (
            <>
                <path

                    d="m14.5 12.59.9 3.88-3.4-2.05-3.4 2.05.9-3.87-3-2.59 3.96-.34L12 6.02l1.54 3.64 3.96.34-3 2.59zM12 3.19l7 3.11V11c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4z"
                    id="path7419"
                    fill={color}
                />
               
               
            </>
        )

        case 'Проверяется СБ ФЛ' :
            return (
                <>

                    <path
                        d="M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7zM12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm1.65 7.35L11.5 12.2V9h1v2.79l1.85 1.85-.7.71z"
                        fill={color}

                    />
                    <path

                        d="m 49.917484,30.182267 h -2.863447 v 9.237513 l 10.022064,4.849694 1.431722,-1.893687 -8.590339,-4.110695 z"
                        id="path7690"
                        fill={color}
                    />

                </>
            ) 

        case 'Заблокирован СБ ФЛ':
            return (
                <>
                    <path
                        d="M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7zM9.91 8.5 8.5 9.91 10.59 12 8.5 14.09l1.41 1.41L12 13.42l2.09 2.08 1.41-1.41L13.42 12l2.08-2.09-1.41-1.41L12 10.59 9.91 8.5z"
                        fill={color}
                    />
                    
                </>
            )


        default:
            return (
                <>
                    <path
                        d="M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7z"
                        id="text7735"
                        fill={color}
                        />
                    <path
                        d="M11 14h2v2h-2zm0-7h2v5h-2z"
                        fill={color}
                    />
                </>
            )
    }
}

const Actuality = ({name, icon})=>{

    const [color, SetColor] = React.useState('#767474ff')

    
    const selectColor = (name) => {
        switch(name){
            case 'Проверен СБ ФЛ' :
                SetColor('#77c144')
                return null;
            case 'Проверен СК':
                SetColor('#77c144')
                return null;
            case 'Заблокирован СБ ФЛ' : 
                SetColor('#ff0000')
                return null
            case 'Проверяется СБ ФЛ':
                SetColor("#ffac00")
                return null
            default : 
            SetColor("#767474ff")

        }
    }

    React.useEffect(()=>{
        selectColor(name)
    }, [])

    return (
        <Tooltip title={name || 'Не проверен СБ ФЛ'}>
            <Box>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >

                    <>
                        <CentralPart color={color} name={name}/>
                    </>
                </svg>
            </Box>


        </Tooltip>
        
    )
}

export {Actuality}