import {Badge} from '@material-ui/core';
import {FormatIndentDecrease, FormatIndentIncrease, Timeline, VisibilityOutlined} from '@material-ui/icons';
import {SubFormBiddings} from 'components/FieldFormat/SubFormBiddings';
import FormLoadSub, {SubApiReplace} from 'components/FormLoadSub';
import {BiddingIcon, ServiceIcon} from 'components/icons';
import {
    ApiDataLoadLookup,
    ColObjectName,
    ColBoolean,
    ColObjectSimpleData,
    ColObjectOptionsName
} from 'components/showItems';
import TabRequestAccesses from 'components/TabRequestAccesses';
import bidding from 'config/bidding';
import {FieldFormat} from 'config/FieldFormat';
import React from 'react';
import detailPanelRender2and1 from 'components/MTableExtend/detailPanelRender2and1';
import {onFormDataActionNestedRoutePlace} from "config/docRequest_routePlaces";
import {asGUID} from "../libs/asGUID";
import userFiles from "./userFiles";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import PageChat from "../components/PageChat";
import {TextTooltipCargo, IconContextCount} from 'components/RoutePlaceIconCargo'
import {FORM_ACTION} from "../FormDataContext";
import moment from "moment";

const tenderSubItems = (item) => {
    return {
        style: 'tabs',
        list: [
            {
                listTitle: 'Маршрут',
                field:     'routePlaces',
                icon:      <Timeline/>,
                onFormDataAction: onFormDataActionNestedRoutePlace,
                detail:    [
                   {
                        render: detailPanelRender2and1,
                        tooltip:   <TextTooltipCargo/>,
                        icon:      () => <IconContextCount field={'loadCargo'} icon={'cargos'} />,
                        colsSplit: 1,
                        field:   'loadCargo',
                        // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
                        cols: async () => {
                            const [lookupCargo, lookupMeasure]
                                    = await Promise.all([
                                ApiDataLoadLookup('/api/cargo_types', 'guid', false),
                                ApiDataLoadLookup('/api/measures', 'guid', false),
                            ]);

                            const getMeasure = api => {
                                const guid = api && api.substr(-36);
                                return lookupMeasure[guid]
                                        ? 'Упаковка: ' + lookupMeasure[guid].name
                                        : guid;
                            };

                            return [
                                {
                                    title:         'Тип груза',
                                    field:         'cargoType',
                                    api:           'cargo_types',
                                    format: FieldFormat.FORMAT_SELECT_API,
                                    object: ColObjectName,
                                    onRowChange:   (data, {field, value}) => {
                                        const cargo = lookupCargo[asGUID(data.cargoType)];
                                        if (!cargo) {
                                            return data;
                                        }
                                        let update = {...data};
                                        if (cargo.measureValue) {
                                            // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                            update.cargoValue = (data.qtyInMeasure * cargo.measureValue).toFixed(2);
                                        }
                                        if (cargo.measureWeight) {
                                            // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                            update.cargoMass = (data.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceValue) {
                                            // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                            update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                        }
                                        if(cargo.okpd2){
                                            update.cargoOkpd2 = cargo.okpd2
                                        }

                                        return update;
                                    },
                                },
                                {
                                    title: 'Упаковок',
                                    field: 'qtyInMeasure',
                                    format: FieldFormat.FORMAT_NUMBER,
                                    object:        ColObjectSimpleData,
                                    onRowChange:   (data, {field, value}) => {
                                        const cargo = lookupCargo[asGUID(data.cargoType)];
                                        if (!cargo) {
                                            return data;
                                        }
                                        let update = {...data};
                                        if (cargo.measureValue) {
                                            // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                            update.cargoValue = (value * cargo.measureValue).toFixed(2);
                                        }
                                        if (cargo.measureWeight) {
                                            // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                            update.cargoMass = (value * cargo.measureWeight).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceValue) {
                                            // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                            update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                                        }
                                        return update;
                                    },
                                },
                                {
                                    title: 'Мест',
                                    field: 'qtyInCargoPlaces',
                                    format: FieldFormat.FORMAT_NUMBER,
                                    object: ColObjectSimpleData,
                                    minValue: 0,
                                    onRowChange: (data, { field, value }) => {
                                        const cargo = lookupCargo[asGUID(data.cargoType)];
                                        if (!cargo) {
                                            return data;
                                        }
                                        let update = { ...data };
                                        if (cargo.cargoPlaceValue) {
                                            // - Объем = [Количество мест] * [ТипГруза].[ОбъемМеста]
                                            // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                            update.cargoValue = (value * cargo.cargoPlaceValue).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceWeight) {
                                            // - Вес = [Количество мест] * [ТипГруза].[ВесМеста]
                                            // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                            update.cargoMass = (value * cargo.cargoPlaceWeight).toFixed(2);
                                        }
                                        if (cargo.measureValue) {
                                            // - Упаковок = [ОБъем] / [ТипГруза].[ОбъемУпаковки]
                                            // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                            update.qtyInMeasure = (update.cargoValue / cargo.measureValue).toFixed(2);
                                        }
                                        return update;
                                    },
                                },
                                {
                                    title: 'Объем, м^3',
                                    field: 'cargoValue',
                                    object: ColObjectSimpleData,
                                    measureText: '(м^3)',
                                    // editComponent: MTableTextField,
                                    format: FieldFormat.FORMAT_NUMBER,
                                    minValue: 0,
                                    onRowChange: (data, { field, value }) => {
                                        const cargo = lookupCargo[asGUID(data.cargoType)];
                                        if (!cargo) {
                                            return data;
                                        }
                                        let update = { ...data };
                                        if (cargo.measureValue) {
                                            // 4. При изменении [Объем] считать:
                                            // - Упаковок = [Объем] / [ТипГруза].[ОбъемУпаковки]
                                            // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                            update.qtyInMeasure = (value / cargo.measureValue).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceValue) {
                                            // - Мест = [Объем] / [ТипГруза].[ОбъемМеста]
                                            // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                            update.qtyInCargoPlaces = (value / cargo.cargoPlaceValue).toFixed(2);
                                        }
                                        if (cargo.measureValue) {
                                            // - Вес = [Количество упаковок] * [ТипГруза].[ВесУпаковки]
                                            // если [ТипГруза].[ВесМеста] = 0 то не пересчитывать
                                            update.cargoMass = (update.qtyInMeasure * cargo.measureWeight).toFixed(2);
                                        } else if (0 === Number(cargo.measureWeight)) {
                                            // если [ТипГруза].[ВесУпаковки] = 0, то
                                            // Вес = [Количество мест] * [ТипГруза].[ВесМеста],
                                            update.cargoMass = (update.qtyInCargoPlaces * cargo.cargoPlaceWeight).toFixed(2);
                                        }
                                        return update;
                                    },

                                },
                                {
                                    title: 'Вес, кг',
                                    field: 'cargoMass',
                                    object: ColObjectSimpleData,
                                    measureText: '(кг)',
                                    // editComponent: MTableTextField,
                                    format: FieldFormat.FORMAT_NUMBER,
                                    minValue: 0,
                                    onRowChange: (data, { field, value }) => {
                                        const cargo = lookupCargo[asGUID(data.cargoType)];
                                        if (!cargo) {
                                            return data;
                                        }
                                        let update = { ...data };
                                        if (cargo.measureWeight) {
                                            // 5. При изменении [Вес] считать:
                                            // - Упаковок = [Вес] / [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                            update.qtyInMeasure = (value / cargo.measureWeight).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceWeight) {
                                            // - Мест = [Вес] / [ТипГруза].[ВесМеста] // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                            update.qtyInCargoPlaces = (value / cargo.cargoPlaceWeight).toFixed(2);
                                        }
                                        if (cargo.cargoPlaceValue) {
                                            // - Объем = [Количество упаковок] * [ТипГруза].[ОбъемУпаковки]
                                            // если [ТипГруза].[ОбъемМеста] = 0 то не пересчитывать
                                            update.cargoValue = (update.qtyInMeasure * cargo.measureValue).toFixed(2);
                                        } else if (0 === Number(cargo.measureValue)) {
                                            // если [ТипГруза].[ОбъемУпаковки] = 0,
                                            // то Вес = [Количество мест] * [ТипГруза].[ОбъемМеста],
                                            update.cargoValue = update.qtyInCargoPlaces * cargo.measureValue;


                                        }
                                        return update;
                                    },
                                },
                            ];
                        },
                    },
                    values => ({
                        render: detailPanelRender2and1,
                        colsSplit: 1,
                        field:   'services',
                        tooltip: 'Услуги',
                        icon:    () => <Badge badgeContent={(values.services||[]).length} color="primary"><ServiceIcon/></Badge>,
                        // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
                        cols: () => (
                                [
                                    {
                                        title:  'Услуга',
                                        field:  'service',
                                        api:    'services',
                                        format: FieldFormat.FORMAT_SELECT_API,
                                        object: ColObjectName,
                                    },
                                ]
                        ),
                    }),
                ],
                cols:      (props, other) => {
                    const {item} = props;

                    //const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
                    //await sleep(2000); // need for correct draw table
                    return [
                        {
                            title:         'Адрес',
                            field:         'point',
                            api:           'fias_address_trees',
                            format: FieldFormat.FORMAT_FIAS_ADDRESS,
                            object: ColObjectName,
                        },
                        {
                            title:   'Тип',
                            format:  FieldFormat.FORMAT_SELECT,
                            field:   'typePoint',
                            options: [
                                {
                                    id:   'погрузка',
                                    name: 'Погрузка',
                                },
                                {
                                    id:   'разгрузка',
                                    name: 'Разгрузка',
                                },
                                // {
                                //     id:   'загрузка-погрузка',
                                //     name: 'Загрузка и погрузка',
                                // },
                            ],
                            object:  ColObjectOptionsName,
                        },
                        // {
                        //     title:  'Владелец груза',
                        //     lookup: lookupCargoOwner,
                        //     // render: rowData => rowData.cargoOwner
                        //     //     && lookupCargoOwner[rowData.cargoOwner],
                        //     api:    'individual',
                        //     format: FieldFormat.FORMAT_SELECT_API,
                        //     field:  'owner',
                        // },
                        // {
                        //     title:  'Дата прибытия',
                        //     format: FieldFormat.FORMAT_DATETIME_LOCAL,
                        //     field:  'dateFrom',
                        //     type:   'datetime',
                        // },
                        // {
                        //     title:  'Дата убытия',
                        //     format: FieldFormat.FORMAT_DATETIME_LOCAL,
                        //     field:  'dateTo',
                        //     type:   'datetime',
                        // },
                        // {
                        //     title:  'Контактное лицо',
                        //     api:    'contact_people',
                        //     lookup: lookupContact,
                        //     format: FieldFormat.FORMAT_SELECT_API,
                        //     field:  'contact',
                        // },
                        {
                            title:    'key_id',
                            field:    'key_id',
                            editable: 'never',
                            hidden:   true,
                        },
                    ];
                },
            },
            {
                listTitle:      'Торги',
                Component:      FormLoadSub,
                AfterComponent: SubFormBiddings,
                item:           bidding,
                icon:           <BiddingIcon/>,
                canAdd:         true,
                field:          'biddings',
                api:            'biddings',
                helperTextForDisableButton : 'Для добавления новой торговой сессии необходимо сохранить текущий тендер.',
                onFormDataAction: (values, props) => {
                    const {action, field, value} = props;
                    const _fix = {}
                    switch (action) {
                        case FORM_ACTION.INIT:
                            // _fix.biddingType = 'Тендер';
                        break;
                    };
                    return  {...values, ..._fix}

                },
                cols:           {
                    BiddingType: {
                        title:   'Тип торгов',
                        field:   'biddingType',
                        format:  FieldFormat.FORMAT_SELECT,
                        cols:    6,
                        options: [
                            {
                                id:   'Тендер',
                                name: 'Тендер',
                            },
                        ],
                    },
                },
                apiFilter:      values => (
                        {
                            docLinkedGuid: values.guid || '00000000-0000-0000-0000-000000000000',
                            docLinkedName: 'Тендер',
                        }
                ),
            },
            {
                listTitle: 'Доступ',
                field:     'tenderAccesses',
                icon:      <VisibilityOutlined/>,
                // Component:      FormRequestSubApiLoader,
                // AfterComponent: SubFormLIstEdit,
                Component: TabRequestAccesses,
                cols: [
                    {
                        title: 'Перевозчик',
                        // lookup:   lookupLegals,
                        field: 'legal',
                        api: 'legals',
                        required: true,
                        apiFilter: () => (
                                { 'exists[contragent]': true }
                        ),
                        format: FieldFormat.FORMAT_SELECT_API,
                        object: ColObjectName,
                    },
                    {
                        title: 'Видимая',
                        field: 'visible',
                        type: 'boolean',
                        object: ColBoolean
                    },
                ]
            },
            item.guid && {

                listTitle: 'Чаты',
                field: 'chats',
                icon: <ChatOutlinedIcon/>,
                Component: PageChat,
                // Component:      FormRequestSubApiLoader,
                // AfterComponent: SubFormLIstEdit,
            },
            item.guid && {
                Component: SubApiReplace,
                api: 'attached_files',
                apiFilter: values => (
                        {
                            objLinkedName: "doc_tenders",
                            objLinkedGuid: values.guid
                        }
                ),
                objLinkedName: "doc_tenders",
                objLinkedGuid: item.guid,
                ...userFiles,
                icon: <AttachFileOutlinedIcon/>
            }
        ]
    }
}

export default tenderSubItems;