import React from "react";
import {FormDataContext} from "../FormDataContext";

const useCheckField = ( check = [], field = "", ) => {
    const {state: {values}, requireFields, putRequiredFields} = React.useContext(FormDataContext);
    const value = values[field]
    const [stateHelperText, setStateHelperText] = React.useState(null)
    const [loadState, setLoadState] = React.useState(false);
    React.useEffect(()=>{
        if(check.length && value){
            setLoadState(true);
            putRequiredFields([...requireFields, {name: field}]);
            Promise.all(check.map(el => el(value, {values})))
                    .then(result => {
                        setLoadState(false)
                        if(result.filter(el => el).length){
                            setStateHelperText(result.filter(el => el !== '').join(', '));
                        }else{
                            putRequiredFields(requireFields.filter(el => el.name !== field));
                            setStateHelperText(false);

                        }
                    })
                    .catch(err => {
                        console.error(err)
                        setStateHelperText(err.message)
                    })
        }
    }, [value])

    return [stateHelperText, loadState]
}

export  default  useCheckField