import React from "react";
import {FormDataContext} from "FormDataContext";
import {Button} from "@material-ui/core";
import {CancelIcon} from "components/icons.jsx";
import ConfirmDialog from "components/ConfirmDialog";

export function ActionButtonCancel(props) {
    const {handleClose, itemName} = {itemName: 'объект', ...props};
    const {state, values} = React.useContext(FormDataContext);
    const {submitting} = state;

    const [confirm, setConfirm] = React.useState(false)

    const {name: objectName} = {objectName: '', ...values};

    const onConfirmDialog = () => setConfirm(true);
    const onConfirmDialogCancel = () => setConfirm(false);
    const onConfirm = () => {
        setConfirm(true);
        handleClose();
    }


    return (
        <>
            <Button
                variant="contained"
                size="small"
                disabled={submitting}
                onClick={onConfirmDialog}
                startIcon={<CancelIcon/>}
            >
                Закрыть
            </Button>
            {confirm && <ConfirmDialog
                show={true}
                title={'Подтвердите действие'}
                text={`Закрыть ${itemName} ${objectName} ?`}
                onConfirm={onConfirm}
                onCancel={onConfirmDialogCancel}
            />}

        </>
    )
}