import {
    CircularProgress,
    TextField,
    Typography,
    Box
} from '@material-ui/core';
import Autocomplete from "../Autocomplete";
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import Grid from "@material-ui/core/Grid";
import useLoaderApi, {useLoaderApiList} from "components/useLoaderApi";

import {STATE} from "libs/api";
import {ItemInfo} from "components/ItemInfo";
import {withStyles} from "@material-ui/core/styles";
import {config} from "../../config";

//  TODO: объединить с файлом FormApiAutocomplete


const renderInputDefault = (params, options) => {
    const {helperText, loading, handleChange, label, errors, item, freeSoloCanEdit, valueSelected, itemLoaded} = options;
    
    const helpers = [
        helperText,
        errors,
    ]
        .filter(v => v)
        .map(v => <div>{v}</div>);
    
    // HACK: подменить ранее выбранное кастомное значение
    if (freeSoloCanEdit && valueSelected) {
        params.inputProps.value = params.inputProps.value || valueSelected;
    }
    const asDeletedStyle = itemLoaded && itemLoaded.isDeleted
        ? {
            style: {
                textDecoration: 'line-through',
            }
        }
        : {}
    return (<ErrorBoundary info={'Textfield'}>
        <TextField
            {...params}
            onChange={handleChange}
            label={label}
            fullWidth
            variant="outlined"
            margin={'dense'}
            error={!!errors}
            disabled={loading}
            required={item.required}
            helperText={helpers}
            InputLabelProps={{shrink: true}}
            InputProps={{
                ...params.InputProps,
                ...asDeletedStyle,
                endAdornment: (
                                  <React.Fragment>
                                      {loading && <CircularProgress
                                          color="inherit"
                                          size={20}
                                      />}
                                      {params.InputProps.endAdornment}
                                      {valueSelected && <ItemInfo item={item} itemLoaded={itemLoaded}/>}
                                  </React.Fragment>
                              ),
            }}
        /></ErrorBoundary>
    );
};

export default function FormApiAutocompleteOther(props) {
    const {textSecondary} = props.item || {};
    const {getOptionDisabled, textDisabled} = props
    const defaultProps    = {
        renderInput:    renderInputDefault,
        getOptionLabel: option => option.name || '',
        onLoadValue:    () => {},
        addText:        'Добавить',
        values:         {},
        renderOption:   (option) => <ErrorBoundary info={'FormApiAutocompleteOther'}>
            <Grid container alignItems="center" style={option.guid === props.value
                    ? {backgroundColor: '#eee'}
                    : {}}>
                <Grid item>
                    <Box display={"flex"} alignItems="center">
                        <Typography variant="subtitle2" color="textPrimary">
                            {option.name}
                        </Typography>
                        {
                            textDisabled && textDisabled(option)
                        }
                    </Box>
                    {textSecondary && <Typography variant="caption" color="textSecondary">
                        {textSecondary(option)}
                    </Typography>}
                </Grid>
            </Grid>
        </ErrorBoundary>
    };

    const {
              api,
              label,
              value,
              values,
              errors,
              onChange,
              onLoadValue,
              apiFilter,
              item,
              multiple,
              renderInput,
              getOptionLabel,
              renderOption,
              helperText,
              addText,
              setUserErrors,
              ...other
          } = {...defaultProps, ...props};

    const {fieldShow, calcWidth, freeSolo, freeSoloField, freeSoloCanEdit} = {
        fieldShow: 'name',
        freeSoloField: null,
        ...item,
    };

    const [open, setOpen] = React.useState(false);
    // const [loading, setLoading]                     = React.useState(false);
    // const [options, setOptions]                     = React.useState([]);
    const [optionsLength, setOptionsLength]    = React.useState(0);
    const [inputValue, setInputValue]          = React.useState('');
    const [valueCustom, setValueCustom] = React.useState(values[freeSoloField]); // valueName
    

    const onError = error => {
        const statusError = error.message.toLocaleLowerCase().includes('not found');
        !statusError && setUserErrors(`${error} api - ${api}`)
        setValueCustom(`${statusError
                ? 'Не найдено'
                : error} ${value}`);
    };
    
    // дозагрузка отсутствующего значения
    
    const [itemLoaded, loadStateItem, forceReloadItem] = useLoaderApi(api, value, {}, {onError});

    const valueSelected = valueCustom || (itemLoaded
        ? itemLoaded[fieldShow]
        : value)
    ;

    const mapItem = item => {
        // console.log('maxLen',item[fieldShow],maxLen);
        // maxLen = Math.max(maxLen, item[fieldShow].length)
        return {
            id:   `/api/${api}/${item.guid}`,
            guid: item.guid,
            name: item[fieldShow],
            item
        }
    };
    
    const [options, loadStateList] = useLoaderApiList(api, {name: inputValue, ...apiFilter}, {onError, mapItem});
    
    
    // React.useEffect(()=>{
    
    // const options = items || [];
    
    // const loadStateList = STATE.LOADED;
    // const options = [];
    
    const loading = loadStateList === STATE.LOADING || loadStateItem === STATE.LOADING;

    const handleChange = event => {
        console.log('handleChange',event)
        // setOptions([]); // ?
        setInputValue(event.target.value);
        // setLoading(true);
    };

    const onChangeLocal = (event, value) => {
        console.log('onChangeLocal', event, value);
        if (!value) {
            // clear values
            onChange({
                target: {
                    value:           null,
                    guid:            null,
                    item:            null,
                    [freeSoloField]: '',
                },
            });
            setValueCustom('');
        } else if (value.otherValue) {
            // set custom
//            setValueSelected(value.inputValue);
            setValueCustom(value.otherValue);
            // setInitialValues({name: value.otherValue});
            onChange({
                target: {
                    value:           null,
                    guid:            null,
                    [freeSoloField]: value.otherValue,
                },
            })
        } else {
            // set selected
            onChange({
                target: {
                    value:           value && value.id,
                    guid:            value && value.guid,
                    item:            value && value.item,
                    [freeSoloField]: '',
                },
            });
            setValueCustom('');
        }
    };

    if (value && !valueSelected && typeof value == 'string') {
        return <React.Fragment>
            <CircularProgress title={'no valueSelected'}/>
        </React.Fragment>;
    }
    // const freeSolo           = Boolean(subConfig);
    let valueEdit = undefined;
    if (open) {
        valueEdit = freeSoloCanEdit
            ? {
                'guid': value,
                'name': inputValue,
            }
            : undefined;
    }
    else {
        valueEdit = {
            'guid': value,
            'name': valueSelected,
        };
    }

    const getOptionSelected = (option, value) => {
        return value && (
            option.guid === value.guid
        )
    }

    return <React.Fragment><ErrorBoundary info={'Autocomplete'}>
        {/*<pre>{JSON.stringify({valueCustom, valueSelected,inputValue})}</pre>*/}
        {/*<pre>config.get(subConfig):{JSON.stringify(config.get(subConfig))}</pre>*/}
        {/*{subConfig &&<pre>addNewElement:{JSON.stringify(addNewElement)}</pre>}*/}
        <Autocomplete
            multiple={multiple}
            freeSolo={freeSolo}
            open={open}
            style={calcWidth && optionsLength ? { width: optionsLength*60, minWidth: "120px" } : {minWidth: "120px"}}
            onOpen={() => {
                setOpen(true);
                // setLoading(true);
                // freeSoloCanEdit && setInputValue(valueSelected);
            }}
            onClose={() => {
                setOpen(false);
                setInputValue(null);
            }}
            filterOptions={(options, params) => {
                // console.log(options, params);
                // return options;
                //const filtered = filter(options, params);
                const filtered = options;

                if (freeSolo && params.inputValue !== '') {
                    filtered.push({
                        otherValue: params.inputValue,
                        name:       `${addText} "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            getOptionSelected={getOptionSelected}
            textDisabled={textDisabled}
            getOptionLabel={getOptionLabel}
            options={options || []}
            loading={loading}
            value={open
                ? null
                : {
                    'guid': value,
                    'name': valueSelected,
                }}
            onChange={onChangeLocal}
            noOptionsText={'Нет вариантов'}
            getOptionDisabled={getOptionDisabled}
            renderInput={params => renderInput(
                params,
                {helperText, loading, handleChange, label, errors, item, freeSoloCanEdit, valueSelected, itemLoaded}
            )}
            renderOption={renderOption}
            clearText={config.toolTipClearIconText}
            {...other}
        />
    </ErrorBoundary></React.Fragment>;
}
