import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import MomentUtils from '@date-io/moment';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {FormDataContext} from 'FormDataContext';

export default function({ item}) {
    const { field, title, required} = item;
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const onClikInput = (event) => {
        if(event.target.value === '01.01.0001 00:00'){
            event.target.value = null
        }
    }
  
    const onChangeDatePicker = handleChange => moment => {
        const valueOriginal = moment && moment._i
                ? moment._i
                : null
        let value = (moment && moment.isValid())
            ? moment.format()
            : valueOriginal;
        handleChange({target: {value}},
        );
    };

    
    return <React.Fragment>
        <AdminShow>[DatetimeLocal]</AdminShow>
        <MuiPickersUtilsProvider
            locale={'ru'}
            utils={MomentUtils}>
            <KeyboardDateTimePicker
                style={{minWidth: "14rem"}}
                autoOk
                hideTabs
                fullWidth
                ampm={false}
                //disableToolbar
                variant="inline"
                clearable="true"
                // disableFuture
                // openTo="year"
                format="DD.MM.YYYY HH:mm"
                label={title}
                margin={'dense'}
                inputVariant={'outlined'}
                // views={['year', 'month', 'date']}
                value={values[field] || null}
                emptyLabel= '01.01.0001 00:00'
                onClick={onClikInput}
                // value={values[field] || null}
                error={!!errors[field]}
                required={required}
                // helperText={required && '*Обязательное поле'}
                onChange={onChangeDatePicker(handleChange(item))}
                InputAdornmentProps={{ position: "start" }}
                invalidDateMessage='Неверный формат данных'
                minDateMessage = ''


            />
        </MuiPickersUtilsProvider>
    </React.Fragment>;
}