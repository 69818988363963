import {
    FavoriteBorder, Help,
} from '@material-ui/icons';

import QueueIcon from '@material-ui/icons/Queue';

import {
    config,
} from 'config';
import FormB2B from 'components/FormB2B/FormB2B';

import SyncTable from 'page/SyncTable';
import {ROLES} from 'config/roles';

import {
    MenuGroupIcon1,
    MenuGroupIcon2,
    MenuGroupIcon3,
    SyncIcon,
} from 'components/icons';
import PageApiLoader from "../components/PageApiLoader3";
import HelpIcon from '@material-ui/icons/Help';
import React from "react";
import PageHelp from 'components/Help/pageHelp';


const menuItems = [
    {
        title:   'Избранное',
        roles:   [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        icon:    FavoriteBorder,
        subOpen: true,
        style:   {
            borderRight:     'solid 3px #8E8',
            backgroundColor: '#88EE8820',
        },
        sub:     [
            config.docRequest.menu,
            config.kanban.menu,
            config.routePoints.menu,
            config.priceRequest.menu,
            config.deliveryRequest.menu,
            config.tender.menu,
        ],
    },
    '-',
    {
        title: 'Моя Компания',
        icon:  MenuGroupIcon1,
        roles: [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        path:  '/my',
        style: {
            borderRight:     'solid 3px #88E',
            backgroundColor: '#8888EE20',
        },
        sub:   [
            config.legals.menu,
            config.member.menu,
            config.routePoints.menu,
            config.cargoType.menu,
            config.settingsProfile.menu
            // config.measure.menu,
        ],
    },
    '-',
    {
        title: 'Настройка очередей',
        icon:  QueueIcon,
        roles: [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        path:  '/route',
        style: {
            borderRight:     'solid 3px #88E',
            backgroundColor: '#3388EE20',
        },
        sub:   [
            config.queueCriteria.menu,
            config.rulesPatterns.menu,
            config.queue.menu,
        ],
    },
    '-',
    {
        title: 'Перевозчики и Контрагенты',
        icon:  MenuGroupIcon2,
        roles: [ROLES.ROLE_USER],
        id:    'carrier',
        path:  '/carrier',
        style: {
            borderRight:     'solid 3px #8E8',
            backgroundColor: '#88EE8820',
        },
        sub:   [
            config.carriers.menu,
            config.contract.menu,
            config.driver.menu,
            config.transport.menu,
            config.service.menu,
        ],
    },
    '-',
    {
        title: 'Документы',
        icon:  MenuGroupIcon3,
        roles: [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        path:  '/docs',
        style: {
            borderRight:     'solid 3px #88E',
            backgroundColor: '#8888EE20',
        },
        sub:   [
            config.docRequest.menu,
            config.priceRequest.menu,
            config.deliveryRequest.menu,
            config.tender.menu,
        ],
    },
    '-',
    {
        title:     'Справка',
        path:      '/help',
        Component: props =>
                       <PageHelp {...props}/>,

        icon:      HelpIcon,
        roles:     [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
    },
    {
        title:     'Синхронизация',
        path:      '/soap/sync',
        Component: props => <PageApiLoader
            {...props}
            {...config.sync}
            canEdit={true}
            canAdd={true}
        />,
        icon:      SyncIcon,
        roles:     [ROLES.ROLE_DEBUG],
    },
    {
        title: '',
        path: '/b2border/',
        hiddenMenu: true,
        Component: props => {
            const url = `${window.location.href}`.split('/')
            const re =  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
            const guid = url.find(el => re.test(el))
            return (
                <>
                    {guid ? <FormB2B guid={guid}/> : <></>}
                </>
            )
          
        },
        icon:      SyncIcon,
        roles:     [ROLES.ROLE_USER],
    }
];

export default menuItems;