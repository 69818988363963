import React from "react";
import API from "libs/api";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";

import {makeStyles} from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => (
    {
        root:         {
            display:  'flex',
            flexWrap: 'wrap',
        },
        margin:       {
            // margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField:    {
            // width: '25ch',
        },
        logo : {
            display: 'block',
            margin: '2rem auto'
        },
        error_message: {
            background: '#fdeded',
            width: '96%',
            margin: '0 2%',
        },
        m_r_1 : {
            marginBottom : '1rem'
        },
        p_bottom_1: {
            paddingBottom: "1rem"
        }

    }
));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

export default function ClosedForm(props) {
    console.log('callfunction LoginForm');

    const classes             = useStyles();

    const [tab, setTab] = React.useState(0);

    return <Dialog
        maxWidth={'xs'}
        open={true}
    >
        <Grid>
            <img src="/logo_rus.svg" className={classes.logo}/>
        </Grid>
        <AppBar position="static" color="default">
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"

            >
                <Tab label="Внимание!"/>
            </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
            <DialogTitle>
                <Typography>Ведутся технические работы на сайте!</Typography>
                <Typography>Доступность сайта будет восстановлена в ближайшее время</Typography>
            </DialogTitle>
            <DialogContent>

            </DialogContent>

        </TabPanel>

    </Dialog>;
}

export {ClosedForm}