import contractCarrier from 'config/contractCarrier';
import asArray from 'libs/asArray';
import React from 'react';
import {
    DoneOutlined,
    ErrorOutlined,
    Email as DomainVerification,
} from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ErrorIcon from '@material-ui/icons/Error';
import  AttachFileOutlinedIcon   from '@material-ui/icons/AttachFileOutlined';

import API from 'libs/api';
import FormLoadSub, {SubApiReplace} from 'components/FormLoadSub';
import HOCLoadItem from 'components/HOCLoadItem';
import InvaitsPage from 'components/InvaitsPage'
import {OrganizationsIcon} from 'components/icons';
import {ColBoolean, ColObjectName, ShowContactPerson, ShowDate, ShowOrganization} from 'components/showItems';
import contactPerson from 'config/contactPerson';
import {FieldFormat} from 'config/FieldFormat';
// import bankAccount from 'config/bankAccount';
import {ROLES} from 'config/roles';
import {STATE} from 'libs/api';
import moment from 'moment';
import PageCarriers from "page/PageCarriers";
import contactInfo from "config/contactInfo";
import bankAccount from 'config/bankAccount';
import userFiles from 'config/userFiles'
import driver from "config/driver";
import transport from "./transport";
import contract from "./contract";

import {FORM_ACTION, FormDataContext} from 'FormDataContext';
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
    CircularProgress,
    Tooltip,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import useLoaderApi from "components/useLoaderApi";
import { Actuality } from 'components/Icons';

const mapSuggestions = fieldName => (data, oldData) => {
    const dataAdress = data.address ? data.address.data : data
    let levelsLook = [
        'country', 'region', 'area', 'city', 'settlement', 'street', 'house', 'flat',
    ];
    const levels = levelsLook.map(lvl => {
            
            return {
                level:            lvl,
                kladr_id:         dataAdress[lvl + '_kladr_id'] || '',
                fias_id:          dataAdress[lvl + '_fias_id'] || '',
                object_type:      dataAdress[lvl + '_type'] || '',
                object_type_full: dataAdress[lvl + '_type_full'] || '',
                object:           lvl === 'house' && dataAdress['block']
                                      ? `${dataAdress[lvl]}  ${dataAdress['block_type']} ${dataAdress['block']}`
                                      : dataAdress[lvl] || '',
                object_with_type: dataAdress[lvl + '_with_type'],
                geo_lat:          dataAdress[lvl + '_fias_id'] === dataAdress['fias_id']
                                      ? dataAdress['geo_lat']
                                      : 0,
                geo_lon:          dataAdress[lvl + '_fias_id'] === dataAdress['fias_id']
                                      ? dataAdress['geo_lon']
                                      : 0,
            };
        })
        .filter(v => v)
        .filter(l => l.object !== '');
    
    const last = levels[levels.length - 1];
    const address = [
        ...levels.map(o => {
            return o.object ?
                `${o.object_type} ${o.object}`
                : ''
        })
    ]
        .filter(e => e !== '')
        .join(', ');
    return data.useCustom
        ? {
            ...oldData,
            [fieldName]: data[data.useCustom],
        }
        : {
            name:             (data.name && (
                data.name.short_with_opf || data.name.full_with_opf
            )) || data.custom_name,
            fullName:         data.address ? data.name && data.name.full_with_opf : null,
            inn:              data.inn,
            kpp:              data.kpp,
            ogrn:             data.ogrn,
            address:          address,
            registrationDate: data.ogrn_date && moment(data.ogrn_date).format(),
            latitude:         data.geo_lat,
            longitude:        data.geo_lon,
            levels,
            last:             last,
            legalAddress:     ''
            
        }
};

const ShowContragentValid = (props) => {
    return (
        <Box mt={1}>
            <Tooltip title="Подтвержденный перевозчик">
                <DoneOutlined />
            </Tooltip>
        </Box>
    )
    
}

const ShowContragentInvite = (props) => {
    return (
        <Box mt={1}>
            <Tooltip title="Сделать приглашение для перевозчика">
                <ErrorOutlined />
            </Tooltip>
        </Box>
    )
}

const ShowLoadedContragent = (props) => {
    const {item, rowData, loadState} = props;
    console.log('ShowContragent', {props})
    const valid = rowData.isTPValid || item && item.data && item.data.length;
    switch (loadState) {
        case STATE.INIT:
        case STATE.LOADING:
            return <Box pt={1}><CircularProgress size={24}/></Box>;
        case STATE.LOADED:
            return valid
                ? <ShowContragentValid {...props} />
                : <ShowContragentInvite{...props} />;
        
        case STATE.ERROR:
        default:
            return <ErrorIcon color={'error'}/>;
    }
}

function Contragent(props) {
    const {rowData} = props;
    const {guid} = {...rowData};
    if (!guid) {
        return '---';
    }
    const Load = rowData.isTPValid
        ? ShowContragentValid
        : HOCLoadItem({path: `/api/carrier_validate/${guid}`})(ShowLoadedContragent);
    return <Load {...props} />;
}

function CheckActuality(props) {
    const {guid, setStateValues} = props;
    const [item, loadState] = useLoaderApi('carrier_validate', guid)
    React.useEffect(() => {
        if (loadState === STATE.LOADED) {
            setStateValues(values => {
                return {
                    ...values,
                    actualityStateName:  item['actual'],
                    actualityDateFormat: item['actual_date'],
                }
            })
        }
        
    }, [loadState])
    
    switch (loadState) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress/>;
        case STATE.LOADED:
            return <>
                Статус: {item && item['actual'] || '---'}<br/>
                Дата: {item && item['actual_date'] || '---'}<br/>
            </>
        case STATE.ERROR:
            return <>
                Ошибка: {item || '---'}<br/>
            </>
        
    }
}

export const RenderIconActuality = ({ name, icon }) => {
    return(
        <Box pt={icon ? 0 : 1} pr={1} >
            <Actuality name={name}/>
        </Box>
    
    )
}

function UpdateActuality(props) {
    const {values, name, setStateValues, value, icon} = props;
    const [open, setOpen] = React.useState(false);
    
    return <>
        {open && <Dialog open={true} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <Typography>
                        Обновление информации о проверке
                    </Typography>
                    <IconButton>
                        <Close onClick={() => setOpen(false)} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <CheckActuality guid={values.guid} setStateValues={setStateValues} />
                </DialogContentText>
                <DialogActions>
                </DialogActions>
            </DialogContent>
        </Dialog>}
        <IconButton size={'small'} onClick={() => setOpen(true)}>
            <Tooltip title={'Обновить информацию о проверке'}><AutorenewIcon /></Tooltip>
        </IconButton>
    </>;
}

UpdateActuality.propTypes = {};
export default {
    generateLink:     o => `/carrier/legals/${o.guid}`,
    listTitle:        'Юридические лица',
    apiName:          'legals',
    itemName:         'Юридическое лицо',
    filterParent:     () => (
        {
            // 'exists[contragent]': menuRoot.id === 'carrier',
            isCarrier: true
        }
    ),
    menu:             {
        title:     'Юридические лица',
        path:      '/legals',
        Component: PageCarriers,
        icon:      OrganizationsIcon,
        roles:     [ROLES.ROLE_USER],
    },
    initialValues:    ({menuRoot}) => (
        {
            // TODO: check work
            isCarrier: true,
        }
    ),
    onFormDataAction: (values, props) => {
        const {action, field, refreshItem} = props;
        let _fix                           = {};
        switch (action) {
            case FORM_ACTION.INIT:
            
        }
        return {
            ...values,
            ..._fix,
        };
    },
    itemActions:      ({item, me}) => {
        return []
    },
    listActions:      (props) => {
        const {menuRoot} = props;
        console.log('listActions', props);
        const actualityState = (props) => {
            return <RenderIconActuality name={props.rowData.actualityStateName} />
        }
        
        return [ {
            component: actualityState
        }];
    },
    colsInTab:        true,
    cols:             (props, item) => {
        const {isForeign, guid} = item;
        const isCarrier = true;
        // {field: 'guid', title: 'ID', format: RequestForm.FORMAT_NONE},
        return [
            {
                field:  'isForeign',
                title:  'Иностранное лицо',
                format: FieldFormat.FORMAT_CHECKBOX,
                hidden: true,
                cols:   2,
                object: ColBoolean,
            },
            {
                field:        'actualityStateName',
                title:        'Состояние актуализации',
                hidden:       true,
                disabled:     true,
                beforeFieldIcon : (name) => {
                    return <Actuality name={name}/>
                },
                format:         FieldFormat.FORMAT_INPUT,
                cols:         4,
                
                startButtons: guid ?  ({values, setStateValues}) => [
                    <UpdateActuality values={values} setStateValues={setStateValues}/>,
                ]  : null,
                helperText: 'Дата обновления: ' + (
                    item.actualityRefreshDate
                        ? moment(item.actualityRefreshDate).format('YYYY-MM-DD hh:mm')
                        : '---'
                ),
            },
            {
                field:        'actualityDateFormat',
                title:        'Дата актуализации',
                hidden:       true,
                disabled:     true,
                format:       FieldFormat.FORMAT_INPUT,
                startButtons: guid
                                  ? ({values, setStateValues}) => [
                        <UpdateActuality values={values} setStateValues={setStateValues}/>,
                    ]
                                  : null,
                cols:         4,
            },
            
            {
                field:          'name',
                title:          'Название carr',
                required:       'true',
                mapSuggestions: mapSuggestions('name'),
                format:         !isForeign
                                    ? FieldFormat.FORMAT_SELECT_DADATA
                                    : FieldFormat.FORMAT_INPUT,
                freeSoloField:  'custom_name',
                helperText:     'Название организации',
                placeholder:    !isForeign
                                    ? 'Для автоматического заполнения начните вводить наименование или инн'
                                    : "Название организации",
                api:            'search/inn',
                cols:           2,
            },
            {
                field:  'fullName',
                title:  'Название полное',
                object: ShowOrganization,
                hidden: true,
                cols:   2,
            },
            // {
            //     field:  'contactInfo',
            //     title:  'Контакты',
            //     object: ColContactInfo,
            //     format:      FieldFormat.FORMAT_SELECT_API,
            //     multi:  true,
            //     api:    'contact_infos',
            //     parentField: 'legal',
            //     subConfig:   'contactInfo',
            //     parentList:  'contactInfos',
            //     apiFilter:   item => (
            //         item && item.guid
            //             ? {
            //             legal: item.guid,
            //         }
            //         : null
            //     ),
            //     hidden:   {xsUp: true},
            // },
            {
                field:          'inn',
                title:          'ИНН',
                placeholder:    'Введите ИНН до 12 цифр',
                cols:           3,
                mapSuggestions: mapSuggestions('inn'),
                freeSoloField:  'custom_inn',
                format:         !isForeign
                                    ? FieldFormat.FORMAT_SELECT_DADATA
                                    : FieldFormat.FORMAT_NONE,
                api:            'search/inn',
                required:       !isForeign,
                hidden:         {xsDown: true},
                // mask: '000000000000',
            },
            {
                field:       'kpp',
                title:       'КПП',
                cols:        isCarrier  ? 3 : 2,
                hidden:      {xsDown: true},
                placeholder: 'Введите КПП до 9 цифр',
                format:      !isForeign
                                 ? FieldFormat.FORMAT_NUMBER
                                 : FieldFormat.FORMAT_NONE,
                mask:        '000000000',
            },
            {
                field:       'ogrn',
                title:       isForeign
                                 ? 'Регистрационный номер'
                                 : 'ОГРН',
                cols:        3,
                hidden:      {mdDown: true},
                format:      FieldFormat.FORMAT_INPUT,
                required:    isForeign,
                mask:        '**************************************************',
                placeholder: isForeign
                                 ? 'Введите рег. номер до 50 символов'
                                 : 'Введите ОГРН до 50 символов',
            },
            {
                field:  'registrationDate',
                title:  'Дата регистрации',
                object: ShowDate,
                cols:   2,
                format: FieldFormat.FORMAT_DATE,
                hidden: {mdDown: true},
            },
            {
                field: 'legalAddress',
                title: 'Юридический адрес',
                cols:  2,
                // format: FieldFormat.FORMAT_SELECT_DADATA,
                format: FieldFormat.FORMAT_FIAS_ADDRESS,
                api:    'fias_address_trees',
                // api:    'search/address',
                mapSuggestions: mapSuggestions('legalAddress'),
                hidden:         true,
                object:         ColObjectName,
            },
            {
                field:  'generalManager',
                title:  'Генеральный директор',
                cols:   2,
                object: ShowContactPerson,
                // object: RequestForm.ApiObjectSelect,
                format:    item && item.guid
                               ? FieldFormat.FORMAT_SELECT_API
                               : FieldFormat.FORMAT_NONE,
                api:       'contact_people',
                apiFilter: values => (
                    {
                        legal: values.guid || 'new',
                    }
                ),
                hidden:    {mdDown: true},
                selection: true,
            },
            {
                field:    'calcKAddress',
                disabled: true,
                hidden:   true,
                cols:     6,
                format:   FieldFormat.FORMAT_CUSTOM,
                
                custom: function Custom({values}) {
                    const {handleChange} = React.useContext(FormDataContext);
                    const api = new API;
                    React.useEffect(() => {
                        if (values.address && !values.legalAddress) {
                            api['post'](`fias_select`, {
                                name:    values.address,
                                address: values.address,
                                levels:  values.levels,
                                custom: false,
                                ...values.last
                            }).then(response=> {
                                handleChange({field: 'legalAddress'})(null, response.body.guid)
                            }).catch((err) => {
                                console.error(err)
                            })
                            
                        }
                    }, [values])
                    return <></>
                }
            },
            // {
            //     field:       'mainBankAccount',
            //     required:    true,
            //     title:       'Главный банковский счет',
            //     cols:        2,
            //     object:      ShowBankAccount,
            //     // object: RequestForm.ApiObjectSelect,
            //     format:      FieldFormat.FORMAT_SELECT_API,
            //     parentField: 'legal',
            //     parentList:  'bankAccounts',
            //     api:         'bank_accounts',
            //     subConfig:   'bankAccount',
            //     hidden:   {xsUp: true},
            //     apiFilter:   item => (
            //         {
            //             parent_guid: item.guid ||
            //                              '00000000-0000-0000-0000-000000000000',
            //         }
            //     ),
            // },A
            // {field: 'parentGuid', title: 'Guid родителя', cols: 2},
            // {field: 'parentType', title: 'Тип родителя', cols: 2},
            // {field: 'replicator', title: 'Репликатор', type: 'int'},
            // {
            //     field: 'isHided',
            //     title: 'Скрыто',
            //     object: ColBoolean,
            //     format: RequestForm.FORMAT_CHECKBOX,
            //     cols: 3
            // },
            // {
            //     field: 'isDeleted',
            //     title: 'Удалено',
            //     object: ColBoolean,
            //     format: RequestForm.FORMAT_CHECKBOX,
            //     cols: 3
            // },
            // {
            //     field:  'notSynced',
            //     title:  'Нужна синхронизация',
            //     object: ColBoolean,
            //     format: RequestForm.FORMAT_NONE,
            //     cols:   3,
            // },
        ]
    },
    subItems:         async (item) => {
        const {isCarrier, isForeign} = item || {}
        return item && item.guid && {
            style: 'tabs',
            list:  [
                       {
                           Component: SubApiReplace,
                           colsInTab:        true,
                           api:       contactPerson.apiName,
                           isForeign: isForeign,
                           apiFilter: values => (
                               {
                                   legal: values.guid,
                                   _apiFilter: 1,
                               }
                           ),
                           ...contactPerson,
                           icon: <contactPerson.menu.icon/>
                       },
                       {
                           Component: SubApiReplace,
                           api:       contactInfo.apiName,
                           isForeign: isForeign,
                           apiFilter: values => (
                               {
                                   legal: values.guid,
                               }
                           ),
                           ...contactInfo,
                           icon: <contactInfo.menu.icon/>
                       },
                       
                       {
                           Component: SubApiReplace,
                           api:       driver.apiName,
                           apiFilter: values => (
                               {
                                   legal: values.guid,
                               }
                           ),
                           ...driver,
                           icon: <driver.menu.icon/>
                       },
                       {
                           Component: SubApiReplace,
                           api:       transport.apiName,
                           apiFilter: values => (
                               {
                                   legal: values.guid,
                               }
                           ),
                           ...transport,
                           icon: <transport.menu.icon/>
                       },
                       {
                           Component: SubApiReplace,
                           api:       contractCarrier.apiName,
                           apiFilter: values => {
                               return {
                                   contragent: values.guid,
                               }
                           },
                           parentField: "contragent",
                           ...contractCarrier,
                           icon: <contract.menu.icon/>
                       },
                       {
                           Component: SubApiReplace,
                           api:       'bank_accounts',
                           apiFilter: values => (
                               {
                                   legal: values.guid,
                               }
                           ),
                           ...bankAccount,
                           field: 'bankAccounts',
                           icon:  <AccountBalanceIcon/>
                       },
                       {
                           Component: SubApiReplace,
                           api:       'attached_files',
                           apiFilter: values => (
                               {
                                   objLinkedName: "legals",
                                   objLinkedGuid: values.guid
                               }
                           ),
                           objLinkedName: "legals",
                           objLinkedGuid: item.guid,
                           
                           ...userFiles,
                           icon: <AttachFileOutlinedIcon/>
                       },
                       !item.isTPValid && {
                           listTitle: 'Приглашения',
                           field:     'invaits',
                           icon:      <DomainVerification/>,
                           Component: InvaitsPage,
                       }
                   ].filter(item => item)
        }
    }
    
};