import {Checkbox, Chip, Switch, CircularProgress, Box, Tooltip} from '@material-ui/core';

import {
    BankAccountIcon,
    BankIcon,
    ClientProfilesIcon,
    CurrencyIcon,
    DocRequestIcon,
    IndividualIcon,
    OrganizationsIcon,
} from 'components/icons';
import {config} from 'config';

import legals from 'config/legals';

import moment from 'moment';
import 'moment/locale/ru';
import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';

import request from 'superagent';

import system from 'actions/system';
import ErrorBoundary from 'components/ErrorBoundary';
import {ApiDataLoad} from "components/ApiDataLoad";
import useLoaderApi from "./useLoaderApi";
import LoadStateShow from "./LoadStateShow";
import {ItemInfo} from "components/ItemInfo";

/**
 * @return {JSX.Element}
 */
export function ColBoolean({item}) {
    return <Checkbox
        checked={Boolean(item)}
        style={{padding: 0}} // todo: move to styles
        color={'primary'}
    />;
}

export function ColBooleanSwitch({item}) {
    return <Switch
        checked={Boolean(item)}
        color={'primary'}
    />;
}

const NoItem     = '---';
const DateFormat = 'DD.MM.YYYY';

// const DateTimeFormat = 'YYYYMMDD';

export function ShowOrganization({row, item}) {
    let {url} = useRouteMatch();
    let to    = `/legals/${row.guid}`;
    return url !== to
        ? <Chip
            icon={<OrganizationsIcon/>}
            size="small"
            label={item}
            to={to}
            component={Link}
            clickable
            color={'primary'}
            variant={'outlined'}
        />
        : item;
}

/**
 * @return {string}
 */
export function ShowDate({item}) {
    const last = moment(item);
    if (!item || !last.isValid()) {
        return '---'
    }
    return last.isBefore()
        ? last.format(DateFormat)
        : last.format(DateFormat);
}

export function showBank({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            variant={'outlined'}
            size="small"
            to={`/banks/${o.guid}`}
            icon={<BankIcon/>}
            color={'primary'}
            label={o.name}
        />
        : NoItem;
}

export function ShowCurrency({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            variant={'outlined'}
            size="small"
            color={'primary'}
            to={`/currencies/${o.guid}`}
            icon={<CurrencyIcon/>}
            label={o.name}
        />

        : NoItem;
}

export function ShowLegal({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    if (!o || !o.name) {
        return NoItem;
    }
    return (
        <Tooltip title={<React.Fragment>
            <div>{o.fullName}</div>
            <div>ИНН:{o.inn}</div>
            <div>КПП:{o.kpp}</div>
        </React.Fragment>}
        >
            <Chip
                color={'primary'}
                variant={'outlined'}
                size="small"
                icon={<OrganizationsIcon/>}
                label={o.name}
                component="a"
                href={legals.generateLink(o)}
                clickable
            />
        </Tooltip>
    );
}

export function ShowBankAccount({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            component={Link}
            size="small"
            icon={<BankAccountIcon/>}
            label={o.name}
            to={`/bank_accounts/${o.guid}`}
            variant={'outlined'}
            clickable
        />
        : NoItem;
}

export function ColObjectShowDateTime(props){
    const date = props.item ? moment(props.item) : false;
    if(date && date.isValid()){
        return date.format("DD.MM.YYYY HH:mm")
    }
    return '---'
}

export function ColObjectSimpleData({item, column, col}) {

    if (!item) {
        return '---'
    }

    return col && col.measureText
        ? `${item} ${col.measureText}`
        : item
}

export function ColContactInfo({item}) {
    return item
        ? <div>{item.name}:<br/> <b>{item.info}</b></div>
        : '---';
}

export function RowContactInfo({item}) {
    return item
        ? <div>{item.name}:<br/> <b>{item.info}</b></div>
        : '---';
}

/**
 * Переменовано col в column
 */
export function ColObjectOptionsName(props) {
    const {item, col, column} = props;
    if(col && (typeof column !== 'object')){
        console.error('new update component ColObjectOptionsName');
        return item && col.options && col.options.length && col.options.find(i => i.id === item).name
            ? col.options.find(i => i.id === item).name
            : '---'
    }
    return item && column && column.options && column.options.length && column.options.find(i => i.id === item).name
            ? column.options.find(i => i.id === item).name
            : '---'
}

export  function  ColObjectNumber(props){
    const {item} = props
    return item ? parseFloat(item).toLocaleString() : "---"
}

export function ColObjectName(props) {
    const {item, row, col, column} = props
    const c = col || column;
    if (item && item.name) {
        const fav = row && c && row[c.field + '_route_name'];
        return <React.Fragment>
            {item.name}
            {fav && <div><b>{fav}</b></div>}
        </React.Fragment>

    }
    if (row && col && col.freeSoloField && row[col.freeSoloField]) {
        return row[col.freeSoloField];
    }
    return '---';
}

export function ColObjectNameOther(props) {
    const {item, row, col} = props;
    // console.log('ColObjectNameOther', props)
    if (!item && col && col.freeSoloField) {
        return row[col.freeSoloField] || '---';
    }

    return item && item.name
        ? item.name
        : '---';
}

export function ColObjectNameOptions(props) {
    const {item} = props;
    const options = props.col ? props.col.options : props.column.options
    console.log('ColObjectNameOptions', item, options)
    const result = options.find(el => el.id === item)
    return item && result
        ? result.name
        : '---';
}

export function ColObjectNameAndItemInfo(props) {
    const {item, row, col, column} = props
    const c = col || column;
    if (item && item.name) {
        const fav = row && c && row[c.field + '_route_name'];
        return <Box display={"flex"} alignItems="center">
            {item.name}
            {fav && <div><b>{fav}</b></div>}
            <ItemInfo item={column} itemLoaded={item}/>

        </Box>

    }
    if (row && col && col.freeSoloField && row[col.freeSoloField]) {
        return row[col.freeSoloField];
    }
    return '---';
}


export function ColObjectFullNameWithColor({item}) {
    return item && item.name
        ? <div style={{
            // background: item.colorTitleBkg,
            background: `linear-gradient(90deg, ${item.colorItem} 0%, #00000000 100%)`,
            // borderRadius: '5px',
            borderLeftColor: item.colorTitleBkg,
            color: item.color,
            borderLeftWidth: '5px',
            borderLeftStyle: 'solid',
            padding: '3px',
            display: "inline-block"
        }}>{item.fullName||item.name}</div>
        : '---';
}

export function ColObjectFullName({item}) {
    return item && item.full_name
        ? item.full_name
        : '---';
}

export function ColContactPerson({item}) {
    return <div><b>{item.workPosition}:</b><br/> {item.name}</div>;
}

export function ShowContactPerson({item}) {
    // let to = `/contact_person/${item.guid}`;
    return item && item.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{item.workPosition}</div>
                <div>{item.address}</div>
            </React.Fragment>}
            >
                <Chip
                    icon={<IndividualIcon/>}
                    size="small"
                    label={item.name}
                    // to={to}
                    // component={Link}
                    // clickable
                    color={'primary'}
                    variant={'outlined'}
                />
            </Tooltip>
        )
        : '---';
}

export function ShowUser({item}) {
    return item && item.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{item.fioFull}</div>
                <div>{item.address}</div>
            </React.Fragment>}>
                <Link to={`/user/${item.guid}`}>
                    <IndividualIcon/> {item.name} (TODO)
                </Link>
            </Tooltip>
        )
        : '---';
}

export function ShowIndividual({item}) {
    // let to      = canView ? `/individual/${item.guid}` : '';
    return item && item.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{item.fioFull || 'Не заполнено'}</div>
                <div>{item.address}</div>
            </React.Fragment>}>
                <Chip
                    icon={<IndividualIcon/>}
                    size="small"
                    label={item.name}
                    // to={to}
                    // component={Link}
                    // clickable
                    color={'primary'}
                    variant={'outlined'}
                />
            </Tooltip>

        )
        : '---';
}

export function ShowClient({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{o.name}</div>
            </React.Fragment>}>
                <Link to={`/client_profiles/${o.guid}`}>
                    <ClientProfilesIcon/> {o.name}
                </Link>
            </Tooltip>
        )
        : '---';
}

export function ShowDocRequest({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{o.name}</div>
            </React.Fragment>}>
                <Link to={`/doc_requests/${o.guid}`}>
                    <DocRequestIcon/> {o.name}
                </Link>
            </Tooltip>
        )
        : '---';
}

export function ShowApiName({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <div>
                <Tooltip title={<React.Fragment>
                    <div>{o.name}</div>
                    <div>guid: {o.guid}</div>
                </React.Fragment>}>
                    <span style={{borderBottom: 'dashed 1px'}}>{o.name}</span>
                </Tooltip>
            </div>
        )
        : '---';
}

export function ShowDangerClass({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip placement="right" title={<React.Fragment>
                <div>usage:{o.usage}</div>
                <div>description:{o.description}</div>
            </React.Fragment>}>
                <span style={{borderBottom: 'dashed 1px'}}>{o.name}</span>
            </Tooltip>
        )
        : '---';
}

export function ShowApiLink(apiName) {
    return ({row, item}) => {
        const o = typeof item == 'object'
            ? item
            : row;
        return o && o.name
            ? (
                <Tooltip title={<React.Fragment>
                    <div>{o.name}</div>
                </React.Fragment>}>
                    <Link to={`/${apiName}/${o.guid}`}>
                        {o.name}
                    </Link>
                </Tooltip>
            )
            : '---';
    };
}

/**
 * load object for column
 * @param apiName
 * @param guid
 * @param object
 * @returns {JSX.Element}
 * @constructor
 */
export function RealApiObjectLoad({apiName, guid, object, row, col}) {
    const decodeGuid = guid
        ? decodeURI(guid)
        : null;

    const [item, loadState] = useLoaderApi(apiName, decodeGuid)

    const NoObject = ({item}) => 'no object for item:' + JSON.stringify(item);
    const Show = object || NoObject;
    return <LoadStateShow small state={loadState} error={item}><Show item={item} row={row} col={col}/></LoadStateShow>
}

/*
const fetch = React.useMemo(
    () =>
        debounce((query, callback) => {
            ( async () => {
                setLoading(true);
                const response = await request.post(`/api/${api}`).
                    set('accept', 'application/json').
                    send({...query, ...apiFilter});
                
                callback(response.body.suggestions.map(suggest => (
                    {
                        suggest: suggest,
                        name: suggest.value,
                    }
                )));
                setLoading(false);
            })()
        }, 400),
    [],
);
*/


export const ApiDataLoadLookup = (apiURI, key = 'guid', value = 'name') => {
    return ApiDataLoad(apiURI)
        .then(
            data => {
                let result     = {};
                let resultData = [];
                data.forEach((i, n) => {
                    result[i[key]] = value
                        ? i[value]
                        : i;
                    resultData[n]  = i;
                });
                return key
                    ? result
                    : resultData;
            },
            error => error,
        );

    // console.log('ApiDataLoadLookup', result, resultData);
};

export const ApiObjectLoad = (apiName, object) => {
    return ({item}) => {
        return <RealApiObjectLoad
            apiName={apiName}
            guid={item}
            object={object}
        />;
    };
};

export const ApiObjectLoadForMTable = (apiName, object, other, span = false) => {
    const {item, multiple, row, col} = other;
    if (!item) {
        if (row && col.freeSoloField) {
            const Show = object || null;
            return object
                ? <ErrorBoundary info={'freeSoloField'} custom={'span'}>
                    <Show {...other} />
                </ErrorBoundary>
                : null;
        }
        return '';
    }
    const items = multiple
        ? item
        : [item];

    return items.map((item,key) => {
        if ('object' === typeof item) {
            const NoObject = ({item}) => 'no object for item:' + JSON.stringify(item);
            const Show     = object || NoObject;
            return <Show key={key} item={item}/>;
        }

        const match = item && typeof item == 'string'
            ? item.match(/\/api\/(.+)\/(.+)/)
            : null;

        let guid = match
            ? decodeURI(match[2])
            : item;
        if (span) {

            return (<span key={key}><RealApiObjectLoad
                apiName={apiName}
                guid={guid}
                object={object}
                item={item}
                row={row}
                col={col}
            /></span>);
        }
        return <div key={key}><RealApiObjectLoad
            apiName={apiName}
            guid={guid}
            object={object}
            item={item}
            row={row}
            col={col}
        /></div>;
    });
};

