import { ApiObjectLoadForMTable } from "components/showItems";
import {
  Box,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";
import { KanbanCardRoutePoints } from "./KanbanCardRoutePoints";
import {KanbanCardItem, CardValueWeight, access} from "./KanbanCardItem";
import {IconPackKanban} from '../icons';
import React from "react";
import { Link } from "react-router-dom";
import request from 'superagent';
import optionsNDS from 'config/optionsNDS';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {withStyles} from "@material-ui/core/styles";
import {MeContext} from "../../meContext";


const RateNDS = ({ndsRate}) => {
  const searchNds = optionsNDS.find(item => item.id === ndsRate);
  const nds = searchNds && searchNds.shortName ? searchNds.shortName : false;
  return (
    <span className="textBold">{
      nds 
      ? nds
      : 'Ставка не известна'
    }</span>
  )

}

 function RenderKanbanCard(card) {
    const dragging = false

    const showValue = (apiName, fieldName) => {
    if (card.data[fieldName] && card.data[fieldName] !== undefined ) {
      return ApiObjectLoadForMTable(apiName, ({ item }) => item.name, {
        item: card.data[fieldName],
        multiple: Array.isArray(card.data[fieldName]),
      }, true);
    }
    return "---"
  };

  const getValueFromApiId = (api) => {
    return request(api).set('accept', 'application/json').query({})
    .then(result => {
      return result.body
    })
    .catch(e => {
      console.log(e)
        return `Ошибка`
    })
  };



  const formatSum = (sum) => {
    return new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: 0,
    }).format(sum);
  };

  const [status, setStatusCard] = React.useState("");
  const [loading, setLoading] = React.useState("");
  const [dangerImg, setDangerImg] = React.useState([]);
  const [detailsFlag, setDetailsFlag] = React.useState({
    init: true,
    message: "Развернуть" ,
    open: false,
    icon: <ArrowDropDownIcon/>
  })

  React.useEffect(() => {

    if(card && card.data && card.data.status && card.data.dangerClasses && card.data.loadingTypes){
        getValueFromApiId(card.data.status)
                .then(result => {
                    setStatusCard(result.name);
                })

        card.data.loadingTypes.map((l) => {
            getValueFromApiId(l)
                    .then(result => {
                        setLoading((e) => e + result.name);
                    });
        });


        card.data.dangerClasses.map(d => {
            getValueFromApiId(d)
                    .then(result => {
                        setDangerImg((e) => [...e, result])
                    });
        });

        if(card.data.carbodyType && !card.data.carbodyTypes){
            card.data.carbodyTypes = card.data.carbodyType
        }
    }else{
        console.log("push2 not card", card)
    }

  }, [card]);

  const changedetailsFlag = () => {
    setDetailsFlag(flag => (
       {
        init : false,
        open: !flag.open,
        icon: flag.open ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>,
        message: flag.open ? "Развернуть" : "Свернуть"
      }
    ))

  }

  const TypographyStyle = withStyles(() => (
    {
        root: {
            color: "#b3bac0",
            fontSize: "0.75rem",
            textTransform: "lowercase"
        },
    }
))(Typography);

  const BodyCard = () => {
    return (
      <Box  className={`body-card ${!detailsFlag.init ? detailsFlag.open ? "show" : "hidden" : ''}`} mb={1}>
          <KanbanCardItem
          field="carrier"
          value={showValue("legals", "carrier")}
          status={status}
        />
        <KanbanCardItem
          field="carrierDriver"
          value={showValue("drivers", "carrierDriver")}
          status={status}
        />
        <KanbanCardItem
          field="carrierTransports"
          value={showValue("transports", "carrierTransports")}
          status={status}
        />
        <KanbanCardItem
          field="typedTransport"
          value={showValue("typed_transports", "typedTransport")}
          status={status}
        />
        <KanbanCardItem
          field="carbodyTypes"
          value={showValue("carbody_types", "carbodyTypes")}
          status={status}
          loading={loading}
          flex={true}
        />

        <CardValueWeight
          field="transportValue"
          value={
            card.data.transportValue ? card.data.transportValue : "---"
          }
          status={status}
          secondValue={
            card.data.transportMass ? card.data.transportMass : "---"
          }
        />
        {status &&
          card.data.dangerClasses.length &&
          access[status].includes("dangerClasses") &&
          dangerImg.length
          ?
          <>
            <span className="react-kanban-card_icon_danger_title"></span>
            {dangerImg.map((d, i) => <smal title={d.name} ><img key={i} src={"data:image/png;base64," + d.picture} alt={d.description} className="react-kanban-card_icon_dannger" width="20px" /></smal>)}
          </>
          : ""}

        {card.data.sumTotal > 0 &&
          status &&
          access[status].includes("sumTotal") && (
            <div className="react-kanban-card__cash">
              <IconPackKanban type="sumTotal" />
              <div>
                <small
                  className="react-kanban-card__cash_text"
                  title={formatSum(
                    Math.round(
                      card.data.sumTotal * card.data.ndsRateValue
                    )
                  )}
                >
                  <div className={`textBold`}>
                    <p>{formatSum(
                      Math.round(
                        card.data.sumTotal * card.data.ndsRateValue
                      )
                    )}{" "}</p>
                    {showValue("currencies", "currency")}
                  </div>{" "}
                  (цена с НДС)
                </small>
                <small
                  className="react-kanban-card__cash_text"
                  title={card.data.sumTotal}
                >
                  <span className={`textBold`}>
                    {" "}
                    <p>{formatSum(card.data.sumTotal)}{" "}</p>
                    {showValue("currencies", "currency")}
                  </span>{" "}
                  (цена без НДС)
                </small>
                <small
                  className="react-kanban-card__cash_text"
                  title={"Ставка НДС"}
                >
                  <RateNDS ndsRate={card.data.ndsRateString} />
                  (ставка НДС)
                </small>
              </div>
            </div>
          )}
      </Box>
    )
  }
  return (
    <>
        {
                card && Object.keys(card).length && <div
                        className={`react-kanban-card ${dragging ? "react-kanban-card--dragging" : ""
                        }`}
                        style={{backgroundColor: card.color}}
                >
        <span>
          <Link
                  to={card.url}
                  className={"react-kanban-link react-kanban-card__title"}
          >
            <span>{card.title}</span>
          </Link>
        </span>
                    <KanbanCardRoutePoints card={card.data}/>
                    <div className="react-kanban-card__point"></div>
                    <BodyCard/>
                    <Button color="primary" size="small" fullWidth startIcon={detailsFlag.icon} onClick={changedetailsFlag}>

                        <TypographyStyle>{detailsFlag.message}</TypographyStyle>
                    </Button>

                </div>
        }
    </>
  )
}



function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
}


const KanbanCardMemo = React.memo(RenderKanbanCard, areEqual)


export  default  function KanbanCard(props){

    return <KanbanCardMemo {...props}/>
}



