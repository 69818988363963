import React from "react";
import {FormDataContext} from "../FormDataContext";
import {FormControl, FormGroup, FormLabel, Grid} from "@material-ui/core";
import {FieldFormat} from "../config/FieldFormat";
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import FormShowField from "components/FormShowField";
import ErrorBoundary from "./ErrorBoundary";

// TODO: передалать  убрать стил вероятно они не нужны. Или если не возможно то привести к общим единицам измерения
const useStyle = makeStyles(theme => ({
    border_box:          {
        border:       '1px solid #d8d8d8',
        position:     'relative',
        borderRadius: '4px',
        padding:      '15px',
    },
    border_block_titile: {
        position:   'absolute',
        top:        '-24px',
        left:       '-13px',
        background: 'white',
        padding:    '0 0.5rem',
    },
    box:                 {
        margin: '0.5em 0',
        padding:      '15px',
    },
    p_relative:          {
        position: 'relative',
    },
    w_100:               {
        width: '100%',
    },
}));

function FormColsBlock(props) {
    const {cols, title, titleHiden: titleHidden, num, border, parent} = props;

    // Получение колонок из контекста или из fieldsShow
    const {cols: ContextCols} = React.useContext(FormDataContext);
    const {fieldsShow} = {fieldsShow:ContextCols, ...props }
    const classes = useStyle();

    const  colFields = fieldsShow && fieldsShow.filter(col => !col.topColsBlock || col.topColsBlock === num);
    if (!colFields || !colFields.length) {
        // Не показывать блок, если нет элементов
        console.log('no elements in block');
        return null;
    }
    return (
        <Grid item sm={12 / cols}
              className={border && classes.p_relative}
        >
            <Grid
                className={
                    border ? classes.border_box : classes.box
                }
            >
                <FormControl component="fieldset" className={classes.w_100}>
                    <FormLabel component="legend"
                               className={border && classes.border_block_titile}>{!titleHidden ? title : ''}</FormLabel>
                    <FormGroup>
                        <Grid container spacing={3}>
                            {colFields.map((item, key) => <ErrorBoundary key={key} info={'FormShowField'}>
                                <FormShowField

                                    parent={parent}
                                    cols={1}
                                    format={FieldFormat.FORMAT_INPUT}
                                    {...item}
                                /></ErrorBoundary>)}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

FormColsBlock.propTypes = {num: PropTypes.number};


export default FormColsBlock;