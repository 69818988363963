import KanbanCard from './KanbanCard';
import {asGUID} from "libs/asGUID";
import React from 'react';
import 'kanban-styles.css';
import {
    Badge,
    Grid
} from "@material-ui/core";

const KanbanCol = props => {
    const {allCards, status} = props;

    console.log("push2", allCards, status)
    return <Grid item style={{minWidth: "210px"}}>
        <div className="react-kanban-column-header"
             style={{backgroundColor: status.colorItem}}>
            {/* <span className={'react-kanban-column-header_title'} data-lenght={cards.length}>{title}</span> */}
            <Badge badgeContent={allCards.length} color="primary">
                <div style={{padding: "0px 10px"}}>
                    <span>{status.fullName}</span>
                </div>
            </Badge>
        </div>
        {allCards && allCards.length ?
                allCards.map((card) => <KanbanCard {...card} card={card} />)
                : <></>
        }
    </Grid>;

}

const KanbanCols = (props) => {
    const {statuses, cards} = props
    return (
                <Grid item style={{padding: "5px", minWidth: "1400px"}}>
                    <Grid container spacing={2}>
                        {statuses.map(status =>
                                <KanbanCol allCards={cards
                                        .filter(card => asGUID(card.status) === status.guid).map(card => ({
                                            id: card.guid,
                                            title: card.name,
                                            data: card,
                                            color: status.colorItem,
                                            url: `/doc_requests/${card.guid}/edit`,
                                        }))
                                } status={status}/>
                        )}
                    </Grid>
                </Grid>

    )
}

export default  KanbanCols