import React from "react";
import {MeContext} from "meContext";

export function useCanOne(action, data, addInfo, initialState) {
    const [value, setValue] = React.useState(initialState = false)
    const me = React.useContext(MeContext);

    React.useMemo(() => {
        let res = 'function' === typeof action
                ? action(data, {me, ...addInfo})
                : action;

        if (res instanceof Promise) {
            res.then(data => setValue(data));
        } else {
            setValue(res);
        }
        // eslint-disable-next-line
    }, [action]);
    return value
}

export function useCanCheck(props, info) {
    return {
        canAdd:    useCanOne(props.canAdd, props, info),
        canEdit:   useCanOne(props.canEdit, props, info),
        canDelete: useCanOne(props.canDelete, props, info)
    }
}
