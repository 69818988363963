import React from "react";


const SumTotal = () => {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M19.576 8.637H19.066V5.192C19.066 4.384 18.429 3.747 17.661 3.747H17.278V1.445C17.278 0.637 16.682 0 15.959 0H15.873L1.318 3.743L1.302 3.747C0.584 3.804 0 4.42 0 5.192V18.555C0 19.363 0.637 20 1.404 20H17.661C18.469 20 19.106 19.363 19.106 18.555V15.02H19.576C19.833 15.02 20 14.849 20 14.596V9.066C20.0004 9.00993 19.9897 8.95432 19.9686 8.90236C19.9476 8.85041 19.9164 8.80311 19.8771 8.76318C19.8377 8.72325 19.7908 8.69147 19.7392 8.66965C19.6875 8.64783 19.6321 8.63639 19.576 8.636V8.637ZM16.04 0.808C16.252 0.849 16.464 1.106 16.464 1.445V3.743H4.722L16.041 0.808H16.04ZM18.256 18.555C18.256 18.894 17.999 19.192 17.66 19.192H1.404C1.065 19.192 0.808 18.894 0.808 18.555V5.192C0.808 4.853 1.065 4.555 1.404 4.555H17.616C17.956 4.555 18.212 4.853 18.212 5.192V8.637H14C13.5796 8.63272 13.1626 8.71236 12.7734 8.87126C12.3842 9.03016 12.0306 9.26512 11.7334 9.56238C11.4361 9.85964 11.2012 10.2132 11.0423 10.6024C10.8834 10.9916 10.8037 11.4086 10.808 11.829C10.8073 12.2483 10.8895 12.6636 11.0497 13.0512C11.2098 13.4387 11.445 13.7907 11.7415 14.0872C12.0381 14.3837 12.3902 14.6187 12.7778 14.7787C13.1653 14.9388 13.5807 15.0208 14 15.02H18.257V18.555H18.256ZM19.146 14.212H14C13.3814 14.2065 12.7896 13.9583 12.3521 13.5209C11.9147 13.0834 11.6665 12.4916 11.661 11.873C11.6673 11.2547 11.9157 10.6635 12.3531 10.2263C12.7904 9.78921 13.3817 9.54099 14 9.535H19.147V14.212H19.146Z" fill="#848484" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export { SumTotal }
