import React from 'react';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import {
    DateRangeSharp,
    Email as DomainVerification, Email,

} from '@material-ui/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import MaterialTable from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import request from 'superagent';
import {config} from "../config";


// Редактировать контактную информацию
// http://tms.foamline.com/my/legals/0bf1dbaa-48dc-11ea-b812-005056a60abf/contact_infos/43ec10da-48dc-11ea-b812-005056a60abf/edit

const useStyles = makeStyles((theme) => ({
    m1: {
        margin: "1rem 0",
    },
    p1 : {
        padding: "1rem",
    },
    pBox: {
        padding: "1rem 0 0 1rem",
    },
    progressBox: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    }
}))

export default function Page(props) {

    const [data, setData] = React.useState([]);
    const [dataEmail, setDataEmail] = React.useState([]);
    const [modal, setModal] = React.useState(false) 
    const [loading, setLoading] = React.useState(true)
    const {item} = props;
    const legal = item.guid

    const classes = useStyles();

    const formatDate = (date) => {
        return date
        ? date.toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : "--"
      } 

    const getData = () => {
        setLoading(true)
        request.get(`/api/carrier_contacts_invites/${legal}?time=${Date.now()}`).set('accept', 'application/json').query({}).then(result => {
            result.body.map(item => {
                setData(data => [...data, {
                    nameContact: item.name,
                    workPosition: item.work_position, 
                    hasInvaits: item.invite,
                    info: item.info
                }])
            })
            setLoading(false)
        }).catch(e => {
            setModal('Ошибка загрузки данных, повторите попытку позже')
            setLoading(false)
            console.error(e)
        });

    }


    const selectRows = (rows) => {
        const result = [];
        rows.map(item => {
            result.push(item.info)
        })
        setDataEmail(result)
    }

    const sendInvaits = () => {
        request
            .post(`/api/carrier_invite/${legal}`)
            .send({legal_name: 'legal_name', name: item.name, emails: dataEmail})
            .set('Accept', 'application/json')
            // .query({})
            .then(() => {
                setModal(dataEmail.length > 1
                    ? `Отправлено приглашение по следующим адресам: ${dataEmail.join(', ')}`
                    : `Отправлено приглашение по следующему адресу ${dataEmail.shift()}`
                );
                setData([]);
                getData();
                setDataEmail([])

            })
            .catch(e => {
                const error = `(${e.response.body.join(', ')})` || '(Неизвестная ошибка)'
                setModal(
                    `Не удалось отправить приглашение по следующим адресам: ${dataEmail.join(', ')} ${error}`
                )
            })
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <ConfirmDialog
                show={modal}
                title={''}
                text={modal}
                onCancel={() => {setModal(false)}}
                confirmDisable={true}
                cancel={"Закрыть"}
            />
            <Box className={classes.pBox}>
                <Box>
                    <Button
                        variant="contained"
                        className={classes.m1}
                        color="primary"
                        disabled={!dataEmail.length}
                        startIcon={<DomainVerification />}
                        onClick={sendInvaits}
                    >
                        Отправить приглашение
                    </Button>
                    
                </Box>
                </Box>
                {data.length 
                    ? <MaterialTable
                        {...config.MaterialTable}
                        title="Контакты"
                        columns={[
                            { 
                                title: 'Контакт', 
                                field: 'nameContact',
                                render: row => row.nameContact.includes('ЭлПочта') ? 'Общий контант' : row.nameContact
                            },
                            { 
                                title: 'Должность', 
                                field: 'workPosition',
                                render: row => row.workPosition ? row.workPosition : '---',
                            }, 
                            { 
                                title: 'Email', 
                                field: 'info' },
                            {
                                title: 'Приглашение',
                                field: 'hasInvaits',
                                render: row => row.hasInvaits ? 'Отправлено' : 'Не отправлено',
                            },
                            {
                                title: 'Действительно до',
                                field: 'DateInvaits',
                                render: row => row.hasInvaits ? formatDate(new Date(row.hasInvaits)) : '---'
                            },
                        ]}
                        data={data}
                        options={{
                            selection: true,
                            selectionProps: rowData => ({
                                color: 'primary',
                            }),
                            toolbarButtonAlignment: 'left',
                            padding:                'dense',
                            emptyRowsWhenPaging:    false,
                            pageSize:               200,
                            pageSizeOptions: [{ value: data.length > 0 ? data.length : 1, label: 'Все' }],
                            headerStyle:            {
                                backgroundColor: '#EEE',
                            }
                        }}
                        onSelectionChange={selectRows}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Поиск...',
                                nRowsSelected: 'Выбрано контактов: {0}'
                            },
                        }}
                    />
                    : loading ?
                    <Box className={classes.progressBox}>
                        <CircularProgress />
                    </Box>
                    : <Typography variant='body2' className={classes.p1}>Нет контактов для отправки приглашения</Typography>  
                }

        </>
    )
}