import {DialogTitle, Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Close as CloseIcon} from "@material-ui/icons";
import React from "react";


const ValidateDownloadPrintedFormsDialogContentDialogTitle = ({title, onClose}) => {
    return (
            <DialogTitle>
                <Grid container alignItems="center" direction="row">
                    <Grid xs={11}>
                        {title}
                    </Grid>
                    <Grid xs={1}>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
    )
}
export default  ValidateDownloadPrintedFormsDialogContentDialogTitle