import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Typography, Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import useLoaderApi, {useLoaderApiList} from "components/useLoaderApi";
import LoadStateShow from "components/LoadStateShow";
import {Close as CloseIcon} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import useStyles from "../../styles";
import {ChatBody} from "./ChatBody";
import {BlockNewMessage} from "./BlockNewMessage";
import {addChatMessage} from "./ChatContext";
import {TabsHorizontal} from "./TabsHorizontal";
import {TabsVertical} from "./TabsVertical";
import ShowMessages from "./ShowMessages";
import asArray from "../../libs/asArray";
import {useChat} from "./useChatRoom";
import * as PropTypes from "prop-types";

import RepeatIcon from '@material-ui/icons/Repeat';

const ChatWindowStyleBox = withStyles((theme) => ({
    root: {
        height:   "52vh",
        overflow: "auto"
    }
}))(Box)

const TabStyles = withStyles((theme) => ({
    root: {
        padding: "1rem",

    }
}))(Tab)

const TabFixHeightStyles = withStyles((theme) => ({
    root: {
        height:       "70px",
        '&:hover':    {
            color:   '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color:   '#40a9ff',
            opacity: 1,
        },
        '&:focus':    {
            color:      '#40a9ff',
            background: '#b1e3f8',
            opacity:    1,
        },

    }
}))(TabStyles)


/**
 * Определяет что значение не превысило максимальное значение
 * Иначе сбрасывает
 * @return mixed
 * @param v
 * @param max
 * @param init
 */
function checkOverFlow(v, max, init = 0) {
    return v < max ? v : init;
}

function firstAndLast(array) {
    return [
        array[0],
        array[array.length - 1]
    ]
}

function ShowPointsFromTo({routePlaces}) {


    const [from, to] = firstAndLast(routePlaces);
    return <>
        <span>{from.point_name}</span>
        <span> {` -> `}</span>
        <span>{to.point_name}</span>
    </>;
}

ShowPointsFromTo.propTypes = {routePlaces: PropTypes.array};

const ShowPoints = ({guid, api}) => {
    const [item, loadState] = useLoaderApi(api, guid)
    return (
        <LoadStateShow state={loadState} error={<span>{JSON.stringify(item)} {guid}</span>}>
            <>
                {item && item.routePlaces && item.routePlaces.length
                    ?
                    <>
                        <span>{[...item.routePlaces].shift().point_name}</span>
                        <span> {` -> `}</span>
                        <span>{[...item.routePlaces].pop().point_name}</span>
                    </>
                    :
                    <></>}

            </>
        </LoadStateShow>
    )

}

const ShowNameGuid = ({guid, api}) => {
    const [item, loadState] = useLoaderApi(api, guid)
    return (
        <LoadStateShow state={loadState} error={<span>{JSON.stringify(item)} {guid}</span>}>
            <span>{item && item.name ? item.name : ''}</span>
        </LoadStateShow>
    )

}

const WorkingItem = ({data, id, type}) => {
    const [item, setItem] = React.useState(false);

    React.useEffect(() => {
        setItem(data.find(el => el.guid === id))
    }, [id])

//     carrier
// : 
// "a6dd591b-29b6-47ad-9405-60efc696ae16"
// docLinkedGuid
// : 
// "d973f800-7451-412b-9584-65df1347505d"
// docLinkedName
// : 
// "doc_request"
    if (!item) {
        return <></>
    }
    switch (type) {

        case 'title':
            return (
                <Box mb={1}>
                    <Typography variant="h6">
                        <span>{<ShowNameGuid guid={item.docLinkedGuid} api={`${item.docLinkedName}`}/>}</span>
                        <span> {` -> `}</span>
                        <span>{<ShowNameGuid guid={item.carrier} api={`legals`}/>}</span>
                    </Typography>
                </Box>
            )
        case 'points': 
                return (
                    <Typography variant="body2">
                        <ShowPoints  guid={item.docLinkedGuid} api={`${item.docLinkedName}`}/>
                    </Typography>
                )
        default:
            return (<></>)
    }
}


export const ChatWindow = ({open, onClose, objectText}) => {
    const classes = useStyles();

    const [rooms, loadState] = useChat()

    const [tabGroup, setTabGroup] = React.useState(null)

    const [tabHorizontal, setTabHorizontal] = React.useState(null);

    const [objectShow, setObjectShow] = React.useState('docLinkedGuid')

    const [initFlag, setInitFlag] = React.useState(true)


    const swapGroupFiled = () => {
        const groupFields = Object.keys(objectText);
        const newIndex = checkOverFlow(1 + groupFields.indexOf(objectShow), groupFields.length) || 0;
        console.log('swapGroupFiled', {newIndex, groupFields});
        setObjectShow(groupFields[newIndex]);
        setInitFlag(true)

    }
    const group = objectText[objectShow];

    React.useEffect(()=>{
        if( rooms && rooms.length && initFlag){
            const {guid, docLinkedGuid, carrier} = [...rooms].shift()
            docLinkedGuid && setTabGroup(docLinkedGuid)
            guid && setTabHorizontal(guid)
            switch(objectShow){
                case 'carrier':
                    carrier && setTabGroup(carrier)
                    guid && setTabHorizontal(guid)
                    break;

                default: 
                docLinkedGuid && setTabGroup(docLinkedGuid)
                guid && setTabHorizontal(guid)
            }
            setInitFlag(false)

        }

    }, [rooms, objectShow])

    React.useEffect(() => {
        if (rooms && rooms.length) {
            if (objectShow === 'carrier') {
                const { guid, carrier } = [...rooms].find(el => el.carrier === tabGroup)
                carrier && setTabGroup(carrier)
                guid && setTabHorizontal(guid)
                return
            }

            const { guid, docLinkedGuid, } = [...rooms].find(el => el.docLinkedGuid === tabGroup)
            docLinkedGuid && setTabGroup(docLinkedGuid)
            guid && setTabHorizontal(guid)
        }
    }, [tabGroup])


    const title = '';
    return (
        <Dialog
            open={open} onClose={onClose}
            maxWidth={"xl"}
            fullWidth={true}
        >
            <DialogTitle>
                <WorkingItem data={rooms} id={tabHorizontal} type={'title'}/>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Tooltip title={'Поменять местами'}>
                        <Button
                            color="primary"
                            variant="contained"
                            // startIcon={group.textButton}  
                            onClick={swapGroupFiled}>
                            {group.textButton()}  
                           
                        </Button>
                        </Tooltip>
                        
                    </Grid>
                    <Grid item xs={10}>
                        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(rooms)}</>}>
                            {tabGroup
                                ? <TabsHorizontal
                                    rooms={asArray(rooms).filter(el => tabGroup && el[objectShow] === tabGroup)}
                                    group={group}
                                    tabHorizontal={tabHorizontal} setTabHorizontal={setTabHorizontal}
                                    tabGroup={tabGroup}
                                    objectShow={objectShow}/>
                                : <Typography>{group.notSelected}</Typography>
                            }
                        </LoadStateShow>
                    </Grid>
                </Grid>

                {onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>}

            </DialogTitle>
            <DialogContent>
                {/*<ChatWindowStyleBox>*/}
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant='h6'>{group.title}</Typography>
                        <TabsVertical
                            rooms={rooms}
                            group={group}
                            tabGroup={tabGroup}
                            setTabGroup={setTabGroup}
                            setTabHorizontal={setTabHorizontal}
                            objectShow={objectShow}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Box mb={1}>
                            <WorkingItem data={rooms} id={tabHorizontal} type={'points'}/>
                        </Box>
                        <ChatWindowStyleBox>
                            <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(rooms)}</>}>
                                {tabHorizontal
                                    ? <ShowMessages room={tabHorizontal}/>
                                    : <Typography>{group.notSelected}</Typography>
                                }
                            </LoadStateShow>
                        </ChatWindowStyleBox>
                    </Grid>
                </Grid>

                {/*<ChatBody*/}
                {/*    group={group}*/}
                {/*    objectText={objectText}*/}
                {/*    objectShow={objectShow}*/}
                {/*    rooms={items}*/}
                {/*    tabHorizontal={tabHorizontal}*/}
                {/*    setTabHorizontal={setTabHorizontal}*/}
                {/*/>*/}
                {/*</ChatWindowStyleBox>*/}
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={10}>
                        <BlockNewMessage room={tabHorizontal} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}