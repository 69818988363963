import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    DialogTitle,
    Dialog,
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';

import {blue} from '@material-ui/core/colors';

import {
    useSelector,
    useDispatch,
} from 'react-redux';

import {ROLES} from 'config/roles';

const roles = Object.keys(ROLES);

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color:           blue[600],
    },
});

function SimpleDialog(props) {
    const classes         = useStyles();
    const {onClose, open} = props;
    
    const handleListItemClick = (value) => {
        onClose(value);
    };
    
    return (
        <Dialog aria-labelledby="simple-dialog-title"
                open={open}>
            <DialogTitle id="simple-dialog-title">Выбор роли</DialogTitle>
            <List>
                {roles.map((role) => (
                    <ListItem button onClick={() => handleListItemClick(role)}
                              key={role}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={role}/>
                    </ListItem>
                ))}
                
                {/*<ListItem autoFocus button*/}
                {/*          onClick={() => handleListItemClick('addAccount')}>*/}
                {/*  <ListItemAvatar>*/}
                {/*    <Avatar>*/}
                {/*      <AddIcon/>*/}
                {/*    </Avatar>*/}
                {/*  </ListItemAvatar>*/}
                {/*  <ListItemText primary="Add account"/>*/}
                {/*</ListItem>*/}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose:       PropTypes.func.isRequired,
    open:          PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function RoleSelector() {
    const dispatch         = useDispatch();
    const me               = useSelector(state => state.system.me && state.system.me.items);
    const {roles: myRoles} = me || {};
    
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = (value) => {
        setOpen(false);
        dispatch({
            type:     'SYSTEM_RECEIVE',
            endPoint: 'me',
            payload:  {
                items: {
                    ...me,
                    roles: [
                        value,
                    ],
                },
            },
        });
    };
    //todo: for ie11
    if (!myRoles || -1 === myRoles.indexOf('ROLE_ADMIN')) {
        return <React.Fragment />;
    }
    return (
        <React.Fragment>
            {/*<IconButton>*/}
            {/*<Typography variant="subtitle1">Selected: {selectedValue}</Typography>*/}
            <Button variant="contained" color="default" onClick={handleClickOpen}>
                Роль: {myRoles.join(', ')}
            </Button>
            {/*</IconButton>*/}
            <SimpleDialog open={open}
                          selectedValue={''}
                          onClose={handleClose}/>
        </React.Fragment>
    );
}
