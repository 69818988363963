// import React from 'react';
import { ROLES } from './roles';
import Component from 'page/QueueCriteria';
import React from 'react';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import QuizIcon from '@material-ui/icons/SquareFoot';
import {ColObjectName } from 'components/showItems';
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import {FORM_ACTION} from '../FormDataContext';
import {FieldFormat} from 'config/FieldFormat';
import { object } from 'prop-types';

const queueCriteria = {
    apiName: 'queue_criterias',
    itemName: 'Общие критерии очереди',
    listTitle: 'Общие Критерии Очереди',
    roles: [
        ROLES.ROLE_USER,
    ],
    menu: {
        title: 'Общие Критерии Очереди',
        path: '/queue_criteria',
        Component,
        roles: [ROLES.ROLE_USER],
        icon: QuizIcon,
    },
    colsInTab: true,
    onFormDataAction: (values, props) => {
        const {action} = props;
        switch (action){
            case FORM_ACTION.INIT:
                return {
                    ...values,
                    typedTransports: (values.typedTransports || [])
                                         .map(guid => ({typedTransport: guid})),
                    fromPoints : (values.fromPoints || [])
                                    .map(guid => ({fromPoint: guid})),
                    toDirections : (values.toDirections || [])
                                    .map(guid => ({toDirection: guid})),
                };
            case FORM_ACTION.SUBMIT:
                return {
                    ...values,
                    // TODO: Подумать, возможно будет мешать пустой массив, в ситуациях
                    // когда с полем не взаимодействуем
                    typedTransports: (values.typedTransports || [])
                                         .map(obj => obj.typedTransport),
                    fromPoints : (values.fromPoints || [])
                                         .map(obj => obj.fromPoint),
                    toDirections : (values.toDirections || [])
                                         .map(obj => obj.toDirection),
                };
        }
        return values;
    },
    cols: [
        {
            title: 'Наименование',
            field: 'name',
        },

    ],
    subItems: {
        style: 'tabs',
        list: [
            {
                listTitle: 'Виды транспорта',
                field: 'typedTransports',
                // api: 'typed_transports',
                // object: ShowLegal,
                // format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                // apiFilter: (values, parent) => (
                //     { 'exists[contragent]': parent.id === 'carrier' }
                // ),
                icon: <LocalShippingIcon />,
                cols: [
                        {
                            title: 'Вид транспорта',
                            field: 'typedTransport',
                            api: 'typed_transports',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName
                        },
                    ]

            },
            {
                listTitle: 'Погрузка',
                field: 'fromPoints',
                icon: <CallMadeIcon />,
                cols: [
                        {
                            title:         'Адрес погрузки',
                            field:         'fromPoint',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                            // editComponent: MTableFiasAddress,
                            // render:        renderMTableAutocomplete(lookupFiasAddressTrees, 'fromPoint', 'address'),
                            // lookupObjects: lookupFiasAddressTrees,
                        }
                    ],
            },
            {
                listTitle: 'Разгрузка',
                field: 'toDirections',
                icon: <CallReceivedIcon />,
                cols: [
                        {
                            title:         'Адрес разгрузки',
                            field:         'toDirection',
                            api:           'fias_address_trees',
                            format:        FieldFormat.FORMAT_FIAS_ADDRESS,
                            object:        ColObjectName,
                        }
                    ],
            },
        ],
    },

    // cols:      [
    //     {
    //         title: 'name',
    //         field: 'name',
    //     },
    //     {
    //         title:     'typedTransports List',
    //         field:     'typedTransports',
    //         multiple:  true,
    //         api:       'typed_transports',
    //         object:    ShowLegal,
    //         format:    FieldFormat.FORMAT_SELECT_API_CHECKBOX,
    //         apiFilter: (values, parent) => (
    //             {'exists[contragent]': parent.id === 'carrier'}
    //         ),
    //     },
    //     {
    //         title:     'fromPoints List',
    //         field:     'fromPoints',
    //         multiple:  true,
    //         api:       'fias_address_trees',
    //         object:    ShowLegal,
    //         format:    FieldFormat.FORMAT_SELECT_API_CHECKBOX,
    //         apiFilter: (values, parent) => (
    //             {'exists[contragent]': parent.id === 'carrier'}
    //         ),
    //     },
    //     {
    //         title:     'toDirections List',
    //         field:     'toDirections',
    //         multiple:  true,
    //         api:       'fias_address_trees',
    //         object:    ShowLegal,
    //         format:    FieldFormat.FORMAT_SELECT_API_CHECKBOX,
    //         apiFilter: (values, parent) => (
    //             {'exists[contragent]': parent.id === 'carrier'}
    //         ),
    //     },


    // ],


};

export default queueCriteria;