import React from 'react';
/*
 * Определение типа объекта в зависимости от свойств api и multi
 */
import {FieldFormat} from 'config/FieldFormat';
import ApiObjectSelect from 'components/ApiObjectSelect';
import {Dummy} from 'components/Dummy';
import {ShowDate} from "components/showItems";
import ErrorBoundary from "./ErrorBoundary";

export function ObjectDetect({rows, row, field, item, props, noneValue, journal}) {
    if(!item){
        return '---';
    }
    const Object = row.api && row.format !== FieldFormat.FORMAT_SELECT_DADATA
        ? ApiObjectSelect
        : row.object || (row.format === FieldFormat.FORMAT_DATE && ShowDate) || Dummy;

    const Objects = row.multi && item[field]
            ? item[field]
            : [item[field]];
    // console.log("push2", Object)
    const result = Objects.map((i, key) => <Object key={key}
                                                   column={row}
                                                   row={item}
                                                   props={props}
                                                   item={i}
                                                   rows={rows}
                                                   journal={journal}

                                                   noneValue={noneValue}
    />).reduce((prev, curr) => [prev, ', ', curr]);

    // console.log("push2 result", result)
    return <ErrorBoundary info={"ObjectDetect"}>
        {result}
    </ErrorBoundary>

}
