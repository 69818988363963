import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {ExpandLess, ExpandMore, Home as HomeIcon} from '@material-ui/icons';
import {matchUserAddItemRoles} from 'config/roles';
import pageMain from 'page/Board';
import menuItems from 'page/menuItems';
import React, {useEffect} from 'react';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import useStyles from 'styles';

// const Switch = (props) => {
//     return <div>Switch:{props.children}</div>;
// };
//
// const Route = (props) => {
//     return <div>Route:{props.path}{props.children}</div>;
// };
let flat = [];

function iterate(items, parent, prefix) {
    items.forEach(item => {
        if (typeof item !== 'object') {
        }
        else if (item.sub) {
            iterate(item.sub, item, item.path
                ? prefix + item.path
                : prefix);
        }
        else if (item.path) {
            flat.push({
                ...item,
                path: item.path
                          ? prefix + item.path
                          : prefix,
                parent,
            });
        }
    });
}

iterate(menuItems, null, '');

export function setTitle(title) {
    
}

class Subscriber {
    list = new Set();
    add  = (title) => {
        if (this.handler) {
            this.handler(title);
        }
        this.title = title;
    };
    
    subscribe = (handler) => {
        this.handler = handler;
    };
    
    add2 = (props) => {
        // console.log('add2',Array.from(this.list));
        if (this.handler) {
            this.handler('add2');
        }
        if (typeof props === 'object') {
            this.list.add(props);
        }
    };
    
    remove = (props) => {
        if (this.handler) {
            this.handler('add2');
        }
        if (typeof props === 'object') {
            this.list.delete(props);
        }
    };
}

export const Breads = new Subscriber();

export function AddBreadCrumb(props) {
    React.useEffect(() => {
        Breads.add2(props);
        return () => {
            Breads.remove(props);
        };
    });
    return <React.Fragment/>;
    
}

export function useBreadcrumbs() {
    const [object, setObject] = React.useState([]);
    
    function handleSetObject(title) {
        if (title) {
//            let newObject = <Typography key={title} color="textPrimary">{title}</Typography>;
            object.push(title);
            setObject([...object]);
        }
        else {
            object.pop(); // hack
            setObject([]);
        }
    }
    
    useEffect(() => {
        Breads.subscribe(handleSetObject);
        return () => {
            Breads.subscribe(null);
        };
    });
    
    return Array.from(Breads.list);
}

const BreadCrumb = {
    to:    '/',
    icon:  <HomeIcon fontSize="small"/>,
    label: 'Главная',
};

function RouteOne(props) {
    const {Component, path, parent, title, Icon} = props;
    const BreadCrumb                                        = {
        to:    path,
        icon:  <Icon/>,
        label: title,
    };
    const parentBreadCrumb                                  = parent && {
        // to:    '/',
        clickable: false,
        icon:      <parent.icon/>,
        label:     parent.title,
    };
    // Breads.add2(parentBreadCrumb);
    // Breads.add2(BreadCrumb);
    //
    // React.useEffect(() => {
    //     return () => {
    //         Breads.remove(BreadCrumb);
    //         if (parent) {
    //             Breads.remove(parentBreadCrumb);
    //         }
    //     }
    // });
    
    return <React.Fragment>
        <AddBreadCrumb {...parentBreadCrumb}/>
        <AddBreadCrumb {...BreadCrumb}/>
        <Component path={path} parent={{menuRoot: parent}} menuRoot={parent} />
    </React.Fragment>;
}

export function MenuComponents({roles}) {
    Breads.add2(BreadCrumb);
    return <Switch>
        <Route path='/' exact>{pageMain}</Route>
        {flat
            .filter(item => matchUserAddItemRoles(item.roles, roles))
            .map(({path, Component, sub2, title, parent, icon}, key) => (
                <Route key={key} path={path}>
                    
                    {/*(TODO: Хлебные крошки работают некорректно)<br />*/}
                    <br/>
                    {Component && <React.Fragment>
                        <RouteOne Component={Component} title={title} Icon={icon} path={path} parent={parent}
                                  />
                    </React.Fragment>}
                </Route>
            ))}
    </Switch>;
}

function MenuItem({path, icon: Icon, title, onClick, sub, open, prefix, style, noCollapseIcon, setOpenIndex, openIndex, classes}) {
    const pathFull = prefix
        ? prefix + path
        : path;
    const match    = useRouteMatch(pathFull);
    const selected = Boolean(pathFull && match);
    if (selected && setOpenIndex && null === openIndex) {
        setOpenIndex();
    }
    // const Icon = React.ForwardRef((props, ref) => <icon props={props} ref={ref} />);
    return <ListItem
        disabled={!Boolean(pathFull || sub)}
        button
        component={!sub && pathFull
            ? Link
            : null}
        to={pathFull}
        selected={selected}
        onClick={onClick}
        style={style}
        className={classes && classes.nested}
    >
        {Icon && <ListItemIcon><Icon/></ListItemIcon>}
        <ListItemText primary={title}/>
        {sub && !noCollapseIcon && (
            open || selected
                ? <ExpandLess/>
                : <ExpandMore/>
        )}
    </ListItem>;
}

export function MenuTree({onClick, roles}) {
    
    const [openIndex, setOpenIndex] = React.useState(null);
    const classes                   = useStyles();
    
    const handleClick = (index, item) => (e) => {
        if (item.sub && item.sub.length) {
            console.log('Class: MenuTree, Function: onClick, Line 93 (): '
                , 'setOpenIndex');
            setOpenIndex(
                openIndex !== index
                    ? index
                    : null,
            );
        }
        else {
            console.log('Class: MenuTree, Function: onClick, Line 93 (): '
                , 'onClick');
            onClick(e);
        }
    };
    
    return (
        <List>
            {menuItems
                // Поиск соответствия ролей или Админ
                .filter(item => '-' === item || matchUserAddItemRoles(item.roles, roles))
                .filter(item=> !item.hiddenMenu)
                .map((item, key) => '-' === item
                    ? <Divider key={key}/>
                    : <React.Fragment key={key}>
                        <MenuItem
                            onClick={handleClick(key, item)}
                            {...item}
                            openIndex={openIndex}
                            open={key === openIndex}
                            noCollapseIcon={Boolean(item.subOpen)}
                            setOpenIndex={() => setOpenIndex(key)}
                        />
                        <RenderSub
                            classes={classes}
                            menuItems={item.sub || []}
                            open={item.subOpen || key === openIndex}
                            handleClick={handleClick}
                            roles={roles}
                            prefix={item.path}
                            parent={item}
                        />
                    </React.Fragment>,
                )
            }
        </List>
    );
}

function RenderSub({classes, menuItems, open, handleClick, roles, prefix, parent}) {
    // const match    = useRouteMatch(prefix);
    // const selected = Boolean(prefix && match);
    return <React.Fragment>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.overNested}>
                {menuItems
                    .filter(item => matchUserAddItemRoles(item.roles, roles))
                    .map((item, key) => <MenuItem
                            onClick={handleClick(key, item)}
                            {...item}
                            style={{...parent.style, ...item.style}}
                            key={key}
                            prefix={prefix}
                            classes={classes}
                        />,
                    )}
            </List>
        </Collapse>
    </React.Fragment>;
}
