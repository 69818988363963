import React from "react";
import API from "libs/api";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {MeContext} from 'meContext';



function WindowAgreemnt(props){

    const {isAcceptAgreement, guid, roles} = React.useContext(MeContext);
    
    const accept = () => {
        const api = new API();
        api.post('users', {
            isAcceptAgreement: true,
            guid
        })
        .then(result => window.location.reload())
    
    }

    return isAcceptAgreement || roles.includes('ROLE_PREVIOUS_ADMIN')
    ? props.children
    : <Dialog
        maxWidth={'xs'}
        open={true}
    >
        <Box p={3} display="flex" justifyContent="center">
            <img src="/logo_rus.svg" />
        </Box>

        <DialogTitle>
            <Typography variant="h6" align="center">{'Для продолжения работы на портале, нужно принять условия пользовательского соглашения'}</Typography>

        </DialogTitle>
        <DialogContent>
            <Link href="/docu/Пользовательское соглашение.doc" color="primary" underline="always">
                <Typography variant="h5" align="center" >{'Пользовательское соглашение'}</Typography>
            </Link>
            <Box py={2}>
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
               
                >
                    <Button
                        title={'Принимаю'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={accept}
                    >
                        Принимаю
                    </Button>
                    <Button
                        title={'Запросить восстановление'}
                        variant={'contained'}
                        onClick={() => { window.location.replace('/connect/logout') }}

                    >
                        Не принимаю
                    </Button>
                </Grid>
            </Box>

        </DialogContent>



    </Dialog>;
} 


export {WindowAgreemnt}
