import AdminShow from 'components/FieldFormat/AdminShow';
import FormApiAutocompleteCheckbox from 'components/FieldFormat/FormApiAutocompleteCheckbox';
import {FormDataContext} from 'FormDataContext';
import React from 'react';

function MyComponent ({item, apiFilter,value, error, handleChange}) {
    const {title, api, required } = item;
    
    // const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    return <React.Fragment>
        <AdminShow>[SelectApiCheckbox]</AdminShow>
        <FormApiAutocompleteCheckbox
            item={item}
            label={title}
            value={value}
            api={api}
            apiFilter={apiFilter}
            required={required}
            error={!!error}
            onChange={handleChange}
            InputProps={{color: 'secondary'}}
            InputLabelProps={{shrink: true}}
        />
    </React.Fragment>;
}


function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
            && prevProps.disabled === nextProps.disabled
        && prevProps.error === nextProps.error
        && JSON.stringify(prevProps.apiFilter) === JSON.stringify(nextProps.apiFilter);
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {parent} = props;
    const {field,apiFilter, disabled}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    const handleChangeLocal                       = (e,value) => {
        return handleChange(props.item)(e, value);
    };
    
    const apiFilterLocal = apiFilter && apiFilter(values, parent)
    const value = values && values[field];
    const error = values && errors && errors[field];
    
    return <MyComponentMemo
        value={value || ''}
        disabled={disabled || false}
        error={error || null}
        apiFilter={apiFilterLocal}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;