import {AppBar, Box, Button, Container, Grid, Paper, Tab, Tabs, Typography} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Warning} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import {CancelIcon, InfoIcon, SaveIcon, DeleteIcon} from 'components/icons';

import LinearProgress from 'components/LinearProgress';
import {AddBreadCrumb} from 'components/Menu';
import FormDebug from 'components/RequestFormDebug';

import RequestFormTabs from 'components/RequestFormTabs';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import API from 'libs/api';
import React from 'react';
import {Link} from 'react-router-dom';
import * as PropTypes from "prop-types";
import {ActionButtons} from "./ActionButtons";
import useListTabs, {uniqTabs} from "./useListTabs";

export function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const defaultProps = {
    onFormDataAction:  values => values,
    canDeleteItem:     values => values,
    actionAddTitle:    'Добавить',
    actionUpdateTitle: 'Сохранить',
    topColsBlocks:     [
        {
            num:  1,
            cols: 1,
        }
    ],
    subItems:          {
        list: [],
    },
};

const GridStyle = withStyles(() => (
        {
            root: {
                padding: "0.75rem"
            },
        }
))(Grid)

const GridStyleMarginTop = withStyles(() => (
        {
            root: {
                padding: "0.75rem",
                marginTop: "1rem"
            },
        }
))(Grid)

const Title = (props) => {
    const { isUpdate } = React.useContext(FormDataContext);
    const {itemName, actionUpdateTitle, actionAddTitle} = props
    return (
        <>
            <Typography variant={'h6'}>{isUpdate
                ? actionUpdateTitle
                : actionAddTitle} {itemName}
            </Typography>
        </>
    )
}


export default function FormEditCreateTabs(props) {
    console.log('callfunction FormEditCreateTabs')

    const {
        dbId,
        cols,
        colsInTab,
        open,
        onSuccess,
        handleSubmit,
        initialValues,
        itemName,
        topColsBlocks,
        subFormHandleChange,
        item,
        subItems,
        onFormDataAction,
        subApi,
        path,
        actionAddTitle,
        actionUpdateTitle,
        helpPrefix,
        parent,
        tab,
        windowUsersetting,
        refreshItem,
        colsField,
        newStatusDocument
    } = {...defaultProps, ...props};

    const isUpdate = initialValues && initialValues[dbId];
  
    const Form = subFormHandleChange
        ? 'div'
        : 'form';

    /**
     * Блокировка перехода на списов если задано options.continue
     * @param data
     * @param options
     */
    const onSuccessLocal = (data, options = {}) => {
        console.log('onSuccessLocal', options)
        if (options.continue) {
            return
        }
        onSuccess(data);
    }
    const ComponentGrid = colsInTab ? GridStyle : GridStyleMarginTop

    return (
        <FormDataContextProvider
            initial={initialValues}
            onFormDataAction={onFormDataAction}
            handleSubmit={handleSubmit}
            cols={cols}
            dbId={dbId}
            _topProps={props}
            menuRoot={props.menuRoot}
            onSuccess={onSuccessLocal}
            refreshItem={refreshItem}
            debug={'FormEditCreateTabs'}
            colsField={colsField || []}
            newStatusDocument={newStatusDocument}
        >
            
            <AdminShow>[FormEditCreateTabs]</AdminShow>
            <Paper>
                <Form>
                    {!subFormHandleChange &&
                    <ActionButtons {...defaultProps} {...props}
                    />}
                    <Paper elevation={0}>
                        <Container maxWidth={false}>
                            {helpPrefix}
                            {
                                !windowUsersetting && <Title itemName={itemName} actionAddTitle={actionAddTitle} actionUpdateTitle={actionUpdateTitle}/>
                            }
                        </Container>
                        <ComponentGrid container>
                            <Grid item xs={12}>
                                {!colsInTab && <AddBreadCrumb label={'Редактировать'}/>}
                                <RequestFormTabs
                                    {...props}
                                    open={open}
                                    // values={values}
                                    // handleChange={handleChange}
                                    // setState={setState}
                                    // handleSuggest={handleSuggest}
                                    // errors={errors}
                                    // submitting={submitting}
                                    topColsBlocks={topColsBlocks}
                                    path={path + '/edit'}
                                    subItems={subItems}
                                    debug={true}
                                    // colsInTab={true}
                                />
                            </Grid>
                        </ComponentGrid>
                    </Paper>
                </Form>
            </Paper>
        </FormDataContextProvider>
    );
}
