import Build from '@material-ui/icons/Build';
import {FieldFormat} from 'config/FieldFormat';
import {ColObjectName, ColObjectNameOptions} from 'components/showItems';
import optionsNDS from 'config/optionsNDS';
import React from 'react';
import pageSettingsProfile from '../page/PageSettingsProfile';
import {ROLES} from 'config/roles';
import {FORM_ACTION} from '../FormDataContext';
import request from 'superagent';



export default {
    listTitle: 'Настройки',
    apiName:   'profile',
    itemName:  'Настройки',
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Настройки',
        path:      '/settings',
        icon:      Build,
        roles:     [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        Component: pageSettingsProfile
    },
    
    onFormDataAction: (values, props) => {
        const {action} = props;
        
        const _fix = {};
        switch (action) {
            case FORM_ACTION.INIT:
              
                break;
            case FORM_ACTION.SUBMIT:
                const newValues = {...values}
                delete newValues._items
                _fix.defaultSettings = {...newValues}
               
                break;
        }
        return {...values, ..._fix}
    },
    // colsInTab: true,
    handleSubmit: (values, action) => {
        // console.log("push2", values, action)
        return new Promise((resolve, reject) => {
            request
                .post(`/api/profile`)
                .set('accept', 'application/json')
                .send(values)
                .then((result) => resolve(result))
                .catch(err => reject(err))
            
        })
    },
    
    topColsBlocks:    [
        {
            num:    1,
            title:  'Финансовая часть',
            cols:   2,
            border: true,
        },
        {
            num:    2,
            title:  'Транспортная часть',
            cols:   2,
            border: true,
        },
      
    ],
    cols: [
        {
            title:        'Валюта',
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectName,
            api:          'currencies',
            field:        'currency',
            topColsBlock: 1,
            cols: 1,
            hidden:       true,
        },
        {
            title:        'Ставка НДС',
            field:        'ndsRateString',
            format:       FieldFormat.FORMAT_SELECT,
            options:      optionsNDS,
            object:       ColObjectNameOptions,
            topColsBlock: 1,
            cols: 1,
            hidden:       true,
        },
    
        {
            field:  'oneoffLimitSum',
            title:  'Лимит суммы для заявок по одноразовому договору',
            format: FieldFormat.FORMAT_NUMBER,
            minValue: 0,
            topColsBlock: 2,
            cols: 1,
        },
        {
            field:  'oneoffLimit',
            title:  'Лимит количества разовых перевозок',
            format: FieldFormat.FORMAT_NUMBER,
            minValue: 0,
            topColsBlock: 2,
            cols: 1,
        },
        {
            title:  'Уровень доступа к документам',
            field:  'accessLevel',
            api:    'enum_access_levels',
            format: FieldFormat.FORMAT_SELECT_API,
            topColsBlock: 2,
            cols: 1,
        }
       
    ],
    
};