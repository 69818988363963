import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import HOCListActions from 'components/HOC/HOCListActions';
import {AddIcon, DeleteIcon, EditIcon} from 'components/icons';
import {config} from 'config';
import MaterialTable from 'material-table';
import React from 'react';
import {transformCols} from './MTableExtend/transformCols';
import {ButtonCanDo, TMSTableAction} from "./MTableExtend/TMSTableAction";
import {Fab} from "@material-ui/core";
import {ApiCacheClear} from "components/ApiDataLoad";
import ErrorBoundary from "./ErrorBoundary";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const text = {
    deleteRow : "Удалить выбранный элемент"
}

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TableMaterialTableShow(props) {
    const {
        listTitle,
        list,
        onEdit,
        detail,
        menu,
        onDelete,
        onClone,
        loadData,
        listActions,
        addMTableOptions,
        canAdd,
        canEdit,
        canDelete
    } = props;

    const {icon: TitleIcon, hiddenIconDelete} = {icon: null, ...menu};

    // const {canAdd, canEdit, canDelete}  = useCanCheck(props);


    const tableRef                      = React.createRef();
    const [selectedRow, setSelectedRow] = React.useState(null);

    const [columns, setColumns] = React.useState([]);

    React.useMemo(() => {
        let c = 'function' === typeof props.cols
            ? props.cols(props, list.items || [], {from: 'tableMaterialTableShow'})
            : props.cols;

        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        }
        else {
            setColumns(c);
        }
    }, [list.items, props]);

    const [deleteRow, setDeleteRow] = React.useState(null);
    const [isAction, setIsAction]   = React.useState(false);
    const actionDeleteRow           = () => {
        setIsAction(true);
        setDeleteRow(null);
        onDelete({...deleteRow, tableData: undefined}).then(() => {
            //очищаем кэш чтоб получить новое значение  
            ApiCacheClear()
            loadData();
            setIsAction(false);
        }).catch(error => {
            console.log('onDelete error', error);
            setIsAction(false);
        });
    };
    React.useEffect(()=>{
        setIsAction(list.isFetching)
    }, [list])

    const onDeleteDialog = rowData => () => {
        setDeleteRow(rowData);
    };

    const Title = props => {
        const {label, icon: Icon} = props;
        return (
            <div style={{
                alignItems:     'center',
                flexDirection:  'row',
                justifyContent: 'left',
                display: 'flex',
                fontSize: '24px',
            }}>
                {Icon && <Icon/>}&nbsp;{label}
            </div>
        );
    };

    const actions = [
        {
            icon:         () => {
                return <Fab size="small" color={'primary'}>
                    <AddIcon/>
                </Fab>;
            },
            tooltip:      'Добавить',
            isFreeAction: true,
            onClick:      onEdit(),
        },
        {
            component: ButtonCanDo,
            icon: <EditIcon />,
            canAction: props.canEdit,
            onAction:  onEdit,
        },
        props.cloneIems && {
            component: ButtonCanDo,
            icon: <FileCopyIcon />,
            canAction: props.cloneIems,
            onAction:   onClone
        },
        !hiddenIconDelete && {
            component: ButtonCanDo,
            icon: <DeleteIcon />,
            canAction: props.canDelete,
            onAction:  onDeleteDialog,
        },
        ...listActions,
    ];
    return <React.Fragment>

        <ConfirmDialog
            show={Boolean(deleteRow)}
            onConfirm={() => actionDeleteRow()}
            onCancel={() => setDeleteRow(null)}
            title = {text.deleteRow}
        />
        <AdminShow>[TableMaterialTableShow]</AdminShow>
        <ErrorBoundary info={'TableMaterialTableShow'}>
        <MaterialTable
                {...config.MaterialTable}
            isLoading={isAction}
            // title={<Title icon={TitleIcon} label={listTitle}/>}
            title={listTitle}
            tableRef={tableRef}
            columns={transformCols(columns, {onEdit, TitleIcon})}
            data={(
                list && list.items
            ) || []}
            // data={query =>
            //     new Promise((resolve, reject) => {
            //         resolve({data: list && list.items || []});
            //     })
            // }
            actions={canAdd || canDelete || canEdit ? actions : []}
            {...config.MaterialTable}
            onRowClick={(
                (evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)
            )}

            options={{
                ...config.MaterialTable.options,
                ...addMTableOptions,
                rowStyle: rowData => (
                    {
                        backgroundColor: selectedRow === rowData.tableData.id
                                             ? '#DDD'
                                             : undefined,
                    }
                ),
            }}
            components={{
                Action: TMSTableAction
            }}
            detailPanel={detail}
        />
        </ErrorBoundary>
    </React.Fragment>;
}

/**
 * @deprecated
 */
const TableMaterialTableShowListActions = HOCListActions()(TableMaterialTableShow);

export default TableMaterialTableShowListActions