import AdminShow from 'components/FieldFormat/AdminShow';
import SelectApi from 'components/FieldFormat/SelectApi';
import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';
import {RequestFormDebugContext} from 'components/RequestFormDebug';
import {FieldFormat} from 'config/FieldFormat';
import {FormDataContextConsumer} from 'FormDataContext';
import React from 'react';

const needUpdate  = (p, n) => p.value === n.value;
const ShowIfEqual = React.memo(
    ({value, target, children}) => value === target
        ? children
        : React.Fragment,
    needUpdate,
);

function UseContextValue(WrappedComponent, selectValue) {
    return function(props) {
        return <FormDataContextConsumer>{context => <React.Fragment>
            <WrappedComponent {...props} values={selectValue(context.state.values)}/>
        </React.Fragment>}</FormDataContextConsumer>;
    };
}

function objectEquals(prevProps, nextProps) {
    console.info('objectEquals', JSON.stringify(prevProps.values));
    return JSON.stringify(prevProps.values) === JSON.stringify(nextProps.values);
}

const Sub = React.memo(props => <SubFormListEdit {...props} />, objectEquals);

const levelFromContext = context => {
    const value = context.state.values.accessLevel || '';
    return value.replace('/api/enum_access_levels/', '');
};

export default function(props) {
    const item = {
        title:        'Уровень видимости документа',
        field:        'accessLevel',
        format:       FieldFormat.FORMAT_SELECT_API,
        api:          'enum_access_levels',
        cols:         2,
        topColsBlock: 1,
        hidden:       true,
    };
    
    const SubFormLIstEditValues = UseContextValue(Sub, values => values[props.itemProps.field] || []);
    
    return <React.Fragment>
        {props.inTab && <br/>}
        <AdminShow/>
        <SelectApi
            item={item}
        />
        {/*<SimpleInput*/}
        {/*    item={{*/}
        {/*        title:        'debug',*/}
        {/*        field:        'debug',*/}
        {/*    }}*/}
        {/*/>*/}
        <FormDataContextConsumer>{context => {
            return <React.Fragment>
                <AdminShow/>
                <ShowIfEqual value={levelFromContext(context)} target={'ВыбраннымПеревозчикам'}>
                    <SubFormLIstEditValues {...props}/>
                </ShowIfEqual>
            </React.Fragment>;
        }}</FormDataContextConsumer>
        <RequestFormDebugContext/>
    </React.Fragment>;
}