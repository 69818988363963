import React from 'react';
import 'kanban-styles.css';
import {
    Typography,
} from "@material-ui/core";
import {useLoaderApiList} from "../components/useLoaderApi";
import Kanban from "components/Kanban/Kanban";

function areEqual(prevProps, nextProps) {
    if (!nextProps.lookupCard) {
        return true
    }
    if(nextProps.lookupCard && nextProps.oldState && nextProps.oldState.length){
        return JSON.stringify(nextProps.oldState) === JSON.stringify(nextProps.lookupCard)
    }
    return  false

}


const MemoMyComponent = React.memo(Kanban, areEqual)

const  PageKanbanLoadinglookupCard = ({statuses, loadStateLanes}) => {
    const [lookupCard, loadStateCard, reloadCards, setItem, lastState] = useLoaderApiList('doc_requests')

    React.useEffect(() => {
        const interval = setInterval(()=> {
            reloadCards()
        }, 30000)
        return ()=> {
            clearInterval(interval)
        }
    }, []);

    return (
           <>
               <Typography variant="h5">
                   Заявки на транспорт
               </Typography>
               <MemoMyComponent
                       lookupCard={lookupCard || null}
                       oldState={lastState}
                       loadStateCard={loadStateCard}
                       statuses={statuses}
                       loadStateLanes={loadStateLanes}
                       text={"Fake kanban"}
               />

           </>
    )
}

export default function PageKanbanLoadinglookupLanes(){
    const [statuses, loadState] = useLoaderApiList('doc_request_statuses', {isShowKanban: true})

    return <PageKanbanLoadinglookupCard
            statuses={statuses}
            loadStateLanes={loadState}
    />


}
