import system from 'actions/system';
import BreadCrumbs from 'components/BreadCrumbs';
import React from 'react';
import {loadCSS} from 'fg-loadcss';

import {
    LinearProgress,
} from '@material-ui/core';

import {
    MenuComponents,
} from 'components/Menu';
import useStyles from 'styles';
import {
    createMuiTheme,
    ThemeProvider,
} from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    
    // Switch,
    // Route,
    // Link
} from 'react-router-dom';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {MeContext} from 'meContext';
import AppLayout from './components/AppLayout';

import {WindowAgreemnt} from "./components/WindowAgreemnt";
// https://fonts.googleapis.com/css?family=Ubuntu&display=swap
// const theme2 = createMuiTheme({
// palette: {
//     secondary: {
//         main: green[500],
//     },
// },
// typography: {
//     fontFamily: 'Ubuntu',
// },
// overrides: {
//     MuiCssBaseline: {
//         '@global': {
//             '@font-face': ['https://fonts.googleapis.com/css?family=Ubuntu&display=swap'],
//         },
//     },
// },
// });


export default function App() {

    const classes          = useStyles();

    const {settings, roles}       = React.useContext(MeContext);
    const {font: useTheme} = settings||{font: 'Roboto'};
 

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.10.2/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);
// https://react-theming.github.io/create-mui-theme/
    const theme = createMuiTheme({
        palette:    {
            secondary: {
                
                main:         '#84be4b',
                // light: '#FF0000',
                dark:         '#a8d087',
                contrastText: '#FFF',
                // hover #a8d087
                //main: '#7dba4c',
                
            },
            primary : {
                main: '#3b71af',
                dark:         '#3b71af',
                contrastText: '#FFF',

            },
            action:    {
                hover: {
                    main: '#a8d087',
                },
            },
        },
        // root: {
        //     '&:nth-of-type(odd)': {
        //         backgroundColor: theme.palette.background.default,
        //     },
        // },
        typography: {
            fontFamily: useTheme,
        },
        // root:{
        //     fontSize: '2rem',
        // },
        root:{
            selected: {
                '&.Mui-selected': {
                    fontWeight:      500,
                    backgroundColor: '#888888 !important',
                    color:           '#444',
                    '&:hover':       {
                        color:           '#888',
                        backgroundColor: '#888888 !important',
                    },
                    // backgroundColor: '#888888 !important',
                }
            }
        },
        overrides:  {
            body:            {
                fontFamily: useTheme,
            },
            MuiTableCell:    {
                root: {
                    whiteSpace: 'nowrap',
                    fontSize: '1rem',
                    // fontFamily: 'inherit',
                },
                paddingNone: {
                  paddingRight: "0.5rem",
                    
                },
                head: {
                    color:      '#80808080',
                    fontWeight: 400,
                },
            },
            MuiListItemIcon: {
                root: {
                    minWidth: '30px',
                },
            },
            MuiIconButton:   {
                root: {
                    fontSize: '1rem',
                },
                sizeSmall:{
                    fontSize: '1rem',
                }
            },
            MuiTableBody:    {
                root: {
                    fontSize: '13px',
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: '#e3f2fd',
                    },
                },
            },
            MuiToolbar:      {
                regular: {
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    minHeight: '48px !important',
                },
            },
            MuiTab: {
                root: {
                    '&.Mui-selected': {
                        fontWeight:  '800 !important',
                        backgroundColor: '#e3f2fd'
                    },
                    maxWidth: null,
                }
            },
            MuiOutlinedInput:{
                root:{
                    '& input:required ~ fieldset':{
                        border: 'solid 2px pink',
                    }
                }
            },
            root:{
                selected: {
                    '&.Mui-selected': {
                        fontWeight:      500,
                        backgroundColor: '#888888 !important',
                        color:           '#444',
                        '&:hover':       {
                            color:           '#888',
                            backgroundColor: '#888888 !important',
                        },
                        // backgroundColor: '#888888 !important',
                    }
                }
            },
            MuiListItem:{
                root: {
                    '&.Mui-selected span': {
                        fontWeight:      '800 !important',
                    }
                }
            }
        },
        // overrides: {
        //     MuiCssBaseline: {
        //         '@global': {
        //             '@font-face': ['https://fonts.googleapis.com/css?family=Ubuntu&display=swap'],
        //         },
        //     },
        // },
    });
    if (!roles) {
        return <div>ERROR user ident...</div>;
    }

    return (
        <WindowAgreemnt>
            <Router>
                <link rel="stylesheet" href={`https://fonts.googleapis.com/css?family=${useTheme}&display=swap`} />
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <AppLayout />
                        <div className={classes.content}>
                            <div className={classes.toolbar} />
                            {/*<Grid container className={classes.root} spacing={2}>*/}
                            <BreadCrumbs />
                            <MenuComponents roles={roles} />
                            {/*</Grid>*/}
                        </div>
                    </div>
                </ThemeProvider>
            </Router>
        </WindowAgreemnt>
    );
}
