import {
    AddIcon,
} from 'components/icons';
import contactInfo from 'config/contactInfo';
import contactPerson from 'config/contactPerson';
import docRequest from 'config/docRequest';
import driver from 'config/driver';
import bankAccount from 'config/bankAccount';
import individual from 'config/individual';
import legals from 'config/legals';
import measure from 'config/measure';
import transport from 'config/transport';
import cargoType from 'config/cargoType';
import service from 'config/service';
import member from 'config/member';
import contract from 'config/contract';
import contractLegal from  'config/contractLegal'
import contractCarrier from 'config/contractCarrier'
import routePoints from 'config/routePoints';
import priceRequest from 'config/priceRequest';
import deliveryRequest from 'config/deliveryRequest';
import carriers from 'config/carriers';
import queue from 'config/queue';
import rulesPatterns from 'config/rulesPatterns';
import docRequest_routePlaces from 'config/docRequest_routePlaces';
import tender from 'config/tender';
import kanban from 'config/kanban';
import settingsProfile from './settingsProfile';
import React from 'react';
import {
    ClearOutlined,
    SearchOutlined,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bidding from './bidding';
import selfRegistration from "./selfRegistration"
import queueCriteria from 'config/queueCriteria';
import sync from 'config/sync';
import {Fab} from '@material-ui/core';



export const config = {
    // Format: FieldFormat,
    apiPrefix:     '/api/',
    formDebug:     true,
    legals,
    bankAccount,
    contactInfo,
    individual,
    contactPerson,
    docRequest_routePlaces,
    driver,
    carriers,
    transport,
    cargoType,
    measure,
    member,
    settingsProfile,
    service,
    routePoints,
    contract,
    contractLegal,
    contractCarrier,
    docRequest,
    priceRequest,
    deliveryRequest,
    tender,
    kanban,
    bidding,
    queue,
    sync,
    selfRegistration,
    rulesPatterns,
    queueCriteria,
    techFields: [
        'cloneOblect',
        'replicator',
        'is_deleted',
        'is_hided',
        'guid',
        'accessLevel',
        'clientProfile',
        'clientProfile',
        'not_synced',
        'ndsRateValue',
        'userAuthor',
        'tarifCost',
        'services',
        'cargos'
    ],
    MaterialTable: {
        options:      {
            toolbarButtonAlignment: 'left',
            padding:                'dense',
            emptyRowsWhenPaging:    false,
            pageSize:               20,
            headerStyle:            {
                backgroundColor: '#EEE',
            },
        },
        icons:        {
            Delete:      React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
            Search:      SearchOutlined,
            ResetSearch: ClearOutlined,
            Add:         () => {
                return <Fab
                    size="small"
                    title={'Добавить'}
                    // variant={'contained'}
                    color={'primary'}
                    // onClick={addNew}
                    // startIcon={<AddIcon/>}
                >
                    <AddIcon/>
                </Fab>;
            },
            FileCopy: React.forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref} />),
            Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        },
        localization: {
            pagination: {
                labelRowsSelect: 'элементов',
                labelDisplayedRows: '{from}-{to} из {count}',
                firstTooltip: 'Первая страница',
                previousAriaLabel: 'Предыдущая страница',
                previousTooltip: 'Предыдущая страница',
                nextAriaLabel: 'Следующая  страница',
                nextTooltip: 'Следующая  страница',
                lastTooltip: 'Последняя страница'
            },
            toolbar:    {
                searchPlaceholder: 'Поиск...',
                nRowsSelected:     '{0} row(s) selected',
            },
            header:     {
                actions: 'Действия',
            },
            body:       {
                emptyDataSourceMessage: 'Нет данных для отображения',
                filterRow:              {
                    filterTooltip: 'Filter',
                },
                editRow:                {
                    deleteText: 'Вы уверены, что хотите удалить строку?',
                },
                editTooltip: 'Редактировать',
                deleteTooltip: 'Удалить',

            },
        },
    },
    maxSizeFile:   2048000,

    get: name => {
        return config[name] || name;
    },
    filesizeSettings: {
        base: 2,
        standard: "jedec",
        fullform: false,
        locale: "ru-RU",
        separator: ',',
        symbols: {
            B: "Б",
            KB: "кБ",
            MB: "МБ",
            GB: "ГБ"
        },
        fullforms: [
            'байта',
            'килобайта',
            'мегабайта',
            'гигабайта'
        ]
    }, 
    timeClipping : new Date('1970-01-01T00:00:00+00:00'),
    DOC_NUMBER_AUTO_DOC_REQUEST : "Присвоится автоматически",
    toolTipClearIconText: "Очистить"

};
