import React from 'react';
import {FormDataContext} from "FormDataContext";
import MaterialTable from 'material-table';
import {
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    IconButton,
    Tooltip,
    TextField,
    Grid,
    Fab
} from '@material-ui/core';

import {STATE} from 'libs/api';
import {config as configGlobal} from 'config'
import {useLoaderApiList} from 'components/useLoaderApi';
import LoadStateShow from 'components/LoadStateShow.jsx'
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmDialog from "../ConfirmDialog";
import {AddIcon, DeleteIcon} from 'components/icons';
import ListEmails from "./ListEmails";
import {verificationAndConversion} from  "./UserNotificationSettings"




const RenderEmailOthers = (props) => {
    const {setState, setStateValues} = React.useContext(FormDataContext);
    const [data, setData] = React.useState(verificationAndConversion(props[props.code]));
    const [show, setShow] = React.useState(false);
    const changeData = (value) => {
        setData(state => {
            state[value.tableData.id] = value
            return [...state]
        })
    }

    const deleteData = (value) => {
        setData(state => state.filter((el, index) => index !== value.tableData.id))
    }

    const addData = () => {
        const newData = {
            is_enable: true,
            value: ""
        }
        setData(state => [newData, ...state])
    }

    const changeAllisEnable = () => {
        setData(state => {
            return state.filter(el => el.is_enable).length
                    ? state.map(el => ({...el, is_enable: false}))
                    : state.map(el => ({...el, is_enable: true}))
        })
    }

    const changeState = (values) => {
        setStateValues(state => {
            const {user_notifications} = state
            state.user_notifications = state.user_notifications
                    .map(el => {
                        if (el.id === props.id) {
                            el[props.code] = values;
                            return el
                        }
                        return el
                    })
            return state
        })
    }

    // console.log("push2", props)
    const handlerButton = () => {
        setShow(true)
    }

    return (
            <>
                <Tooltip title={"Добавить/редактировать список электронных почт"}>
                    <Box px={1}>
                        <IconButton variant="contained" color="primary" disabled={props.disabled}
                                    onClick={handlerButton}>
                            <EditIcon/>
                        </IconButton>
                    </Box>
                </Tooltip>
                <ConfirmDialog
                        show={show}
                        title={"Добавить/редактировать почту "}
                        custemDialogContent={<ListEmails {...props} dataTableMui={{
                            data,
                            changeData,
                            deleteData,
                            addData,
                            changeAllisEnable
                        }}/>}
                        cancel={"Закрыть"}
                        maxWidth={"xl"}
                        confirmDisable={true}
                        onCancel={() => {
                            setShow(false)
                            changeState(data.filter(el => el.value))
                        }}
                />
            </>
    )

}

export default RenderEmailOthers