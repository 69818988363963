import React from 'react';
import {CircularProgress, Grid, Tooltip} from '@material-ui/core';
import useLoaderApi from "./useLoaderApi";
import {STATE} from "../libs/api";
import ErrorIcon from "@material-ui/icons/Error";

// Только дозагрузка данных через api
// TODO: update for-prod


const MyComponent = (props) => {
    const {column, row, item} = props;
    let [, apiName, guid] = item && typeof item == 'string'
            ? item.match(/\/api\/(.+)\/(.+)/) || [null, column.api, item]
            : [];

    const [itemShow, loadState] = useLoaderApi(apiName, guid)
    if (!guid) {
        if(column.freeSolo && row[column.freeSoloField]){
            return row[column.freeSoloField]
        }
        return '---'
    }

    switch (loadState) {
        case STATE.LOADED:
            return column.object
                    ? <column.object column={column} row={row} item={itemShow}/>
                    : 'no column.object';
        case STATE.ERROR:
            return <Tooltip title={itemShow}>
                <ErrorIcon title={itemShow} style={{color: "#e51c23"}}/>
            </Tooltip>;
        default:
            return <i><CircularProgress
                    title={`ApiObjectSelect load ${apiName} ${guid}`}
                    size={24}/></i>
    }
}


export default function ApiObjectSelect(props) {
    const itemList = Array.isArray(props.item) ? [...props.item] : [props.item]

    return <Grid container spacing={2}>
        {itemList.map(i => <Grid item ><MyComponent {...props} item={i}/></Grid>)}
    </Grid>
}

