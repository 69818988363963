import React from "react";
import ContentHelp from "./ContentHelp";
import {Box, Grid, Typography} from '@material-ui/core';

const PageHelp = (props) => {
    const [link, setLink] = React.useState('')
    const clickMarkDownLink = (link) => (e) => {
        e.preventDefault()
        console.log("push2", link)
        if(link.includes("http://") || link.includes("https://") ){
            window.open(link);
            return
        }
        setLink(link)
    }
    return (
            <>
                <h1>Справка</h1>
                <Grid container  spacing={2}>
                    <Grid xs={3}>
                        <Box className={"listPages"}>
                            <ContentHelp
                                    changeLink={setLink}
                                    link={''}
                                    activeLink={link}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={9}>
                        {
                            link === ''
                                    ? <Typography variant={'h6'}>Выберите раздел</Typography>
                                    : <ContentHelp
                                            changeLink={setLink}
                                            link={link}/>
                        }

                    </Grid>
                </Grid>

            </>
    )
}

export  default  PageHelp;