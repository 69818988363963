import moment from 'moment';
import * as PropTypes from 'prop-types';

function ShowDateTime(props) {
    const {value} = props;

    return moment(value).format('ll');
}

ShowDateTime.propTypes = {value: PropTypes.any};

export default ShowDateTime;