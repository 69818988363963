import React from 'react';
import {BankAccountIcon} from 'components/icons';
import {
    showBank,
    ShowCurrency,
} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import API from "libs/api";
import HOCExplainCols from 'components/HOCExplainCols';
import {FormDataContext} from 'FormDataContext';
import {SubFormEditCreate} from 'components/SubFormEditCreate';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import bankConnfig from 'config/bank'

const data_example = {
    "opf": {
        "type": "BANK_BRANCH",
        "full": null,
        "short": null
    },
    "name": {
        "payment": "КРАСНОДАРСКИЙ ФИЛИАЛ ПАО РОСБАНК",
        "full": null,
        "short": null
    },
    "bic": "040349744",
    "swift": null,
    "inn": "7730060164",
    "kpp": "231143001",
    "okpo": null,
    "correspondent_account": "30101810403490000744",
    "treasury_accounts": null,
    "registration_number": "2272/87",
    "payment_city": "г Краснодар",
    "state": {
        "status": "ACTIVE",
        "code": null,
        "actuality_date": 1656288000000,
        "registration_date": null,
        "liquidation_date": null
    },
    "rkc": null,
    "cbr": null,
    "address": {
        "value": "350049, г Краснодар, ул. Олимпийская, 8/ им. Тургенева, 135/1",
        "unrestricted_value": "350049, г Краснодар, ул. Олимпийская, 8/ им. Тургенева, 135/1",
        "data": {
            "postal_code": "350049",
            "country": "Россия",
            "country_iso_code": "RU",
            "federal_district": "Южный",
            "region_fias_id": "d00e1013-16bd-4c09-b3d5-3cb09fc54bd8",
            "region_kladr_id": "2300000000000",
            "region_iso_code": "RU-KDA",
            "region_with_type": "Краснодарский край",
            "region_type": "край",
            "region_type_full": "край",
            "region": "Краснодарский",
            "area_fias_id": null,
            "area_kladr_id": null,
            "area_with_type": null,
            "area_type": null,
            "area_type_full": null,
            "area": null,
            "city_fias_id": "7dfa745e-aa19-4688-b121-b655c11e482f",
            "city_kladr_id": "2300000100000",
            "city_with_type": "г Краснодар",
            "city_type": "г",
            "city_type_full": "город",
            "city": "Краснодар",
            "city_area": null,
            "city_district_fias_id": null,
            "city_district_kladr_id": null,
            "city_district_with_type": null,
            "city_district_type": null,
            "city_district_type_full": null,
            "city_district": null,
            "settlement_fias_id": null,
            "settlement_kladr_id": null,
            "settlement_with_type": null,
            "settlement_type": null,
            "settlement_type_full": null,
            "settlement": null,
            "street_fias_id": "ff730a7d-cd80-47df-b962-40f8b1f498c8",
            "street_kladr_id": "23000001000077900",
            "street_with_type": "ул им. Тургенева",
            "street_type": "ул",
            "street_type_full": "улица",
            "street": "им. Тургенева",
            "stead_fias_id": null,
            "stead_cadnum": null,
            "stead_type": null,
            "stead_type_full": null,
            "stead": null,
            "house_fias_id": null,
            "house_kladr_id": null,
            "house_cadnum": null,
            "house_type": "д",
            "house_type_full": "дом",
            "house": "135/1",
            "block_type": null,
            "block_type_full": null,
            "block": null,
            "entrance": null,
            "floor": null,
            "flat_fias_id": null,
            "flat_cadnum": null,
            "flat_type": null,
            "flat_type_full": null,
            "flat": null,
            "flat_area": "218.1",
            "square_meter_price": null,
            "flat_price": null,
            "postal_box": null,
            "fias_id": "ff730a7d-cd80-47df-b962-40f8b1f498c8",
            "fias_code": "23000001000000007790000",
            "fias_level": "7",
            "fias_actuality_state": "0",
            "kladr_id": "23000001000077900",
            "geoname_id": "542420",
            "capital_marker": "2",
            "okato": "03401000000",
            "oktmo": "03701000001",
            "tax_office": "2311",
            "tax_office_legal": "2311",
            "timezone": "UTC+3",
            "geo_lat": "45.0560163",
            "geo_lon": "38.9605011",
            "beltway_hit": null,
            "beltway_distance": null,
            "metro": null,
            "divisions": null,
            "qc_geo": "0",
            "qc_complete": "9",
            "qc_house": "10",
            "history_values": null,
            "unparsed_parts": "ОЛИМПИЙСКАЯ, 8",
            "source": "350049, г Краснодар, ул. Олимпийская, 8/ им. Тургенева, 135/1",
            "qc": "1"
        }
    },
    "phones": null
}

const bankCols = bankConnfig.cols

const Custom = ({ values }) => {
    const { handleChange, deleteNodeState } = React.useContext(FormDataContext);
    const { modal, bic } = values

    const [windowOpen, setwindowOpen] = React.useState(false);

    const onAdd = (values, action) => {
        const api = new API();
        setwindowOpen(false)
        return api.post('bank_add', {
            name: values.name,
            bik: values.bik,
            corrAccount: values.corrAccount || '',
            isCustom: true,
        }).then(response => {
            handleChange({ field: 'bank' })(null, response.body.guid)
        });

    }

    const closeModal = () => {
        setwindowOpen(false);
        deleteNodeState('modal');
        deleteNodeState('bic');
    }



    React.useEffect(() => {
        setwindowOpen(modal)
    }, [modal])

    const DialogEditCreate = HOCExplainCols({ from: 'bank_accounts' })(DialogEditCreate3);



    return (
        <>
            <DialogEditCreate
                title={'Форма для добавления нового банка'}
                dbId={'guid'}
                open={windowOpen}
                handleSubmit={onAdd}
                handleClose={closeModal}
                // onSuccess={}
                windowUsersetting={true}
                // initialValues={{...initialValues, ...item, ...props.filter}}
                FormComponent={SubFormEditCreate}
                // cols={[]}
                cols={bankCols}
                initialValues={{ bik: bic }}
            />
        </>
    )
}

const mapSuggestions = fieldName => (data, oldData,{setState, suggestion}) => {
    console.log('mapSuggestions',{fieldName,data, oldData, suggestion})
    if (data.hasOwnProperty('useCustom')) {
        return {
            ...oldData,
            bank: null,
            [fieldName]: data.country,
            modal : true,
        }
    }
    const api = new API();
    api.post('bank_add', {
        name:        data.name.payment,
        bik:         data.bic,
        corrAccount: data.correspondent_account,
        isCustom : false,
    }).then(response => {
        console.log('mapSuggestions result', response.body.guid)
        setState(state => {
            console.log('mapSuggestions setState', state, {
                ...state,
                bank: response.body.guid,
            })
            return {
                ...state,
                values: {
                    ...state.values,
                    bank: response.body.guid,
                }
            }
        })
    });
    return {
        ...oldData,
        [fieldName]: data.bic
    };
}

export default {
    listTitle: 'Банковские счета',
    apiName:   'bank_accounts',
    itemName:  'Банковский счет',
    canDelete: true,
    // filterParent: 'parentGuid',
    // filterParent: parent => (
    //     {
    //         'exists[legal.contragent]': parent.id === 'carrier',
    //     }
    // ),
    breads:    [
        {
            apiName: 'legals',
            field:   'parentGuid',
        },
    ],
    menu: {
        icon: BankAccountIcon,
    },
   
    cols: (props, item) => {

        return [
            {
                title: 'Основной',
                field: 'mainAccount',
                format: FieldFormat.FORMAT_CHECKBOX,
                hidden: true,
            },
            {
                title: 'Наименование',
                field: 'name',
                format: FieldFormat.FORMAT_INPUT
            },
            {
                title: 'Номер счета',
                field: 'accountNumber',
                format: FieldFormat.FORMAT_INPUT
            },
            {
                field: 'currency',
                title: 'Валюта',
                object: ShowCurrency,
                format: FieldFormat.FORMAT_SELECT_API,
                api: 'currencies',
                hidden: { xsDown: true },
            },
            {
                title: 'Бик Банка',
                field: 'bic',
                format: FieldFormat.FORMAT_SELECT_DADATA,
                mapSuggestions: mapSuggestions('bic'),
                helperText:    'Для поиска банка',
                placeholder:    'Для поиска банка',
                api:            'search/bik',
                hidden: true,
            },
            // {
            //     field:          'name',
            //     title:          'Название',
            //     required:       'true',
            //     mapSuggestions: mapSuggestions('name'),
            //     format:         FieldFormat.FORMAT_SELECT_DADATA,
            //     freeSoloField:  'custom_name',
            //     helperText:     'Название организации',
            //     api:            'search/inn',
            //     cols:           2,
            // },
            {
                field: 'bank',
                title: 'Банк',
                object: showBank,
                format: FieldFormat.FORMAT_SELECT_API,
                api: 'banks',
                hidden: { smDown: true },
            },
            {
                field: 'modal',
                format:   FieldFormat.FORMAT_CUSTOM,
                hidden: true,
                custom: Custom,
            },
        ];
    }
    };