import React from 'react';
import useExplainCols from "components/useExplainCols";

// HOCExplainCols
/**
 * @deprecated Переходим на использование useExplainCols внутри контекста
 * @param info
 * @returns {function(*): function(*=): (null|*)}
 */
export default (info = {}) => WrappedComponent => props => {
    const {cols, item} = props;
    console.log('props explainCols refreshItem', props);
    const columns = useExplainCols(cols, props, item, info, null);
    if(!columns){
        // wait until promise resolve
        return null;
    }
    return <WrappedComponent {...props} cols={columns}/>;
};


// export default HOCExplainCols;
