// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import store from 'reducers/store';

import MeLoader from 'MeLoader';

ReactDOM.render(
    <Provider store={store}>
        <MeLoader />
    </Provider>
    ,
    document.getElementById('root'),
);
