import React from "react";
import {Grid} from "@material-ui/core";
import FormColsBlock from "./FormColsBlock";
import {RequestFormErrors} from "./RequestFormDebug";
import {FormDataContext} from "../FormDataContext";
import ErrorBoundary from "./ErrorBoundary";

const FormFieldsShow = (props) => {
    const {topColsBlocks, parent} = props;
    const {cols} = React.useContext(FormDataContext);
    return <React.Fragment>
        {props.inTab && <br/>}
        <Grid container spacing={2}>
            {topColsBlocks
                .map((block, blockKey) => (
                        <ErrorBoundary key={blockKey} info={'FormColsBlock'}>
                            <FormColsBlock
                                parent={parent}
                                num={blockKey + 1}
                                cols={topColsBlocks.length}
                                {...block}
                            /></ErrorBoundary>
                    )
                )}
            <RequestFormErrors cols={cols}/>
        </Grid>
    </React.Fragment>;
};

export default FormFieldsShow