import CircularProgress from '@material-ui/core/CircularProgress';
import AdminShow from 'components/FieldFormat/AdminShow';
import {EditIcon} from 'components/icons';
import {config} from 'config';
import {STATE} from 'libs/api';

import MaterialTable, {MTableToolbar, MTableEditRow} from 'material-table';
import React from 'react';
import request from 'superagent';
import HOCExplainCols from 'components/HOCExplainCols';
import HOCLoadItem from 'components/HOCLoadItem';
import {RequestFormDebugContext} from 'components/RequestFormDebug';
import {FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import {transformColsNoContext} from 'components/MTableExtend/transformCols';
import MTEditRowContext from "./MTableExtend/MTEditRowContext";

// export default

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function SubFormEditCreateTable(props) {
    const {
              listTitle,
              canEdit,
              onEdit,
              values,
              onChange,
              item,
              itemValues,
              cols,
              loadState,
              loaded

          }                = props;
    const {api, apiFilter} = item;
    console.log('SubFormEditCreateTable props', props);
    console.log('SubFormEditCreateTable loaded2', loaded);

    React.useEffect(() => {
        console.log('SubFormEditCreateTable loaded', {loaded, values, loadState});

        // infinite loop
        if(loadState === STATE.LOADED) {
            console.log('SubFormEditCreateTable loaded onChange', {loaded,values});
            onChange && onChange(null, loaded);
        }
    },[loadState]);
    const onFormDataAction = values => {
        console.log('SubFormEditCreateTable onFormDataAction ', values)
        return values
    }


    // const [loadState, setState] = React.useState(STATE.INIT);
    const query = apiFilter(itemValues);

    switch (loadState) {
        case STATE.INIT:
        case STATE.LOADING:
            return <>{JSON.stringify(values)}<CircularProgress size={24}/></>;
        case STATE.LOADED:
            break;
        case STATE.ERROR:
        default:
            return <React.Fragment>Error load data</React.Fragment>;
    }
    // if (!cols.length) {
    //     return <CircularProgress size={24}/>;
    // }
    return <React.Fragment>
        <AdminShow>[SubFormEditCreateTable]</AdminShow>
        <FormDataContextProvider initial={values} onFormDataAction={onFormDataAction} debug={'SubFormEditCreateTable'}>
        <MaterialTable
            title={item.listTitle ? item.listTitle : listTitle}
            columns={transformColsNoContext(cols, {onEdit})}
            data={values && values.length ? values.filter(item => !item.isDeleted) : []}
            actions={[
                // canEdit && {
                //     icon:    EditIcon,
                //     tooltip: 'Редактировать',
                //     onClick: (event, rowData) => onEdit(rowData)(),
                // },
            ]}
            {...config.MaterialTable}
            // components={{
            //     Cell: MyMTableCell,
            // }}
            options={{
                ...config.MaterialTable.options,
                showTitle:              item.listTitle ? true : false,
                search:                 false,
                paging:                 false,
                toolbarButtonAlignment: 'left',
            }}
            components={{
                // Toolbar: props => (
                //     <div>
                //         <MTableToolbar {...props} />
                //     </div>
                // ),
                EditRow: MTEditRowContext,
            }}
            editable={{
                onRowAdd:    newData =>
                                 new Promise((resolve) => {
                                     setTimeout(() => {
                                         {
                                             const data = [...values];
                                             data.push(newData);
                                             console.log('newData', newData, data);
                                             onChange(null, data, () => resolve());
                                         }
                                         resolve();
                                     }, 0);
                                 }),
                onRowUpdate: (newData, oldData) =>
                                 new Promise((resolve) => {
                                     const data = [...values];
                                     console.log('newData', newData, data);
                                     const index = data.indexOf(oldData);
                                     data[index] = newData;
                                     onChange(null, data);
                                     console.log('newData', newData, data);
                                     setTimeout(() => {
                                         resolve();
                                     }, 0);
                                 }),
                onRowDelete: oldData =>
                                 new Promise((resolve) => {
                                     setTimeout(() => {
                                         {
                                             let data    = [...values];
                                             const index = data.indexOf(oldData);
                                             data[index].isDeleted = true
                                             onChange(null, data);
                                             resolve();
                                             // this.setState({ data }, () => resolve());
                                         }
                                         resolve();
                                     }, 1000);
                                 }),
            }}
        />  
        <RequestFormDebugContext/>
        </FormDataContextProvider>
    </React.Fragment>;
}

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SubFormEditCreateTableLoader(props) {
    const {
              listTitle,
              canEdit,
              onEdit,
              values,
              onChange,
              item,
              itemValues,
              cols,
              loadState,
              loaded,


          }                = props;
    const {api, apiFilter} = item;
    console.log('SubFormEditCreateTable props', props);

    const query = apiFilter(itemValues);

    const loadProps = {
        multiple: true,
        path:  `/api/${api}`,
        field: 'loaded',
        query,
        loaded
    };
    const SubFormEditCreateTableWrapped = values
        ? SubFormEditCreateTable
        : HOCLoadItem(loadProps)(SubFormEditCreateTable);

    return <SubFormEditCreateTableWrapped loaded={values} loadState={STATE.LOADED} {...props} />;

}

/**
 * @deprecated
 */
export default HOCExplainCols({from: 'subFormEditCreateTable'})(
    SubFormEditCreateTableLoader,
);
