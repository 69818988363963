import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import API from "libs/api";
const request = new API();

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        console.log('getDerivedStateFromError', JSON.stringify(error));
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        request.post('ErrorBoundary', {
            message:        error.message,
            componentStack: errorInfo.componentStack
        })
        console.log('componentDidCatch', error, errorInfo);
    }

    render() {
        if (this.state.hasError || !this.props.children) {
            // Можно отрендерить запасной UI произвольного вида
            const add = !this.props.children
                        ? ':noChildren'
                        : null;

            switch(this.props.custom){
                //Стилизация вывода ошибки, нужен например для стилизации в таблице
                case 'span':
                    return <span>Упс {this.props.info} {add}</span>;
                default:
                    return <h5>Упс {this.props.info} {add}</h5>;
            }
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    /**
     * Error title
     */
    info: PropTypes.string.isRequired,
};

export default ErrorBoundary;