import {FormControl, FormGroup, FormLabel} from '@material-ui/core';
import AdminShow from 'components/FieldFormat/AdminShow';
import FormRequestSubApiLoader from 'components/FormRequestSubApiLoader';
import RequestForm from 'components/RequestForm';
import {SubFormEditCreate} from 'components/SubFormEditCreate';
import {config} from 'config';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import React from 'react';
import useLoaderApi from "../useLoaderApi";
import {asGUID} from "../../libs/asGUID";
import * as PropTypes from "prop-types";
import {STATE} from "../../libs/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import ErrorBoundary from "../ErrorBoundary";


function LoadStateShow(props) {
    const {state, children, error} = props
    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress/>;
        case STATE.LOADED:
            return <ErrorBoundary info={'LoadStateShow:children'}>{children}</ErrorBoundary>;
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {error}</Alert>;
    }
}

LoadStateShow.propTypes = {
    loadState: PropTypes.any,
    error:     PropTypes.node,
    children:  PropTypes.node
};

function SubForm(props) {
    const {item, subValues, handleChange, onFormDataAction} = props;
    const {title, api} = item;

    const LoadedData   = props => {
        const {values, apiName} = props;
        if (apiName) {
            handleChange(null, values);
        }
        const subItem = config.get(item.subConfig);
        const {cols, onFormDataAction} = subItem;

        // TODO: validate conflict
        return <React.Fragment>
            <AdminShow>[FormDataContextProvider]</AdminShow>
            <FormDataContextProvider
                initial={values}
                cols={cols}
                onFormDataAction={onFormDataAction}
                item={item}
                subForm={item}
                debug={'SubForm'}
            >
                <FormDataContextConsumer>{context => {
                    // console.log('handleChange subValues FormDataContextConsumer', context);
                    if (JSON.stringify(values) !== JSON.stringify(context.state.values)) {
                        // handleChange(null, context.state.values);
                    }
                }}</FormDataContextConsumer>
                <SubFormEditCreate
                    dbId={'guid'}
                    // itemName={'itemName'}
                    open={true}
                    subForm={item}
                    // returnOnly={true}
                    // handleSubmit={handleReadOnlySubmit}
                    // handleClose={() =>setOpenDialog(false)}
                    // onChange={onChange}
                    // subFormHandleChange={handleChange(item)}
                    // subFormHandleChange={subFormHandleChange}
                    // onSuccess={() => {}}
                    // values={values[field]}
                    // initialValues={initialValues}
                    {...config.get(item.subConfig)}
                    FormComponent={RequestForm}
                />
            </FormDataContextProvider>
        </React.Fragment>
            ;

    };

    let itemProps = typeof subValues == 'string'
        ? {
            AfterComponent: LoadedData,
            apiFilter:      () => {
            },
            item:           {
                apiName: api,
                guid:    subValues && subValues.substr(-36),
            },
        }
        : null;

    // hack: Не загружать данные, если они уже есть вложенным объектом, а не guid
    const guid = typeof subValues === 'string'
        ? asGUID(subValues)
        : null;

    const opts = typeof subValues === 'string'
        ? {}
        : {initial: subValues};

    const [loadedValues, loadState] = useLoaderApi(api, guid,{},opts);

    return <React.Fragment>
        <AdminShow>[SubForm]</AdminShow>
        <FormControl component="fieldset" variant={'outlined'} fullWidth>
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                <LoadStateShow state={loadState} error={loadedValues}>
                    <LoadedData values={loadedValues}/>
                </LoadStateShow>
            </FormGroup>
        </FormControl>
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const SubFormMemo = React.memo(SubForm, areEqual);

function SubFormMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const {onFormDataAction} = {
        onFormDataAction: (v, p) => v,
        ...config.get(props.item.subConfig),
    };

    const handleChangeLocal = (e, values) => {

        if (values) {
            return handleChange(props.item)(e, onFormDataAction(values, {action: 'subform'}));
        } else {
            return handleChange(props.item)(e, values);
        }
    };

    const subValues = values && values[field];
    const error     = errors && errors[field];
    // return 'TODO: Subform'
    return <SubFormMemo
        values={values}
        subValues={subValues || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        onFormDataAction={onFormDataAction}
        {...props}
    />;
}

export default SubFormMemoUse;