import React from "react";
import {config} from 'config';
import PageApiLoader from "components/PageApiLoader3";

export default function PriceRequest(props) {
    return <PageApiLoader
        {...props}
        {...config.priceRequest}
        canEdit={true}
        canAdd={true}
    />
}