import React from 'react';
import AdminShow from 'components/FieldFormat/AdminShow';
import {RequestFormDebugContext} from 'components/RequestFormDebug';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import {MTableEditRow} from 'material-table';
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import * as PropTypes from "prop-types";
import {RenderList} from "../../config/RenderList";
import {FieldFormat} from "../../config/FieldFormat";
import ErrorBoundary from "../ErrorBoundary";
import Typography from "@material-ui/core/Typography";
import {InputPropsDefault} from "./../EndAdornmentComponent"

window.maxNum = 0;

function RenderComp(props) {
    // const {format,item} = props;
    const {cols, format, parent, tracePath, ...item} = props;
    const Render = RenderList(format);
    const {handleChange} = React.useContext(FormDataContext);
    // if (format === FieldFormat.FORMAT_NONE || type) {
    //     // Временно исключить новый рендер для того где указано lookup и type
    //     return null;
    // }

    // const RenderComponent = (props) => {
    //     const {columnDef, error, helperText, rowData, value, onChange} = props;
    // console.log('handleChange detectEditComponent', value, {props});
    const onRowChange = rowData => rowData; // TODO: get from props
    return <ErrorBoundary info={'materialTable.render_v2:' + format}>
        <Render
            item={item}
            // submitting={submitting}
            parent={props}
            variant={'standard'}
            InputPropsDefault={InputPropsDefault(item)}
            // values={rowData}
        />
    </ErrorBoundary>;
    // };


}

RenderComp.propTypes = {};

function TableCellRowActions(props) {
    const {mode, icons, options, components, size, localization, columns, onEditingApproved} = props;
    const {item, values} = React.useContext(FormDataContext)


    const isValid = columns.every((column) => {
        if (column.validate) {
            const response = column.validate(values);
            switch (typeof response) {
                case "object":
                    return response.isValid;
                case "string":
                    return response.length === 0;
                case "boolean":
                    return response;
            }
        } else {
            return true;
        }
    });

    // clone from m-table-edit-row
    const  handleSave = (event, data) => {
        console.log('handleSave clicked', onEditingApproved, {data,values})
        onEditingApproved(
            mode,
            data,
            values
        );
    };


    const actions = [
        {
            icon:     icons.Check,
            tooltip:  localization.saveTooltip,
            disabled: !isValid,
            onClick:  handleSave,
        },
        mode === 'delete' && {
            icon:    props.icons.Clear,
            tooltip: localization.cancelTooltip,
            onClick: () => {
                props.onEditingCanceled(props.mode, props.data);
            },
        },
    ].filter(v=>v);

    return <TableCell
        size={size}
        padding="none"
        key="key-actions-column"
        style={{
            width:   42 * actions.length,
            padding: "0px 5px",
            ...options.editCellStyle,
        }}
    >
        <div style={{display: "flex"}}>
            <components.Actions
                data={values}
                actions={actions}
                components={components}
                size={size}
            />
        </div>
    </TableCell>;
}

TableCellRowActions.propTypes = {size: PropTypes.any};
export default function MTEditRowContext(props) {
    // return props => {
    console.log('MTEditRowContext props', props)
    const {data, columns, debug} = props;
    const {item, values} = React.useContext(FormDataContext)
    const index = data && data.tableData &&  data.tableData.hasOwnProperty('id')
        ? data.tableData.id
        : values.length;
    const initialValues = item.initialValues || (() => ({_debug_default_initialValues : true}))
    // if(typeof data === 'undefined'){
    //
    // }
    console.log('MTEditRowContext initialValues', initialValues, initialValues())

    console.log('MTEditRowContext', props);
    // const item = {}; // todo: add object from top and menuRoot
    // onEditingApproved: ƒ (mode, newData, oldData)


    // clone from m-table-edit-row

    const localization = {
        ...MTableEditRow.defaultProps.localization,
        ...props.localization,
    };

    const elementSize = (props) =>
        props.options.padding === "default" ? "medium" : "small";

    const size = elementSize(props);

    // end clone from m-table-edit-row

    React.useEffect(()=>{
        if(props.mode === 'add'){
            let initial = initialValues();
            // hack: временное решение для добавления строки сразу
            props.onEditingApproved(props.mode, initial, initial);
        }
    },[])

    return <ErrorBoundary info={'MTEditRowContext'}>
        <FormDataContextProvider
            // initial={data || initialValues && initialValues({item})}
            cols={columns}
            initial={values[index] || initialValues()}
            item={{field: index}}
            onFormDataAction={item.onFormDataAction}
            debug={'MTEditRowContext'}
            hideDebug={true}
        >
            <AdminShow>[Context:{debug}]</AdminShow>
            <TableRow>
                <TableCell />
                <ErrorBoundary info={'MTEditRowContext:TableCellRowActions'}>
                <TableCellRowActions
                    onEditingApproved={props.onEditingApproved}
                    onEditingCanceled={props.onEditingCanceled}
                    columns={columns}
                    components={props.components}
                    size={size}
                    localization={localization}
                    options={props.options}
                    icons={props.icons}
                    mode={props.mode}
                />
                </ErrorBoundary>
                <ErrorBoundary info={'MTEditRowContext:TableCellss'}>
                { props.mode === 'delete' ?
                <TableCell
                    size={size}
                    padding={
                        props.options.actionsColumnIndex === 0 ? "none" : undefined
                    }
                    key="key-edit-cell"
                    colSpan={columns.length}
                >
                    <Typography variant="h6">{localization.deleteText}</Typography>
                </TableCell>
                : columns.filter(i => !i.hidden).map((i, key) => <TableCell key={key}>
                    <RenderComp {...i}/>
                </TableCell>)}
                </ErrorBoundary>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2+columns.length}><RequestFormDebugContext/></TableCell>
            </TableRow>
        </FormDataContextProvider>
    </ErrorBoundary>;
    // };
}
