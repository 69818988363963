import React from "react";

import {
    Box,
    Grid,
    Button,
    Typography,
    TextField,
    CircularProgress,
    Chip
} from '@material-ui/core';
import useLoaderApi, {useLoaderApiList} from 'components/useLoaderApi';
import {asGUID} from 'libs/asGUID';
import LoadStateShow from 'components/LoadStateShow.jsx'
import {FormDataContext} from 'FormDataContext';
import MaterialTable from 'material-table';
import {
    Email as DomainVerification, Email,

} from '@material-ui/icons';
import request from 'superagent';
import {config} from "../config";

export const ModalWindowSendDocumentEmail = ({getParameters, onClose, realLink, onSend}) => {

    const [emails, setEmails] = React.useState([]);
    const {state: {values, errors}} = React.useContext(FormDataContext);
    // const newLink = realLink.replace('.email', '.xlsx')
    // const onSend = () => {
    //     const link = `${newLink}&emails=${emails.join()}`
    //     request.get(link)
    //         .set('accept', 'application/json')
    //         .query({})
    //         .then(result => {})
    //         .catch(err => {})
    //     setEmails([])
    //     onClose()
    // }
    // const [data, loadState] = useLoaderApi(`carrier_contacts_invites`, values.carrier, {})
    const [data, loadState] = useLoaderApi(`carrier_contacts_invites`, values.carrier,  {},{initial: []})

    const selectRows = rows => setEmails(rows.map(item => item.info))
    // const selectRows = (rows) => {
    //     const result = [];
    //     rows.map(item => {
    //         result.push(item.info)
    //     })
    //     setEmails(result)
    // }


    return (
        <LoadStateShow state={loadState} error="Произошла ошибка при загрузке контактов">
            <Box mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!emails.length}
                    startIcon={<DomainVerification/>}
                    onClick={onSend(emails)}
                >
                    Отправить документ
                </Button>

            </Box>

            <MaterialTable
                    {...config.MaterialTable}
                title="Список Email"

                columns={[
                    {
                        title:  'Имя',
                        field:  'name',
                        render: row => row.name ? row.name : "---"
                    },
                    {
                        title:  'Должность',
                        field:  'workPosition',
                        render: row => row.work_position ? row.work_position : '---',
                    },
                    {
                        title: 'Email',
                        field: 'info'
                    },

                ]}
                style={{minWidth: "700px"}}
                data={data}
                options={{
                    selection:              true,
                    selectionProps:         rowData => ({
                        color: 'primary',
                    }),
                    toolbarButtonAlignment: 'left',
                    padding:                'dense',
                    emptyRowsWhenPaging:    false,
                    pageSize:               200,
                    pageSizeOptions:        [{value: data.length > 0 ? data.length : 1, label: 'Все'}],
                    headerStyle:            {
                        backgroundColor: '#EEE',
                    }
                }}
                onSelectionChange={selectRows}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Поиск...',
                        nRowsSelected:     'Выбрано контактов: {0}'
                    },
                }}
            />


        </LoadStateShow>
    )
}