import AdminShow from 'components/FieldFormat/AdminShow';
import FormApiAutocomplete from 'components/FieldFormat/FormApiAutocomplete';
import {FormDataContext} from 'FormDataContext';
import {apiSplit} from 'libs/apiSplit';
import React from 'react';
import FormApiAutocompleteOther from "./FormApiAutocompleteOther";

function MyComponent({item, value, error, handleChange, apiFilter}) {
    const {title, api, required } = item;
    
    // const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    return <React.Fragment>
        <AdminShow>[SelectApiIndex]</AdminShow>
        <FormApiAutocompleteOther
            item={item}
            label={title}
            value={value}
            api={api}
            apiFilter={apiFilter}
            required={required}
            error={!!error}
            onChange={handleChange}
            InputProps={{color: 'secondary'}}
            InputLabelProps={{shrink: true}}
        />
    </React.Fragment>;
}


function areEqual(prevProps, nextProps) {
    return false;
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error
        && JSON.stringify(prevProps.apiFilter) === JSON.stringify(nextProps.apiFilter);
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {parent,item}                                = props;
    const {field, apiFilter}                      = item;
    const {state: {values, errors}, handleChange, setState} = React.useContext(FormDataContext);
    
    
    const handleChangeLocal = (newValues) => {
        setState(({values}) => {
            let valuesOld    = values[item.field] || [];
            valuesOld[item.fieldIndex] = apiSplit(newValues.target.value);
            handleChange(item)(null, valuesOld);
        })
    };
    
    // const handleChangeLocal = (e) => {
    //     handleChangeIndex(item, item.fieldIndex)
    //     return handleChange(props.item)(e, e.target.value);
    // };
    
    const apiFilterLocal = apiFilter && apiFilter(values, parent);
    const value          = values && values[field] && values[field][item.fieldIndex];
    const error          = values && errors && errors[field];
    
    // console.log('apiFilterLocal',apiFilterLocal);
    
    return <MyComponentMemo
        value={value || ''}
        error={error || null}
        apiFilter={apiFilterLocal}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;