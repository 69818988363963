import Chip from '@material-ui/core/Chip';
import ErrorBoundary from 'components/ErrorBoundary';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FieldFormat} from 'config/FieldFormat';
import {RenderList} from 'config/RenderList';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import React from 'react';
import {RequestFormDebugContext} from '../RequestFormDebug';
import {ApiObjectLoadForMTable} from '../showItems';

export const detectRender = ({col, num, onEdit, TitleIcon}) => rowData => {
    if (col.render) {
        // disable for field with lookup
        return col.render(rowData);
    }
    if (col.lookup) {
        // disable for field with lookup
        return undefined;
    }
    const handleClick = onEdit && num === 0
                        // ? () => onEdit(rowData)()
                        ? null
                        : null;
    if (col.api && col.object) {
        return <div onClick={handleClick}>
            {ApiObjectLoadForMTable(col.api, col.object, {
                item:   rowData[col.field],
                row:    rowData,
                column: col.field,
                multiple: col.multiple,
                col,
            })}
        </div>;
    } else if (col.object) {
        return <div>
            <col.object
                item={rowData[col.field]}
                row={rowData}
                column={col.field}
                col={col}
                onClick={handleClick}
            />
        </div>;

    } else {
        let value = rowData[col.field];
        if ('object' === typeof value) {
            return JSON.stringify(value);
        }

        return num === 0
            ? <Chip variant={'outlined'}
                    size="small"
                    avatar={TitleIcon && <TitleIcon/>}
                    label={rowData[col.field]}
                    onClick={handleClick}/>
            : <div>{rowData[col.field]}</div>;
    }
};
// TODO: refactor detectEditComponent, use HOC
/**
 * @deprecated
 * @param item
 * @param num
 * @returns {React.NamedExoticComponent<object>|null|*}
 */
function detectEditComponent(item, num) {
    console.log('detectEditComponent call')
    const {format, editComponent, field, lookup, freeSoloField, type} = {
        format: FieldFormat.FORMAT_INPUT,
        ...item,
    };
    if (editComponent) {
        console.error('Old component style editComponent for ' + item.field, item.title);
        return editComponent;
    }
    const Render = RenderList(format);
    if (format === FieldFormat.FORMAT_NONE || type) {
        // Временно исключить новый рендер для того где указано lookup и type
        return null;
    }

    const RenderComponent =  (props) => {
        const {columnDef, error, helperText, rowData, value, onChange} = props;
        console.log('handleChange detectEditComponent',value, {props})
        const onRowChange                                              = rowData => rowData; // TODO: get from props
        return <ErrorBoundary info={'materialTable.render:' + format}>
            <AdminShow>[MTContext]</AdminShow>
            <FormDataContextProvider initial={{
                [field]: value,
                [freeSoloField]: rowData[freeSoloField]
            }}
                                     debug={'transformCols'}
            >
                <FormDataContextConsumer>{context => {
                    const {_items, ...valuesAdd} = context.state.values;
                    console.log('detectEditComponent handleChange subValues FormDataContextConsumer', {
                        field,
                        freeSoloField,
                        valuesAdd,
                        context,
                        value,
                        props,
                        v1: JSON.stringify(valuesAdd[freeSoloField]),
                        v2: JSON.stringify(rowData[freeSoloField])
                    });
                    if (
                        JSON.stringify(valuesAdd[freeSoloField]) !== JSON.stringify(rowData[freeSoloField])
                    ) {
                        console.log('handleChange onRowDataChange freeSoloField', {
                            ...rowData,
                            ...valuesAdd,
                        });
                        props.onRowDataChange(onRowChange({
                            ...rowData,
                            ...valuesAdd,
                        }, {field: freeSoloField, value: valuesAdd[freeSoloField]}));
                    } else if (
                        JSON.stringify(value) !== JSON.stringify(context.state.values[field])
                    ) {
                        console.log('handleChange onRowDataChange FormDataContextConsumer', {
                            field,
                            freeSoloField,
                            valuesAdd,
                            context,
                            value,
                            props,
                        });
                        // onChange(context.state.values[field]);
                        props.onRowDataChange(onRowChange({
                            ...rowData,
                            ...valuesAdd,
                        }, {field,value}));
                    }
                }}</FormDataContextConsumer>
                <Render
                    item={item}
                    // submitting={submitting}
                    parent={props}
                    variant={'standard'}
                    values={rowData}
                />
                <RequestFormDebugContext debug={'detectEditComponent'}/>
            </FormDataContextProvider>
        </ErrorBoundary>;
    };

    function areEqual(prevProps, nextProps) {

        console.log('detectEditComponent areEqual ' + prevProps.rowData.field, prevProps, nextProps);
        return prevProps.value === nextProps.value
            && prevProps.error === nextProps.error
            && (!freeSoloField || prevProps.rowData[freeSoloField] === nextProps.rowData[freeSoloField])
            ;
    }

    return React.memo(RenderComponent, areEqual);
}

/**
 * TODO: Временное решение. для объединения с detectEditComponent
 * @deprecated
 * @param item
 * @param num
 * @returns {React.NamedExoticComponent<object>|null|*}
 */
function detectEditComponentNoContext(item, num) {
    const {format, editComponent, field, lookup, freeSoloField, type} = {
        format: FieldFormat.FORMAT_INPUT,
        ...item,
    };
    if (editComponent) {
        console.error('Old component style editComponent for ' + item.field, item.title);
        return editComponent;
    }
    const Render = RenderList(format);
    if (format === FieldFormat.FORMAT_NONE || type) {
        // Временно исключить новый рендер для того где указано lookup и type
        return null;
    }

    const RenderComponent = (props) => {
        const {columnDef, error, helperText, rowData, value, onChange} = props;
        console.log('handleChange detectEditComponent', value, {props});
        const onRowChange = rowData => rowData; // TODO: get from props
        return <ErrorBoundary info={'materialTable.render_v2:' + format}>
            <Render
                item={item}
                // submitting={submitting}
                parent={props}
                variant={'standard'}
                values={rowData}
            />
        </ErrorBoundary>;
    };

    function areEqual(prevProps, nextProps) {

        console.log('detectEditComponentNoContext areEqual ' + prevProps.rowData.field, prevProps, nextProps);
        return prevProps.value === nextProps.value
            && prevProps.error === nextProps.error
            && (!freeSoloField || prevProps.rowData[freeSoloField] === nextProps.rowData[freeSoloField])
            ;
    }

    return React.memo(RenderComponent, areEqual);
}

/**
 * @deprecated переходим на работу через контекст
 * @param cols
 * @param onEdit
 * @param TitleIcon
 */
export const transformCols = (cols, {onEdit, TitleIcon}) => cols
    .filter(i => i.hidden !== true)
    .map((col, num) => {
        const render = detectRender({col, num, onEdit, TitleIcon});
        return {
            ...col,
            // title:  col.title,
            // field:  col.field,
            type:          col.type && col.type === 'float'
                               ? 'numeric'
                               : col.type,
            hidden:        col.hidden === true,
            editComponent: detectEditComponentNoContext(col, num),
            render: render,
            validate: rowData => {
                // todo: make validate
                // проблема в том что rowData не получается из редактирования

                console.log('validate', {rowData, col}, !col.required, !!rowData[col.field], !col.required || !!rowData[col.field])
                return true // disabled
                return !col.required || !!rowData[col.field]
            },
        }
    })
    .filter(i => !i.hidden)
    .filter(i => 'string' === typeof i.title);

/**
 * TODO: Временное решение. для объединения с transformCols
 * @param cols
 * @param onEdit
 * @param TitleIcon
 */
export const transformColsNoContext = (cols, {onEdit, TitleIcon}) => cols
    .filter(i => i.hidden !== true)
    .map((col, num) => {
        const render = detectRender({col, num, onEdit, TitleIcon});
        return {
            ...col,
            // title:  col.title,
            // field:  col.field,
            type:          col.type && col.type === 'float'
                               ? 'numeric'
                               : col.type,
            hidden:        col.hidden === true,
            editComponent: detectEditComponentNoContext(col, num),
            render: render,
        }
    })
    .filter(i => !i.hidden)
    .filter(i => i.showTitleObject || 'string' === typeof i.title);

