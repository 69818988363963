import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import {Close as CloseIcon} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const CheckAttachPrintedFormsDialogWindow = ({title, onConfirm, onCancel, text, show}) => {

    return (
            <Dialog open={show} onClose={onCancel}>
                <DialogTitle>
                    <Grid item container>
                        <Grid xs={11}>
                            {title}
                        </Grid>
                        <Grid xs={1}>
                            <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Нет
                    </Button>
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export  default  CheckAttachPrintedFormsDialogWindow