/**
 * @param props
 * @returns {{ident: *, guid: *}}
 */
export const apiSplit = function(props) {
    if (typeof props !== 'string') {
        console.log('no split', props);
        return props;
    }
    const match = props.match(/\/api\/(.+)\/(.+)/);
    if (!match) {
        return props;
    }
    let [, , guid] = match;
    return guid;
};
