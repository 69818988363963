import React from 'react';
import * as PropTypes from "prop-types";
import {CircularProgress} from "@material-ui/core";
import ErrorBoundary from "components/ErrorBoundary";
import {Alert} from "@material-ui/lab";

const {STATE} = require("libs/api");

function LoadStateShow(props) {
    const {state, children, error, small} = props
    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress style={small ? {width: "10px", height: "10px"} : null}/>;
        case STATE.LOADED:
            return <ErrorBoundary info={'LoadStateShow:children'}>{children || ''}</ErrorBoundary>;
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {error || 'undefined'}</Alert>;
    }
}

LoadStateShow.propTypes = {
    state:    PropTypes.any,
    error:    PropTypes.node,
    children: PropTypes.node
};

export default LoadStateShow;