export const SYSTEM = {
    REQUEST: "SYSTEM_REQUEST",
    RECEIVE: "SYSTEM_RECEIVE",
    REQUEST_ME: "SYSTEM_REQUEST_ME",
    RECEIVE_ME: "SYSTEM_RECEIVE_ME",

    REQUEST_MANAGERS: "SYSTEM_REQUEST_MANAGERS",
    RECEIVE_MANAGERS: "SYSTEM_RECEIVE_MANAGERS",

    REQUEST_CONTRACTOR_MANAGERS: "SYSTEM_REQUEST_CONTRACTOR_MANAGERS",
    RECEIVE_CONTRACTOR_MANAGERS: "SYSTEM_RECEIVE_CONTRACTOR_MANAGERS",

    REQUEST_USERS: "SYSTEM_REQUEST_USERS",
    RECEIVE_USERS: "SYSTEM_RECEIVE_USERS",

    REQUEST_ROLES: "SYSTEM_REQUEST_ROLES",
    RECEIVE_ROLES: "SYSTEM_RECEIVE_ROLES",

    REQUEST_NEW_COMMENTS: "SYSTEM_REQUEST_NEW_COMMENTS",
    RECEIVE_NEW_COMMENTS: "SYSTEM_RECEIVE_NEW_COMMENTS",
};

const initialState = {
    user: {
        isFetching: false,
        error: false,
    },
    managers: {
        items: [],
        total_count: 0,
        isFetching: false,
        error: false,
    },
    contractor_managers: {
        items: [],
        total_count: 0,
        isFetching: false,
        error: false,
    },
    users: {
        items: [],
        total_count: 0,
        isFetching: false,
        error: false,
    },
    roles: {
        items: [],
        isFetching: false,
        error: false,
    },
};

export default (state = initialState, action) => {
    const {type, payload, endPoint} = action;

    switch(type) {
        case SYSTEM.REQUEST_ME:
            return {
                ...state,
                user: {
                    ...state.user,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_ME:
            return {
                ...state,
                user: {
                    ...state.user,
                    isFetching: false,
                    ...payload,
                }
            };
        case SYSTEM.REQUEST_MANAGERS:
            return {
                ...state,
                managers: {
                    ...state.managers,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_MANAGERS:
            return {
                ...state,
                managers: {
                    ...state.managers,
                    isFetching: false,
                    ...payload,
                }
            };
        case SYSTEM.REQUEST_CONTRACTOR_MANAGERS:
            return {
                ...state,
                contractor_managers: {
                    ...state.contractor_managers,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_CONTRACTOR_MANAGERS:
            return {
                ...state,
                contractor_managers: {
                    ...state.contractor_managers,
                    isFetching: false,
                    ...payload,
                }
            };
        case SYSTEM.REQUEST_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    isFetching: false,
                    ...payload,
                }
            };
        case SYSTEM.REQUEST_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    isFetching: false,
                    ...payload,
                }
            };

        case SYSTEM.REQUEST_NEW_COMMENTS:
            return {
                ...state,
                newComments: {
                    ...state.newComments,
                    isFetching: true,
                }
            };
        case SYSTEM.RECEIVE_NEW_COMMENTS:
            return {
                ...state,
                newComments: {
                    ...state.newComments,
                    isFetching: false,
                    ...payload,
                }
            };
        case SYSTEM.REQUEST:
            return {
                ...state,
                [endPoint]: {
                    ...state[endPoint],
                    isFetching: true,
                    timestamp: Date.now(),
                    ...payload,
                },
            };
        case SYSTEM.RECEIVE:
            return {
                ...state,
                [endPoint]: {
                    ...state[endPoint],
                    error: undefined,
                    isFetching: false,
                    timestamp: Date.now(),
                    ...payload,
                }
            };
        default:
            return state;
    }
};