import {Box, Typography} from "@material-ui/core";
import {ObjectDetect} from "./ObjectDetect";
import React from "react";

const FinalList = ({listCols, values}) => {
    const listExceptionsField = [
        'newPassword',
        'checkPassword',
        'isForeign',
        'code'
    ]
    return (<>
                {
                    listCols(values)
                            .filter(el => el.format !== 'simple_text_box')
                            .filter(el => !listExceptionsField.includes(el.field))
                            .map(item => <Box py={1} display={"flex"} alignItems={"baseLine"}>
                                <Typography variant={"body2"}>{item.title}:</Typography>
                                <Box ml={1}>
                                    <ObjectDetect noneValue={'---'} rows={{[item.field]: values[item.field]}} field={item.field} item={{[item.field]: values[item.field]}} row={item}/>
                                </Box>
                            </Box>)
                }
            </>
    )
}
export default  FinalList