import React from "react";
import {FormDataContext} from "FormDataContext";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";

import {WarningOutlined} from "@material-ui/icons";
import asArray from "libs/asArray";
import ErrorBoundary from "components/ErrorBoundary";

export function ActionButtonRequired(props) {
    const {itemName} = props;
    const {
        state,
        cols,

        isRequired
    } = React.useContext(FormDataContext);
    const {submitting, arErrors} = state;

    const [show, setShow] = React.useState(false)

    const onConfirmDialog = () => setShow(true);
    const onConfirmDialogCancel = () => setShow(false);

    if (!isRequired || !isRequired.length) {
        return null;
        // return JSON.stringify({isRequired, arErrors});
    }

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="error"
                disabled={submitting}
                onClick={onConfirmDialog}
                startIcon={<WarningOutlined/>}
            >
                Заполните все поля
            </Button>
            {show && <Dialog open={true} onClose={onConfirmDialogCancel}>
                <DialogTitle onClose={onConfirmDialogCancel}>Не заполнены поля {itemName}</DialogTitle>
                <DialogContent dividers>
                    <ErrorBoundary info={'isRequired'}>
                        {asArray(isRequired)
                            .filter(({title, field}) => title || field)
                            .map(({title, field}, key) => (
                                <Typography key={key} gutterBottom>
                                    Не заполнено <b>{title || field}</b>
                                </Typography>
                            ))
                        }
                    </ErrorBoundary>
                    <ErrorBoundary info={'arErrors'}>
                        {Object.entries(arErrors).map(([entryField, entryErrors], key) => {
                            const col = cols.find(c => c.field === entryField) || {};
                            return <Typography key={key} gutterBottom>
                                Заполнено не верно <b>{col.title}</b>
                                <ul>{asArray(entryErrors)
                                    .map((er, key) => <li key={key}>{er}</li>)
                                }</ul>
                            </Typography>
                        })}
                    </ErrorBoundary>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmDialogCancel} color="primary" autoFocus>
                        OK
                    </Button>

                </DialogActions>
            </Dialog>}
        </>
    )
}