import {config} from 'config';
import React from "react";
import PageApiLoader3 from "components/PageApiLoader3";

export default function Page(props) {
    return <PageApiLoader3
        {...props}
        {...config.member}
        canEdit={true}
        canAdd={true}
    />
}