import React from 'react';
import {TextField} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';

export default function({submitting, item}) {
    const {field, title, required, rowsMax, rows, disabled} = {rows:1, rowsMax: 5, ...item};
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const handlerChangeLocal = (e) => {
        return handleChange(item)(e, e.target.value)

    }

    
    return <React.Fragment>
        <TextField
            multiline
            // rows={4}
            // rowsMax={6}
            disabled={disabled || submitting}
            label={title}
            value={values[field] || ''}
            required={required}
            margin={'dense'}
            rows={rows}
            rowsMax={rowsMax}
            fullWidth
            variant={'outlined'}
            name={field}
            onChange={handlerChangeLocal}
            error={!!errors[field]}
            helperText={errors[field]}
            
            InputLabelProps={{shrink: true}}
        />
    </React.Fragment>;
}