import React from "react";
import {FormDataContext} from "../FormDataContext";
import {ApiDataLoad} from "./ApiDataLoad";
import {Box, Button, Grid, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import FormFieldsShow from "./FormFieldsShow";
import RequestForm from "./RequestForm";
import ConfirmDialog from "./ConfirmDialog";
import FinalList from "./selfRegistrationFinalList";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {

        padding: "0 1rem"
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Учетные данные', 'Юридическое лицо', 'Контакты'];
};

const Body = ({handleSubmit, listCols, topColsBlocks, handlerSelfRegistration, listTitle}) => {
    const classes = useStyles();
    const {state, handleSubmitForm,  setStateValues, isRequired, checkField} = React.useContext(FormDataContext);

    const [activeStep, setActiveStep] = React.useState(state.values._step);
    const [modal, setModal] = React.useState(false);
    const steps = [...topColsBlocks]

    const handleNext = (event) => {
        if(activeStep === steps.length - 1){
            setModal(true)
            return
        }
        setStateValues(values => ({...values, _step: activeStep+1}))
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const sendEmail = (event) => {
        ApiDataLoad('/api/registration/sent_code', {email: state.values.email}, {method: "get"})
                .then(result => {
                    console.log("push2", result)
                })
                .catch(err => console.log(err))
        handleNext()

    }

    const handleBack = () => {
        if(activeStep ===0){
            return handlerSelfRegistration();
        }
        setStateValues(values => ({...values, _step: activeStep-1}))
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    };

    const handleReset = () => {
        setStateValues(values => ({...values, _step: 0}))
        setActiveStep(0);
    };

    const disabledButton = isRequired.length || checkField.length;

    const ButtonStep = () => {
        switch (activeStep){
            case 0:
                return (
                        <Button variant="contained" color="primary" onClick={sendEmail} disabled={disabledButton}>
                            {'Подтвердить почту'}
                        </Button>
                )
            case (steps.length - 1):
                return (
                        <Button variant="contained" color="primary" onClick={handleNext} disabled={disabledButton}>
                            {'Зарегистрироваться'}
                        </Button>
                )
            default:
                return  (
                        <Button variant="contained" color="primary" onClick={handleNext} disabled={disabledButton}>
                            {'Следующий шаг'}
                        </Button>
                )
        }
    }


    return (
            <Grid className={classes.root}>
                <Box display={"flex"} justifyContent={"center"}>
                    <Typography variant={"h5"}>
                        {listTitle}
                    </Typography>
                </Box>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, key) => (
                            <Step key={key}>
                                <StepLabel>{label.title}</StepLabel>
                            </Step>
                    ))}
                </Stepper>
                <Grid>
                    <Grid className={classes.instructions}>
                        <FormFieldsShow
                                topColsBlocks={topColsBlocks}
                                // dbId={'guid'}
                                // itemName={itemName}
                                open={true}
                                // handleSubmit={handleSubmit(apiName)}
                                // handleClose={openDialogEdit(false)}
                                // subApi={subApi}
                                // tab={sublist||'edit'}
                                // onSuccess={addChatMessage}
                                initialValues={{}}
                                // item={item}
                                FormComponent={RequestForm}  //??? TODO: test not need?
                        />
                    </Grid>
                    <Box display={"flex"} justifyContent="flex-end">
                        <Button
                                onClick={handleBack}
                                className={classes.backButton}
                        >
                            {activeStep === 0 ? 'Назад' : 'Предыдущий шаг'}
                        </Button>
                        <ButtonStep/>
                    </Box>
                </Grid>
                <ConfirmDialog
                        show={modal}
                        title={'Зарегистрироваться на Портале?'}
                        onConfirm={(event) => {
                            setModal(false)
                            handleSubmitForm(event, state.values)
                        }}
                        custemDialogContent={<FinalList values={state.values} listCols={listCols}/> }
                        onCancel={() => {
                            setModal(false)
                        }}
                />
            </Grid>
    );
}

export default  Body