import {TransportIcon} from 'components/icons';
import {ColBoolean, ColObjectName, ShowLegal} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import {asGUID} from 'libs/asGUID';
import PageTransport from 'page/PageTransport';

export default {
    listTitle:    'Транспортные средства',
    apiName:      'transports',
    itemName:     'Транспортное средства',
    roles:        [
        ROLES.ROLE_USER,
    ],
    canEdit: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },
    canDelete: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },

    filterParent: (props) => {
        const {menuRoot, legal, _items} = props;
        const {_self} = _items || {};
        const {contragent} = _self || {};
        let filter = {
            'legal.isCarrier': menuRoot.id === 'carrier',
        };
        if (contragent) {
            filter['legal[]'] = [asGUID(contragent), legal];
        }
        return filter
    },
    menu:         {
        title:     'Транспортные средства',
        path:      '/transports',
        Component: PageTransport,
        icon:      TransportIcon,
        roles:     [ROLES.ROLE_USER],
    },

    cols: [
        {
            title: 'Наименование',
            field: 'name',
            required: true,
            cols:  2,
        },
        {
            title:  'Тип',
            api:    'transport_types',
            object: ColObjectName,
            format: FieldFormat.FORMAT_SELECT_API,
            field:  'type',
            cols:   2,
        },
        {
            title: 'Марка',
            field: 'marka',
            cols:  3,
        },
        {
            title: 'Модель',
            field: 'model',
            cols:  3,
        },
        {
            title: 'Гос номер',
            field: 'number',
            cols:  3,
        },
        {
            title: 'Объем, м^3',
            field: 'value',
            format: FieldFormat.FORMAT_NUMBER,
            cols:  3,
            minValue: 0
        },
        {
            title: 'Грузоподъемность, кг',
            field: 'mass',
            format: FieldFormat.FORMAT_NUMBER,
            cols:  3,
            minValue: 0
        },
        {
            title:  'Черный список',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            field:  'isBanned',
            cols:   3,
        },
        {
            title:     'Юридическое лицо',
            field:     'legal',
            api:       'legals',
            object:    ShowLegal,
            format:    FieldFormat.FORMAT_SELECT_API,
            apiFilter: (values, parent) => {
                const {menuRoot} = parent;
                if (!menuRoot) {
                    console.error('No menuRoot TODO: update');
                    return {
                        'exists[contragent]': parent.id === 'carrier',
                    };
                }
                return {
                    'exists[contragent]': menuRoot.id === 'carrier',
                };
            },
        },
        // {
        //     title:  'Организация',
        //     api:    'legals',
        //     object: ColObjectName,
        //     field:  'legal',
        // },
    ],
};