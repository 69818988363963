import React from "react";

import { SvgIcon } from '@material-ui/core';

const OtherIcon = () => {
    return (
        <SvgIcon viewBox={"0 0 56 56"}>
            <g>
                <path style={{fill: "#E9E9E0"}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                <polygon style={{fill: "#D9D7CA"}} points="37.5,0.151 37.5,12 49.349,12 	" />
                <path style={{fill: "#C8BDB8"}} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z" />
                <circle style={{fill: "#FFFFFF"}} cx="18.5" cy="47" r="3" />
                <circle style={{fill: "#FFFFFF"}} cx="28.5" cy="47" r="3" />
                <circle style={{fill: "#FFFFFF"}} cx="38.5" cy="47" r="3" />
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>

        </SvgIcon>
    )
}
export { OtherIcon };