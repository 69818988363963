import React from 'react';
import {ExpandMore} from '@material-ui/icons';
import detailPanelRenderDefault from 'components/MTableExtend/detailPanelRenderSubtable';
import RequestFormDebug from 'components/RequestFormDebug';
import {config} from 'config';
import {generateKey} from 'config/docRequest';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import asArray from 'libs/asArray';
import MaterialTable,{MTableBody} from 'material-table';
// import MTableBody from 'material-table/dist/components/m-table-body';
import AdminShow from './AdminShow';
import {transformCols} from 'components/MTableExtend/transformCols';
import MTEditRowContext from "components/MTableExtend/MTEditRowContext";
import Paper from "../Paper";
// import {PropTypes} from "@material-ui/core";
import PropTypes from 'prop-types';
import useExplainCols from "components/useExplainCols";
import MTBodyRowContext from "components/MTableExtend/MTBodyRowContext";
import ConfirmDialog from "components/ConfirmDialog";

const textContent = {
    SubFormListEdit : {
        copyRow: 'Копировать',
    }
}

function MTBodyContext(props) {
    const {item, values, handleChangeTop} = React.useContext(FormDataContext);
    console.log('MTBodyContext', props)
    return <MTableBody {...props} />
    return <>
        {JSON.stringify(props.renderData)}
        <MTableBody
        {...props}
        // renderData2={values.map((i,key) => ({
        //     ...i,
        //     "point": "859d340c-0c01-11ed-b903-005056a6c16d",
        //     tableData: {id:key}
        // }))}
    /></>
}

function SubFormListEdit(props) {
    const [isLoading, setIsLoading]   = React.useState(false);
    // const [columns, setColumns] = React.useState(false);

    const {
        listTitle,
        canEdit,
        canDelete,
        canAdd,
        // values,
        onChange,
        detail,
        // handleChange,
        setIsLoadingTop,
        parentRowData,
        optionsMaterialTable,
        componentsMaterialTable,
        onFormDataAction,
        initialValues,
        itemProps,
        customStyle,
    } = {
        setIsLoadingTop: setIsLoading,
        // onFormDataAction: console.log,
        canAdd:                  true,
        canEdit:                 true,
        canDelete:               true,
        detail:                  [],
        optionsMaterialTable:    {},
        componentsMaterialTable: {},
        onFormDataAction:        values => values,
        initialValues:           () => ({}),
        ...props,
    };

    const {item, values, handleChangeTop} = React.useContext(FormDataContext);
    console.log('SubFormListEdit values', values)

    const cloneRow = (event, rowData) => {
        console.log('cloneRow', this)
        const id = generateKey(() => {})
        const data    = [...values, {...rowData, guid: id}];
        // const data    = [...values, {...rowData, guid: id, tableData:{editing:"update"}} ];
        onChange(null, data);
    }

    const addRow = (event) => {
        console.log('addRow', this)
        const initialValues = item.initialValues || (() => ({_debug_addRow_initialValues : true}))
        const rowData =  initialValues();
        const id = generateKey(() => {})
        const data    = [...values, {...rowData, guid: id}];
        // const data    = [...values, {...rowData, guid: id, tableData:{editing:"update"}} ];
        onChange(null, data);
        // handleChangeTop(item)(null, [])
    }
    // todo: update to useExplainCols, not tested
    const columnsReal = useExplainCols(props.cols, props, values,  {from: 'subFormList'}, false);
    const columns = columnsReal ? columnsReal.map(i=>({...i})) : columnsReal;
    console.log({columnsReal})

    const tableRef = React.createRef();
    const [rowDelete, setRowDelete] = React.useState(-1);

//
    const onRowDeleteConfirm = () => {
        const nextValues = values
            .filter(i => i.tableData.id !== rowDelete)
        handleChangeTop(item)(null, nextValues);
        setRowDelete(-1);
    }

    let onRowDeleteClick = (event, oldData) => {
        console.log('onRowDelete', oldData, arguments)

        setRowDelete(oldData.tableData.id);

        const nextValues = values
            .filter(i => i.tableData.id !== oldData.tableData.id)
        // handleChangeTop(item)(null, nextValues)
        // handleChangeTop(item)(null, [])
    };

    let onRowDelete = oldData => {
        console.log('onRowDelete', oldData, arguments)
        return new Promise((resolve) => {
            console.log('onRowDelete '+ oldData.tableData.id, {values,oldData})
            const nextValues = values
                .filter(i => i.tableData.id !== oldData.tableData.id)
            console.log('onRowDelete', nextValues)
            //onChange(null, nextValues);
            console.log('onRowDelete', item, nextValues)
            handleChangeTop(item)(null, [])
            resolve();
            // this.setState({ data }, () =>
            // resolve());
        });
    };

    let onRowEdit = (newData, oldData) =>
        new Promise((resolve) => {
            setIsLoadingTop(true);
            const data = [...values];
            console.log('newData', newData, data);
            const index = data.indexOf(oldData);
            let tmpData = {...newData};
            ['loading_date', 'unloading_date'].forEach(fieldChange => {
                if (tmpData[fieldChange] && typeof tmpData[fieldChange] == 'string') {
                    tmpData[fieldChange] = tmpData[fieldChange].substr(0, 10);
                }
            });
            console.log('tmpData', tmpData);
// onFormDataAction
            data[index] = tmpData;
            // TODO: update data without redraw
            // onChange(null, data.map(({tableData, ...i}) => i));
            onChange(null, data);

            console.log('newData', tmpData, data, [...data]);

            //TODO: expand details move code to config

            let detailPanelIndex;
            if (newData.typePoint === 'погрузка') {
                detailPanelIndex = 0;
            } else if (newData.typePoint === 'разгрузка') {
                detailPanelIndex = 1;
            }
            if(detailPanel && newData.typePoint) {
                const sortedData = tableRef.current.dataManager.sortedData;
                const lookData = sortedData.find(d => d.tableData.id === oldData.tableData.id)
                let indexOpen = sortedData.indexOf(lookData);
                console.log('sortedData',sortedData, indexOpen, detailPanelIndex);
                const render = detailPanel[detailPanelIndex]({}).render;
                if((lookData.tableData.showDetailPanel || "").toString() !== render.toString()) {
                    tableRef.current.onToggleDetailPanel(
                        [indexOpen],
                        render
                    );
                }
            }

            setTimeout(() => {
                resolve();
                // this.setState({ data }, () =>
                // resolve());
                setIsLoadingTop(false);
            }, 0);
        });

    let onRowAdd = newData =>
        new Promise((resolve) => {
            console.log('onRowAdd', {values, newData});
            newData['guid'] = generateKey(() => {});
            onChange(null, [...values, newData], resolve);
            resolve();
        });


    let detailPanel = asArray(detail).map((det) => {
        return panelValues =>  {
            let panel = typeof det == 'function'
            ? det(panelValues)
            : det;
            // console.log('panel.panel', panel);
            // console.log('panel.render', panel.render);

            let tooltipPanel = typeof det.tooltip == 'function'
            ? det.tooltip(panelValues)
            : det.tooltip;

            const render = panel.render || detailPanelRenderDefault;
            return {
                icon:     panel.icon,
                openIcon: ExpandMore,
                tooltip:  tooltipPanel || 'Услуги', // todo: multi
                render:   render(({detail, panel, values, panelValues, onChange, tableRef, setIsLoading})),
            };
        }
    });

    const [showAddRow, setShowAddRow] = React.useState(false);

    if (!values.length) {
        // Скрыть пустую таблицу
        optionsMaterialTable.showEmptyDataSourceMessage = false;
        if(!showAddRow) {
            componentsMaterialTable.Header = () => null;
        }
        // hack: для того что бы отображать шапку при редактировании
        componentsMaterialTable.Body =  props => {
            if (showAddRow !== props.showAddRow) {
            setShowAddRow(props.showAddRow);
            }
            return <>
                <MTableBody {...props} />
                </>
        }
    }
    console.log('MaterialTable values', values.length)
    return <React.Fragment>
        {/*{(new Date()).toJSON()}*/}
        {/*<pre>[{(new Date()).toJSON()}]values_sub={JSON.stringify(values, null, 2)}</pre>*/}
        <AdminShow>[SubFormListEdit]</AdminShow>
        <ConfirmDialog onConfirm={onRowDeleteConfirm} onCancel={()=>setRowDelete(-1)} show={rowDelete >=0} text={`Удалить ${listTitle}?`}/>
        <MaterialTable
            isLoading={isLoading || !columns}
            title={listTitle || ''}
            // columns={columns|| []}
            columns={columns||[]}
            // initialValues={initialValues}
            style={customStyle}
            data={values}
            tableRef={tableRef}
            actions={[
                canAdd && {
                    icon: config.MaterialTable.icons.FileCopy,
                    tooltip: textContent.SubFormListEdit.copyRow,
                    onClick: cloneRow
                },
                {
                    icon:config.MaterialTable.icons.Delete,
                    disabled: !canDelete,
                    tooltip: "Удалить",
                    onClick: onRowDeleteClick
                },
                {
                    icon:config.MaterialTable.icons.Add,
                    tooltip: 'Добавить',
                    position: "toolbar",
                    disabled: !canAdd,
                    onClick: addRow,
                },
                // canEdit && {
                //     icon:    EditIcon,
                //     tooltip: 'Редактировать',
                //     onClick: (event, rowData) => onEdit(rowData)(),
                // },
                //
                // {
                //     icon:         'refresh',
                //     tooltip:      'Обновить данные',
                //     isFreeAction: true,
                //     onClick:      () => tableRef.current.onQueryChange(),
                // },
            ]}
            {...config.MaterialTable}
            // components={{
            //     Cell: MyMTableCell,
            // }}
            options={{
                search:                 false,
                // showTitle:              false,
                paging:                 false,
                toolbarButtonAlignment: 'left',
                ...optionsMaterialTable,
            }}
            components={{
                // Container: (props) => <div {...props} />,
                ...componentsMaterialTable,
                Body: MTBodyContext ,
                Row: MTBodyRowContext,
                EditRow: MTEditRowContext

                // EditRow: props => <pre>{JSON.stringify(props,null,2)}</pre>
                // Toolbar: props => (
                //     <div>
                //         <MTableToolbar {...props} />
                //     </div>
                // ),
            }}
            detailPanel={detailPanel}
            editable={{
                // isEditHidden: () => !canEdit,
                // isDeleteHidden: () => !canDelete,
                // onRowAdd:    canAdd
                //                  ? onRowAdd
                //                  : null,
                onRowUpdate: canEdit
                                 ? onRowEdit
                                 : null,
                // onRowDelete: canDelete
                //                  ? onRowDelete
                //                  : null,
            }}
        />

    </React.Fragment>;
}



function SubFormListEditContext(props){
    const {itemProps, ...other} = props;
    const {values} = React.useContext(FormDataContext);
    console.log({itemProps})


    const { handleChange,handleChangeTop, item} = React.useContext(FormDataContext);
    console.log('SubFormListEdit values', values)



    const handleChangeValues = item => (event, newValues) => {
        console.log('use old handleChangeValues',item,newValues);
        console.error('use old handleChangeValues');
        handleChange(item)(null, newValues);
    };


    const onChange = handleChangeValues(itemProps)
    // const onChange = props =>  console.log('onChange handleChangeValues', props);

    return <>
        {/*itemProps.field={itemProps.field} itemProps.cols={itemProps.cols}*/}
        {/*values[itemProps.field]={JSON.stringify(values[itemProps.field])}*/}
        {/*routePlaces={JSON.stringify(values.routePlaces)}]*/}
        {/*<CheckBox onClick={onChangeV}/>*/}
        <FormDataContextProvider
            item={itemProps}
            initial={values[itemProps.field]||[]}
            cols={itemProps.cols}
            // onFormDataAction={itemProps.onFormDataAction || (values => values)}
            debug={'SubFormListEdit:list:'+itemProps.field}
        >
            <SubFormListEdit {...props} onChange={onChange}/>
        </FormDataContextProvider>
    </>
}
SubFormListEditContext.propTypes = {
    itemProps: PropTypes.any,
};
export default SubFormListEditContext;