import React from 'react';
import 'kanban-styles.css';
import LoadStateShow from "components/LoadStateShow";
import KanbanCols from "./KanbanCols";

const Kanban = (props) => {
    const {lookupLanes, loadStateLanes, lookupCard, loadStateCard, statuses} = props

    return <React.Fragment>
        <LoadStateShow state={loadStateLanes} error={lookupLanes}>
            <LoadStateShow state={loadStateCard} error={lookupCard}>
                <KanbanCols statuses={statuses} cards={lookupCard}/>
            </LoadStateShow>
        </LoadStateShow>

    </React.Fragment>
            ;
}

export default  Kanban
