import React from 'react';
import {RoutePointsIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';
import PageRoutePoints from 'page/PageRoutePoints';
import {ROLES} from 'config/roles';
import {Checkbox} from '@material-ui/core';
import {asGUID} from "../libs/asGUID";



const mapSuggestions = field => (data,val, {suggestion}) => {
    //todo: work fias
    let levelsLook    = [
        'country', 'region', 'area', 'city', 'settlement', 'street', 'house', 'flat',
    ];
    const levels = levelsLook.map(lvl => {

            return {
                level:            lvl,
                kladr_id:         data[lvl + '_kladr_id'] || '',
                fias_id:          data[lvl + '_fias_id'] || '',
                object_type:      data[lvl + '_type'] || '',
                object_type_full: data[lvl + '_type_full'],
                object:           lvl === 'house' && data['block']
                                    ? `${data[lvl]}  ${data['block_type']} ${data['block']}`
                                    : data[lvl] || '',
                object_with_type: data[lvl + '_with_type'],
                geo_lat:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lat']
                                      : 0,
                geo_lon:          data[lvl + '_fias_id'] === data['fias_id']
                                      ? data['geo_lon']
                                      : 0,
            };
        })
        .filter(v => v)
        .filter(l => l.object !== '' );

    console.log('mapSuggestionslevels', levels, data)
    const last    = levels[levels.length - 1];
    const address = [ ...levels.filter(el => el.object)
        .map(o => `${o.object_type} ${o.object}`)]
        .join(', ');

    return {
        [field]:   address,
        name:      val.name ? val.name : address,
        latitude:  data.geo_lat,
        longitude: data.geo_lon,
        jsonData: data,
        levels,
        ...last,
        _log:      console.log(data),
        fias_address_id: suggestion.fias_address_id,
        unrestricted_value: suggestion.unrestricted_value,
        custom : levels.length  === 1 ? true: false
    }
};

export default {
    listTitle:        'Избранные пункты',
    apiName:          'route_points',
    itemName:         'Избранный пункт',
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Избранные пункты',
        path:      '/route_points',
        Component: PageRoutePoints,
        roles:     [ROLES.ROLE_USER],
        icon:      RoutePointsIcon,
    },
    canDelete: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.clientProfile)
    },
    canEdit: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.clientProfile)
    },
    initialValues:    () => (
        {
            isLoading:   true,
            isUnloading: true,
        }
    ),
    cols:             [
        {
            title:      'Наименование',
            required:   true,
            field:      'name',
            hidden:     {xsUp: true},
            helperText: 'Наименование точки маршрута',
            cols:       2,
        },
        {
            title:          'Адрес',
            required:       true,
            field:          'address',

            // format: FieldFormat.FORMAT_FIAS_ADDRESS,
            // api:           'fias_address_trees',
            // hidden:         true,
            mapSuggestions: mapSuggestions('address'),
            format:         FieldFormat.FORMAT_SELECT_DADATA,
            helperText:     'Наименование точки маршрута(поиск)',
            placeholder:    'Для автоматического заполнения начните вводить адрес',
            api:            'search/address/no_fav',
            cols:           2,
        },
        // {
        //     title:    'Пункт погрузки',
        //     field:    'isLoading',
        //     object:   ColBoolean,
        //     format:   FieldFormat.FORMAT_CHECKBOX,
        //     cols:     2,
        // },
        // {
        //     title:    'Пункт разгрузки',
        //     field:    'isUnloading',
        //     object:   ColBoolean,
        //     format:   FieldFormat.FORMAT_CHECKBOX,
        //     cols:     2,
        // },
        {
            title:    'широта',
            field:    'latitude',
            disabled: true,
            cols:     3,
            hidden: true,
        },
        {
            title:    'долгота',
            field:    'longitude',
            disabled: true,
            cols:     3,
            hidden: true,
        },
        {
            title:    'Найдены данные',
            field:    'idAddr',
            disabled: true,
            hidden:   true,
            format:   FieldFormat.FORMAT_CUSTOM,
            custom:   ({value}) => <React.Fragment><Checkbox checked={Boolean(value)}/>найден подтвержденный
                адрес</React.Fragment>,
            cols:     3,
        },
    ],
};

/*
area: null
area_fias_id: null
area_kladr_id: null
area_type: null
area_type_full: null
area_with_type: null
beltway_distance: null
beltway_hit: null
block: null
block_type: null
block_type_full: null
capital_marker: "0"
city: "Мытищи"
city_area: null
city_district: null
city_district_fias_id: null
city_district_kladr_id: null
city_district_type: null
city_district_type_full: null
city_district_with_type: null
city_fias_id: "5f290be7-14ff-4ccd-8bc8-2871a9ca9d5f"
city_kladr_id: "5000004400000"
city_type: "г"
city_type_full: "город"
city_with_type: "г Мытищи"
country: "Россия"
country_iso_code: "RU"
federal_district: "Центральный"
fias_actuality_state: "0"
fias_code: "50000044000000000580000"
fias_id: "49079eba-81c0-4d7c-b43a-538f02b6ea13"
fias_level: "7"
flat: null
flat_area: null
flat_price: null
flat_type: null
flat_type_full: null
geo_lat: "55.9346095"
geo_lon: "37.7425936"
geoname_id: null
history_values: null
house: "12"
house_fias_id: null
house_kladr_id: null
house_type: "влд"
house_type_full: "владение"
kladr_id: "50000044000005800"
metro: null
okato: "46446000000"
oktmo: "46746000001"
postal_box: null
postal_code: "141006"
qc: null
qc_complete: null
qc_geo: "0"
qc_house: null
region: "Московская"
region_fias_id: "29251dcf-00a1-4e34-98d4-5c47484a36d4"
region_iso_code: "RU-MOS"
region_kladr_id: "5000000000000"
region_type: "обл"
region_type_full: "область"
region_with_type: "Московская обл"
settlement: null
settlement_fias_id: null
settlement_kladr_id: null
settlement_type: null
settlement_type_full: null
settlement_with_type: null
source: null
square_meter_price: null
street: "Волковское"
street_fias_id: "49079eba-81c0-4d7c-b43a-538f02b6ea13"
street_kladr_id: "50000044000005800"
street_type: "ш"
street_type_full: "шоссе"
street_with_type: "Волковское шоссе"
tax_office: "5029"
tax_office_legal: "5029"
timezone: null
unparsed_parts: null
__proto__: Object
 */