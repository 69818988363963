import React from "react";
import useLoaderApi, {useLoaderApiList} from "./useLoaderApi";
import {STATE} from "../libs/api";
import {
    CircularProgress, 
    Box, 
    Tooltip,
    Table, 
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography 
} from "@material-ui/core";
import MaterialTable from "material-table";
import {transformColsNoContext} from "./MTableExtend/transformCols";
import ShowDateTime from "./ShowDateTime";
import {ColObjectFullNameWithColor, ColObjectName, ColObjectShowDateTime, ShowUser} from "./showItems";
import {ObjectDetect} from "./ObjectDetect";
import {config} from "../config";
import {FormDataContext, FormDataContextProvider} from "../FormDataContext";
import ErrorBoundary from "./ErrorBoundary";
import LoadStateShow from 'components/LoadStateShow.jsx'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { object } from "prop-types";

const StyleTypograhy = withStyles((theme)=>({
    root : {
        fontSize: "12px",
        overflow: 'hidden',
        textOverflow: "ellipsis",
    }
}))(Typography)

const MaterialTableStyle = withStyles((theme) => (
    {
        root: {
            backgroundColor: "red",
        }
    }
))(MaterialTable)

const StyledTableContainer = withStyles((theme) => ({
    root : {
    }
  }))(TableContainer);





  const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: "3px 10px",
        margin: "0",
        height: "20px"
    },
    body: {
      fontSize: 12,
      minWidth: "220px",
      maxWidth: "220px",
      overflow: 'hidden',
      textOverflow: "ellipsis",
    },
  }))(TableCell);

  const StyledTableCellNestedTable = withStyles((theme) => ({
    root: {
        padding: "3px 0px",
        paddingLeft: "1.5rem",
        margin: "0",
        height: "20px"
    },
    body: {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: "ellipsis",
    },
  }))(TableCell);

  const StyledTableCellMtable = withStyles((theme) => ({
    root: {
        // padding: "3px 10px",
        margin: "0",
        height: "20px"
    },
    body: {
      fontSize: 16,
    //   minWidth: "220px",
    //   maxWidth: "220px",
      overflow: 'hidden',
      textOverflow: "ellipsis",
    },
  }))(TableCell);

  const TextHeaderBox = withStyles((theme) => ({
    root: {
        color: "black",  
        marginTop: "8px", 
        marginBottom: "8px", 
        fontSize: "12px"
    }
  }))(Box)


  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "#cbe4f6 !important",
      },
      '&:nth-of-type(even)' : {
        backgroundColor: "#fff9f1",
      }
    },
  }))(TableRow);

  const StyledTableRowPersist = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "#daebc6 !important",
      },
      '&:nth-of-type(even)' : {
        backgroundColor: "#fff9f1",
      }
    },
  }))(TableRow);
  const StyledTableRowDelete = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "#f6d6d6 !important",
      },
      '&:nth-of-type(even)' : {
        backgroundColor: "#fff9f1",
      }
    },
  }))(TableRow);

  const StyleTitileBox = withStyles((them)=> ({
    root: {
        textTransform: "uppercase"
    }
  }))(Box)


const ShowValue = value => {
    // const {value, col} = props;
    if(value === null) {
        return '---'
    }
    return JSON.stringify(value)
}

const ColsShowDiffOld = (cols, admin) => props => {
    const {item} = props;

    console.log('ColsShowDiff', props, Object.entries(item));
    return Object.entries(item).map(([field, [from, to]]) => {
        const col = cols.find(i => field === i.field)
        // return <>{field}: <ShowValue value={from} col={col} field={field}/> -> <ShowValue value={to} col={col}/></>
        if (from && typeof from === "object" && from.date && from.timezone) {
            from = from.date
        }
        if (to && typeof to === "object" && to.date && to.timezone) {
            to = to.date
        }
        
        return col
            ? <Box display="flex" alignItems="center" >
                {col.title}
                <Box mr = {1}>:</Box>
                <ObjectDetect noneValue={'[НЕТ]'} rows={{[field]: from}} field={field} item={{[field]: from}} row={col}/>
                <Box mx = {1}>{`->`}</Box>
                <ObjectDetect noneValue={'[НЕТ]'} rows={{[field]: to}} field={field} item={{[field]: to}} row={col}/>
                </Box>
                
            : <div>
                {admin 
                ? `${field}: ${ShowValue(from)} -> Not found ${ShowValue(to)}`
                : ''}
            </div>
    });
}

const createShowObject = (field, values, col, journal ) => {
    if(!col || journal){
        return ShowValue(values)
    }
    
    return <ObjectDetect noneValue={'---'} rows={{[field]: values}} field={field} item={{[field]: values}} row={col} journal={!journal}/>

}

const AdressRoutePlaces = ({guid}) => {

    const [item, loadState] = useLoaderApi(`doc_request_route_places`, guid, {})

    if(item && item.pointName){

        return (
            <StyleTypograhy>{item.pointName}</StyleTypograhy>
        )
    }
    
    if (item && typeof item === 'string' && item.toLocaleLowerCase().includes('not found')){

        return (
            <StyleTypograhy>{"точка удалена"}</StyleTypograhy>
        )
    }

    return (
        <StyleTypograhy>Загрузка</StyleTypograhy>
    )



} 

const textHeader = (subConfig, row, address = false, newPoint = false, routePlacesGuid = false) => {
    const {icon, title} = subConfig[row.generalField] || {}
    let value = null;

    switch (true){
        case !!newPoint:
            value = address.to;
            return;
        case !!address:
            value = address.from;
            return;
        case !!routePlacesGuid:
            value = <AdressRoutePlaces guid={routePlacesGuid}/>;
            break;
    }

    return (
        <TextHeaderBox
            display={"flex"}
            alignItems="center"
        >
            {icon}
            <StyleTitileBox mx={1}>{title}:</StyleTitileBox>
            {value}
        </TextHeaderBox>
    )
}

const ColsShowDiff = (cols, admin, subConfig) => props => {
    const {item, row:{action}} = props;
    const techGyud = [];
    const techGyud2 = [];
    // console.log('ColsShowDiff', props, Object.entries(item), cols);
    const arrayShowCols = []
   try{
    Object.entries(item).map(([field, [from, to]]) => {
        let generalKey = null;
        let generalField = null
        let fieldSub = null
        let generalKeyFieldsub
        if(field.includes('||')){
            if(field.split('||').length === 4){
                const [key,  fieldIncludesSub, fieldIncludes, generalKeyIncludes] = field.split('||')
            field = fieldIncludes;
            fieldSub = fieldIncludesSub
            generalKey = generalKeyIncludes;
            generalField = key;
            generalKeyFieldsub = `${field}${generalKeyIncludes}`

            }else{
                const [key, fieldIncludes,  generalKeyIncludes] = field.split('||')
            field = fieldIncludes;
            generalKey = generalKeyIncludes;
            generalField = key;
            }
            
        }
        
        // field === 'point' && console.log("push2", field, from, to)
        const col = cols.find(i => field === i.field)
        if (from && typeof from === "object" && from.date && from.timezone) {
            from = from.date
        }
        if (to && typeof to === "object" && to.date && to.timezone) {
            to = to.date
        }
        const result = {
            generalKey,
            generalField,
            fieldSub,
            generalKeyFieldsub
        }
        // console.log('push2', field, generalField, to )
        const resultFrom =  createShowObject(field, from, col, admin);
        const resultTo = createShowObject(field, to, col, admin);
        const tititleOblect = col ? col.title : field;
        result.point = field === 'point'
        result[`${generalField}Guid`] = field === 'guid' && generalField;
        result[`${generalField}GuidValue`]  = to;
        result.name = <Tooltip title={tititleOblect} placement="top-start"><Box my={1}>{tititleOblect}</Box></Tooltip>;
        result.from = <Tooltip title={resultFrom} placement="top-start"><StyleTypograhy>{resultFrom}</StyleTypograhy></Tooltip>
        result.to =  <Tooltip title={resultTo} placement="top-start"><StyleTypograhy>{resultTo}</StyleTypograhy></Tooltip>
        result.toValues = to;
        arrayShowCols.push(result)
    });
   }catch{
        console.log("push2", item, cols)
   }

    //Ввыод таблицы при инцилизации объекта 
    if(action === 'persist' && !admin ){
        return (
            <StyledTableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {arrayShowCols.map((row) => (
                        <StyledTableRowPersist key={row.name}>
                            <StyledTableCell component="th" scope="row"  width={'30%'}>
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left" width={'70%'}>{row.to}</StyledTableCell>
                        </StyledTableRowPersist>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
        )
    }
    //Обычный вывод
    return (
        <StyledTableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {arrayShowCols.map((row) => {
                        if(row.generalKey){
                            if(techGyud.includes(row.generalKey)){
                                return null
                            }
                            const data =  arrayShowCols.filter(el => el.generalKey === row.generalKey)
                            techGyud.push(row.generalKey)
                            const point = item[`${row.generalField}||point||${row.generalKey}`] ? item[`${row.generalField}||point||${row.generalKey}`] : false
                            const generalField = [...data].shift().generalField
                            const address = data.find(el => el.point)
                            const objectGuid = data.find(el => el[`${generalField}Guid`])
                            const newPoint = point && [...point].shift() === null ? true : false
                            const dataSubCargo = data.filter(el => el.fieldSub === "cargos")
                            const dataSubServices= data.filter(el => el.fieldSub === "services")                      
                            const subTitle = {
                                cargos : "Грузы",
                                services : "Услуги"
                            }

                            const createBody = (data, newPoint) => {

                                return newPoint ?
                                data.map(el => 
                                    <StyledTableRowPersist key={el.name}>
                                        <StyledTableCellNestedTable component="th" scope="row"  width={'30%'}>
                                            {el.name}
                                        </StyledTableCellNestedTable>
                                        <StyledTableCellNestedTable align="left" width={'70%'} colSpan={2}>{el.to}</StyledTableCellNestedTable>
                                    </StyledTableRowPersist>
                                
                                ) 
                                    : data.map(el => {
                                        if (el.toValues === null) {

                                            return (
                                                <StyledTableRowDelete key={el.name}>
                                                    <StyledTableCellNestedTable component="th" scope="row" width={'10%'}>
                                                        {el.name}
                                                    </StyledTableCellNestedTable>
                                                    <StyledTableCellNestedTable align="left" width={'35%'}>{el.from}</StyledTableCellNestedTable>
                                                    <StyledTableCellNestedTable align="left" width={'35%'}>{el.to}</StyledTableCellNestedTable>
                                                </StyledTableRowDelete>
                                            )
                                        }

                                        return (<StyledTableRow key={el.name}>
                                            <StyledTableCellNestedTable component="th" scope="row" width={'10%'}>
                                                {el.name}
                                            </StyledTableCellNestedTable>
                                            <StyledTableCellNestedTable align="left" width={'35%'}>{el.from}</StyledTableCellNestedTable>
                                            <StyledTableCellNestedTable align="left" width={'35%'}>{el.to}</StyledTableCellNestedTable>
                                        </StyledTableRow>)
                                    }
                                )
                            }
                           
                            return (
                                <>
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                            {textHeader(subConfig, row, address, newPoint, objectGuid ? objectGuid[`${generalField}GuidValue`] : '')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCellNestedTable component="th" scope="row" colSpan={3}>
                                            <StyledTableContainer aria-label="simple table">
                                                <Box pl={0.5}>
                                                    <Table aria-label="simple table">
                                                        <TableBody>
                                                            {createBody(data.filter(el => !el.fieldSub && !el.routePlacesGuid), newPoint)}
                                                            {dataSubCargo.length ? <>

                                                                <StyledTableRow >
                                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                                        <Box py={1}>{"ГРУЗЫ:"}</Box>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                                <StyledTableRow >
                                                                    <StyledTableCellNestedTable component="th" scope="row" colSpan={3}>
                                                                        <StyledTableContainer aria-label="simple table">
                                                                            <Box pl={4}>
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>
                                                                                        {createBody(dataSubCargo, newPoint)}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Box>
                                                                        </StyledTableContainer>
                                                                    </StyledTableCellNestedTable>
                                                                </StyledTableRow>
                                                            </> : <></>}
                                                            {dataSubServices.length ? <>
                                                                <StyledTableRow >
                                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                                        <Box py={1}>{"УСЛУГИ:"}</Box>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                                <StyledTableRow >
                                                                    <StyledTableCellNestedTable component="th" scope="row" colSpan={3}>
                                                                        <StyledTableContainer aria-label="simple table">
                                                                            <Box pl={4}>
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>
                                                                                        {createBody(dataSubServices, newPoint)}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Box>
                                                                        </StyledTableContainer>
                                                                    </StyledTableCellNestedTable>
                                                                </StyledTableRow>
                                                            </> : <></>}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </StyledTableContainer>
                                        </StyledTableCellNestedTable>
                                    </StyledTableRow>


                                </>
                            )
                        }
                        return (<StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row" >
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left" width={'35%'}>{row.from}</StyledTableCell>
                            <StyledTableCell align="left" width={'35%'}>{row.to}</StyledTableCell>
                        </StyledTableRow>)
                    })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}





export const LogsShow = props => {
    const {formProps, item, listTitle, admin, subItems } = props
    const {apiName} = formProps || {}
    const {guid} = item || {}

    // todo: not need for component
    const {cols} = React.useContext(FormDataContext)
    // console.log("push2", colsSubItemsArray)

    const [colsState, setColsState ] = React.useState([])
    const [subConfig, setSubConfig] = React.useState({})

    React.useEffect(() => {
        const colsSubItems = [];
            const colsSubItemsArray = [];
            const requestSubItem = typeof subItems === "function" && subItems(item, {}, {}, {}, {} )
            requestSubItem.list && requestSubItem.list.length && requestSubItem.list.map( async(el) => {
                setSubConfig( data=>({...data, [el.field]:{
                    title: el.listTitle,
                    icon: el.icon
                }})) 
                el.detail && el.detail.forEach(d => {
                    
                    typeof d.cols === 'function' && colsSubItems.push(d.cols())
                })
                //Субконфиги могут быть функцией, масивом объектов, объектом, промисом    
                if (typeof el.cols === 'object') {
                    //Здесь проверверям являтся ли cols массивом объектом, или объектом.  
                    Array.isArray(el.cols) ? el.cols.map(item => { colsSubItemsArray.push({ ...item }) }) : colsSubItemsArray.push({ ...el.cols })
                   
                } else {
                    const array = []
                   
                    typeof el.cols === 'function' &&  colsSubItems.push(el.cols(item, {}, {}, {}, {}, el.detail && typeof el.detail[1].cols === 'function' ? el.detail[1].cols() : ()=>{}))
                }
            })
            Promise.all(colsSubItems)
                .then(array => {
                    array.map(ar => {
                        ar.map(el => {
                            colsSubItemsArray.push(el)
                        })
                    })
                    setColsState([...cols, ...colsSubItemsArray])
                })
    }, [])


    const createExtraData = (data, key) => {
        const result = []
        let extraDataKey = ''
        data.diff[key].map((item, index) => {
            if(!item){
                result.push(null)
            }
            if(item && typeof item === 'object' && Object.keys(item).length){
                Object.keys(item).map(objectKey => {
                    data.diff[objectKey] = [data.diff[key][0][objectKey] || null,  data.diff[key][1][objectKey] || null]
                }) 
                extraDataKey = Object.keys(item).shift()
                result.push(item[extraDataKey])  
            }
            if(item && typeof item == 'string'){
                const json = JSON.parse(item);
                extraDataKey =  Object.keys(json).shift()
                result.push(json[extraDataKey]) 
            } 
        })
        return {extraDataKey, result}
    }

    const createCheckKey = (element, key) => {
        let deleteKey = false
       try{
        const [firstElement] = Array.isArray(element.diff[key]) ? element.diff[key] : [null, null];
        if(firstElement && typeof firstElement === 'object' && !firstElement.timezone){
            element.diff[key].forEach(val => {
                const generalKey = Math.random() 
                Object.entries(val).map(([resultKey, resultValue]) => {
                    if(key === "routePlaces" && resultKey === "guid" && (val.services.length || val.cargos) ){
                        element.diff[`${key}||${resultKey}||${generalKey}`] = ['null', resultValue]
                    }
                    if( 
                        colsState.find(el => el.field === resultKey)
                    ){
                        // console.log('push2', resultKey, resultValue)
                        if(typeof resultvalue !== 'string' &&  resultValue.length === 1){
                            
                            Object.entries(resultValue[0]).forEach(([subKey, values]) => {                                
                                if(colsState.find(el => el.field === subKey)){
                                    element.diff[`${key}||${resultKey}||${subKey}||${generalKey}`] = Array.isArray(values) ? values : ['null', values]
                                }
                            });
                            return null

                        }
                        if(config.techFields.includes(resultKey)){
                            return
                        }
                        element.diff[`${key}||${resultKey}||${generalKey}`] = Array.isArray(resultValue) ? resultValue : ['null', resultValue]
                        return
                    }else{
                        
                    }
                    
                })
            })
            deleteKey = true
        }
       }catch{
        console.log("push2", element.diff[key])
       }

        if(!colsState.find(el => el.field === key) && !key.includes('||')){
            deleteKey = true;
        }
        if(config.techFields.includes(key)){
            deleteKey = true;
        }
        
        if(key === 'extraData'){
            // const {extraDataKey, result} = createExtraData(element, key) 
            // element.diff[extraDataKey] = result;
            deleteKey = true;
        }
     
        // if (element.diff[key][0] === null ) {
            
        // }
        if (element.diff[key][0] == element.diff[key][1]) {
            deleteKey = true
        }
        if (parseFloat(element.diff[key][0]) == parseFloat(element.diff[key][1])) {
            deleteKey = true
        }
        return deleteKey
    }

    const chekElemetData = (element) => {
        for (const key in element.diff) {
            const  deleteKey = createCheckKey(element, key)
            deleteKey && delete element.diff[key]
        }
        return element
    } 


   
    const handrelLoadingData = (data) => {
        if(admin){

            return data
        }
        if (data && Array.isArray(data)) {
                   
            return [...data
                .filter(el => el.diff && !Array.isArray(el.diff))
                .map(el => {
                   
                    return chekElemetData(el)
                })
                .filter(el => Object.keys(el.diff).length)
            ]
        }
        return data
          
    } 

    const columns = [
        {
            title:  'Дата',
            field:  'created_at',
            object: ColObjectShowDateTime,
            width: "5%"
        },
        {
            title:  'Пользователь',
            field:  'user',
            api:    'users',
            object: ColObjectName,
            width: "5%"
        },
        
        admin && {
            title: 'Действие',
            field: 'action',
            width: "5%"
        },
        {
            title:  <Box display={'flex'} width={"100%"} pl={1}>
                <Box width={"30%"}>{"Поле"}</Box>
                <Box width={"35%"}>{"Было"}</Box>
                <Box width={"35%"}>{"Стало"}</Box>
            </Box>,
            field:  'diff',
            showTitleObject: true,
            object: ColsShowDiff(colsState, admin, subConfig),
            width: "90%",
        },

    ];

    const onEdit = (data) => console.log('onEdit', data)

    const [items, loadState] = useLoaderApiList(`logs/${apiName}/${guid}`, {})
    // items && colsState && colsState.length && console.log("push2", colsState, handrelLoadingData(items), items)
    return (
        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(items)}</>}>
            <FormDataContextProvider
                cols={columns}
                item={item}
                initial={items || []}
                // onFormDataAction={itemProps.onFormDataAction || (values => values)}
                debug={'LogsShow:list'}
            ><ErrorBoundary info={'LogsShow:list'}>
                    {colsState && colsState.length 
                        ? <MaterialTable
                            {...config.MaterialTable}
                            options={{
                                ...config.MaterialTable.options,
                                search: false,

                            }}
                            title={listTitle}
                            columns={transformColsNoContext(columns, { onEdit })}
                            data={handrelLoadingData(items)}
                            components={{
                                Header: props => {

                                    return (
                                        <TableHead style={props.headerStyle}>
                                            <TableRow>
                                                {
                                                    props.columns.map(el =>
                                                        <TableCell>{el.title}</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                    )
                                },
                            }}
                        />
                        : <CircularProgress/>
                    }
                </ErrorBoundary>
            </FormDataContextProvider>            
        </LoadStateShow>
    )

    
}

