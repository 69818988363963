import asArray from 'libs/asArray';
import React from 'react';
import {FORM_ACTION} from 'FormDataContext';
import {ColBooleanSwitch} from 'components/showItems';
import request from "superagent";
import {FieldFormat} from 'config/FieldFormat';
import moment from 'moment';


const regForFieldPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/g

const regForFieldEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const errorMessage = 'Ошибка в работе сервиса';

const mapSuggestions = fieldName => (data, oldData) => {
    const dataAdress = data.address ? data.address.data : data
    let levelsLook = [
        'country', 'region', 'area', 'city', 'settlement', 'street', 'house', 'flat',
    ];
    const levels = levelsLook.map(lvl => {
            
            return {
                level:            lvl,
                kladr_id:         dataAdress[lvl + '_kladr_id'] || '',
                fias_id:          dataAdress[lvl + '_fias_id'] || '',
                object_type:      dataAdress[lvl + '_type'] || '',
                object_type_full: dataAdress[lvl + '_type_full'] || '',
                object:           lvl === 'house' && dataAdress['block']
                                      ? `${dataAdress[lvl]}  ${dataAdress['block_type']} ${dataAdress['block']}`
                                      : dataAdress[lvl] || '',
                object_with_type: dataAdress[lvl + '_with_type'],
                geo_lat:          dataAdress[lvl + '_fias_id'] === dataAdress['fias_id']
                                      ? dataAdress['geo_lat']
                                      : 0,
                geo_lon:          dataAdress[lvl + '_fias_id'] === dataAdress['fias_id']
                                      ? dataAdress['geo_lon']
                                      : 0,
            };
        })
        .filter(v => v)
        .filter(l => l.object !== '');
    
    const last = levels[levels.length - 1];
    const address = [
        ...levels.map(o => {
            return o.object ?
                `${o.object_type} ${o.object}`
                : ''
        })
    ]
        .filter(e => e !== '')
        .join(', ');
    return data.useCustom
        ? {
            ...oldData,
            [fieldName]: data[data.useCustom],
        }
        : {
            name:             (data.name && (
                data.name.short_with_opf || data.name.full_with_opf
            )) || data.custom_name,
            fullName:         data.address ? data.name && data.name.full_with_opf : null,
            inn:              data.inn,
            kpp:              data.kpp,
            ogrn:             data.ogrn,
            address:          address,
            registrationDate: data.ogrn_date && moment(data.ogrn_date).format(),
            latitude:         data.geo_lat,
            longitude:        data.geo_lon,
            levels,
            last:             last,
            legalAddress:     ''
            
        }
};


const chekLegal = (value) => {
    if(value.custom_name || value.custom_inn){
        return  ""
    }
        return request.get(`/api/registration/legal_check`)
            // mikhailova@foamline.tech
            .set('accept', 'application/json')
            .query({inn: value.inn || "",  kpp: value.kpp || ""})
            .then(result => {
                return result.body.length ? 'Компания уже зарегистрирована на портале' : ''
            })
    }



const colsIsNotForeign = [
    {
        field:          'name',
        title:          'Название',
        required:       true,
        mapSuggestions: mapSuggestions('name'),
        format:         FieldFormat.FORMAT_SELECT_DADATA,
        freeSoloField:  'custom_name',
        helperText:     'Название организации',
        placeholder:    'Для автоматического заполнения начните вводить наименование или инн',
        api:            'search/inn',
        topColsBlock: 3,
        cols:           1,
        check:  [
            (value) => {
                if(value && typeof  value == "object"){
                     return chekLegal(value)
                }else{
                    return ""
                }
                
            }
        ],
        _step : 2
        
    },
    {
        field:          'inn',
        title:          'ИНН',
        placeholder:    'Введите ИНН до 12 цифр',
        format: FieldFormat.FORMAT_SELECT_DADATA,
        topColsBlock: 3,
        cols:           1,
        mapSuggestions: mapSuggestions('inn'),
        freeSoloField:  'custom_inn',
        api:            'search/inn',
        required: true,
        check: [
            (value) => {
                if(value && typeof  value == "object"){
                    return chekLegal(value)
                }else{
                    return ""
                }
            }
            
        ],
        _step : 2
    },
    {
        field:       'kpp',
        title:       'КПП',
        topColsBlock: 3,
        cols:           1,
        placeholder: 'Введите КПП до 9 цифр',
        mask:        '0'.repeat(9),
        check: [
            (value) => value.length && value.length !== 9 ?  'Некорректный КПП' : '',
        ],
        _step : 2
    },
    {
        field:       'ogrn',
        title:       'ОГРН',
        topColsBlock: 3,
        cols:           1,
        format:      FieldFormat.FORMAT_INPUT,
        mask:        '*'.repeat(50),
        placeholder: 'Введите ОГРН до 50 символов',
        _step : 2
    },
]

const colsIsForeign = [
    {
        field:          'name',
        title:          'Название',
        required:       true,
        format:         FieldFormat.FORMAT_INPUT,
        helperText:     'Название организации',
        placeholder:    "Название организации",
        topColsBlock: 3,
        cols:           1,
        _step : 2
    },
    {
        field:       'ogrn',
        title:      'Рег. номер',
        topColsBlock: 3,
        cols:        1,
        format:      FieldFormat.FORMAT_INPUT,
        required:    true,
        mask:        '*'.repeat(50),
        placeholder: 'Введите рег. номер до 50 символов',
        check: [
            (value) => {
                if(value.length < 4){
                    return 'Регистрационный номер должен содержать ка минимум 4 символа'
                }
                return request.get(`/api/registration/legal_check`)
                    .set('accept', 'application/json')
                    .query({ogrn: value, foreign: true})
                    .then(result => {
            
                        return result.body.length ? 'Компания уже зарегистрирована на портале' : ''
                    }).catch(err => {
                        const {response} = {...err}
                        return response.body && response.body.length
                            ? response.body
                            : 'Сервис временно недоступен';
                    })
            }
        ],
        _step : 2
    },
]

const createCols = (items, useStep) => {
    const {_step, isForeign, email} = items
    const cols = [
        {
            field: "messageEmail",
            format: FieldFormat.FORMAT_SIMPLE_TEXT_BOX,
            message: 'Указанный почтовый ящик будет использоваться в качестве логина для авторизации на TMS портале',
            topColsBlock: 1,
            cols: 1,
            _step : 0
        },
        {
            title: 'E-Mail',
            field: 'email',
            required: true,
            topColsBlock: 1,
            cols: 1,
            helperText: "Указанный почтовый ящик будет использоваться в качестве логина для авторизации на TMS портале",
            check : [
                (value) => {
                    if(!value.match(regForFieldEmail)){
                        return 'Неверный формат почты'
                    }
                    return request.get(`/api/registration/email_check`)
                        .set('accept', 'application/json')
                        .query({email: value})
                        .then(result => {
                            return result.body.length ? 'Данная электронная почта уже  зарегистрирована' : ''
                        })
                        
                },
            ],
            _step : 0
        },
        {
            field: "message",
            format: FieldFormat.FORMAT_SIMPLE_TEXT_BOX,
            message: `Введите код который был отправлен Вам на почту ${email}`,
            topColsBlock: 2,
            cols: 1,
            _step : 1
        },
        {
            title: 'Код подтверждения',
            field: 'code',
            required: true,
            topColsBlock: 2,
            cols: 1,
            helperText: "Введите код подтверждения",
            mask: "0000",
            check : [
                (value, {values} ) => {
                    const code = [...values.email]
                        .map(el => el.charCodeAt(0))
                        .reduce((current, previous) => previous + current);
                    return value != code ? 'Неверный код' : ''
                },
            ],
            _step : 1
        },
        {
            title: 'Пароль для входа',
            field: 'newPassword',
            topColsBlock: 2,
            cols: 1,
            required: true,
            format: FieldFormat.FORMAT_PASSWORD,
            check : [
                (value) => value.length < 6 ? 'Пароль должен содержать не менее 6 символов' : '',
                (value) => !value.match(regForFieldPassword) ? 'Пароль должен содержать латинские символы в нижнем и верхнем регистре, и цифры' : '',
            
            ],
            _step : 1
        },
        {
            title: 'Подтверждение пароля',
            field: 'checkPassword',
            topColsBlock: 2,
            cols: 1,
            required: true,
            format: FieldFormat.FORMAT_PASSWORD,
            check : [
                (value, {values}) => value !== values.newPassword ? 'Пароли должны совпадать'  : '',
            ],
            _step : 1
        },
        {
            field:  'isForeign',
            title:  'Иностранное лицо',
            format: FieldFormat.FORMAT_CHECKBOX,
            topColsBlock: 3,
            cols: 1,
            object:       ColBooleanSwitch,
            _step : 2
        },
        {
            title: 'ФИО',
            field: 'contact_fio',
            required: true,
            topColsBlock: 4,
            cols: 1,
            _step : 3
        },
        {
            title: 'Контактный телефон',
            field: 'contact_phone',
            required: true,
            topColsBlock: 4,
            cols: 1,
            _step : 3
        }
    ]
    const unityCols = isForeign ? colsIsForeign : colsIsNotForeign
    const newColsList = [...cols, ...unityCols]
    if(useStep){
        return newColsList.filter(el => el._step === _step)
    }
    return newColsList
}



export default {
    listTitle: "Регистрация на портале",
    onFormDataAction:  (values, props) => {
        const {action, value, field} = props;
        
        const _fix = {}
    
        switch (action){
            case FORM_ACTION.INIT:
                _fix._step = 0
                _fix.isForeign = false
                break;
            case FORM_ACTION.CHANGE:
          
                break;
            case FORM_ACTION.SUBMIT:
            
                break;
        }
    
        return {...values, ..._fix}
    },
    cols: (props, items) => {
        return createCols(items, true)
    },
    // listCols : [...cols, ...colsIsForeign, ...colsIsNotForeign],
    listCols: {
        createCols: createCols
    },
    topColsBlocks: [
        {
            num:    1,
            title:  'Учетные данные',
            cols:   1,
            border: true,
        },
        {
            num:    2,
            title:  'Безопасность',
            cols:   1,
            border: true,
        },
        {
            num:    3,
            title:  'Юридическое лицо',
            cols:   1,
            border: true,
        },
        {
            num:    4,
            title:  "Контактные данные",
            cols:   1,
            border: true,
        },
    ]
}