import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    TextField
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import ErrorBoundary from 'components/ErrorBoundary';
import HOCExplainCols from 'components/HOCExplainCols';
import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';
import FormDebug from 'components/RequestFormDebug';
import {SubFormEditCreate} from 'components/SubFormEditCreate';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import {config} from 'config';
import bidding from 'config/bidding';
import {FormDataContext} from 'FormDataContext';
import {transformColsNoContext} from 'components/MTableExtend/transformCols';
import MTEditRowContext from "components/MTableExtend/MTEditRowContext";
import MaterialTable from 'material-table';
import moment from 'moment';
import React from 'react';
import MTBodyRowContext from "../MTableExtend/MTBodyRowContext";
import useExplainCols from "../useExplainCols";
import {FieldFormat} from "../../config/FieldFormat";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useLoaderApi from 'components/useLoaderApi';

const textRow = {
    fastSearch: 'Быстрый фильтр',
    fastSortingIsActive : 'Активные',
} 

const useStyles = makeStyles(() => (
    {
       displayInline: {
           display: 'inline',
       },
       linckFilterSoring : {
           display: 'inline',
           marginLeft: '1rem',
           cursor: 'pointer', 
           color: '#3b71af',
           textDecoration: 'underline',
       },
       activeLinckFilterSoring : {
            display: 'inline',
            marginLeft: '1rem',
            cursor: 'pointer', 
            
        },
        m_025 : {
            margin: "0.25rem"
        },
    }
));

/**
 * @deprecated Вернуться на использование SubFormListEdit
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SubFormBiddings(props) {
    console.log('SubFormBiddings', props)
    const classes  = useStyles();
    const [isLoading, setIsLoading]   = React.useState(false);
    // const [columns, setColumns] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(false)
    const {stateContext,  deleteNodeState, state, valuesTop} = React.useContext(FormDataContext);
   console.log('statestate',state,valuesTop)
    
    const {
              listTitle,
              canEdit,
              canDelete,
              canAdd,
              onChange,
              item,
              itemValues,
              list,
              detail,
            filter,
              handleChange,
              setIsLoadingTop,
              parentRowData,
              onFormDataAction,
              filterTable,
              itemProps,
              forceReloadData,
          } = {
        setIsLoadingTop: setIsLoading,
        onFormDataAction: values => values,
        forceReloadData: () => {},
        canAdd:          true,
        canEdit:         true,
        canDelete:       true,
        ...props,
    };
   
    const valuesNewDefault = {
        'biddingType': 'Запрос',
    };

    const {values} = React.useContext(FormDataContext);
    // const list = {
    //     items: values,
    // };

    const field     = 'newBidding';
    const valuesNew = itemValues[field] || valuesNewDefault;
    const [sortingIsActive, setSortingIsActive ] = React.useState(filterTable && filterTable.array.length ? [...filterTable.array].shift().name : '');
    const [valuesState, setValuesState] = React.useState(() => {
        // TODO: refactor code
        return filterTable && filterTable.array.length
            ? (list.items||[])
                .filter(e => [...filterTable.array].shift().fields.includes(e[filterTable.field]))
            : [...list.items]
    });
    const [valuesAll, setValuesAll]  = React.useState(list.items && list.items.length ? [...list.items]: []);
    React.useEffect(()=>{
        console.log('useEffect', list)
        setValuesState(filterTable && filterTable.array.length
            ? (list.items||[])
                .filter(e => [...filterTable.array].shift().fields.includes(e[filterTable.field]))
            : [...list.items])
    },[list.items])

    

    // React.useMemo(() => {
    //     let c = bidding.cols;
    //
    //     if ('function' === typeof c) {
    //         console.log('setIsDefining c', props, bidding);
    //         c = c(props, list.items, {from: 'subFormBuilding'});
    //     }
    //     if (c instanceof Promise) {
    //         c.then(data => {
    //             console.log('setIsDefining', data, bidding.cols);
    //             setColumns(data.filter(v => v));
    //         });
    //     }
    //     else {
    //         setColumns(props.cols);
    //     }
    // }, [props, list, bidding]);

    const columns = useExplainCols(bidding.cols, {itemProps}, {},{from:'biddings'})
    
    const tableRef = React.createRef();
       
    React.useEffect(() => {
        if (isLoading) {
            // force close DetailPanel
            tableRef.current && tableRef.current.onToggleDetailPanel([0], '');
        }
    }, [isLoading, tableRef]);

   
    //Устанвливаем значение по таблицы и сортируем её согласно быстрому фильтру

    //Устанвливаем первоночальное значение для быстрой сортировки   
    React.useEffect(() => {
        console.log('itemProps2', itemProps.helperTextForDisableButton)
        
    }, [])
    
  
    const [itemCurrency, loadStateCurrency] = useLoaderApi('currencies', item.currency)

    let onRowDelete = oldData =>
        new Promise((resolve) => {
            setTimeout(() => {
                {
                    let data    = [...list.items];
                    const index = data.indexOf(
                        oldData);
                    data.splice(index, 1);
                    onChange(null, data);
                    resolve();
                    // this.setState({ data }, () =>
                    // resolve());
                }
                resolve();
            }, 100);
        });

    let onRowEdit = (newData, oldData) => {
        let data = [...valuesAll]
        data = data.splice(data.findIndex(item => item.guid === newData))
        setValuesState([...data, newData])
        setValuesAll([...data, newData])

        return fetch('/api/multi/biddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(newData)
        }).then(forceReloadData)
            .catch(e => { console.log(e) });
    }
        
    
    const onRowAdd = (values, action)  => {
        console.log('oRowAdd', values )
        const docDate = new Date;
        const newBidding = { ...values, docLinkedName: valuesTop._sub_name, docLinkedGuid: valuesTop.guid, docDate: docDate.toISOString('newBidding', newBidding)};
        // deleteNodeState('newBidding')
        setIsAdd(e => !e)
        return fetch('/api/multi/biddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(newBidding)
        })
        .then(response => response.json())
        .then(result => {
            newBidding.guid = result.guid
            newBidding.name = result.guid
            forceReloadData();
        }).catch(e => {console.log(e)});
       

    }

    const closeModal = () => {
        setIsAdd(e => !e)
    } 


    // const [addNew, setAddNew] = React.useState(false);

    const FullScreenWrapperNone = 'div';

    let detailInfo = rowData => {
        // TO
        console.log('props.cols', columns, detail.cols
            ? detail.cols(itemValues[detail.field], rowData)
            : []);
        return (
                <ErrorBoundary info={'Grid'} >
                    <Grid container spacing={2} style={{width: '80vw'}}>
                        {columns
                              .filter(i => !i.detailHidden)
                              .map(col => {
                            let value = rowData[col.field];
                            if (value && col.type === 'datetime') {
                                const last = moment(value);
                                value      = last.format('DD.MM.YYYY HH:mm:ss');
                            } else if (col.type === 'boolean') {
                                return         <FormControl><br />
                                    <FormControlLabel
                                            label={col.title}
                                            control={
                                                <Switch
                                                        disabled={true}
                                                        checked={!!value}
                                                        color="primary"
                                                />
                                            }
                                    />
                                </FormControl>
                            }
                                                  
                            let valueLocal = col.format === 'datetime_local' && moment(value).isValid() 
                                ? moment(value).format("DD.MM.YYYY HH:mm")
                                : value;

                            return <Grid item xs={3} className={classes.m_025}>
                                <TextField
                                        margin={'dense'}
                                        fullWidth
                                        label={col.title}
                                        value={valueLocal || ''}
                                        placeholder={'нет'}
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            // readOnly: true,
                                        }}
                                />
                            </Grid>;
                        })}
                    </Grid>
                </ErrorBoundary>
        );
    };

    const DialogEditCreate = HOCExplainCols({from: 'SubFormBiddings'})(DialogEditCreate3);
    
    const filterData = (filter = filterTable.array.find(i => i.name === sortingIsActive), newBidding = false) => {
        if (newBidding) {
            const data = [...valuesAll, newBidding];
            const newValues = filter.fields ? data.filter(e => filter.fields.includes(e[filterTable.field])) : [...data]
            setValuesState(newValues)
            setValuesAll(newValues)
            return null
        }
        const newValues = filter.fields ? valuesAll.filter(e => filter.fields.includes(e[filterTable.field])) : [...valuesAll]
        setValuesState(newValues)
    }

    const clickFilter = (filter) => () => {
        filterData(filter)
        setSortingIsActive(filter.name)
    }

    if(!columns){
        return <CircularProgress/>
    }
    const initial = bidding.initialValues && bidding.initialValues({item: itemValues, filter});
    return <React.Fragment>
        {/*{(new Date()).toJSON()}*/}
        {/*<pre>values={JSON.stringify(values, null, 2)}</pre>*/}
        <br />
        <DialogEditCreate
            title={'Форма для добавления новой торговой сессии.'}
            dbId={'guid'}
            open={isAdd}
            handleSubmit={onRowAdd}
            handleClose={closeModal}
            // onSuccess={}
            windowUsersetting={true}
            // initialValues={{...initialValues, ...item, ...props.filter}}
            FormComponent={SubFormEditCreate}
            // cols={[]}
            cols={bidding.cols}
            {...bidding}
            initialValues={initial}
            itemProps={itemProps}
            maxWidth={"lg"}
        />

        <Box style={{margin: '1rem 0'}}>
            <Button
                variant="contained"
                startIcon={<Add/>}
                style={{marginRight: '10px'}}
                onClick={() => setIsAdd(e => !e)}
                    disabled={!itemValues.guid}
                color="primary">
                Добавить
            </Button>
            {!itemValues.guid &&
            <Typography variant='body2' style={{margin: '1rem 0'}}>
                {itemProps.helperTextForDisableButton ? itemProps.helperTextForDisableButton : 'Для добавления новой торговой сессии необходимо сохранить текущую заявку.'}
            </Typography>
            }

        </Box>
            

        <Grid>
            {filterTable && <Typography variant='body2' className={classes.displayInline}>{`${textRow.fastSearch}:`}</Typography>}
            {filterTable && filterTable.array.map(e =>
                <Typography 
                    variant='body2' 
                    className={sortingIsActive == e.name ? classes.activeLinckFilterSoring : classes.linckFilterSoring}
                    onClick={clickFilter(e)}
                    >
                        {e.name}
                </Typography>
            )}
        </Grid>         
        <br />
        <MaterialTable
            sortingIsActive={sortingIsActive}
            isLoading={isLoading || !columns}
            title={listTitle}
            // columns={columns || []}
            // columns={transformColsNoContext(columns || [], onRowEdit)}
            columns={columns.filter(c => c.object || !c.detailHidden && c.format !== FieldFormat.FORMAT_NONE)}
            data= {valuesState}
            tableRef={tableRef}
            actions={[
                // canEdit && {
                //     icon:    EditIcon,
                //     tooltip: 'Редактировать',
                //     onClick: (event, rowData) => onEdit(rowData)(),
                // },
                //
                // {
                //     icon:         'refresh',
                //     tooltip:      'Обновить данные',
                //     isFreeAction: true,
                //     onClick:      () => tableRef.current.onQueryChange(),
                // },
            ]}
            {...config.MaterialTable}
            // components={{
            //     Cell: MyMTableCell,
            // }}
            options={{
                sorting:                true,
                search:                 false,
                showTitle:              false,
                paging:                 false,
                toolbar: false,
                toolbarButtonAlignment: 'left',
            }}
            components={{
                // Container: (props) => <Paper elevation={0} {...props} />,
                Row: MTBodyRowContext,
                EditRow: MTEditRowContext
                // EditRow: props => <pre>{JSON.stringify(props,null,2)}</pre>
                // Toolbar: props => (
                //     <div>
                //         <MTableToolbar {...props} />
                //     </div>
                // ),
            }}
            detailPanel={detail && [
                {
                    icon: () => <ArrowForwardIosIcon/>,
                    tooltip: 'Подробно',
                    render:  detailInfo,
                },
                data => detail(data, {currency : loadStateCurrency === 2 ? itemCurrency : ''}),
            ]}
            editable={{
                isEditHidden: (rowData) => filterTable && filterTable.array.find(item => item.name === 'Не активные').fields.includes(rowData.docStatus),
                // isDeleteHidden: () => !canDelete,
                onRowAdd:    canAdd
                                 ? onRowAdd
                                 : null,
                onRowUpdate: canEdit
                                 ? onRowEdit
                                 : null,
                onRowDelete: canDelete
                                 ? onRowDelete
                                 : null,
            }}
   
        />
        </React.Fragment>;
}

// const SubFormBiddings = HOCExplainCols({from: 'Biddings'})(BiddingForm)


export {SubFormBiddings}