import React from "react";

import {IconPackKanban} from 'components/icons';

const fields = {
  carrier: {
    title : "Перевозчик",
  },
  carrierDriver: {
    title : "Водитель",
  },
  carrierTransports: {
    title : "Транспортное средство",
  },
  typedTransport: {
    title : "Тип транспорта",
  },
  carbodyTypes: {
    title : "Тип загрузки",
  },
}

const defaultAcces = [
  "transportValue",
  "transportMass",
  "loadingTypes",
  "typedTransport",
  "carbodyTypes",
  "dangerClasses",
  "sumTotal",
];

const access = {
  Заявка: [ 
    ...defaultAcces
  ],
  Новая: [
    ...defaultAcces
  ],
  ВОбработке: [
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  НаПодписании: [
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  Подписана: [
    "carrier",
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  Выполняется: [
    "carrier",
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  НаПогрузке: [
    "carrier",
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  Отменена: [
    "carrier",
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  Выполнена: [
    "carrier",
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
  Архив: [
    "carrierDriver",
    "carrierTransports",
    ...defaultAcces
  ],
};


const Empty = () => (
  <div className="react-kanban-card__point">
    <p>Не определенно</p>
  </div>
)

const KanbanCardItem = ({ field, value, status, flex = false, loading = "", secondValue = "" }) => {

  if (!field) {
    return <Empty />
  }

  if (!status || !access[status].includes(field)) {
    return <></>
  }

  //Нужно сделать так const {title, Icon} =  {icon:IconPackKanban, title:"Не задано", ...fields[field]}
  const title  = fields[field] && fields[field].title ? fields[field].title : "Не задано"

  return (
    <div className="react-kanban-card__point">
    <smal title={title}>
    <IconPackKanban type={field} loading={loading} />
    </smal>
    <small
      className="react-kanban-card__point_text"
      title={title}
    >
      <span className={flex ? "value_flex" : "value"}>
        {typeof value == "function" ? value() : value}
      </span>
    </small>
  </div>
  )


}

const CardValueWeight = ({ field, value, status, secondValue = "" }) => {
  if (!field) {
    return <Empty />
  }

  if (!access || !status || !access[status].includes(field)) {
    return <></>
  }


  return (
    <div className="react-kanban-card__point">
      <smal title="Объем и грузоподъемность">
        <IconPackKanban type="transportValueCargo" />
      </smal>
      <small
        className="react-kanban-card__point_text"
        title={"Объем"}
      >
        <span className="value">
          {typeof value == "function" ? value() : value}
        </span>
        {"(в м^3)"}
      </small>
      <img
        src="kanbanIcons/weight.png"
        className=""
        width="20px"
        alt="weigth"
        style={{ margin: "0 5px" }}
      />
      <small
        className="react-kanban-card__point_text"
        title={"Грузоподъемность"}
      >
        <span className="value">
          {typeof secondValue == "function" ? secondValue() : secondValue}
        </span>
        {"(в кг. )"}
      </small>
    </div>
  )
}

export {KanbanCardItem, CardValueWeight, access}