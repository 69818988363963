import moment from 'moment';

import {config} from 'config'

const getDateLoad = (item) => {
    return item.dateFrom
        ? new Date(item.dateFrom)
        : null;
};

// const getDateUnload = (item) => {

//     return item.dateTo
//         ? new Date(item.dateTo)
//         : null;
// };


const NO_DATE = '---';

export const getMinDate = list => list && list.length
    ? list.reduce(
        (A, B) => getDateLoad(A) < getDateLoad(B)
            ? A
            : B,
        list[0],
    )
    : null;

export const getMaxDate = list => list && list.length
    ? list.reduce(
        (A, B) => getDateLoad(A) > getDateLoad(B)
            ? A
            : B,
        list[0],
    )
    : null;


export const ShowLoadingDate = ({item, formatTime}) => {
    const min = getMinDate(item);
    // return JSON.stringify(min);
    if(formatTime){
        return min && new Date(min.dateFrom) > config.timeClipping 
        ? min.dateFrom
        : NO_DATE;
    }
    return min && new Date(min.dateFrom) > config.timeClipping 
        ? moment(min.dateFrom).format('DD.MM.YYYY HH:mm')
        : NO_DATE;
};

export const ShowUnloadingDate = ({item, formatTime }) => {
    const max = getMaxDate(item);
    
    if(formatTime){
        return max && new Date(max.dateFrom) > config.timeClipping 
        ? max.dateFrom
        : NO_DATE;
    }
    return max && new Date(max.dateFrom) > config.timeClipping 
        ? moment(max.dateFrom).format('DD.MM.YYYY HH:mm')
        : NO_DATE;
};

export const ShowLoadingPlacesFrom = ({item}) => {
    const min = getMinDate(item);
    return min && min.pointName
        ? min.pointName
        : NO_DATE;
};

export const ShowUnloadingPlacesFrom = ({item}) => {
    const max = getMaxDate(item);
    return max && max.pointName
        ? max.pointName
        : NO_DATE;
};

export const ShowLoadingPlacesFromNotDate = ({ item }) => {
    const itemIsLoading = item.filter(el => el.isLoading)

    const point = itemIsLoading.shift()
    return point ? point.pointName : NO_DATE

};

export const ShowUnloadingPlacesFromNotDate = ({ item }) => {
    const itemIsUnloading = item.filter(el => el.isUnloading)

    const point = itemIsUnloading.pop()
    return point ? point.pointName : NO_DATE

};