import React from "react";
import {Tooltip, IconButton, Grid, Typography} from "@material-ui/core";

import HelpIcon from '@material-ui/icons/Help';
import {HelpWindow} from "./HelpWindow";
import {useLocation } from 'react-router-dom';


export const reduceNotRead = (A, B) => A + Number(B.notRead)


export const Help = props => {
    const path = useLocation().pathname
    const pathPageHelp = '/help'
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => path === pathPageHelp ? null : setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
        <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleOpen}
                edge="start"
        >
            <Tooltip title={"Справка"}>
                <HelpIcon/>

            </Tooltip>
        </IconButton>
        <HelpWindow open={open} onClose={handleClose}/>

    </>
}