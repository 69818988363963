import {Grid, Switch} from '@material-ui/core';
import {FormDataContextConsumer} from 'FormDataContext';
import {MeContext} from 'meContext';
import React from 'react';
import ErrorMessage from './ErrorMessage';

/**
 * @return {null}
 */
export default function RequestFormDebug({errors, values, debug}) {
    const [checked, setChecked] = React.useState(false);
    const {roles}               = React.useContext(MeContext);
    const toggleChecked         = () => {
        setChecked((prev) => !prev);
    };
    return <React.Fragment>{roles && roles.includes('ROLE_DEBUG') && <Grid item xs={12}>
        <Switch checked={checked} onChange={toggleChecked}/>
        {checked && <pre>formDebug[{JSON.stringify(debug)}]:
            {JSON.stringify(errors, null, 2)}
            {JSON.stringify(values, null, 2)}
        </pre>}
    </Grid>}
    </React.Fragment>;
}

export function RequestFormDebugContext() {
    return <FormDataContextConsumer>{context => <RequestFormDebug
        values={context.state.values}
        debug={context.debug + JSON.stringify({
            field: context.item ? context.item.field : 'none',
            parentField:context.itemTop && context.itemTop.field || 'none'
        })}
        errors={context.state.errors}/>
    }</FormDataContextConsumer>;
}

export function RequestFormErrors({cols}) {
    const eachErrorMessage = ([field, value]) => {
        const val = JSON.stringify(value);
        if (field === 'errors') {
            return <ErrorMessage title={val} extended={false}/>;
        }
        return <React.Fragment>
            <ErrorMessage title={field + ': ' + val} extended={false}/>
        </React.Fragment>;
    };

    return <FormDataContextConsumer>{context => {
        let errors = context.state.errors;
        return errors && Object.entries(errors)
            .filter(([field]) => !cols.find(i => i.field === field))
            .slice(0, 10)
            .map(eachErrorMessage);
    }}</FormDataContextConsumer>

}