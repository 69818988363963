import {config} from "../config";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import {FormDataContext, FormDataContextProvider} from "../FormDataContext";
import useLoaderApi, {useLoaderApiList} from "./useLoaderApi";
import useExplainCols from "./useExplainCols";
import {STATE} from "../libs/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {ObjectDetect} from "./ObjectDetect";
import * as PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import {FieldFormat} from "../config/FieldFormat";
import ErrorBoundary from "./ErrorBoundary";
import HOCExplainCols from "./HOCExplainCols";
import InputAdornment from "@material-ui/core/InputAdornment";
import RequestForm from "./RequestForm";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import asArray from "libs/asArray";


function getObjectByApi(item) {
    const found = Object.values(config).find(o => o.apiName === item.api);
    return {
        cols: [],
        ...found,
    };
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



const SubFormShow = ({item}) => {

    const {state: {values}} = React.useContext(FormDataContext);

    const {api, apiFilter} = item;

    const query = values[item.field];
    console.log('listValues',{query})
    const [listValues, loadState] = useLoaderApi(query, null, {}, {prefix: '', mode: 'query'});

    const subConfig = config.get(item.subConfig);

    const columns = useExplainCols(subConfig.cols, {}, item, {subForm:item}, []);

    switch (loadState) {
        case STATE.LOADING:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{subConfig.listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            <CircularProgress/>
                        </TableRow>
                    </TableBody>
                </Table>
            </>
        case STATE.LOADED:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{subConfig.listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        {columns.map(col => <TableRow>
                            <StyledTableCell>{col.title}</StyledTableCell>
                            <StyledTableCell><ObjectDetect
                                rows={listValues}
                                field={col.field}
                                item={listValues}
                                row={col}
                                // props={props}
                            /></StyledTableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
                {/*SubFormEditCreateTableLoader: {JSON.stringify({listValues}, null, 2)}*/}
                {/*SubFormEditCreateTableLoader: {JSON.stringify({subConfig}, null, 2)}*/}
                {/*SubFormEditCreateTableLoader: {JSON.stringify({columns}, null, 2)}*/}
            </>
        default:
            return 'ErrorSubForm:';
    }
}

const SubTableShow = ({item}) => {
    const {field} = item;

    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const {api, apiFilter} = item;
    // console.log('SubFormEditCreateTable props', props);

    const query = apiFilter(values);

    const [listValues, loadState] = useLoaderApiList(api,query);

    const subConfig = config.get(item.subConfig);

    const columns = useExplainCols(subConfig.cols, {}, item, {}, []);

    switch (loadState) {
        case STATE.LOADING:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{subConfig.listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            <CircularProgress/>
                        </TableRow>
                    </TableBody>
                </Table>
            </>

        case STATE.LOADED:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{subConfig.listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            {columns.map(col => <StyledTableCell>{col.title}</StyledTableCell>)}
                        </TableRow>
                        {(listValues||[]).map(row => <TableRow>
                            {columns.map(col => <StyledTableCell><ObjectDetect
                                rows={listValues}
                                field={col.field}
                                item={row}
                                row={col}
                                // props={props}
                            /></StyledTableCell>)}
                        </TableRow>)}
                    </TableBody>
                </Table>
                {/*SubFormEditCreateTableLoader: {JSON.stringify({listValues}, null, 2)}*/}
                {/*SubFormEditCreateTableLoader: {JSON.stringify({subConfig}, null, 2)}*/}
                {/*SubFormEditCreateTableLoader: {JSON.stringify({columns}, null, 2)}*/}
            </>
        default:
            return 'ErrorSubTable ' + subConfig.listTitle ;
    }

    // return <>
    //     <pre>
    //     SubFormEditCreateTableLoader: {JSON.stringify({listValues},null,2)}
    //         </pre>
    //
    //     <SubFormEditCreateTable loaded={listValues} loadState={STATE.LOADED} />
    // </>;
    //
    //
    //
    // return <React.Fragment>
    //     <AdminShow>[SubTable:{field}]</AdminShow>
    //     {/*values={JSON.stringify(values)}*/}
    //     <SubFormEditCreateTable
    //         item={item}
    //         itemValues={values}
    //         onChange={handleChange(item)}
    //         // apiFilter={apiFilter && apiFilter(values,parent)}
    //         values={values[field]}
    //         errors={errors[field]}
    //         {...config.get(item.subConfig)}
    //     />
    // </React.Fragment>;
}


function SubTableShowSubItems(props) {
    const {subItem} = props;
    const {cols,listTitle} = subItem;
    const item = subItem;
    console.log('subItem',{subItem, cols});

    // return JSON.stringify({cols});
    const {field} = item;

    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const {api, apiFilter} = item;
    // console.log('SubFormEditCreateTable props', props);

    const query = apiFilter(values);

    const [listValues, loadState] = useLoaderApiList(api,query);

    const subConfig = config.get(item.subConfig);

    const columns = useExplainCols(cols, {}, item, {}, []);

    switch (loadState) {
        case STATE.LOADING:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            <CircularProgress/>
                        </TableRow>
                    </TableBody>
                </Table>
            </>

        case STATE.LOADED:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            {columns.map(col => <StyledTableCell>{col.title}</StyledTableCell>)}
                        </TableRow>
                        {(listValues||[]).map(row => <TableRow>
                            {columns.map(col => <StyledTableCell><ObjectDetect
                                rows={listValues}
                                field={col.field}
                                item={row}
                                row={col}
                                // props={props}
                            /></StyledTableCell>)}
                        </TableRow>)}
                    </TableBody>
                </Table>

            </>
        default:
            return <>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}><b>{listTitle}</b></StyledTableCell>
                        </TableRow>
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            {columns.map(col => <StyledTableCell>{col.title}</StyledTableCell>)}
                        </TableRow>
                        <TableRow>
                            <StyledTableCell colSpan={columns.length}>{listValues}</StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </>

    }

}

SubTableShowSubItems.propTypes = {subItem: PropTypes.any};


const ShowItemCard = props => {
    const {item, showApiInItemInfo} = props;
    const {cols} = React.useContext(FormDataContext);
    const [subItems, setSubItems] = React.useState({list:[]});


    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
    });

    const classes = useStyles();

    React.useEffect(() => {
        Promise.resolve(
            'function' === typeof props.subItems
                ? props.subItems({...item})
                : props.subItems
        ).then(items => items && setSubItems({...items, list : items.list.filter(el => showApiInItemInfo ? asArray(showApiInItemInfo).includes(el.api): el)}))
    },[item]);

    const MainBkock = <Table size="small" className={classes.table} aria-label="customized table">
        <TableHead>
            <TableRow>
                <StyledTableCell>Параметр</StyledTableCell>
                <StyledTableCell align="right">Значение</StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {/*<TableParamsShow*/}
            {/*    rows={cols}*/}
            {/*    item={item}*/}
            {/*/>*/}
            {cols.map((row, key) => {
                switch (row.format) {
                    case FieldFormat.FORMAT_NONE:
                        return null;
                    case FieldFormat.FORMAT_SUB_FORM:
                        return <>
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row" colSpan={2}>
                                    <ErrorBoundary info={'FORMAT_SUB_FORM'}>
                                        <SubFormShow item={row} />
                                    </ErrorBoundary>
                                </StyledTableCell>
                            </StyledTableRow>
                        </>
                    case FieldFormat.FORMAT_SUB_TABLE:
                        return <>
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row" colSpan={2}>
                                    <ErrorBoundary info={'FORMAT_SUB_TABLE'}>
                                        <SubTableShow item={row} />
                                    </ErrorBoundary>
                                </StyledTableCell>
                                {/*[SubTable:{JSON.stringify({row})}]*/}
                            </StyledTableRow>
                        </>
                    default:
                        return <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">
                                {row.title}
                            </StyledTableCell>
                            <ErrorBoundary info={'default'}>
                                <StyledTableCell align="right">{item && <ObjectDetect
                                    rows={cols}
                                    field={row.field}

                                    item={item}
                                    row={row}
                                    props={props}
                                />}</StyledTableCell>
                            </ErrorBoundary>
                        </StyledTableRow>
                }
            })}
        </TableBody>
    </Table>


    console.log('subItems',{subItems,psubItems:props.subItems},props)

    return <>

        {!showApiInItemInfo || showApiInItemInfo.includes("main") ? MainBkock : <></>}

        {subItems.list
            .filter(i=>!!i.apiFilter)
            .map((subItem,key) => {
                return <>
                    <StyledTableRow key={key}>
                        <StyledTableCell component="th" scope="row" colSpan={2}>
                            <ErrorBoundary info={'subItem'}>
                                <SubTableShowSubItems subItem={subItem}/>
                            </ErrorBoundary>
                        </StyledTableCell>
                    </StyledTableRow>
                </>
            })}


        {/*<pre>{JSON.stringify([cols, item], null, 2)}</pre>*/}
    </>;
}

const ShowCard = ShowItemCard;

/**
 * 'Лупа' для элемента
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const ItemInfo = props => {
    const {item, itemLoaded} = props;
    const {title, showApiInItemInfo}         = item;
    const [open, setOpen] = React.useState(false);
    const onClose         = () => setOpen(false);
    const object          = getObjectByApi(item);
    if (!itemLoaded || !object.cols.length) {
        return null;
    }
    return <InputAdornment position="end">
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth={true}>
            <DialogTitle>
                <Typography variant={'h5'}>{title}</Typography>
            </DialogTitle>
            <DialogContent style={{ overflow: "scroll" }}>
                {/*{JSON.stringify({item})}*/}
                {/*{JSON.stringify(object.cols)}*/}
                {/*<RequestFormTabs*/}
                {/*        {...object}*/}
                {/*        dbId={'guid'}*/}
                {/*        // itemName={itemName}*/}
                {/*        // open={true}*/}
                {/*        // handleSubmit={handleSubmit(apiName)}*/}
                {/*        // handleClose={openDialogEdit(false)}*/}
                {/*        // subApi={subApi}*/}
                {/*        // tab={sublist||'edit'}*/}
                {/*        // onSuccess={onElementUpdate}*/}
                {/*        initialValues={itemLoaded}*/}
                {/*        FormComponent={RequestForm}*/}
                {/*        subItems={{*/}
                {/*            list: [],*/}
                {/*        }}*/}
                {/*        topColsBlocks={[*/}
                {/*            {*/}
                {/*                num:  1,*/}
                {/*                cols: 1,*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*        // cols={cols}*/}
                {/*        // fullScreen={fullScreen}*/}
                {/*        // path={path + '/' + (guid || 'new')}*/}
                {/*/>*/}
                <FormDataContextProvider cols={object.cols} initial={itemLoaded} debug={'ItemInfo'}>
                    <ShowCard
                        {...object}
                        dbId={'guid'}
                        // itemName={itemName}
                        // open={true}
                        // handleSubmit={handleSubmit(apiName)}
                        // handleClose={openDialogEdit(false)}
                        // subApi={subApi}
                        // tab={sublist||'edit'}
                        // onSuccess={onElementUpdate}
                        item={itemLoaded}
                        initialValues={itemLoaded}
                        FormComponent={RequestForm}
                        showApiInItemInfo={showApiInItemInfo}
                        // cols={cols}
                        // fullScreen={fullScreen}
                        // path={path + '/' + (guid || 'new')}
                    />
                </FormDataContextProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
        <IconButton size={'small'} edge="end" onClick={() => setOpen(true)}>
            <SearchIcon/>
        </IconButton>
    </InputAdornment>;
};
