import React from "react";
import {Alert} from '@material-ui/lab';
// import useLoaderApi from 'components/useLoaderApi';
import API from '../libs/api';
import { Box,
    Typography, 
    TextField,
    CircularProgress,
    Breadcrumbs,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Chip  
} from '@material-ui/core';

import {config } from 'config'

import {ApiDataLoad} from "components/ApiDataLoad";




export const CheckModalWindowPrintendForms = ({ arrayData }) => {


    const [content, setContent] = React.useState([])

    const ContentCell = (props) => {

        const {justifyContent} = props

        return (
            <Box display="flex" alignItems="center" justifyContent={justifyContent} p={1}>
                {props.children}
            </Box>
        )
    }

    const  DescriptionCell = ({content}) => {

       
        if (content.object && content.object.length && !content.error) {
            return (
                <ContentCell justifyContent={"left"}>
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                        {
                            content.object.map(
                                item => <Link color="inherit" href="#" onClick={e => {e.preventDefault()}}>
                                    {item}
                                </Link>
                            )
                        }

                    </Breadcrumbs>
                </ContentCell>

            )
        }

        if(content.error){
            return (
                <ContentCell justifyContent={"left"}>
                 <Alert severity="warning">{content.object}</Alert>
            </ContentCell>
            )
        
        }
    
        return (
            <ContentCell justifyContent={"center"}>
                <CircularProgress size={20}/>
            </ContentCell>
        )
    
    }

    
    const TableBodyContent = () => {

        //Здесь происходит сортировка полученных данных и вывод их в таблице 
        const statusLoading = content.length ? (content.length / arrayData.length) * 100 : 0

        if (statusLoading === 100) {

            const arrayForSorting = []

            content.map((item, index) => {
                arrayForSorting.push({ o: [...item.object].shift(), i: index })
            })


            return (

                <>
                    {arrayForSorting.sort(function (a, b) {
                        if (a.o.toLowerCase() < b.o.toLowerCase())
                            return -1
                        if (a.o.toLowerCase() > b.o.toLowerCase())
                            return 1
                        return 0
                    })
                        .map((a) =>
                            <TableRow>
                                <TableCell>
                                    <DescriptionCell content={content[a.i]} />
                                </TableCell>
                                <TableCell>
                                    <LinkCell content={content[a.i]} />
                                </TableCell>
                            </TableRow>
                        )}
                </>
            )
        }

        return (
            <LinearProgress variant="determinate" value={statusLoading} />
        )
    }

    
    const LinkCell = ({content}) => {
        
        if (content.error) {
            return (
                <ContentCell justifyContent={"center"}>

                </ContentCell>
            )
        }

        if (content.link && content.link.length && !content.error) {
            return (
                <ContentCell justifyContent={"right"}>
                    <Link href={`/${content.link.join('/')}`} target="_blanck">
                        <Chip size="small" variant="outlined" color="primary" label="Перейти" />
                    </Link>
                </ContentCell>
            )
        }
    
        return (
            <ContentCell justifyContent={"center"}>
                <CircularProgress size={20}/>
            </ContentCell>
        )
    
    }
    
    const loadingAndHandlerData = (data) => {
        //Здесь разбервем каждый объект полученый от бека, дозагружаем данные и отправлям их CreateDataForTable чтоб их обработать в CreateDataForTable
        // Результат работы функции сохраням в state
        // Пример:
        // {
        //     field: "ContactInfos"
        //     object: "legals"
        //     path: [{ object: "legals", guid: "0c9399ae-9e8d-4f58-af59-bdc4c766e9eb" }]
        //     status: "Empty"
        //     type: "Телефон организации"
        // } 

        const promises = []
        try {
            data.path.map(el => promises.push(ApiDataLoad(`/api/${checkObjectForApi(el.object)}/${el.guid}`)))
        } catch {
            if (data.field.includes('extraData[')) {
                data.extraDataField = data.field.replace('extraData[', '').replace(']', '');
                data.field = 'extraData'
                data.path = [{
                    object: data.object,
                    guid: data.guid
                }];
                promises.push(ApiDataLoad(`/api/${checkObjectForApi(data.object)}/${data.guid}`));


            } else {
                promises.push(ApiDataLoad(`/api/${checkObjectForApi('')}/${''}`))
            }


        }
        return Promise.all(promises)
            .then(resultPromises => CreateDataForTable( {
                    ...data,
                    path: data.path,
                    parents : resultPromises 
                })           
            )
            .catch(err => {
                return ({
                    object: `${err} ${data.object}`,
                    error: true
                })
            })
    }

    const checkObjectForApi  = (object) => {
        //Проверям имя оюъект api объекта на фронте  
        const exception = {
            // driver: 'drivers',
            // individual: 'individuals',
            // bankaccount: "bank_accounts",
            // doc_requests : "doc_requests",
            // docrequest: "doc_requests",
            // docrequestrouteplace: "doc_request_route_places",
            // docrequest_routeplace: "doc_request_route_places",
            // transport: "transports"
        }
        return exception[object.toLowerCase()] ? exception[object.toLowerCase()] : object
        
    }
    const checkObjectForLink  = (object) => {
        //Проверям объект на сотвествии пути для объектов на фронте, path для ссылке
        const exception = {
            // BankAccount : "bank_accounts",
            // Legals : "legals",
            // Driver: "drivers",
            // driver: "drivers",
            // DocRequest: "doc_requests",
            // docRequest: "doc_requests",
            // Transport: "transports"
        }
        return exception[object] ? exception[object] : object
        
    }
    const checkField = (object) => {
        //Проверям полученый филд на сотвествии филда в конфиге на фронте
        const exception = {
            // CarrierTransports : "carrierTransports",
            // Date: "dateFromText",
            // Contragent: "contragent",
            // Contact: "contact",
            // Model: "model",
            // Marka : "marka",
            // Transport_types : "transport_types",
            // Number : "number",
            // Mass : "mass",
            // Value : "value"
            
        }
        return exception[object] ? exception[object] : object
    }


    const showCols = object => {
        //Проверям объекта на соотвествии имении конфига, возражаем cols конфига.  
        // console.log("push2", object)
        const exception = {
            // Legals : "legals",
            bank_accounts : 'bankAccount',
            individuals : 'individual',
            // DocRequest: "docRequest",
            doc_requests : "docRequest",
            doc_request_route_places: 'docRequest_routePlaces',
            // docRequest_routePlace: 'docRequest_routePlaces',
            // DocRequestRoutePlace : 'docRequest_routePlaces',
            // Transport: "transport",
            transports: "transport",
            contracts: "contract",
            contact_people: "contactPerson",
            drivers: "driver"


        }
        if(typeof config[exception[object] ? exception[object] : object].cols == "object"){
            return config[exception[object] ? exception[object] : object].cols
        }
        return config[exception[object] ? exception[object] : object].cols({}, {}, {}) 
        
    } 


    const CreateDataForTable = (data) => {
        // Здесь происходит обработка полученых данных, возвращаем объект по которому строим ссылки и описание ссылке 
        // Пример получаемого объекта
        // {
        //     field: "ContactInfos"
        //     object: "legals"
        //     parents: Array(1)
        //     0 : { isTest: false, isCarrier: true, isForeign: false, isTPValid: true, inn: '', … }
        //     length: 1

        //     path: Array(1)
        //     0 : { object: 'legals', guid: '8f08e775-9625-11ea-b814-005056a60abf' }
        //     length: 1

        //     status: "Empty"
        //     type: "Телефон организации"
        // }
        // console.log("push2 api2", data)
        return Promise.all([showCols(data.object)])
        .then(answer => {
            let object = []
            let link = []
            
            data.path.filter(el => el.object !== 'individuals').map((el, ind) => {
                switch (el.object) {
                    case 'legals' :
                        const isCarrier = data.parents[ind].isCarrier;
                        link.push(`${isCarrier ? "carrier"  : "my"}`)
                        link.push(`${checkObjectForLink(el.object)}`)
                        link.push(`${el.guid}`);

                        // object.push(`${isCarrier ? "Перевозчик"  : "Заказчик"}`)
                        object.push(config.legals.itemName) 
                        object.push(`${data.parents[ind].name}`);
                        break;
                    case 'Driver':
                        link.push(`${checkObjectForLink(el.object)}`)
                        link.push(`${el.guid}`);
                        object.push(`${data.parents[ind].name}`);
                        break;
                    case 'doc_request_route_places':

                        link = [`${data.parents[ind].docRequest.replace('/api/', '')}`, `tab-2`];
                        object.push('Точка машрута')
                        object.push(`${data.parents[ind].pointName}`);
                        break;
                    default:
                        link.push(`${checkObjectForLink(el.object)}`)
                        link.push(`${el.guid}`);
                        object.push(`${data.parents[ind].name}`);

                }
            })
            switch(data.field){
                case 'cargos':
                    object.push(`Грузы`)
                    break;
                case 'ContactInfos':
                    object.push(`Контакты Фирмы`)
                    object.push(`${data.type}`);
                    link.push(`contact_infos`);
                    break;
                case 'mainBankAccount':
                    object.push(`Банковские счета`)
                    object.push(`Главный счет`);
                    link.push(`bank_accounts`);
                    break;
                case 'extraData':
                    Promise.all([config.docRequest.subItems({})])
                    .then(result => {
                        const cols = result.shift().list.shift().cols({})
                        object.push(`Данные ТН`)
                        object.push(`${cols.filter(el => el.field === checkField(data.extraDataField))[0].title}`);
                        link.push(`tab-1`);
                    })
                    
                    break
                default:
                    object.push(`${answer[0].filter(el => el.field === checkField(data.field))[0].title}`)

            }
            return {object, link}
           
            
        })
        .catch(err => {
            // console.log("push2 error", err)
            const object = `${data.parents[0].name} не удалось загрузить ${data.field}`;
            return {
                object,
                error: true
            } 
        })

    }

    React.useEffect(()=>{
        arrayData.map(rowData => {
            loadingAndHandlerData(rowData)
            .then(
                result => {
                    setContent(item => [...item, result])   
                } 
            )
            .catch(err => {
                setContent(item => [...item, err])
              
            })
        })
        
        
    }, [arrayData])
  
    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table" size="small" >
                {/* <TableHead>
                    <TableRow>
                        <TableCell>Раздел</TableCell>
                        <TableCell align="right">Ссылка</TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody>
                  
                    {
                        <TableBodyContent/>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

}