import {Breadcrumbs, Typography, Box} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";

export const DialogTitleBreadcrumbs = ({breadcrumbs}) => {

    return (
            <>
                <Box display={"flex"}>
                    <Breadcrumbs
                            separator={'->'}
                            aria-label="breadcrumb"
                    >
                        {
                            breadcrumbs.map(el => <Typography variant={"body2"}>{el}</Typography>)
                        }
                    </Breadcrumbs>
                </Box>

            </>
    )
}