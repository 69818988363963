import React from "react";
import {IMaskInput} from 'react-imask';

export default function TextMaskCustom(props) {
    const {onChange, value, ...other} = props;
    const onAccept = (value, mask) => {
        if (props.value !== value) {
            onChange({target: {name: props.name, value}})
        }
    }

    return (
        <IMaskInput
            {...other}
            value={String(value)}
            onAccept={onAccept}
            // overwrite
        />
    );
} 