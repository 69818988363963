import {MeasureIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageMeasure from 'page/PageMeasure';

export default {
    listTitle: 'Упаковка',
    apiName:   'measures',
    itemName:  'Упаковку',
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Упаковка',
        path:      '/measures',
        Component: PageMeasure,
        roles:     [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        icon:      MeasureIcon,
    },
    cols:      [
        {
            title: 'Название упаковки',
            field: 'name',
        },
    ],
};
