import React from 'react';
import {Box} from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import {AddBreadCrumb} from 'components/Menu';
import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';
import {FormDataContext, FormDataContextProvider} from 'FormDataContext';
import AdminShow from "components/FieldFormat/AdminShow";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    
    return (
        <ErrorBoundary info={'Box'}>
            <Box
                style={{width: '100%'}}
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </Box></ErrorBoundary>
    );
}

const ShowTabPanel = (props) => {
    const defaultProps = {
        handleSuggest: () => {},
        colsInTab:     false,
    };

    const {tab, i, n} = {...defaultProps, ...props};
    // console.log('ShowTabPanel', props)
    const {state: {values, errors}, handleChange, cols} = React.useContext(FormDataContext);
    // const {state: {values, errors}, handleChange} = {state: {values: {}, errors: {}}, handleChange: () => {}};
    
    const handleChangeValues = item => (event, newValues) => {
        console.error('use old handleChangeValues');
        handleChange(item)(null, newValues);
    };

    const handleChangeWarning = item => (event, newValue, setStateCallBack) => {
        console.error('use old handleChange');
        handleChange(item)(event, newValue, setStateCallBack)
    }

    const {Component, apiName, ...other} = {
        Component: SubFormListEdit,
        apiName: `tab-${n}`,
        ...i
    }

    return <TabPanel value={tab} index={apiName} key={n}>
        <AdminShow>[TAB:{n}]</AdminShow>
        <ErrorBoundary info={'.Component || SubFormListEdit'}>
            <AddBreadCrumb label={i.listTitle}/>
            {i.format && 'i.format removed was <FormMultiSelect/>'}
            <Component
                inTab={true}
                formProps={props}
                itemProps={i}
                item={values}
                itemValues={values}
                handleChange={handleChangeWarning}
                onChange={handleChangeValues(i)}
                // apiFilter={apiFilter && apiFilter(values)}
                values={values[i.field] || []}
                errors={errors[i.field] || []}
                cols={i.cols || []}
                initialValues={i.initialValues || (() => ({}))}
                {...other}
            />
        </ErrorBoundary>
    </TabPanel>;
};

export default ShowTabPanel;