import ErrorBoundary from 'components/ErrorBoundary';
import {FormDataContext, FormDataContextConsumer} from 'FormDataContext';
import React from 'react';
import {
    FormControl, FormGroup, FormLabel,
    Grid,
} from '@material-ui/core';

import ErrorMessage from 'components/ErrorMessage';
import {FieldFormat} from 'config/FieldFormat';
import FormDebug from 'components/RequestFormDebug';
import {RenderList} from 'config/RenderList';
import AdminShow from 'components/FieldFormat/AdminShow';

const eachErrorMessage = ([field, value]) => <ErrorMessage
    title={field + ': ' + value} extended={false}
/>;

const defaultProps = {
    handleChange: () => console.warn('handleChange not set'),
    values:       {},
    errors:       {},
    debug:        true,
    cols:         [],
};

export default function RequestForm(props) {

    const {
              submitting,
              debug,
              topColsBlocks,
              parent,
              subForm,
          }
              = {...defaultProps, ...props};

    if (props.cols) {
        console.error('deprecated: usage of cols')
    }
    // const [columns, setColumns] = React.useState([]);

    const {cols: columns} = React.useContext(FormDataContext);

    // React.useMemo(() => {
    //     console.log('RequestForm',{cols})
    //     let c = 'function' === typeof cols
    //         ? cols(props, {}, {subForm,from: 'requestForm'})
    //         : cols;
    //     console.log('RequestForm',{c})
    //
    //     if (c instanceof Promise) {
    //         c.then(data => setColumns(data));
    //     } else {
    //         setColumns(c);
    //     }
    //     // eslint-disable-next-line
    // }, [cols]);

    const columnsMap = (item, key) => {
        const {cols, render, format} = {
            cols:   1,
            format: FieldFormat.FORMAT_INPUT,
            ...item,
        };
        let sm                       = 12 / cols;

        const Render = RenderList(format);
        if (format === FieldFormat.FORMAT_NONE) {
            return null;
        }
        return <Grid item key={key} sm={sm} xs={12}>
            <ErrorBoundary info={'RequestForm'}>
                <Render
                    item={item}
                    submitting={submitting}
                    parent={parent}
                />
            </ErrorBoundary>
        </Grid>;
    };

    const topColsBlocksMap = ({topColsBlocks, columns}) => (block, blockKey) => {
        const {cols, title, num} = {
            num:  blockKey + 1,
            cols: topColsBlocks.length,
            ...block,
        };
        return (
            <Grid key={blockKey} item sm={12 / cols}>
                <FormControl component="fieldset" style={{width: '100%'}}>
                    <FormLabel component="legend">{title}</FormLabel>
                    <FormGroup>
                        <Grid container spacing={3}>
                            <ErrorBoundary info={'columns'}>
                                {columns
                                    .filter(b => b.topColsBlock === num)
                                    .map(columnsMap)}
                            </ErrorBoundary>
                        </Grid>
                    </FormGroup></FormControl>
            </Grid>
        );
    };

    if (props.cols) {
        return <>deprecated: usage of cols</>;
    }
    return (
        <Grid container spacing={3}>
            <AdminShow>[RequestForm]</AdminShow>
            {topColsBlocks && Array.isArray(topColsBlocks)
                ? topColsBlocks.map(topColsBlocksMap({columns, topColsBlocks}))
                : (columns||[]).map(columnsMap)
            }
            <FormDataContextConsumer>{context => {
                let errors = context.state.errors;
                return errors && Object.entries(errors)
                                       .filter(([field]) => !columns.find(i => i.field === field))
                                       .slice(0, 10)
                                       .map(eachErrorMessage);
            }}</FormDataContextConsumer>
        </Grid>
    );

}