import {FieldFormat} from "../config/FieldFormat";
import {RenderList} from "../config/RenderList";
import {Grid} from "@material-ui/core";
import ErrorBoundary from "./ErrorBoundary";
import React from "react";
import {InputPropsDefault} from "./EndAdornmentComponent"

export default function FormShowField(props) {
    const {cols, format, parent, ...item} = props;
    if (format === FieldFormat.FORMAT_NONE) {
        return null;
    }
    const Render = RenderList(format);
    let sm = 12 / cols;

    if(!parent) {
        // console.error('parent not set')
    }
    return <Grid item sm={sm} xs={12}>
        <ErrorBoundary info={'FormShowField:'+format}>
            <Render
                item={props}
                // submitting={submitting}
                parent={parent}
                InputPropsDefault={InputPropsDefault(props)}
            />
        </ErrorBoundary>
    </Grid>;
}


FormShowField.propTypes = {};
