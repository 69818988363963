import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import ShowTabPanel from 'components/FieldFormat/ShowTabPanel';
import {FieldFormat} from 'config/FieldFormat';
import {AppBar, CircularProgress, Grid, Tab, Tabs,} from '@material-ui/core';
import {Link} from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import useListTabs, {uniqTabs} from "components/useListTabs";
import FormFieldsShow from "components/FormFieldsShow";

export {FieldFormat} from 'config/FieldFormat';

function a11yProps(index) {
    return {
        id:              `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const defaultProps = {
    handleSuggest: () => { },
    colsInTab:     false,
    showJournalUser : false,
    // subItems:      {
    //     list: [],
    // },
    // topColsBlocks: [
    //     {
    //         num:  0,
    //         cols: 1,
    //     }
    // ],
    // _handleChange: console.log(),
    // handleChange: FormDataContextConsumer(context => console.log('context',context )),
};


const eachErrorMessage = ([field, value]) => {
    const val = JSON.stringify(value);
    if (field === 'errors') {
        return <ErrorMessage title={val} extended={false}/>;
    }
    return <React.Fragment>
        <ErrorMessage title={field + ': ' + val} extended={false}/>
    </React.Fragment>;
};

/*
                    item.render = <SubComp item={item} submitting={submitting} values={values} errors={errors}/>;
                    break;
                default:
                    item.render = <FormDataContextConsumer>{context => <div>[default:{(new Date()).toJSON()}]<br/>{format}<TextField

* */


function RequestFormTabs(props) {

    const {
        submitting,
        colsInTab,
        debug,
        tab,
        topColsBlocks,
        parent,
        item,
        subItems,
        showJournalUser,
            ...topItem
    } = {...defaultProps, ...props};

    // https://medium.com/@tofusoup429/how-to-switch-focus-of-reactjs-text-field-components-by-pressing-enter-key-5ffc56c677ea
    const onKeyDown = e => {
        console.log('keyPress', e.target);
        if (e.keyCode === 13) {
            console.log('value', e.target.value);
            // put the login here
        }
    };



    // const {cols} = React.useContext(FormDataContext);
    // const fieldsShow = cols;


    //
    // const FormFields = (props) => {
    //     return <FormFieldsShow {...props} topColsBlocks={topColsBlocks.filter(i => i.num)}/>;
    // };
    //
    // /**
    //  * Обображение блока сверху табов
    //  * @param props
    //  * @returns {JSX.Element}
    //  * @constructor
    //  */
    // const FormFields0 = (props) => {
    //     return <FormFieldsShow {...props} topColsBlocks={topColsBlocks.filter(i => !i.num)} />;
    // };

    const tabList = useListTabs(
        topColsBlocks,
        {item, subItems, colsInTab, parent, showJournalUser, topItem}
    );

    // console.log('callfunction RequestFormTabs tabList', tabList)
    // return <CircularProgress/>;
    if (!tabList) {
        return <CircularProgress title={'RequestFormTabs'}/>;
    }

    return (
        <ErrorBoundary info={'grid'}>
            <AdminShow>[RequestFormTabs]</AdminShow>
            <Grid container spacing={1}>
                {/*<Grid item sm={12}>*/}
                {/*    <FormFields0/>*/}
                {/*</Grid>*/}
                {!colsInTab && <FormFieldsShow topColsBlocks={topColsBlocks} parent={parent}/>}
                {/*{JSON.stringify(topColsBlocks)}*/}
                {colsInTab && <Grid item xs={12}>
                    <AdminShow>[tabList.length:{tabList.length}]</AdminShow>
                    <AppBar position="static" color="default">
                        {tabList.length>1 && <Tabs
                            value={tab || 'edit'}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {tabList.map((i, key) => {
                                const tabName = i.apiName||`tab-${key}`
                                return <Tab
                                    key={key}
                                    value={tabName}
                                    label={i.listTitle}
                                    icon={i.icon}
                                    component={Link}
                                    to={tabName}
                                    {...a11yProps(key)}
                                />
                            })}
                        </Tabs>}
                    </AppBar>
                    {tabList.map((i, n) => <ShowTabPanel
                            {...props} i={i} n={n} key={n}
                    />)}
                </Grid>}
            </Grid>
        </ErrorBoundary>
    );
}




const areEqual = (prevProps, nextProps) => prevProps.tab === nextProps.tab && uniqTabs(prevProps.tabList) === uniqTabs(nextProps.tabList);


const RequestFormTabsMemo = React.memo(RequestFormTabs, areEqual)

//
// function RequestFormTabsUse(props){
//     // return "RequestFormTabsUse";
//     const {topColsBlocks, item, subItems, colsInTab, parent} = props;
//
//     const tabList = useListTabs(
//         topColsBlocks,
//         {item, subItems, colsInTab, parent}
//     );
//     return <RequestFormTabsMemo {...props} tabList={tabList} />
// }

export default RequestFormTabs;