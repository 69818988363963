import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import request from 'superagent';
import {STATE} from 'libs/api';

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormRequestSubApiLoader(props) {
    const {apiFilter, item, AfterComponent} = props.itemProps || {};
    const {apiName, guid}                   = item || {};
    const [state, setState]                 = React.useState(guid ? STATE.INIT : STATE.LOADED);
    const [list, setList]                   = React.useState([]);

    React.useEffect(() => {
        if (STATE.INIT !== state) {
            return;
        }
        setState(STATE.LOADING);
        request
            .get(`/api/${apiName}/${guid}`)
            .set('accept', 'application/json')
            .query(apiFilter(props.item))
            .then(response => {
                setList(response.body);
                setState(STATE.LOADED);
            })
            .catch(error => {
                console.error('FormRequestSubApiLoader', error);
                setList(error.message);
                setState(STATE.ERROR);
            });
    }, [state, apiName, apiFilter, props.item, guid]);
    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress/>;
        case STATE.LOADED:
            return <AfterComponent {...props} {...item} values={list}/>;
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {list} {`/api/${apiName}`}</Alert>;
    }
};