import {FieldFormat} from "../../config/FieldFormat";
import request from "superagent";
import {FormDataContext, FormDataContextProvider} from "../../FormDataContext";
import FormFieldsShow from "../FormFieldsShow";
import RequestForm from "../RequestForm";
import React from "react";
import {Box, Button} from "@material-ui/core";
import {ChatContext} from "./ChatContext";
import {withStyles} from "@material-ui/core/styles";

const StyleButton = withStyles(()=>({
    root:{
        padding: '1rem'
    }
}))(Button)

function SaveForm(props) {
    const {state, handleSubmitForm, isRequired, isUpdate} = React.useContext(FormDataContext);
    const {flagBlockingChange, item} = props;
    const {disabled} = {...item};
    const {submitting} = state;
    return <Box mt={1}>
        <StyleButton
            variant="contained"
            size="large"
            fullWidth
            color={'primary'}
            onClick={event => handleSubmitForm(event, state.values)}
            disabled={disabled || (isRequired && isRequired.length) || submitting || flagBlockingChange}
        >
            Отправить
        </StyleButton>
    </Box>
}


const cols = (props, {room}) => [
    {
        title:    'Новое сообщение',
        field:    'data',
        format:   FieldFormat.FORMAT_TEXTAREA,
        rows:     2,
        rowsMax:  4,
        cols:     12 / 10,
        disabled: !room,
        required: true,
    },
    {
        titleAdd:  'Отправить',
        format:    FieldFormat.FORMAT_CUSTOM,
        startIcon: null,
        cols:      12 / 2,
        custom:    SaveForm,
        disabled:  !room,
    },
];


export const BlockNewMessage = ({id, createNewMessage, room}) => {

    const {dispatchEvent} = React.useContext(ChatContext);


    const topColsBlocks = [
        {
            num:  0,
            cols: 1,
        }
    ];


    const handleSubmit = (values, action) => {
        // const api = new API();
        return request
            .post(`/api/chat/${room}`)
            .set('accept', 'application/json')
            .send(values)
            ;
    };

    const addChatMessage = (data) => {

        const {body} = data;

        console.log('addChatMessage', body)
        dispatchEvent(body);
    }

    return (

        <>
            <FormDataContextProvider
                cols={cols}
                // item={{field}}
                initial={{room}}
                debug={'NestedFieldsForm'}
                handleSubmit={handleSubmit}
                onSuccess={addChatMessage}
            >
                <FormFieldsShow
                    topColsBlocks={topColsBlocks}
                    // dbId={'guid'}
                    // itemName={itemName}
                    open={true}
                    // handleSubmit={handleSubmit(apiName)}
                    // handleClose={openDialogEdit(false)}
                    // subApi={subApi}
                    // tab={sublist||'edit'}
                    // onSuccess={addChatMessage}
                    initialValues={{}}
                    // item={item}
                    FormComponent={RequestForm}  //??? TODO: test not need?
                />
            </FormDataContextProvider>
        </>
    )
}