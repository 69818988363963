import {STATE} from "../libs/api";
import {CircularProgress,DialogActions, DialogContent,} from "@material-ui/core";
import {ModalWindowSendDocumentEmail} from "./ModalWindowSendDocumentEmail";
import {CheckModalWindowPrintendForms} from "./CheckModalWindowPrintendForms";

import Button from "@material-ui/core/Button";

import React from "react";
import ValidateDownloadPrintedFormsDialogContentDialogTitle from './ValidateDownloadPrintedFormsDialogContentDialogTitle'


const ValidateDownloadPrintedFormsDialogContent = ({
                                                loadState,
                                                confirmSendDocuments,
                                                onSend,
                                                checkArrayData,
                                                onClose,
                                                confirm,
                                                cancel,
                                                handleSubmit,
                                                sendEmail=false,
                                                attach= false}) => {

    switch (loadState){
        case STATE.INIT:
        case STATE.LOADING:
            return (
                    <>
                        <DialogContent>
                            <CircularProgress/>
                        </DialogContent>
                    </>
            );
        case STATE.ERROR:
            const title = checkArrayData.length ? 'Список отсутствующих данных' : "Проверка данных";

            const content = confirmSendDocuments
                    ? <ModalWindowSendDocumentEmail onSend={onSend}/>
                    : <CheckModalWindowPrintendForms arrayData={checkArrayData} onClose={onClose}/>

            return (
                    <>
                        <ValidateDownloadPrintedFormsDialogContentDialogTitle title={confirmSendDocuments ? 'Отправить документы по email' : title} onClose={onClose}/>
                        <DialogContent>{content}</DialogContent>
                        {!confirmSendDocuments && <DialogActions>
                            <Button onClick={onClose} color="primary">
                                {cancel}
                            </Button>
                            <Button onClick={handleSubmit} color="primary" autoFocus>
                                {confirm}
                            </Button>
                        </DialogActions>}
                    </>
            );
        case STATE.LOADED:
            if (sendEmail) {
                return <>
                    <ValidateDownloadPrintedFormsDialogContentDialogTitle title={"Отправить документы по email"} onClose={onClose}/>
                    <DialogContent>
                        Документы успешно отправлены
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Закрыть
                        </Button>
                    </DialogActions>
                </>
            }
            if(attach) {
                return <>
                    <ValidateDownloadPrintedFormsDialogContentDialogTitle title={"Файл прикреплен"} onClose={onClose}/>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Закрыть
                        </Button>
                    </DialogActions>
                </>
            }
            return <></>
    }

};


export  default  ValidateDownloadPrintedFormsDialogContent