import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Typography,
} from '@material-ui/core';
import MyDocumentRights from 'components/MyDocumentRights';
import {ReturnUser, SwitchUser} from 'components/SwitchUser';
import React from 'react';

import {MeContext} from "meContext";

export default function MyProfile() {
    const logout = '/connect/logout';
    const me     = React.useContext(MeContext);
    
    const [anchorEl, setAnchorEl]     = React.useState(null);
    const [openOption, setOpenOption] = React.useState(null);
    
    const openMenu = openOption === 'menu';
    
    const onMenuOpen = name => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenOption(name);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setOpenOption(null);
    };
    
    const canReturnUser = me.roles.includes('ROLE_PREVIOUS_ADMIN');
    const canSwitchUser = me.is_admin && !canReturnUser;
    return <React.Fragment>
            <ListItem style={{paddingLeft: '0'}}>
                <ListItemAvatar>
            <IconButton onClick={onMenuOpen('menu')}>
                <Avatar alt={me.username} src={me.picture.includes('/img/') ? `/upload/${me.picture}` : me.picture} style={{width : '80px', height : '80px'}}/>
            </IconButton>
                </ListItemAvatar>
            <ListItemText primary={me.username} secondary={<React.Fragment>
                <Typography variant={'caption'} display="block">
                    {me.email}
                </Typography>
                <Typography variant={'caption'} display="block">
                {me.client}
                </Typography>
            </React.Fragment>} />
            </ListItem>
        {openOption === 'documentRights' && <MyDocumentRights open={true} onClose={handleClose} />}
        {openOption === 'SwitchUser' && <SwitchUser onClose={handleClose} />}
        {openOption === 'ReturnUser' && <ReturnUser onClose={handleClose} />}
        <Modal open={openMenu}>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical:   'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical:   'top',
                    horizontal: 'right',
                }}
                open={openMenu}
                onClose={handleClose}
            >
                {/* <MenuItem onClick={onMenuOpen('settings')}>Настройки пользователя</MenuItem> */}
                <MenuItem onClick={onMenuOpen('documentRights')}>Настройки профиля</MenuItem>
                {canSwitchUser && <MenuItem onClick={onMenuOpen('SwitchUser')}>Войти как...</MenuItem>}
                {canReturnUser && <MenuItem onClick={onMenuOpen('ReturnUser')}>Начальный вход</MenuItem>}
                <MenuItem onClick={handleClose}>Закрыть</MenuItem>
                <MenuItem onClick={() => {window.location.replace(logout);}}>Выход</MenuItem>
            </Menu>
        </Modal>
    </React.Fragment>
        ;
    
}

