import React from 'react';
import {SubFormEditCreateTable} from 'components/SubFormEditCreateTable';
import {config} from 'config';
import {FormDataContext, FormDataContextProvider} from 'FormDataContext';
import AdminShow from 'components/FieldFormat/AdminShow';
import SubFormListEdit from "./SubFormListEdit";
import TableMaterialTableShow from "components/TableMaterialTableShow";
import {useLoaderApiList} from "components/useLoaderApi";
import LoadStateShow from "components/LoadStateShow";
import {STATE} from "libs/api";

/**
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 */
export default function (props) {
    const {item} = props;
    const {field, apiFilter} = item;

    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const subItem = config.get(item.subConfig);

    const {cols, onFormDataAction, apiName} = subItem;
    console.log('SubTable', item, props, subItem)
    console.log('SubTable cols', cols)

    const query = apiFilter(values);
    const onResponse = data => {
        handleChange(item)(null, data);
    }
    const [list, loadState] = useLoaderApiList(apiName, query);
    React.useEffect(() => {
        if (loadState === STATE.LOADED) {
            handleChange(item)(null, list);
        }
    }, [loadState])



    return <LoadStateShow state={loadState} error={list}>
        <SubFormListEdit
        title={'title'}
        itemProps={{
            ...item,
            ...subItem,
            // field: field,
        }}
        cols={cols}
        //{...subItem}
    />
    </LoadStateShow>

    return <TableMaterialTableShow
        itemProps={subItem}
        cols={cols}
        //{...subItem}
    />


    // return <pre>TODO: SubFormEditCreateTable: {JSON.stringify(item,null,2)}</pre>
    // return <SubFormListEdit
    //     item={subItem}
    //     itemProps={subItem}
    //     itemValues={{}}
    //     // onChange={handleChangeValues(i)}
    //     // apiFilter={apiFilter &&
    //     // apiFilter(values)}
    //     // onChange={handleChangeTable}
    //     // setIsLoadingTop={setIsLoading}
    //     values={values[field]}
    //     // parentRowData={rowData}
    //     // errors={errors[detail.field]||[]}
    //     {...subItem}
    // />
    // return <SubApiReplace
    //     itemProps={subItem}
    //     item={values}
    // />

    //
    // return <><pre>
    //     {/*{JSON.stringify({item},null,2)}*/}
    //     {/*{JSON.stringify({subItem},null,2)}*/}
    //     </pre>
    //     <FormLoadSub
    //     itemProps={{
    //         ...subItem,
    //         ...item,
    //         cols: subItem.cols,
    //         // item: subItem,
    //         // apiFilter: item.apiFilter || subItem.apiFilter,
    //         // subItem: subItem,
    //         AfterComponent: SubApiReplace,
    //     }}
    //     // item={values}
    //     // formProps={{}} // todo: menuRoot
    //
    //     listTitle={item.listTitle}
    //     inTab={true}
    //     formProps={props}
    //     // itemProps={item}
    //     item={values}
    //     // detail={i.detail}
    //     itemValues={values}
    //     // handleChange={handleChangeWarning}
    //     // onChange={handleChangeValues(i)}
    //     // apiFilter={apiFilter && apiFilter(values)}
    //     values={values[item.field] || []}
    //     errors={errors[item.field] || []}
    //     cols={item.cols || []}
    //     initialValues={item.initialValues || (() => ({}))}
    //
    //     // apiFilter={values => (
    //     // {
    //     //     legal: values.guid,
    //     // }
    //     // )}
    // />
    // </>

    return <React.Fragment>
        <AdminShow>[SubTable:{field}]</AdminShow>
        <FormDataContextProvider
            initial={values}
            cols={cols}
            onFormDataAction={onFormDataAction}
            item={item}
            debug={'SubTable'}
        >

            <SubFormEditCreateTable
                item={item}
                // itemValues={values}
                // onChange={handleChange(item)}
                // apiFilter={apiFilter && apiFilter(values,parent)}
                values={values[field]}
                errors={errors[field]}
                {...config.get(item.subConfig)}
            />
        </FormDataContextProvider>
    </React.Fragment>;
}