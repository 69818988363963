import React from "react";
import {config} from 'config';
import PageApiLoader3 from 'components/PageApiLoader3';

export default function Page(props) {
    return <PageApiLoader3
        {...props}
        {...config.docRequest}
        canEdit={true}
        canAdd={true}
    />;
}