import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import {emphasize, withStyles} from '@material-ui/core/styles';
import {useBreadcrumbs} from 'components/Menu';
import React from 'react';
import {Link} from 'react-router-dom';

const StyledBreadcrumb = withStyles((theme) => (
    {
        root: {
            backgroundColor:    theme.palette.grey[100],
            height:             theme.spacing(3),
            color:              theme.palette.grey[800],
            fontWeight:         theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.grey[300],
            },
            '&:active':         {
                boxShadow:       theme.shadows[1],
                backgroundColor: emphasize(theme.palette.grey[300], 0.12),
            },
        },
    }
))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default function BreadCrumbs(props) {
    const breadcrumbs = useBreadcrumbs();
    return <Breadcrumbs aria-label="breadcrumb">
        {/*<StyledBreadcrumb*/}
        {/*    component={Link}*/}
        {/*    clickable*/}
        {/*    to="/"*/}
        {/*    icon={<HomeIcon fontSize="small"/>}*/}
        {/*    label={'Главная'}*/}
        {/*/>*/}
        {/*{parent && <StyledBreadcrumb*/}
        {/*    // component={Link}*/}
        {/*    icon={parent.icon && <parent.icon fontSize="small"/>}*/}
        {/*    label={parent.title}*/}
        {/*/>}*/}
        {/*<StyledBreadcrumb*/}
        {/*    component={Link}*/}
        {/*    clickable*/}
        {/*    href={path}*/}
        {/*    icon={Icon && <Icon fontSize="small"/>}*/}
        {/*    label={title}*/}
        {/*/>*/}
        {breadcrumbs.map((props,key) => props.to
            ? <StyledBreadcrumb
                key={key}
                component={Link}
                clickable
                {...props}
            />
            : <StyledBreadcrumb
                key={key}
                {...props}
            />
        )}
    
    </Breadcrumbs>;
}