import React from "react";


const TransportValueCargo = () => {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.909997 15.476L10.238 19.421L18.981 15.476M19.09 4.619V15.402M10 8.313V19.095M0.909997 4.167V15.49M9.858 1L0.908997 4.16L9.858 7.915L18.976 4.545L9.858 1Z" stroke="#848484" stroke-width="0.917" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export { TransportValueCargo }
