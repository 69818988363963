import {BankAccountIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';

export default {
    listTitle: 'Банк',
    apiName:   'banks',
    itemName:  'Банк',
    canDelete: true,
    menu: {
        icon: BankAccountIcon,
    },
   
    cols: (props, item) => {

        return [
            {
                title: 'Наименование Банка',
                field: 'name',
                format: FieldFormat.FORMAT_INPUT,
                required:  true,
            },
            {
                title: 'Кор счет',
                field: 'corrAccount',
                format: FieldFormat.FORMAT_INPUT,
               
            },
            {
                title: 'Бик Банка',
                field: 'bik',
                format: FieldFormat.FORMAT_INPUT,
                required:  true,
            },
        ];
    }
    };