import Custom from 'components/FieldFormat/Custom';
import FiasAddress from 'components/FieldFormat/FiasAddress';
import SimpleInput from 'components/FieldFormat/SimpleInput';
import NumberSimpleInput from 'components/FieldFormat/NumberSimpleInput';
import Textarea from 'components/FieldFormat/Textarea';
import SelectOptions from 'components/FieldFormat/SelectOptions';
import SelectApi from 'components/FieldFormat/SelectApi';
import SubForm from 'components/FieldFormat/SubForm';
import SubTable from 'components/FieldFormat/SubTable';
import SelectDadata from 'components/FieldFormat/SelectDadata';
import FieldFormatDate from 'components/FieldFormat/Date';
import DatetimeLocal from 'components/FieldFormat/DatetimeLocal';
import Checkbox from 'components/FieldFormat/Checkbox';
import Passwordfeild from 'components/FieldFormat/PasswordField';
import FileAdd from 'components/FieldFormat/FileAdd'
import SimpleTextBox from 'components/FieldFormat/SimpleTextBox'
import {FieldFormat} from './FieldFormat';
import SelectApiCheckbox from 'components/FieldFormat/SelectApiCheckbox';
import SelectApiIndex from 'components/FieldFormat/SelectApiIndex';
import  SelectPhoneContacts from 'components/FieldFormat/SelectPhoneContacts'
import { object } from 'prop-types';

export const RenderList = (format) => {
    const formats = {
        [FieldFormat.FORMAT_CUSTOM]:              Custom,
        [FieldFormat.FORMAT_INPUT]:               SimpleInput,
        [FieldFormat.FORMAT_NUMBER]:              NumberSimpleInput,
        [FieldFormat.FORMAT_FLOAT]:               NumberSimpleInput,
        [FieldFormat.FORMAT_TEXTAREA]:            Textarea,
        [FieldFormat.FORMAT_SELECT]:              SelectOptions,
        [FieldFormat.FORMAT_SELECT_API]:          SelectApi,
        [FieldFormat.FORMAT_SUB_FORM]:            SubForm,
        [FieldFormat.FORMAT_SUB_TABLE]:           SubTable,
        [FieldFormat.FORMAT_SELECT_DADATA]:       SelectDadata,
        [FieldFormat.FORMAT_DATE]:                FieldFormatDate,
        [FieldFormat.FORMAT_DATETIME_LOCAL]:      DatetimeLocal,
        [FieldFormat.FORMAT_FIAS_ADDRESS]:        FiasAddress,
        [FieldFormat.FORMAT_CHECKBOX]:            Checkbox,
        [FieldFormat.FORMAT_SELECT_API_CHECKBOX]: SelectApiCheckbox,
        [FieldFormat.FORMAT_SELECT_API_INDEX]:    SelectApiIndex,
        [FieldFormat.FORMAT_PASSWORD]:            Passwordfeild,
        [FieldFormat.FORMAT_FILE_ADD]:            FileAdd,
        [FieldFormat.FORMAT_PHONE_CONTACTS]:      SelectPhoneContacts,
        [FieldFormat.FORMAT_SIMPLE_TEXT_BOX]:     SimpleTextBox,
    };
    return formats[format] || formats[FieldFormat.FORMAT_INPUT];
};
