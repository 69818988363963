import React from "react";
import {FormDataContext, FormDataContextConsumer} from "../FormDataContext";
import {CircularProgress, Box} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ToolTipClearIcon from "./ToolTipClearIcon";

const EndAdornmentComponent = (item, value, error, customComponent = null) => {
    const {checkField} = React.useContext(FormDataContext)
    let firstIcon

    if (item && item.check) {

        if (checkField.length && checkField.includes(item.field)) {
            firstIcon =  <CircularProgress color="primary" size={20}/>
        }
        else if (error) {
            firstIcon = <ErrorOutlineIcon color="error"/>
        }
        else if (value && value.length) {
             firstIcon =  <CheckCircleOutlineIcon style={{color: "#4caf50"}}/>
        }else{
            firstIcon = <></>
        }

    }

    const secondIcon =  <FormDataContextConsumer>{context => item && item.endButtons && item.endButtons({
        values:         context.state.values,
        setStateValues: context.setStateValues,
    })}</FormDataContextConsumer>

    return (
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Box display={"flex"} >
                    {firstIcon}
                </Box>
                <Box>
                    {secondIcon}
                </Box>
                {
                    customComponent &&
                    <Box>
                        {customComponent}
                    </Box>

                }
            </Box>
    )

}

export const InputPropsDefault = (props) => {
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext)
    const {field, disabled, format} = props
    const value = values && values[field];
    const error = errors && errors[field];
    const clearAll = (e) => {

        return handleChange(props)(e, null)
    }

   return {
       endAdornment: EndAdornmentComponent(props, value, error,
               <ToolTipClearIcon
                       value={value}
                       disabled={disabled}
                       format={format}
                       clearAll={clearAll}/>)
   }
}


export default  EndAdornmentComponent