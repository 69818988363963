import React from 'react';
import {FieldFormat} from 'config/FieldFormat';
import { ColObjectNameOther} from 'components/showItems';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    Box,
} from '@material-ui/core';

const DownLoad = ({rowData}) => {
    const {guid} = rowData
    const downLoad = React.useCallback(guid => () => {
        window.open(`/api/file/${guid}`);
    }, [])

    return (
        <Box pt={1}>
            <GetAppIcon onClick={downLoad(guid)}/>
        </Box>
    )
}

export const ATTACHED_FILE_TYPE = {
    FACSIMILE : '6cc9faeb-2db5-11ed-b81e-005056a60abf',
    POST_FACSIMILE : "984335be-2db5-11ed-b81e-005056a60abf"
}

export default {
    listTitle: 'Файлы',
    apiName:   'attached_files',
    itemName:  'Файлы',
    canDelete: true,
    listActions: () => {
        return [
            {
                component: DownLoad,
            },
        ];
    },

    cols: async (props, item, {me}) => {
        const {documentType, objLinkedName, objLinkedGuid} = props
        return [
            {
                title: 'Тип файла',
                field: 'documentType',
                required: true,
                api:       'user_file_doc_types',
                apiFilter: () => ({
                    'objects.guid': objLinkedName,
                }),
                format:    FieldFormat.FORMAT_SELECT_API,
                object:    ColObjectNameOther,

                // format:    FieldFormat.FORMAT_SELECT,
                // options: [
                //     {
                //     id:   '1',
                //     name: 'variant 1',
                // },
                // {
                //     id:   '2',
                //     name: 'variant 2',
                // },],
            },
            {
                title: 'Описание',
                field: 'description',
                format: FieldFormat.FORMAT_INPUT,
                required: true,

            },
            {
                title: "Имя",
                field: "name",
                disabled: true,
                required: true,
                helperText: `Для загрузки файла нажмите на кнопку "Выбрать файл"`
            },

          {
                field:      'dataFile',
                title:      'Файл',
                helperText: 'Нажмите на кнопку слева для загрузки файла',
                hidden:     true,
                format: FieldFormat.FORMAT_FILE_ADD,
                framing: false,
                objLinkedName: objLinkedName,
                objLinkedGuid: objLinkedGuid

            },
        ];
    }
    };