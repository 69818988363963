import React from 'react';
import {FormDataContext} from "FormDataContext";
import MaterialTable from 'material-table';
import {
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    IconButton,
    Tooltip,
    TextField,
    Grid,
    Fab
} from '@material-ui/core';

import {STATE} from 'libs/api';
import {config as configGlobal} from 'config'
import {useLoaderApiList} from 'components/useLoaderApi';
import LoadStateShow from 'components/LoadStateShow.jsx'
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmDialog from "../ConfirmDialog";
import {AddIcon, DeleteIcon} from 'components/icons';

const ListEmails = (props) => {
    const {data, changeData, changeAllisEnable, addData, deleteData} = props.dataTableMui
    const checkTitle = (values) => {

        return (
                <Grid container spacing={2} style={{paddingRight: "0.5rem"}} alignItems={"center"}>
                    <Checkbox
                            checked={data.filter(el => el.is_enable).length === data.length}
                            size="small"
                            color="primary"
                            indeterminate={data.filter(el => el.is_enable).length && data.filter(el => el.is_enable).length !== data.length}
                            onChange={changeAllisEnable}

                    />
                    <Typography variant={"body"}>
                        Рассылать
                    </Typography>
                </Grid>
        )
    }

    const mainTitle = () => {

        return (
                <Grid container alignItems={"center"}>
                    <Typography variant={"h6"}>
                        {"Список Email"}
                    </Typography>
                    <Tooltip
                            title={"Добавить запись"}
                    >
                        <IconButton
                                variant="contained"
                                color="primary"
                                onClick={ () => {
                                    addData()
                                }}
                        >
                            <Fab size="small" color={'primary'}>
                                <AddIcon/>
                            </Fab>
                        </IconButton>
                    </Tooltip>

                </Grid>
        )
    }

    const MyTextField = (data) => {
        const {value} = data;
        const [state, setState] = React.useState(value || "");

        const change = (event) => {
            setState(event.target.value)
        }

        const blur = () => {
            changeData({...data, value: state})
        }

        return (
                <TextField
                        id="outlined-basic"
                        label="Значение"
                        variant="outlined"
                        value={state}
                        fullWidth
                        onChange={change}
                        onBlurCapture={blur}
                />
        )
    }

    return (
            <>
                <Box minWidth={700}>
                    <MaterialTable
                            title={mainTitle()}
                            columns={[
                                {
                                    title: checkTitle(),
                                    field: 'check',
                                    cellStyle: {
                                        width: 200
                                    },
                                    render: (data) => {
                                        const {is_enable} = data;
                                        const change = () => {
                                            changeData({...data, is_enable: !is_enable})
                                        }
                                        return <Checkbox
                                                checked={is_enable}
                                                size="small"
                                                color="primary"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                                onChange={change}
                                        />
                                    }
                                },
                                {
                                    title: 'Email',
                                    field: 'value',
                                    width: "80%",
                                    render: (data) => <MyTextField {...data}/>
                                },
                            ]}
                            data={data}
                            actions={[
                                {
                                    icon: DeleteIcon,
                                    tooltip: 'Удалить запись',
                                    onClick: (event, rowData) => {
                                        deleteData(rowData)
                                    }

                                }
                            ]}
                            {...configGlobal.MaterialTable}
                            options={{
                                // tableLayout: 'auto',
                                selection: false,
                                actionsColumnIndex: -1,
                                selectionProps: rowData => {

                                    return {
                                        color: 'primary',
                                    }
                                },
                                // pageSizeOptions: [{ value: data.length > 0 ? data.length : 1, label: 'Все' }],


                            }}

                    />
                </Box>
            </>
    )
}

export  default  ListEmails