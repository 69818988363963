import {FormControl, FormControlLabel, Switch} from '@material-ui/core';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContext} from 'FormDataContext';
import React from 'react';

export default function({item}) {
    const {disabled, field, title} = item;
    
    const {state: {values}, handleChange} = React.useContext(FormDataContext);
    
    return <React.Fragment>
        <AdminShow>[CheckBox]</AdminShow>
        <FormControl>
            <FormControlLabel
                label={title}
                control={
                    <Switch
                        disabled={disabled}
                        checked={!!values[field]}
                        onChange={handleChange(item)}
                        color="primary"
                    />
                }
            />
        </FormControl>
    </React.Fragment>;
}