import React from 'react';
import {FindInPageOutlined as MenuIcon} from '@material-ui/icons';
import {ColObjectName, ShowCurrency, ShowDate, ColObjectNameOther, ColObjectFullNameWithColor} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import priceRequestSubItems from 'config/priceRequestSubItems';
import {ROLES} from 'config/roles';
import asArray from 'libs/asArray';
import {ShowLoadingDate, ShowLoadingPlacesFromNotDate, ShowUnloadingPlacesFromNotDate } from 'libs/dateLoadUnload';
import Component from 'page/PriceRequest';
import {FORM_ACTION} from '../FormDataContext';
import {SubApiReplace} from 'components/FormLoadSub';
import userFiles from 'config/userFiles'
import  AttachFileOutlinedIcon   from '@material-ui/icons/AttachFileOutlined';
import {CountBiddings} from 'components/Icons/';


let STATUS_DRAFT = 'ea7f7bad-55d1-11ed-b81e-005056a60abf';
export default {
    listTitle:     'Запрос стоимости',
    apiName:       'doc_price_requests',
    itemName:      'Запрос стоимости',
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title: 'Запрос стоимости',
        path:  '/price_request',
        Component,
        roles: [ROLES.ROLE_USER],
        icon:  MenuIcon,
    },
    cloneIems: {
        guid: null,
        name: null,
        clone : true,
        replicator: null,
        docNumber: 'Присвоится автоматически',
        _sub_name: 'ЗаявкаНаТранспорт',
        docDate: new Date(),
        _date_now: new Date(),
        isAuto: false,
        status:    STATUS_DRAFT,
        _CREATE:   {
            newBidding: {
                apiName:     'biddings',
                parentField: {
                    priceRequest:  'guid',
                    docLinkedName: '_sub_name',
                    docDate:       '_date_now',
                },
            },
        },

        //Для удаление определенных полей в объекте массиве объектов, нужно ввиде ключа указать поле масива, а поля для удаление прописать в виде массива значений
        deleteInField:
                {
                    routePlaces : ['dateFromText', 'dateFrom', 'docRequest', 'guid']
                },
        //Обработчик для тонкой работы с данными.
        handlerData: (data) => {
            for (const key in data) {
                if (key === 'routePlaces') {
                    data[key].map(it => {
                        it.loadCargo && it.loadCargo.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.unloadCargo && it.unloadCargo.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.cargos && it.cargos.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                        it.services && it.services.map(i => {
                            delete i['routePlace']
                            delete i['guid']
                        })
                    })
                }
            }
            return data

        }

    },
    topColsBlocks: [
        {
            num:   1,
            title: 'Информация о запросе',
            cols:  2,
        },
        {
            num:   2,
            title: 'Требования к транспорту',
            cols:  2,
        },
        {
            num:   3,
            title: 'Стоимость заявки',
            cols:  2,
        },
        // {
        //     num:   4,
        //     title: 'Информация о перевозчике',
        //     cols:  2,
        // },
    ],
    initialValues: () => (
        {
            docNumber: 'Присвоится автоматически',
            status:    STATUS_DRAFT,
            _sub_name: 'ЗапросСтоимости',
            _date_now:           new Date(),
            docDate:   new Date(),
            _CREATE:   {
                newBidding: {
                    apiName:     'biddings',
                    parentField: {
                        priceRequest:  'guid',
                        docLinkedName: '_sub_name',
                        docDate:       '_date_now',
                    },
                },
            },
        }
    ),
    onFormDataAction: (values, props) => {
        const {action, field} = props;
        let _fix       = {};
        switch (action) {
            case FORM_ACTION.INIT:
                _fix.routePlaces = [
                    ...asArray(values.routePlaces).map(({cargos, ...i}) => ({
                        ...i,
                        loadCargo:   asArray(cargos).filter(i => !i.isUnload),
                        unloadCargo: asArray(cargos).filter(i => i.isUnload) || [],
                    })),
                ];
                _fix.routePlaces.map( item => {
                    if(item.isLoading){
                        item.typePoint = 'погрузка'
                    }
                    if(item.isUnloading){
                        item.typePoint = 'разгрузка'
                    }

                })
                // console.log('onFormDataAction', {_fix});
                break;
            case FORM_ACTION.SUBMIT:
                console.log('FORM_ACTION.SUBMIT', {_fix, values});
                _fix.routePlaces = [
                    ...values.routePlaces.map(({loadCargo, unloadCargo, ...i}, index) => ({
                        ...i,
                        cargos: [
                            ...asArray(loadCargo).map(i => ({...i, isUnload: false})),
                            ...asArray(unloadCargo).map(i => ({...i, isUnload: true})),
                        ],
                        indexInPlace: index
                    })),
                ];
                case FORM_ACTION.CHANGE:
                    switch (field) {
                        case 'routePlaces':
                            values.routePlaces && values.routePlaces.map( item => {
                                if(item.typePoint === 'погрузка'){
                                    item.isLoading = true
                                    item.isUnloading = false
                                }
                                if(item.typePoint === 'разгрузка'){
                                    item.isLoading = false
                                    item.isUnloading = true
                                }
                                return item
                            })
                            break;
                    }

                    break;
                break;
        }

        return {
            ...values,
            ..._fix,
        };
    },
    listActions: (props) => {

        return [
            {
                component: CountBiddings
            }
        ]
    },
    colsInTab:     true,
    cols:          [
        {
            title:        'Номер документа',
            field:        'docNumber',
            hidden:       {xsUp: true},
            disabled:     true,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Дата погрузки',
            field:        'routePlacesInfo',
            object:       ShowLoadingDate,
            format:       FieldFormat.FORMAT_NONE,
            hidden:       true,
            topColsBlock: -1,
        },
        {
            title:        'Откуда',
            field:        'routePlacesInfo',
            object:       ShowLoadingPlacesFromNotDate,
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },
        {
            title:        'Куда',
            field:        'routePlacesInfo',
            object:       ShowUnloadingPlacesFromNotDate,
            format:       FieldFormat.FORMAT_NONE,
            topColsBlock: -1,
        },

        {
            title:        'Номер документа клиента',
            field:        'clientDocNumber',
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Статус',
            field:        'status',
            required:     false,
            topColsBlock: 1,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'doc_price_request_statuses',
            object:       ColObjectFullNameWithColor,
            cols:         2,
        },
        {
            title:        'Заказчик перевозки',
            api:          'legals',
            apiFilter: () => (
                {isCarrier: false}
            ),
            field:        'customer',
            format:       FieldFormat.FORMAT_SELECT_API,
            cols:         2,
            hidden:       true,
            required:     true,
            topColsBlock: 1,
        },
        {
            title:     'Контактное лицо',
            api:       'contact_people',
            apiFilter: (rowData) => (
                {
                    legal: rowData && rowData.customer,
                }
            ),
            // lookup: lookupContact,
            format:       FieldFormat.FORMAT_SELECT_API,
            object:       ColObjectNameOther,
            field:        'customerContact',
            cols:         2,
            hidden:       true,
            required:     true,
            topColsBlock: 1,
        },
        {
            title:        'Вид транспорта',
            field:        'typedTransport',
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'typed_transports',
            object:       ColObjectName,
            cols:         1,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:        'Объем, от м^3',
            field:        'transportValue',
            required:     true,
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Объем до, м^3',
            field:        'transportValueMax',
            type:         'int',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Грузоподъемность, кг',
            field:        'transportMass',
            format:       FieldFormat.FORMAT_NUMBER,
            cols:         2,
            topColsBlock: 2,
            minValue: 0
        },
        {
            title:        'Тип Кузова',
            api:          'carbody_types',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            field:        'carbodyTypes',
            topColsBlock: 2,
            cols:         2,
            filter:       {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'carbodyType[]',
            },
        },
        {
            title:        'Тип загрузки',
            api:          'loading_types',
            field:        'loadingTypes',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },
        {
            title:        'Классы опасности',
            api:          'danger_classes',
            field:        'dangerClasses',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         1,
            topColsBlock: 2,
        },
        // {
        //     title:        'Уровень видимости документа',
        //     field:        'accessLevel',
        //     format:       FieldFormat.FORMAT_SELECT_API,
        //     api:          'enum_access_levels',
        //     cols:         2,
        //     topColsBlock: 2,
        //     hidden:   true,
        // },
        // {
        //     title: 'userAuthor',
        //     field: 'userAuthor',
        // },
        {
            title:        'Комментарий',
            field:        'comment',
            hidden:       true,
            topColsBlock: 1,
        },
        {
            title:        'Валюта',
            object:       ShowCurrency,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'currencies',
            field:        'currency',
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
    ],
    subItems:   item =>  ({
        style: 'tabs',
        list: [...priceRequestSubItems, {
            Component: SubApiReplace,
            api: 'attached_files',
            apiFilter: values => (
                {
                    objLinkedName: "price_request",
                    objLinkedGuid: values.guid
                }
            ),
            objLinkedName: "price_request",
            objLinkedGuid: item.guid,

            ...userFiles,
            icon: <AttachFileOutlinedIcon />
        }],
    }),
};
