import {FormDataContextConsumer} from 'FormDataContext';
import {Grid} from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';


const detailPanelRenderSubtable = ({panel, values, onChange, tableRef, setIsLoading}) => rowData => <FormDataContextConsumer>{context => {
    let item        = context.values;
    const valuesAll = rowData[panel.field] || [];

    console.log('detailPanelRenderDefault', {valuesAll, rowData});
    //filter(i=>i.key_id === rowData.key_id)
    const handleChangeSubTable = (event, valuesNew, resolve) => {
        const index   = values.indexOf(rowData);
        values[index] = {...rowData, [panel.field]: valuesNew};
        console.log(
            'handleChangeSubTable',
            {index, values, rowData, valuesNew},
            [{...rowData, [panel.field]: valuesNew}],
        );
        onChange(null, values, resolve);
    };

    const setRowDataKeyId = (key_id) => {
        const index = values.indexOf(rowData);
        console.log('valuesNew key index', index);
        rowData.key_id = key_id;
        values[index]  = rowData;
        onChange(null, values.map(({tableData, ...i}) => i), tableRef.current.onQueryChange);
    };

    const itemProps = {
        field: panel.field,
        cols: panel.cols,
        onFormDataAction: panel.onFormDataAction,
        // onFormDataAction
    };
    console.log('itemProps',itemProps)
    return 'itemProps'
    return <Grid container>
        <Grid item xs={1}>
            {/*<pre>{JSON.stringify(valuesAll, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(valuesOther, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
        </Grid>
        <Grid item xs={11}>
            <ErrorBoundary info={'detailPanelRenderSubTable'+panel.field}>
                <SubFormListEdit
                    listTitle={panel.tooltip || 'Список'}
                    item={item}
                    itemProps={itemProps}
                    itemValues={valuesAll}
                    // onChange={handleChangeValues(i)}
                    // apiFilter={apiFilter &&
                    // apiFilter(values)}
                    onChange={handleChangeSubTable}
                    setIsLoadingTop={setIsLoading}
                    values={valuesAll}
                    parentRowData={rowData}
                    // errors={errors[detail.field]||[]}
                    optionsMaterialTable={{showTitle: true}}
                    {...panel}
                    cols={panel.cols
                        ? panel.cols(item[panel.field], rowData, setRowDataKeyId)
                        : []}
                />
            </ErrorBoundary>
        </Grid>
    </Grid>;
}}
</FormDataContextConsumer>;

export default detailPanelRenderSubtable;