import { ROLES } from 'config/roles';
import React from 'react';
import Component from 'page/RulesPatterns';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import { FieldFormat } from 'components/RequestFormTabs';
import { ApiDataLoadLookup, ShowLegal, ColObjectName } from 'components/showItems';


const checkBoks = {
    baseUpgradeFree : 'Для свободных',
    baseUpgradeAuctionDesc : 'Торги на понижение',
    baseUpgradeAuctionAsc : 'Торги на повышение',
    baseUpgradeAppointOthers : 'Назначенными другими',
    baseUpgradeAppointSystem :  'Назначенный системой',
    baseDegradeFree : 'Для свободных',
    baseDegradeAuctionDesc : 'Торги на понижение',
    baseDegradeAuctionAsc : 'Торги на повышение',
    baseDegradeAppointOthers : 'Назначенными другими',
    baseDegradeAppointSystem :  'Назначенный системой',
}   



const workingFomChecbox = (data, categories) => {

    return Object.keys(checkBoks)
        .filter(e => e.includes(categories))
        .filter(e => data[e])
        .map(e => checkBoks[e])
        .join(', ')  
}

export default {
    listTitle: 'Шаблоны влияния на рейтинг',
    itemName: 'Шаблон влияния на рейтинг',
    apiName: 'rules_patterns',
    roles: [
        ROLES.ROLE_USER,
    ],
    menu: {
        title: 'Шаблоны влияния на рейтинг',
        path: '/rules_patterns',
        Component,
        roles: [ROLES.ROLE_USER],
        icon: EqualizerIcon,
    },    
    topColsBlocks: [
        {
            num: 1,
            title: '',
            cols: 1,
        },
        {
            num: 2,
            title: 'База "заявки от которых считается повышение""',
            border : true,
            cols: 2,
        },
        {
            num: 3,
            title: 'База "заявки от которых считаютется понижение"',
            border: true,
            cols: 2,
        },
        
    ],
    colsInTab: true,
    cols: [
        {
            title: 'Имя шаблона',
            field: 'name',
            format: FieldFormat.FORMAT_INPUT,
            topColsBlock: 1,
            required: true,
        },
        {
            title: 'Повышение',
            format: FieldFormat.FORMAT_NONE,
            topColsBlock: 1,
            object: ({row})=> workingFomChecbox(row, 'Upgrade')
        },
        {
            title: 'Понижение',
            format: FieldFormat.FORMAT_NONE,
            topColsBlock: 1,
            object: ({row})=> workingFomChecbox(row, 'Degrade')
        },
        {
            title: checkBoks['baseUpgradeFree'],
            field: 'baseUpgradeFree',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 2,
            hidden: true
        },
        {
            title: checkBoks['baseUpgradeAuctionDesc'],
            field: 'baseUpgradeAuctionDesc',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 2,
            hidden: true
        },
        {
            title: checkBoks['baseUpgradeAuctionAsc'],
            field: 'baseUpgradeAuctionAsc',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 2,
            hidden: true
        },
        {
            title: checkBoks['baseUpgradeAppointOthers'],
            field: 'baseUpgradeAppointOthers',
            format: FieldFormat.FORMAT_CHECKBOX,
            topColsBlock: 2,
            hidden: true
        },
        {
            title: checkBoks['baseUpgradeAppointSystem'],
            field: 'baseUpgradeAppointSystem',
            format: FieldFormat.FORMAT_CHECKBOX,
            topColsBlock: 2,
            hidden: true
        },
        {
            title: checkBoks['baseDegradeFree'],
            field: 'baseDegradeFree',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 3,
            hidden: true
        },
        {
            title: checkBoks['baseDegradeAuctionDesc'],
            field: 'baseDegradeAuctionDesc',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 3,
            hidden: true
        },
        {
            title: checkBoks['baseDegradeAuctionAsc'],
            field: 'baseDegradeAuctionAsc',
            format: FieldFormat.FORMAT_CHECKBOX,
            cols: 1,
            topColsBlock: 3,
            hidden: true
        },
        {
            title: checkBoks['baseDegradeAppointOthers'],
            field: 'baseDegradeAppointOthers',
            format: FieldFormat.FORMAT_CHECKBOX,
            topColsBlock: 3,
            hidden: true
        },
        {
            title: checkBoks['baseDegradeAppointSystem'],
            field: 'baseDegradeAppointSystem',
            format: FieldFormat.FORMAT_CHECKBOX,
            topColsBlock: 3,
            hidden: true
        },
       

    ],
    subItems: {
        style: 'tabs',
        list: [
            {
                listTitle: 'События на повышение',
                field: 'ratingRatesUpgradings',
                icon: <CallMadeIcon />,
                cols:[
                        {
                            title: 'Событие рейтинга',
                            field: 'ratingEvent',
                            api: 'event_types',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName
                        },
                        {
                            title: 'Коэффициент',
                            field: 'ratio',
                            format: FieldFormat.FORMAT_NUMBER,
                            initialEditValue: 1,
                            minValue: 0
                        },
                    ]
                
            },
            {
                listTitle: 'События на понижение',
                field: 'ratingRatesDegradings',
                icon: <CallReceivedIcon/> ,
                cols: [
                        {
                            title: 'Событие рейтинга',
                            field: 'ratingEvent',
                            api: 'event_types',
                            format: FieldFormat.FORMAT_SELECT_API,
                            object: ColObjectName

                        },
                        {
                            title: 'Коэффициент',
                            field: 'ratio',
                            format: FieldFormat.FORMAT_NUMBER,
                            initialEditValue : 1,
                            minValue: 0
                        },
                    ]
            },
            {
                listTitle: 'Правила перерасчета',
                icon: <i className="material-icons">%</i>,  
                field: 'transferRules',
                
                cols: [
                        {
                            title:         'Категория перевозчика',
                            api:           'categories',
                            apiFilter:     () => (
                                {
                                    is_loading: 1,
                                }
                            ),
                            format:        FieldFormat.FORMAT_SELECT_API,
                            field:         'carrierCategory',
                            object:        ColObjectName,
                        },
                        {
                            title: 'Процент на понижение',
                            field: 'percentToDegrade',
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            maxValue: 100
                        },
                        {
                            title: 'Процент на повышение',
                            field: 'percentToUpgrade',
                            format: FieldFormat.FORMAT_NUMBER,
                            minValue: 0,
                            maxValue: 100
                        },
                        // {
                        //     title: 'ценарий использования',
                        //     field: 'conflictScenario',
                        //     format: FieldFormat.FORMAT_SELECT,
                            
                        // },
                        {
                            title:         'Сценарий использования',
                            api:           'enum_conflict_scenarios',
                            apiFilter:     () => (
                                {
                                    is_loading: 1,
                                }
                            ),
                            format:        FieldFormat.FORMAT_SELECT_API,
                            object:        ColObjectName,
                            field:         'conflictScenario',
                        },
                        
     
                    ],
            },          
        ],
    },
};
