import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';
import {ColBoolean, ColObjectName, ColObjectShowDateTime, ColObjectSimpleData, ColObjectNameAndItemInfo} from 'components/showItems';
import moment from 'moment';
import React from 'react';
import {FieldFormat} from './FieldFormat';
import Badge from '@material-ui/core/Badge';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';


export default {
    listTitle:        'Торги',
    apiName:          'biddings',
    itemName:         'Торги',
    canAdd:           true,
    canEdit:          true,
    canDelete:        false,
    // filterTable:   {
    //     field: 'docStatus',
    //     array : [
    //         {
    //             name : 'Активные',
    //             fields : ['Проводится', 'Планируется']
    //         },
    //         {
    //             name : 'Не активные',
    //             fields : ['Отменено', 'Окончено']
    //         },
    //         {
    //             name : 'Все',
    //
    //         }
    //     ]
    // },
    initialValues: (props) => {
        const {item} = props || {};
        const initBiddingType = {
            'ЗаявкаНаТранспорт':  'Аукцион',
            'ЗапросСтоимости':  'Запрос',
            'Тендер': 'Тендер',
        }
        return {
            cl:          console.log('biddings_initialValues', props),
            docStatus:   'Планируется',
            biddingType: item && item._sub_name && initBiddingType[item._sub_name],
        }
    },
    cols: async({itemProps}) => ([
        itemProps && itemProps.cols && itemProps.cols.BiddingType,
        // {
        //     title:        'Ставки',
        //     field:        'bids',
        //     // detailHidden: true,
        //     // object: ColObjectName,
        //     object: ({row}) => (row.bids || []).length,
        //     editable:     'never',
        //     format:       FieldFormat.FORMAT_NONE,
        // },
        {
            title: 'Стартовая цена',
            field: 'priceStart',
            format:  FieldFormat.FORMAT_FLOAT,
            cols:  4,
        },
        {
            title:    'Статус',
            field:    'docStatus',
            format:   FieldFormat.FORMAT_NONE,
            api:      'enum_doc_statuses',
            // render:   o => ENUM_BIDDING_STATUS[o.biddingStatus] || '---',
            object:   ColObjectName,
            editable: 'never',
            cols:     4,
            disabled: true
        },
        {
            title:        'Номер документа',
            field:        'docNumber',
            detailHidden: true,
            editable:     'never',
            format:       FieldFormat.FORMAT_NONE,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ColObjectShowDateTime,
            editable:     'never',
            detailHidden: true,
            format:       FieldFormat.FORMAT_DATETIME_LOCAL,
        },
        
        // {
        //     title: 'Купить, сумма',
        //     field: 'priceBuynowSumm',
        //     hidden: true,
        //     detailHidden: true,
        // },
        // {
        //     title: 'Купить, процент',
        //     field: 'priceBuynowPercent',
        //     hidden: true,
        //     detailHidden: true,
        // },

        {
            title : 'Старт торгов',
            field:  'datetimeStart',
            format: FieldFormat.FORMAT_DATETIME_LOCAL,
            object: ColObjectShowDateTime,
            cols:   4,
            ...itemProps && itemProps.cols && itemProps.cols.datetimeStart,
        },
        {
            title : 'Окончание торгов',
            field:  'datetimeEnd',
            format: FieldFormat.FORMAT_DATETIME_LOCAL,
            object: ColObjectShowDateTime,
            cols:   4,
            ...itemProps && itemProps.cols && itemProps.cols.datetimeEnd, 
        },
        {
            title:  'Срок, мин',
            field:  'timeDurationMins',
            format:  FieldFormat.FORMAT_NUMBER,
            minValue: 0,
            cols:   4,
        },
        {
            title:  'Продление, мин',
            field:  'timeProlongMins',
            format:  FieldFormat.FORMAT_NUMBER,
            minValue: 0,
            cols:   4,
        },
        // {
        //     title: 'Шаг, сумма',
        //     field: 'stepBidSumm',
        //     hidden: true,
        //     detailHidden: true,
        // },
        // {
        //     title: 'Шаг, процент',
        //     field: 'stepBidPercent',
        //     hidden: true,
        //     detailHidden: true,
        // },
        {
            title:  'Открытые торги',
            helperText:   'Показывать текущую ставку',
            field:  'isOpenedLot',
            format: FieldFormat.FORMAT_CHECKBOX,
            object: ColBoolean,
            cols:   4,
            editable: 'never',
        },
        {
            title:  'Запрет выше',
            helperText:   'Запрещать делать ставку выше стартовой цены',
            field:  'betsOverpriceDenied',
            format: FieldFormat.FORMAT_CHECKBOX,
            object: ColBoolean,
            cols:   4,
            editable: 'never',
            disabled: true,
        },
    ]),
    onFormDataAction: (values, props) => {
        const {field, value, action} = props;
        if (!values.datetimeStart || action !== 'change') {
            return values;
        }
        if (field === 'datetimeEnd' || field === 'datetimeStart') {
            if (!values.datetimeEnd || !values.datetimeStart) {
                return values;
            }
            let calc = moment.duration(
                    moment(values.datetimeEnd)
                            .diff(values.datetimeStart),
            ).asMinutes();

            if (calc > 0) {
                return {...values, timeDurationMins: Math.round(calc)};
            }
        } else if (field === 'timeDurationMins') {
            let calc = moment(values.datetimeStart).add(value, 'minutes').format();
            return {...values, datetimeEnd: calc};
        }
        return values;
    },
    detail:           (data, items) => ({
        disabled: !(data.bids || []).length,
        icon:     () => {
            return (
                <Badge badgeContent={data.bids.length} color="primary">
                    <EmojiEventsIcon />
                </Badge>
            )
        },
        openIcon:  () => {
            return (
                <Badge badgeContent={data.bids.length} color="primary">
                    <EmojiEventsOutlinedIcon />
                </Badge>
            )
        },
        tooltip:  'Ставки',
        field:    'bids',
        render:   rowData => {
            const cols      =  () => {
                return [
                    {
                        title: 'Дата ставки',
                        field: 'bidDatetime',
                        type: 'datetime',
                        format: FieldFormat.FORMAT_DATETIME_LOCAL,
                        object: ColObjectShowDateTime,

                    },
                    {
                        title:  'Перевозчик',
                        field:  'bidCarrier',
                        format: FieldFormat.FORMAT_SELECT_API,
                        api:    'legals',
                        object: ColObjectNameAndItemInfo,
                        showApiInItemInfo : [
                            'contact_people',
                            'contact_infos',
                        ], 
                    },
                    {
                        title: 'Ставка',
                        field: 'bidValue',
                        render: (e) => {
                            return `${new Intl.NumberFormat("ru-RU", {minimumFractionDigits: 0, }).format(e.bidValue)} ${items.currency.name || ''}`
                        }
                    },
                ];
            };
            const valuesSub = rowData.bids;
            return  <SubFormListEdit
                    canAdd={false}
                    canEdit={false}
                    canDelete={false}
                    item={valuesSub}
                    listTitle={"Ставки"}
                    itemProps={{
                        field: 'bids',
                        cols: cols,
                    }}
                    itemValues={valuesSub}
                    customStyle={{maxWidth: "65%", marginLeft: "100px"}}
                    // onChange={handleChangeValues(i)}
                    // apiFilter={apiFilter &&
                    // apiFilter(values)}
                    // onChange={handleChangeTable}
                    // setIsLoadingTop={setIsLoading}
                    values={valuesSub}
                    parentRowData={rowData}
                    // errors={errors[detail.field]||[]}
                    // {...detail}
                    cols={cols}
            />;
        },
    }),
};