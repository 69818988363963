import {ROLES} from 'config/roles';
import {
    ViewModuleOutlined as MenuIcon,
} from '@material-ui/icons';
import PageKanban from 'page/PageKanban';

export default {
    listTitle: 'Доска канбан',
    apiName:   'kanban',
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Доска канбан',
        path:      '/kanban',
        Component: PageKanban,
        roles:     [ROLES.ROLE_USER],
        icon:      MenuIcon,
    },
    cols:      null,
};
