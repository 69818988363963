export const fonts = [
    'Fira Sans',
    'Lora',
    'Merriweather',
    'Montserrat',
    'Noto Sans',
    'Noto Serif',
    'Nunito',
    'Open Sans',
    'Open Sans Condensed',
    'Oswald',
    'PT Sans',
    'PT Serif',
    'Playfair Display',
    'Roboto',
    'Roboto Condensed',
    'Roboto Mono',
    'Roboto Slab',
    'Rubik',
    'Source Sans Pro',
    'Ubuntu',
    'Alegreya',
    'Alegreya Sans',
    'Arimo',
    'Cormorant Garamond',
    'Cuprum',
    'Didact Gothic',
    'EB Garamond',
    'Exo 2',
    'Fira Sans Condensed',
    'IBM Plex Sans',
    'IBM Plex Serif',
    'Old Standard TT',
    'PT Sans Caption',
    'PT Sans Narrow',
    'Play',
    'Source Code Pro',
    'Tinos',
    'Ubuntu Condensed',
    'Vollkorn',
    'Yanone Kaffeesatz',
    'Alice',
    'Cormorant',
    'El Messiri',
    'Fira Sans Extra Condensed',
    'IBM Plex Mono',
    'Istok Web',
    'Jura',
    'M PLUS 1p',
    'M PLUS Rounded 1c',
    'Montserrat Alternates',
    'PT Mono',
    'PT Serif Caption',
    'Philosopher',
    'Playfair Display SC',
    'Prata',
    'Ruda',
    'Russo One',
    'Sawarabi Gothic',
    'Spectral',
    'Ubuntu Mono',
    'Alegreya SC',
    'Alegreya Sans SC',
    'Anonymous Pro',
    'Arsenal',
    'Cormorant Infant',
    'Cormorant SC',
    'Cousine',
    'Fira Mono',
    'Gabriela',
    'Kosugi',
    'Kosugi Maru',
    'Literata',
    'Marmelad',
    'Oranienbaum',
    'Pattaya',
    'Podkova',
    'Rubik Mono One',
    'Scada',
    'Spectral SC',
    'Tenor Sans',
    'Andika',
    'Cormorant Unicase',
    'Fira Code',
    'Kurale',
    'Ledger',
    'Seymour One',
    'Vollkorn SC',
].sort((a,b) => a.localeCompare(b));
