import PageApiLoader from 'components/PageApiLoader3';
import contactInfo from 'config/contactInfo';
import contactPerson from 'config/contactPerson';
import contract from 'config/contract';
import driver from 'config/driver';
import carriers from "config/carriers";
import transport from 'config/transport';
import React from 'react';

// Редактировать контактную информацию
// http://tms.foamline.com/my/legals/0bf1dbaa-48dc-11ea-b812-005056a60abf/contact_infos/43ec10da-48dc-11ea-b812-005056a60abf/edit

export default function Page(props) {

    // React.useEffect(() => {
    //     Breads.add2('PageOrganizations');
    // },[]);
    const {menuRoot} = {
        menuRoot: {},
        ...props
    };


    return <PageApiLoader
        {...props}
        {...carriers}
        subApi={[
            {
                item:        contactPerson,
                parentField: 'legal',
            },
            {
                item:        contactInfo,
                parentField: 'legal',
            },
            // {
            //     item:        bankAccount,
            //     parentField: 'legal',
            // },
            {
                item:        driver,
                parentField: 'legal'
            },
            {
                item:        transport,
                parentField: 'legal'
            },
            {
                item:        contract,
                parentField: 'legal.contragent'
            },
        ]}
        newTable={true}
        canEdit={true}
        canAdd={true}
        canDelete={true}
    />;
}