import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';

import {
    Box,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    Slider,

} from '@material-ui/core';
import {
    Save,
    Close,
    Edit

} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FormDataContext } from 'FormDataContext';
import Cropper from 'react-easy-crop'
import API from 'libs/api';
import ConfirmDialog from "components/ConfirmDialog";
import {MeContext} from "meContext";
import useLoaderApi from 'components/useLoaderApi';
import {asGUID} from 'libs/asGUID';
import {filesize} from "filesize";
import {STATE} from 'libs/api';
import {config} from 'config'
import {withStyles} from "@material-ui/core/styles";

// const {filesizeSettings} = config

const TextUnderButton = withStyles(() => (
    {
        root: {
            fontSize: "0.7rem"
        },
    }
))(Typography);

const api = new API()

const useStyles = makeStyles(() => (
    {

        root: {
            display: 'flex',
            alignItems: "center",

        },
        dialogTitle: {
            marginTop: '1rem',
            textAlign: "left",
        },
        dialogContent: {
        },
        imgButton: {
            display: 'flex',
            justifyContent: "center",
            padding: "1rem 0",
        },
        dialogAction: {
            display: "flex",
            justifyContent: "flex-start",
        },
        dialogActionButton: {
            marginRight: "10px"
        },
        avatar: {
            width: '125px',
            height: '125px',
            cursor: "pointer",
        },
        textTitle: {
            marginLeft: "20px",
        },
        boxAvatar: {
            position: 'relative',
        },
        editAvatar: {
            position: "absolute",
            bottom: "8px",
            left: "93px",
            zIndex: "2",
            cursor: "pointer",
        },
        editAvatarCircle: {
            position: "absolute",
            bottom: "-25px",
            left: "75px",
            background: "white",
            padding: "2rem",
            borderRadius: "50%",
            zIndex: "1",
            cursor: "pointer",
        },


    }
));

const textPhoto = {
    dialog: {
        success: 'Выбранная фотография:',
        error: 'Выбран не тот формат, выберите фотографию с расширением png, и или jpg',
        save: "Соxранить",
        close: "Закрыть",
        edit: "редактировать",
    },
    ConfirmDialog : {
        title: "Превышен максимальный размер файла",
        buttonClose :'Закрыть'
    }
}

const fileTypesForInput = ['image/png', 'image/jpeg'];

const clickAvatar = () => {
    document.getElementById('fileButton').click()
}

function MyComponent(props) {
    const { item, values } = props;
    const { id } = values;
    const { topColsBlock, disabled, field, title, format, options, helperText, required, apiFilter, framing, objLinkedName, objLinkedGuid, ...other } = item;
    const { state, changeValueState, setStateValues } = React.useContext(FormDataContext)
    const { documentType } = state.values
    // console.log("push2", values)

    const classes = useStyles();
    const [fileUpload, setFfileUpload] = React.useState('');
    const [dialog, setDialog] = React.useState('');
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [confirmSize, setConfirmSize] = React.useState(false);
    const [croppedAreaPixelsState, setCroppedAreaPixelsState] = React.useState('');
    const me = React.useContext(MeContext);

    const {maxSizeUploadFile} = {
        maxSizeUploadFile: config.maxSizeFile,
        ...me
    }


    const [typeFile, loadStateFile] = useLoaderApi('user_file_doc_types', asGUID(documentType))

    const checkTypeFile = (type, file, separator) => {
        const typeFile = file.split(separator).pop();
        return type.includes(typeFile)

    }

    React.useEffect(()=>{

        if(state.values.name){
            new API().get(`user_file_doc_types/${asGUID(documentType)}`)
            .then(result => {
               if(result.mask && !checkTypeFile(result.mask, state.values.name, '.')){
                textPhoto.ConfirmDialog.text  = `Тип файла "${result.name}" поддерживает следующие форматы ${result.mask.split(',').join(', ')}`;
                textPhoto.ConfirmDialog.title = "Неверный формат файла"
                setConfirmSize(el => !el)
                setStateValues(values => ({
                    ...values,
                    name: null,
                    data: null,
                    type: null,
                }))
               }
            })
        }

    }, [documentType])


    const handlerChangeImg = (e) => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        try {
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.onloadend = () => {
                setFfileUpload(reader.result)
                setDialog(textPhoto.dialog.success)
            }
            if (fileTypesForInput.includes(file.type)) {
                reader.readAsDataURL(file)
                return null
            }
            setDialog(textPhoto.dialog.error)
        }
        catch {
            console.log('Error!!!')
        }

    }

    const closeAll = () => {
        setFfileUpload('')
        setDialog('')
    }

    const downLoad = id => {
        window.open(`/api/file/${id}`);
    }

    const buttonHandler = React.useCallback((id, documentType) => () => {
        if(id){
            downLoad(id)
            return null
        }else if (!documentType){
            return null
        }else{
            clickAvatar()
        }
    }, [])
 
    const uploadFile = (e) => {
        const file = e.target.files[0]
        if(!file){
            return null
        }
        if (file.size > maxSizeUploadFile) {
            const sizeFile = filesize(maxSizeUploadFile, config.filesizeSettings)
            textPhoto.ConfirmDialog.text = `Превышен максимальный размер загружаемого файла в ${sizeFile}. Файл не был загружен.`;
            textPhoto.ConfirmDialog.title = "Превышен максимальный размер файла";
            setConfirmSize(true)
            return null
        }
        if(loadStateFile === STATE.LOADED && typeFile.mask && !checkTypeFile(typeFile.mask, file.type, '/') ){
            textPhoto.ConfirmDialog.text  = `Тип файла "${typeFile.name}" поддерживает следующие форматы ${typeFile.mask.split(',').join(', ')}`;
            textPhoto.ConfirmDialog.title = "Неверный формат файла"
            setConfirmSize(true)
            return null
        }


        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {

                const base64data = reader.result;
                setFfileUpload(base64data)
                setStateValues(values => ({
                    ...values,
                    name: file.name,
                    data: base64data,
                    type: file.type,
                    objLinkedName: objLinkedName,
                    objLinkedGuid: objLinkedGuid,

                }))

                // changeValueState('dataFile', base64data)
                closeAll()
            }
        } catch {
            closeAll()
            return null
        }

    }


    const uploadPhoto = () => {
        getCroppedImg(fileUpload, croppedAreaPixelsState).then(r => {
            const reader = new FileReader();
            reader.readAsDataURL(r);
            reader.onloadend = () => {
                const base64data = reader.result;
                setFfileUpload(base64data)
                changeValueState('picture_data', base64data)
                closeAll()
            }
        })
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsState(croppedAreaPixels)
    }

    const getCroppedImg = (imageSrc, crop) => {
        const image = new Image()
        image.src = imageSrc
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        /* setting canvas width & height allows us to 
        resize from the original image resolution */
        canvas.width = 250
        canvas.height = 250
        ctx.drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            canvas.width,
            canvas.height
        )

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    if (framing) {
        return (
            <React.Fragment>
                <AdminShow>[INPUT]</AdminShow>
                <Dialog
                    open={!!dialog}
                    onClose={closeAll}
                >
                    <DialogTitle id="alert-dialog-title"
                        className={classes.dialogTitle}
                    >
                        <Box className={classes.dialogAction}>
                            {
                                fileUpload &&
                                <Button
                                    variant="contained"
                                    color={'primary'}
                                    startIcon={<Save />}
                                    onClick={uploadPhoto}
                                    className={classes.dialogActionButton}
                                >
                                    {textPhoto.dialog.save}
                                </Button>
                            }

                            <Button
                                variant="contained"
                                startIcon={<Close />}
                                onClick={closeAll}
                            >
                                {textPhoto.dialog.close}
                            </Button>
                        </Box>
                        {dialog}
                    </DialogTitle>
                    {
                        fileUpload &&
                        <DialogContent className={classes.dialogContent}>
                            <Box>
                                <Box style={{ height: "50vh", position: 'relative', overflow: "hiden" }}>
                                    <Cropper
                                        image={fileUpload}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </Box>
                                <Box>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom) => setZoom(zoom)}
                                        classes={{ root: 'slider' }}
                                    />
                                </Box>

                            </Box>
                        </DialogContent>

                    }

                </Dialog>
                <Box className={classes.root}>
                    <Box className={classes.boxAvatar} onClick={clickAvatar}>
                        <Avatar
                            src={values['picture_data'] ? values['picture_data'] : api.getUploadImg(values['picture'])}
                            className={classes.avatar}
                        />
                        <Edit className={classes.editAvatar} />
                        <Box className={classes.editAvatarCircle} />
                    </Box>

                    <Typography variant='body2' className={classes.textTitle}>
                        {helperText}
                    </Typography>
                    <input type="file" id="fileButton" onChange={handlerChangeImg} hidden/>
                </Box>


            </React.Fragment>
        )
    }
    return (
        <Box>
            <Box className={classes.root}>
                <Box className={classes.boxAvatar}
                    onClick={buttonHandler(id, documentType)}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!documentType}
                    >
                        {id ? 'Скачать файл' : 'Выбрать файл'}
                    </Button>

                </Box>
                <input type="file" id="fileButton" onChange={uploadFile} hidden accept={typeFile && typeFile.mask || ''} />
                <ConfirmDialog
                    show={confirmSize}
                    title={textPhoto.ConfirmDialog.title}
                    text={textPhoto.ConfirmDialog.text}
                    confirmDisable={true}
                    cancel={textPhoto.ConfirmDialog.buttonClose}
                    onCancel={() => {
                        setConfirmSize(false)
                    }}
                />
            </Box>
            {
                !id && <Box mt={1}>
                    <TextUnderButton variant='body2'>{`(Максимальный размер загружаемого файла ${filesize(maxSizeUploadFile, config.filesizeSettings)})`}</TextUnderButton>
                </Box>
            }

        </Box>
    )

}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const { field } = props.item;
    const { state: { values, errors }, handleChange } = React.useContext(FormDataContext);
    const handleChangeLocal = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };

    const value = values && values[field];
    const error = errors && errors[field];

    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;