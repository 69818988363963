import React from 'react';
import {STATE} from "libs/api";
import {uniqTabs} from "./useListTabs";
import {FieldFormat} from "../config/FieldFormat";

/**
 * Проверка уникальности колонок по нескольким полям
 * @param cols
 * @returns {*|string}
 */
function uniqCols(cols) {
    if (!cols) {
        return 'null'
    }
    return cols
        .map(i => 'key:'.concat(i.field, i.title, i.format, i.hidden, i.disabled))
        .join(';')

}

const fake = [
    {
        title:        'Наименование',
        field:        'OWNER_NAME',
        format:       FieldFormat.FORMAT_INPUT,
        cols:         2,
        topColsBlock: 5,
        hidden:       true
    },
];

export function useExplainColsFake() {
    const [f, setFake] = React.useState(fake);
    console.log('callfunction useExplainCols Fake')
    return f;
}

const notEmpty = arrayItem => arrayItem;

export default function useExplainCols(cols, props, values, info, initialState = null) {
    const [columns, setColumns] = React.useState(Array.isArray(cols) ? cols : initialState);

    const setColumnsCheck = preNextCols => {
        const nextCols = preNextCols.map(col => {
            let {required, check} = col;
            if(typeof required == 'function'){
                required =  required(values);
            }
            // if(required && !check){
            //     check = value => !value.length && 'Поле не заполнено';
            // }
            return {...col, required, check};
        });

        setColumns(prevCols => {
            const equal = uniqCols(prevCols) === uniqCols(nextCols);
            return equal ? prevCols : nextCols
        })
    }

    React.useEffect(()=> {
        Promise
            .resolve('function' === typeof cols ? cols(props, values, info) : cols)
            .then(data => setColumnsCheck(data.filter(notEmpty)))
    }, [cols, values])

    // React.useMemo(() => {
    //     console.log('callfunction useExplainCols cols changed', cols)
    // }, [cols]);
    // React.useMemo(() => {
    //     console.log('callfunction useExplainCols item changed', item)
    // }, [item]);

    // React.useMemo(() => {
    //     let c = 'function' === typeof cols
    //         ? cols(props, item, info)
    //         : cols;

    //     if (c instanceof Promise) {
    //         c.then(data => {
    //             console.log('useExplainCols Promise resolved', data)
    //             setColumnsCheck(data.filter(v => v))
    //         });
    //     } else {
    //         console.log('useExplainCols no Promise resolved', c)
    //         setColumnsCheck(c && c.filter(v => v));
    //     }
    //     // eslint-disable-next-line
    // }, [cols, item]);

    return columns;
}