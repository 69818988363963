import React from "react";

import { SvgIcon } from '@material-ui/core';

const HtmlIcon = () => {
    return (
        <SvgIcon viewBox={"0 0 56 56"}>
            <g>
                <path style={{fill: '#E9E9E0'}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                <polygon style={{fill: '#D9D7CA'}} points="37.5,0.151 37.5,12 49.349,12 	" />
                <path style={{fill: '#EC6630'}} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z" />
                <g>
                    <path style={{fill: '#FFFFFF'}} d="M17.455,42.924V53h-1.641v-4.539h-4.361V53H9.785V42.924h1.668v4.416h4.361v-4.416H17.455z" />
                    <path style={{fill: '#FFFFFF'}} d="M27.107,42.924v1.121H24.1V53h-1.654v-8.955h-3.008v-1.121H27.107z" />
                    <path style={{fill: '#FFFFFF'}} d="M36.705,42.924h1.668V53h-1.668v-6.932l-2.256,5.605H33l-2.27-5.605V53h-1.668V42.924h1.668
			l2.994,6.891L36.705,42.924z"/>
                    <path style={{fill: '#FFFFFF'}} d="M42.57,42.924v8.832h4.635V53h-6.303V42.924H42.57z" />
                </g>
                <g>
                    <path style={{fill: '#EC6630'}} d="M23.207,16.293c-0.391-0.391-1.023-0.391-1.414,0l-6,6c-0.391,0.391-0.391,1.023,0,1.414l6,6
			C21.988,29.902,22.244,30,22.5,30s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L17.914,23l5.293-5.293
			C23.598,17.316,23.598,16.684,23.207,16.293z"/>
                    <path style={{fill: '#EC6630'}} d="M41.207,22.293l-6-6c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L39.086,23
			l-5.293,5.293c-0.391,0.391-0.391,1.023,0,1.414C33.988,29.902,34.244,30,34.5,30s0.512-0.098,0.707-0.293l6-6
			C41.598,23.316,41.598,22.684,41.207,22.293z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </SvgIcon>
    )
}
export { HtmlIcon };