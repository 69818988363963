import {DriverIcon} from 'components/icons';
import {ColBoolean, ShowIndividual, ShowLegal} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import {asGUID} from 'libs/asGUID';
import pageDrivers from 'page/PageDrivers';
import {SubApiReplace} from "../components/FormLoadSub";
import contactInfo from "./contactInfo";
import React from "react";
import {TabForm} from "../components/NestedFieldsForm";

export default {
    listTitle:        'Водители',
    apiName:          'drivers',
    itemName:         'Водителя',
    roles:            [
        ROLES.ROLE_USER,
    ],
    canEdit: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },
    canDelete: (rowData,{me}) => {
        return asGUID(me.client_profile_guid) === asGUID(rowData.mainClientProfile)
    },

    filterParent: (props) => {
        const {menuRoot, legal, _items} = props;
        const {_self} = _items || {};
        const {contragent} = _self || {};
        let filter = {
            'legal.isCarrier': menuRoot.id === 'carrier',
        };
        if (contragent) {
            filter['legal[]'] = [asGUID(contragent), legal];
        }
        return filter
    },
    menu:             {
        title:     'Водители',
        path:      '/drivers',
        Component: pageDrivers,
        icon:      DriverIcon,
        roles:     [ROLES.ROLE_USER],
    },
    topColsBlocks:    [
        {
            num:  1,
            cols: 2,
            border: true,
            title:  'Общие',
        },
        {
            num:    2,
            title:  'Контактная информация',
            cols:   2,
            border: true
        },
    ],
    onFormDataAction: (values, {old, action}) => {
        const genName = v => v && v.individual
            ? 'Водитель ' + v.individual.name : '';
        const update  = !old
            || action === 'change'
            || !old.individual
            || old.name === genName(old)
            || !old.name;

        return update
            ? {
                ...values,
                name: genName(values),
            }
            : values;
    },
    colsInTab: true,
    cols:             [
        {
            title:        'Физ лицо',
            field:        'individual',
            api:          'individuals',
            subConfig:    'individual',
            format:       FieldFormat.FORMAT_SUB_FORM,
            object:       ShowIndividual,
            cols: 1,
            sub : 'driver',
            topColsBlock: 1,
        },
        {
            title:        'Контакты',
            field:        'contacts',
            api:          'contact_infos',
            // todo: not use apiFilter for new objects
            apiFilter:    (item) => item && item.guid
                ? {
                    driver: item.guid,
                    none:        console.log('apiFilter driver', item),
                }
                : {
                    driver: 'new',
                    none:        console.log('apiFilter driver', item),
                }
            ,
            multi:        true,
            subConfig:    'contactInfo',
            format:       FieldFormat.FORMAT_SUB_TABLE,
            parentField:  {
                driver: 'guid',
                name:          'name',
                comment:       'comment',
            },
            cols: 1,
            topColsBlock: 2,
            hidden:       true,
        },
        {
            title:     'Юридическое лицо',
            field:     'legal',
            required:  true,
            api:       'legals',
            object:    ShowLegal,
            format:    FieldFormat.FORMAT_SELECT_API,
            apiFilter: () => (
                { 'exists[contragent]': true }
            ),
            cols: 1,
            topColsBlock: 1,
        },
        {
            title:        'Черный список',
            field:        'isBanned',
            object:       ColBoolean,
            format:       FieldFormat.FORMAT_CHECKBOX,
            cols:         6,
            topColsBlock: 1,
        },
        
    ],
    // subItems: async (props) => ({
    //     style: 'tabs',
    //     list:  [
    //         {
    //             cl: console.log('subItems driver', props),
    //             Component: TabForm,
    //             AfterComponent: SubApiReplace,
    //             api:       contactInfo.apiName,
    //             apiFilter: ({guid}) => (
    //                 {
    //                     _apiFilter: 'apiFilter',
    //                     driver: guid || 'new',
    //                 }
    //             ),
    //             ...contactInfo,
    //             listTitle:        'Контакты',
    //             icon: <contactInfo.menu.icon/>
    //         },
    //     ],
    // })
};