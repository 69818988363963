import React from 'react';
import {FormDataContext} from "FormDataContext";
import MaterialTable from 'material-table';
import {
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    IconButton,
    Tooltip,
    TextField,
    Grid,
    Fab
} from '@material-ui/core';

import {STATE} from 'libs/api';
import {config as configGlobal} from 'config'
import {useLoaderApiList} from 'components/useLoaderApi';
import LoadStateShow from 'components/LoadStateShow.jsx'
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmDialog from "../ConfirmDialog";
import {AddIcon, DeleteIcon} from 'components/icons';
import RenderEmailOthers from "./RenderEmailOthers";

export const verificationAndConversion = (data) => {
    if(Array.isArray(data) && data.length && typeof data[0] == "string"){
        return JSON.parse(data[0])
    }
    return data
}


const UserNotificationSettings = () => {

    const [channels, setChannels] = React.useState([]);

    const {state: {values}, setStateValues} = React.useContext(FormDataContext);

    const createChildrens = (data, channels) => {
        const result = []
        data.events.map(el => {
            const children = {}
            channels.map(channel => {
                if (channel.code.includes('_others')) {
                    children[`${channel.code}_data`] = el.channels[channel.code].othersList || []
                }
                //el.channels[channel.name].isEnable
                children[channel.code] = el.channels[channel.code].isEnable

            })
            result.push({...el, ...children})
        })
        return result
    };

    const createParent = (item, data, channels) => {
        const parent = {}
        channels.map(channel => {
            parent[channel.code] = !data.find(el => el.parentId && el.parentId === item.id && !el[channel.code])
        })
        return {...item, ...parent}
    }


    const createData = (result) => {
        if (!result.channels || !result.notifications || !result.notifications.length) {
            return null
        }
        setChannels(result.channels)
        if (!values.user_notifications) {
            const finalyData = []
            result.notifications.map(item => {

                createChildrens(item, result.channels).map(el => finalyData.push({
                    ...el,
                }))
                finalyData.push(createParent(item, finalyData, result.channels));
            })
            console.log({finalyData})
            setStateValues(values => ({
                ...values,
                user_notifications: [...finalyData],
                channels: [...result.channels]
            }));
        }

    }

    const [notifications, loadState] = useLoaderApiList(`user_notifications/${values.guid}`, {}, {onResponse: createData})

    const checkAllRows = (name) => {
        if (!values.user_notifications) {
            return false;
        }
        return values.user_notifications.find(el => !el[name]) ? false : true
    }

    const checkIndeterminate = (code) => {
        if (!values.user_notifications) {
            return false;
        }
        const count = values.user_notifications.filter(el => el[code]).length

        return count && count < values.user_notifications.length

    }


    const changeTitle = (code) => () => {
        const value = values.user_notifications.find(el => !el[code]) ? true : false
        const data = [...values.user_notifications];
        data.map(el => {
            el[code] = value;
        })
        setStateValues(values => ({...values, user_notifications: [...data]}))
    }


    const changeRow = (props, code) => () => {
        if (!values.user_notifications) {
            return false;
        }
        const data = [...values.user_notifications]
        data.map(el => {
            if (el.id === props.id) {
                el[code] = !props[code]
            }
            if (el.parentId === props.id) {
                el[code] = !props[code]
            }

        })
        data.filter(el => !el.parentId).map(el => {
            const children = data.filter(item => item.parentId === el.id)
            channels.map(channel => {
                el[channel.code] = !children.find(el => !el[channel.code])
            })

        })
        setStateValues(values => ({...values, user_notifications: [...data]}))

    }



    const RenderRow = (props, code) => {
        if (code.includes('_others') && !props.events) {
            return <Grid container spacing={2} alignItems={"center"}>
                <Checkbox
                        checked={props[code]}
                        size="small"
                        color="primary"
                        onChange={changeRow(props, code)}
                        inputProps={{'aria-label': 'secondary checkbox'}}

                />
                <RenderEmailOthers {...props} code={`${code}_data`} disabled={!props[code]}/>
                <Box width={"28px"} height={"28px"} borderRadius="50%"
                     bgcolor={!props[code] ? "text.disabled" : "primary.main"} display={"flex"}
                     justifyContent={"center"} alignItems={"center"}>

                    <Typography style={{
                        fontSize: "0.75rem",
                        color: "white"
                    }}>{verificationAndConversion(props[`${code}_data`]).filter(el => el.is_enable).length}</Typography>

                </Box>
            </Grid>
        }
        return (
                <Checkbox
                        checked={props[code]}
                        size="small"
                        color="primary"
                        onChange={changeRow(props, code)}
                        inputProps={{'aria-label': 'secondary checkbox'}}

                />
        )
    }

    const createTitleTable = (name, shortname, description) => {

        return (<Box ml={1.3}>
            <FormControlLabel
                    value="end"
                    control={<Checkbox
                            checked={checkAllRows(name)}
                            size="small"
                            color="primary"
                            indeterminate={checkIndeterminate(name)}
                            onChange={changeTitle(name)}
                    />}
                    label={<Tooltip title={description} interactive placement="top"><Typography
                            variant='body2'>{shortname}</Typography></Tooltip>}
                    labelPlacement="end"
            />
        </Box>)
    }

    const createColumns = () => {
        const data = [
            {
                title: 'Событие',
                field: 'description',
                render: ({parentId, description}) => {
                    return (
                            <Box ml={parentId ? 2 : 0} display="flex">
                                <Typography>{description}</Typography>
                            </Box>
                    )
                }
            },
        ]

        channels.map(el => {
            data.push({
                title: createTitleTable(el.code, el.name, el.description),
                field: el.code,
                render: (props) => RenderRow(props, el.code)
            })
        })
        return data
    }

    return (
            <LoadStateShow state={loadState} error="Произошла ошибка при загрузке настроек подписок на событий">
                <MaterialTable
                        title="Подписка на события"
                        columns={createColumns()}
                        data={values.user_notifications}
                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                        {...configGlobal.MaterialTable}
                        options={{
                            // tableLayout: 'auto',
                            selection: false,
                            selectionProps: rowData => {

                                return {
                                    color: 'primary',
                                }
                            },
                            // pageSizeOptions: [{ value: data.length > 0 ? data.length : 1, label: 'Все' }],
                            headerStyle: {
                                backgroundColor: '#EEE',
                            },
                            rowStyle: rowData => {
                                return {backgroundColor: rowData.parentId ? "#ffffff" : "#e3f2fd"}

                            },

                        }}

                />
            </LoadStateShow>
    )
}

export default UserNotificationSettings

