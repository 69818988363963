import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import HOCLoadItem from 'components/HOCLoadItem';
import RequestForm from 'components/RequestForm';
import {FieldFormat} from 'config/FieldFormat';
import {STATE} from 'libs/api';
import React from 'react';
import request from 'superagent';

/**
 * @deprecated Удалить, старый вариант
 */
const userSettings = {
    cols: [],
};

const config = {
    cols: [
        {
            field:  'oneoffLimitSum',
            title:  'Лимит суммы для заявок по одноразовому договору',
            format: FieldFormat.FORMAT_NUMBER,
            minValue: 0
        },
        {
            field:  'oneoffLimit',
            title:  'Лимит количества разовых перевозок',
            format: FieldFormat.FORMAT_NUMBER,
            minValue: 0
        },
        {
            title:  'Уровень доступа к документам',
            field:  'defaultAccessLevel',
            api:    'enum_access_levels',
            format: FieldFormat.FORMAT_SELECT_API,
        },
    ],
};

function MyDocumentRights(props) {
    //const [open, setOpen]   = React.useState(false);

    const {open, onClose, item, loadState} = props;
    
    const handleSubmit = values => request
        .post(`/api/profile`)
        .set('accept', 'application/json')
        .send(values);
    
    switch (loadState) {
        case STATE.INIT:
        
    }
    switch (loadState) {
        case STATE.INIT:
        case STATE.LOADING:
            return <Backdrop open={true}>
                <CircularProgress/>
            </Backdrop >;
        case STATE.LOADED:
            // return <Dialog open={true} maxWidth={'sm'} fullWidth={true}>
            //     {JSON.stringify({item})}
            // </Dialog>;
            return <DialogEditCreate3
                title={'Настройки профиля'}
                dbId={'guid'}
                open={open}
                handleSubmit={handleSubmit}
                handleClose={onClose}
                onSuccess={onClose}
                windowUsersetting={true}
                // initialValues={{...initialValues, ...item, ...props.filter}}
                FormComponent={RequestForm}
                {...config}
                initialValues={item}
                loadState={loadState}
            />;
        case STATE.ERROR:
        default:
            return <Dialog open={true}>
                <Alert severity="error">Error: {item}</Alert>
            </Dialog>;
    }
}

export default HOCLoadItem({path: '/api/profile'})(MyDocumentRights);
