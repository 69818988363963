import useStyles from "styles";
import React from "react";
import {Badge, IconButton, Box, Typography} from "@material-ui/core";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import {ChatWindow} from "components/Chat/ChatWindow";
import {useChat} from "./useChatRoom";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import asArray from "libs/asArray";

export const reduceNotRead = (A, B) => A + Number(B.notRead)

export const Chat = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const objectText = {
        'docLinkedGuid': {
            api:         'doc_requests',
            api2:        'legals',
            title:       "",
            textButton:  () => {
                return (
                    <Box display="flex">
                        <ArrowDownwardIcon/>
                        <span>Заявки \ Перевозчики</span>
                        <ArrowForwardIcon/>
                    </Box>
                )
            },
            secondGroup: "carrier",
            notSelected: 'Не выбрана заявка',
        },
        "carrier":       {
            api:         'legals',
            api2:        'doc_requests',
            title:       "",
            textButton:  () => {
                return (
                    <Box display="flex">
                        <ArrowDownwardIcon/>
                        <span>Перевозчики \ Заявки</span>
                        <ArrowForwardIcon/>
                    </Box>
                )
            },
            secondGroup: "docLinkedGuid",
            notSelected: 'Не выбран перевозчик',
        }
    };
    const [rooms, loadState] = useChat()

    return <>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpen}
            edge="start"
        >
            <Badge badgeContent={asArray(rooms).reduce(reduceNotRead,0)} color="error">
                <ChatOutlinedIcon/>
            </Badge>
        </IconButton>
        <ChatWindow open={open} onClose={handleClose} objectText={objectText}/>
    </>
}

