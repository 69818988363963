import React from 'react';
import * as PropTypes from "prop-types";
import {MeContext} from "meContext";

const emptyCall = () => {};
const useWebSocket = (url, opts) => {
    let interval;

    const {
        onOpen,
        onMessage,
        onClose,
        onError,
    } = {
        onOpen:    emptyCall,
        onMessage: emptyCall,
        onClose:   emptyCall,
        onError:   emptyCall,
        ...opts
    };
    const [readyState, setReadyState] = React.useState(WebSocket.CLOSED);

    const me = React.useContext(MeContext);

    let ws = {};

    function connect() {
        ws = new WebSocket(url);

        ws.onopen = e => {
            console.log('Socket is open')
            setReadyState(ws.readyState);
            ws.send(JSON.stringify({action: 'ME', me}));
            clearInterval(interval);
            interval = setInterval(() => {
                // console.log(ws.readyState, ws)
                ws.send(JSON.stringify({action: 'PING'}));
                // ws.ping()

            }, 1000)
            onOpen(ws, e);
        }
        ws.onmessage = e => {
            try {
                const json = JSON.parse(e.data);
                console.log('Socket onmessage', json);
                onMessage(json, ws);
            }
            catch {
                console.log('Socket onmessage error', e.data);
            }
        }
        // ws.onclose = e => onClose(ws, e);

        // ws.onmessage = function (e) {
        //     console.log('Message:', e.data);
        // };

        ws.onclose = function (e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            onClose(e);
            setReadyState(ws.readyState);
            clearInterval(interval);
            setTimeout(function () {
                connect();
            }, 1000);
        };

        ws.onerror = function (err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            setReadyState(ws.readyState);
            onError(err)
            ws.close();
        };
    }

    React.useEffect(() => {
        connect();
    }, []);

    const sendData = data => {
        // InvalidStateError: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.
        if (ws && ws.send && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(data));
        }
    }

    return [
        readyState === WebSocket.OPEN,
        sendData,
        readyState
    ]
}

useWebSocket.propTypes = {
    url:  PropTypes.string,
    opts: PropTypes.shape({
        onOpen:    PropTypes.func,
        onMessage: PropTypes.func.isRequired,
        onClose:   PropTypes.func,
        onError:   PropTypes.func,
    })
};
export {useWebSocket};