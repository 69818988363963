
import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tooltip,

} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Close as CloseIcon} from "@material-ui/icons";
import {useLocation } from 'react-router-dom';
import {config} from "../../config";

import {DialogTitleBreadcrumbs} from './DialogTitleBreadcrumbs'
import ContentHelp from "./ContentHelp";



export const HelpWindow = ({open, onClose, }) => {
    const re =  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    const location = useLocation().pathname.split('/').filter(el => el !== '');
    const breadcrumbs = ['Справка:'];

    const createLink = (data) => data
            .filter(el => el !== 'edit')
            .map(el =>  re.test(el) ? 'edit' : el)
            .join('/')

    location
            .filter(el => !re.test(el))
            .forEach(element => {
                Object.values(config)
                        .forEach(({menu, apiName, itemName}) => {
                            if(menu && menu.path && menu.path === `/${element}`){
                                breadcrumbs.push(menu.title)
                            }else if(apiName === element && !breadcrumbs.includes(itemName)){
                                breadcrumbs.push(itemName)
                            }
                        })

            })

    const [link, setLink] = React.useState(createLink(location))
    React.useEffect(() =>{
        setLink(createLink(location))
    }, [open])

    const clickMarkDownLink = (link, forceReloadData) => (e) => {
        e.preventDefault()
        // console.log("push2", link)
        if(link.includes("http://") || link.includes("https://") ){
            window.open(link);
            return
        }
        setLink(link)
        typeof forceReloadData === 'function' && forceReloadData()
    }

    return (
            <Dialog
                    open={open} onClose={onClose}
                    maxWidth={"xl"}
                    fullWidth={true}
            >
                <DialogTitle>
                    <Grid container alignItems="center" direction="row" >
                        <Grid item xs={11}>
                            <DialogTitleBreadcrumbs breadcrumbs={breadcrumbs}/>
                        </Grid>
                        <Grid item xs={1} container justify={"flex-end"}>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {
                        open && <ContentHelp
                                link={`${link}/`}
                                changeLink={setLink}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
    )
}