import React from "react";
import {useLoaderApiList} from "../useLoaderApi";
import {ChatContext} from "./ChatContext";

export const useChat = () => {
    const {rooms, loadStateRooms} = React.useContext(ChatContext)
    return [rooms, loadStateRooms]
}


const useChatRoom = (room) => {

    const [items, loadState, reload, setItems] = useLoaderApiList(`chat/${room}`, {})
    const {addEventListener, removeEventListener} = React.useContext(ChatContext)

    React.useEffect(() => {
        addEventListener(room, (data) => {
            setItems(old => [...old, data])
        })
        return () => {
            removeEventListener(room);
        }
    }, [room])

    return [items, loadState];
};

export default useChatRoom;