import {FormDataContextConsumer} from 'FormDataContext';
import {Grid} from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import SubFormListEdit from 'components/FieldFormat/SubFormListEdit';
import detailPanelRenderDefault from './detailPanelRenderSubtable';
import asArray from 'libs/asArray';


const detailPanelRender2and1Main = ({panel, values, onChange, tableRef, setIsLoading, rowData}) =>
    <FormDataContextConsumer>{context => {
        let item        = context.values;
        const valuesAll = rowData[panel.field] || [];

        console.log('detailPanelRenderDefault', {valuesAll, rowData});
        //filter(i=>i.key_id === rowData.key_id)
        const handleChangeSubTable = (event, valuesNew, resolve) => {
            console.log(
                'onChange handleChangeSubTable', {rowData, values},
            );
            try {
                // const index   = values.indexOf(rowData);
                // values[index] = {...rowData, [panel.field]: valuesNew};
                // console.log(
                //     'onChange handleChangeSubTable',
                //     {index, values, rowData, valuesNew},
                //     [{...rowData, [panel.field]: valuesNew}],
                // );
            } catch (e) {
                console.log('onChange handleChangeSubTable', e);
            }
            onChange(null, valuesNew, resolve);
        };

        const setRowDataKeyId = (key_id) => {
            const index = values.indexOf(rowData);
            console.log('valuesNew key index', index);
            rowData.key_id = key_id;
            values[index]  = rowData;
            onChange(null, values.map(({tableData, ...i}) => i), tableRef.current.onQueryChange);
        };

        const {colsSplit} = {colsSplit: 1, ...panel};
        // if(panel.field === 'loadCargo' && item.typePoint !== 'погрузка'){
        //     return <></>
        // }
        // if(panel.field === 'unloadCargo' && item.typePoint !== 'разгрузка'){
        //     return <></>
        // }

        const itemProps = {
            field: panel.field,
            cols: panel.cols,
            // onFormDataAction: values => values
            onFormDataAction: panel.onFormDataAction,
            initialValues: panel.initialValues || (() => ({})),
        };
        console.log('itemProps',itemProps)
        // return 'itemProps'

        return <Grid item sm={12 / colsSplit} xs={12}>
            <ErrorBoundary info={'detailPanelRender2and1:'+panel.field}>
                <SubFormListEdit
                    listTitle={panel.title || panel.tooltip || 'Список'}
                    item={item}
                    itemProps={itemProps}
                    itemValues={valuesAll}
                    // onChange={handleChangeValues(i)}
                    // apiFilter={apiFilter &&
                    // apiFilter(values)}
                    onChange={handleChangeSubTable}
                    setIsLoadingTop={setIsLoading}
                    values={valuesAll}
                    parentRowData={item}
                    // errors={errors[detail.field]||[]}
                    optionsMaterialTable={{showTitle: true}}
                    {...panel}
                    // cols={panel.cols
                    //     ? panel.cols(item[panel.field], rowData, setRowDataKeyId)
                    //     : []}
                />
            </ErrorBoundary>
        </Grid>;
    }}
    </FormDataContextConsumer>;


const detailPanelRender2and1 = ({detail, panel, values, panelValues, onChange, tableRef, setIsLoading}) => rowData => {

    const subChange1 = (event, valuesNew, resolve) => {
        try {
            const index   = values.indexOf(rowData);
            console.log('subChange index',{values,index,rowData})
            values[index] = {...rowData, [panel.field]: valuesNew};
            console.log('onChange subChange', {rowData, valuesNew, panelValues, values, panel});
            // onChange(null, values, resolve);
            onChange(event, values);
        }catch (e){
            console.log('subChange',e,valuesNew)
        }

    };

    // const onChange = data => {
    //     console.log('onChange', data);
    // };
    return <Grid container>
        <Grid item xs={1}>
            {/*<pre>{JSON.stringify(valuesAll, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(valuesOther, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
        </Grid>
        <Grid item xs={11}>
            <Grid container spacing={1}>{asArray(detail).map((det) => {
                let subPanel = det;
                if (typeof det == 'function') {
                    subPanel = det(values);
                }
                const subValues = values[subPanel.field];

                const subChange = (event, valuesNew, resolve) => {
                    try {
                        const index   = values.indexOf(rowData);
                        console.log('subChange index',{values,index,rowData})
                        values[index] = {...rowData, [subPanel.field]: valuesNew};
                        console.log('onChange subChange', {rowData, valuesNew, panelValues, values, panel, subPanel});
                        // onChange(null, values, resolve);
                        onChange(event, values);
                    }catch (e){
                        console.log('subChange',e,valuesNew)
                    }

                };

                return detailPanelRender2and1Main({
                    panel:    subPanel,
                    values:   values,
                    onChange: subChange,
                    tableRef,
                    setIsLoading,
                    rowData:  rowData,
                });
            })}
            </Grid>
        </Grid>
    </Grid>;

};


export default detailPanelRender2and1;