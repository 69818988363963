import {Badge, IconButton, Snackbar, Tooltip} from "@material-ui/core";
import React from "react";
import url from "url";
import {MeContext} from "../meContext";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import PowerOutlined from "@material-ui/icons/PowerOutlined";
import PowerOffOutlined from "@material-ui/icons/PowerOffOutlined";
// import {reduceNotRead} from "./Chat/Chat";
import AdminShow from "./FieldFormat/AdminShow";
import {useWebSocket} from "../libs/useWebsocket";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import * as PropTypes from "prop-types";

function ConnectedIcon(props) {
    const {clients} = props;
    return <Tooltip title={<React.Fragment>
        <List>{clients.map((cl,key) => <ListItem key={key}>
            <ListItemAvatar>
                <Avatar src={cl.picture}/>
            </ListItemAvatar>
            <ListItemText primary={cl.username} secondary={cl.client}/>
        </ListItem>)}</List>
    </React.Fragment>}>
        <Badge badgeContent={clients.length}>
            <PowerOutlined/>
        </Badge>
    </Tooltip>;
}

ConnectedIcon.propTypes = {clients: PropTypes.arrayOf(PropTypes.any)};

export default function WebSocketEvents() {

    const [message, setMessage] = React.useState('');
    const [clients, setClients] = React.useState([]);

    const handleClose = () => setMessage(null);
    window.setMessage = setMessage;

    // const hostname = 'tms-stage.foamline.com';
    const hostname = null;

    const wsUrl = url.format({
        protocol: window.location.protocol === 'https:' ? 'wss' : 'ws',
        hostname: hostname || process.env.WDS_SOCKET_HOST || window.location.hostname,
        port:     process.env.WDS_SOCKET_PORT || window.location.port,
        // Hardcoded in WebpackDevServer
        pathname: process.env.WDS_SOCKET_PATH || '/ws',
        slashes:  true,
    })

    const onMessage = (message) => {
        const {action, clients} = message;
        // console.log('message', message)

        if (action === 'SHOW_CLIENTS') {
            setClients(clients.filter(v => v));
            // console.log('message clients', clients)
        }
    }

    const [openConnection, sendMessage] = useWebSocket(wsUrl, {onMessage})

    setInterval(() => {sendMessage({action: 'LIST'})}, 5000);

    // React.useEffect(() => {
    //     if (myWs && [WebSocket.OPEN, WebSocket.CONNECTING].includes(myWs.readyState)) {
    //         return;
    //     }
    //     if (myWs) {
    //         console.log('WebSocket readyState', myWs.readyState);
    //     }
    //
    //     myWs = new WebSocket(
    //         url.format({
    //             protocol: window.location.protocol === 'https:' ? 'wss' : 'ws',
    //             hostname: hostname || process.env.WDS_SOCKET_HOST || window.location.hostname,
    //             port:     process.env.WDS_SOCKET_PORT || window.location.port,
    //             // Hardcoded in WebpackDevServer
    //             pathname: process.env.WDS_SOCKET_PATH || '/ws',
    //             slashes:  true,
    //         })
    //     );
    //     window.myWs = myWs;
    //
    //
    //     myWs.onopen = function () {
    //         console.log('WebSocket подключился');
    //         // setMessage('WebSocket подключился');
    //         myWs.send(JSON.stringify({action: 'ME', me}));
    //     };
    //
    //     myWs.onmessage = function (message) {
    //         const jsonTryParse = raw => {
    //             try {
    //                 return JSON.parse(raw)
    //             } catch {
    //                 return false
    //             }
    //         };
    //         const json = jsonTryParse(message.data);
    //
    //         if (json) {
    //             console.log('WebSocket Message: ', json);
    //             if (json.type && json.type === 'message') {
    //                 console.log('WebSocket Message: ', json.message);
    //                 setMessage(json.message);
    //             }
    //             // setMessage(<pre>{JSON.stringify(message.data, null, 2)}</pre>);
    //         } else {
    //             console.log('WebSocket Message: ', message.data);
    //             setMessage(message.data);
    //         }
    //     };
    //
    //     myWs.onerror = function (message) {
    //         console.log('WebSocket error Message: ', message);
    //         myWs = null;
    //         window.myWs = myWs;
    //     };
    //
    //     myWs.onclose = function (message) {
    //         console.log('WebSocket close Message: ', message);
    //         myWs = null;
    //         window.myWs = myWs;
    //     };
    //
    //     function wsSendEcho(value) {
    //         myWs.send(JSON.stringify({action: 'ECHO', data: value.toString()}));
    //     }
    //
    //     function wsSendAll(value) {
    //         myWs.send(JSON.stringify({action: 'ALL', data: value.toString()}));
    //     }
    //
    //     function wsSendPing() {
    //         myWs.send(JSON.stringify({action: 'PING'}));
    //     }
    //
    //     window.wsSendEcho = wsSendEcho;
    //     window.wsSendAll = wsSendAll;
    //     window.wsSendPing = wsSendPing;
    //     return () => {
    //         console.log('WebSocket close');
    //         if (myWs) {
    //             myWs.close()
    //         }
    //         myWs = null;
    //     }
    // }, [])

    // const openConnection = myWs && myWs.readyState === WebSocket.OPEN;

    return <>
        <AdminShow alert>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                // onClick={handleOpen}
                edge="start"
                // className={classes.ml_1}
            >
                {openConnection
                    ? <ConnectedIcon clients={clients}/>
                    : <PowerOffOutlined/>
                }
            </IconButton>
        </AdminShow>
        <Snackbar
            anchorOrigin={{
                vertical:   'top',
                horizontal: 'center',
            }}
            open={Boolean(message)}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            // key={state.Transition.name}
        >
            {/*    <Alert onClose={handleClose} severity="error">*/}
            {/*    {message}*/}
            {/*</Alert>*/}
        </Snackbar>
    </>
        ;
}
