import {withStyles} from "@material-ui/core/styles";
import AutocompleteOriginal from "@material-ui/lab/Autocomplete";

const Autocomplete = withStyles(() => (
        {
            root: {
                "& .MuiAutocomplete-clearIndicator" : {
                    visibility: "visible"
                }
            }
        }
))(AutocompleteOriginal)

export default Autocomplete