import React from 'react';
import {MTableBodyRow} from 'material-table';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContext, FormDataContextProvider} from 'FormDataContext';
import ErrorBoundary from "components/ErrorBoundary";
import MTCellContext from "components/MTableExtend/MTCellContext";


export default function MTBodyRowContext(props) {
    console.log('MTBodyRowContext', props)
    const {data, columns, debug} = props;
    const index = data.tableData.id;
    const {item, values} = React.useContext(FormDataContext)
    const initialValues = item.initialValues || (() => ({_debug_default_initialValues: true}))
    const initial = values[index] || initialValues();

    return <ErrorBoundary info={'MTBodyRowContext'}>
        <FormDataContextProvider
            cols={columns}
            initial={initial}
            item={{field: index}}
            onFormDataAction={item.onFormDataAction}
            debug={'MTBodyRowContext'}
            // hideDebug={true}
        >
            <AdminShow>[Context:{debug}]</AdminShow>
            <MTableBodyRow
                {...props}
                data={initial}
                components={{
                    ...props.components,
                    Cell: MTCellContext
                }}
            />
        </FormDataContextProvider>
    </ErrorBoundary>;
}
