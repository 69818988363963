import React from 'react';

export default (info = {}) => WrappedComponent => props => {
    const [listActions, setListActions] = React.useState([]);
    
    React.useMemo(() => {
        let res = 'function' === typeof props.listActions
            ? props.listActions(props, info)
            : props.listActions;
        
        if (res instanceof Promise) {
            res.then(data => setListActions(data));
        }
        else {
            setListActions(res);
        }
        // eslint-disable-next-line
    }, [props.listActions]);
    
    console.log({listActions: props.listActions}, listActions);
    if (!listActions || !Array.isArray(listActions)) {
        console.error(`${props.listTitle} listActions return not array ${WrappedComponent.name}`, {listActions} );
    }
    return <WrappedComponent {...props} listActions={listActions || []}/>;
}

