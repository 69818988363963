import {Timeline} from '@material-ui/icons';
import detailPanelRender2and1 from 'components/MTableExtend/detailPanelRender2and1';
import {
    ApiDataLoadLookup,
    ColObjectNameOther,
    ColObjectShowDateTime,
    ColObjectName,
    ColObjectOptionsName,
    ColObjectSimpleData,
} from 'components/showItems';
import {asGUID} from 'libs/asGUID';
import {ServiceIcon} from 'components/icons';
import {FieldFormat} from './FieldFormat';
import React from 'react';
import {FORM_ACTION} from "../FormDataContext";
import {TextTooltipCargo, IconContextCount} from 'components/RoutePlaceIconCargo'


export const onFormDataActionNestedRoutePlace =  (values, props) => {
    console.log('onFormDataAction nested routePlaces', values, props)
    //  todo: remove typePoint
    const {action, field, value} = props;
    switch (action) {
        case FORM_ACTION.INIT:
            const _fix = {}
                if(values.isUnloading){
                    _fix.typePoint = 'разгрузка';
                    _fix.isUnloading = true;
                    _fix.isLoading = false

                }else{
                    _fix.typePoint = 'погрузка';
                    _fix.isUnloading = false;
                    _fix.isLoading = true
                }
            return {
                ...values,
                // loadCargo:   asArray(values.cargos).filter(i => !i.isUnload),
                // unloadCargo: asArray(values.cargos).filter(i => i.isUnload),
                ..._fix
            };
        case FORM_ACTION.CHANGE:

            // if (field === 'loadCargo' || field === 'unloadCargo') {
            //     return {
            //         ...values,
            //         cargos:   [...values.loadCargo, ...values.unloadCargo],
            //         isUnloading: true,
            //     }
            // }
                console.log("push2", field, value)
            if (field === 'typePoint' && value === 'разгрузка') {
                return {
                    ...values,
                    isLoading:   false,
                    isUnloading: true,
                }
            }
            if (field === 'typePoint' && value === 'погрузка') {
                return {
                    ...values,
                    isLoading:   true,
                    isUnloading: false,
                }
            }
          
    }
    return values
};

export default {
    listTitle:        'Маршрут',
    field:            'routePlaces',
    icon:             <Timeline/>,
    initialValues: () => ({
        typePoint: 'погрузка',
    }),
    onFormDataAction: onFormDataActionNestedRoutePlace,

    detail:    [
        {
            render:    detailPanelRender2and1,
            tooltip:   <TextTooltipCargo/>,
            title:     'Груз',
            colsSplit: 1,
            icon:      () => <IconContextCount field={'cargos'} icon={'cargos'} />,

            field:     'cargos',
            // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
            onFormDataAction: (values, props) => {
                console.log('onFormDataAction nested routePlaces loadCargo', values, props)
                return values;
            },
            initialValues: () => ({
                qtyInMeasure: 0,
                qtyInCargoPlaces: 0,
                cargoValue: 0,
                cargoMass: 0,
                cargoOkpd2: '',
            }),
            cols: async () => {
                const [lookupCargo, lookupMeasure]
                          = await Promise.all([
                    ApiDataLoadLookup('/api/cargo_types', 'guid', false),
                    ApiDataLoadLookup('/api/measures', 'guid', false),
                ]);

                // const getMeasure = api => {
                //     const guid = api && api.substr(-36);
                //     return lookupMeasure[guid]
                //         ? 'Упаковка: ' + lookupMeasure[guid].name
                //         : guid;
                // };

                return [
                    {
                        title:           'Груз',
                        field:           'cargoType',
                        freeSolo:        true,
                        freeSoloField:   'cargoTypeName',
                        api:             'cargo_types',
                        noSpinerLoading: true,
                        format:          FieldFormat.FORMAT_SELECT_API,
                        object:          ColObjectName,
                        onRowChange:   (data, {field, value}) => {
                            console.log('onRowChange',data)
                            const cargo = lookupCargo[asGUID(data.cargoType)];
                            if (!cargo) {
                                return data;
                            }
                            let update = {...data};
                            if (cargo.measureValue) {
                                // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                update.cargoValue = (data.qtyInMeasure * cargo.measureValue).toFixed(2);
                            }
                            if (cargo.measureWeight) {
                                // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                update.cargoMass = (data.qtyInMeasure * cargo.measureWeight).toFixed(2);
                            }
                            if (cargo.cargoPlaceValue) {
                                // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                            }
                            if(cargo.okpd2){
                                update.cargoOkpd2 = cargo.okpd2
                            }
                        
                            return update;
                        },
                    },
                    {
                        title:         'Упаковок',
                        field:         'qtyInMeasure',
                        // type:          'numeric',
                        // editComponent: MTableTextField,
                        format: FieldFormat.FORMAT_NUMBER,
                        object:        ColObjectSimpleData,  
                        onRowChange:   (data, {field, value}) => {
                            const cargo = lookupCargo[asGUID(data.cargoType)];
                            if (!cargo) {
                                return data;
                            }
                            let update = {...data};
                            if (cargo.measureValue) {
                                // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                update.cargoValue = (value * cargo.measureValue).toFixed(2);
                            }
                            if (cargo.measureWeight) {
                                // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                update.cargoMass = (value * cargo.measureWeight).toFixed(2);
                            }
                            if (cargo.cargoPlaceValue) {
                                // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
                            }
                            return update;
                        },
                    },
                    {
                        title:         'Мест',
                        field:         'qtyInCargoPlaces',
                        // type:          'numeric',
                        // editComponent: MTableTextField,
                        format: FieldFormat.FORMAT_NUMBER,
                        object:        ColObjectSimpleData,
                        minValue: 0,
                        onRowChange:   (data, {field, value}) => {
                            const cargo = lookupCargo[asGUID(data.cargoType)];
                            if (!cargo) {
                                return data;
                            }
                            let update = {...data};
                            if (cargo.cargoPlaceValue) {
                                // - Объем = [Количество мест] * [ТипГруза].[ОбъемМеста]
                                // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                update.cargoValue = (value * cargo.cargoPlaceValue).toFixed(2);
                            }
                            if (cargo.cargoPlaceWeight) {
                                // - Вес = [Количество мест] * [ТипГруза].[ВесМеста]
                                // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                update.cargoMass = (value * cargo.cargoPlaceWeight).toFixed(2);
                            }
                            if (cargo.measureValue) {
                                // - Упаковок = [ОБъем] / [ТипГруза].[ОбъемУпаковки]
                                // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                update.qtyInMeasure = (update.cargoValue / cargo.measureValue).toFixed(2);
                            }
                            return update;
                        },
                    },
                    {
                        title:         'Объем, м^3',
                        field:         'cargoValue',
                        // type:          'numeric',
                        object:        ColObjectSimpleData,
                        measureText:   '(м^3)',
                        // editComponent: MTableTextField,
                        format: FieldFormat.FORMAT_NUMBER,
                        minValue: 0,
                        onRowChange:   (data, {field, value}) => {
                            const cargo = lookupCargo[asGUID(data.cargoType)];
                            if (!cargo) {
                                return data;
                            }
                            let update = {...data};
                            if (cargo.measureValue) {
                                // 4. При изменении [Объем] считать:
                                // - Упаковок = [Объем] / [ТипГруза].[ОбъемУпаковки]
                                // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
                                update.qtyInMeasure = (value / cargo.measureValue).toFixed(2);
                            }
                            if (cargo.cargoPlaceValue) {
                                // - Мест = [Объем] / [ТипГруза].[ОбъемМеста]
                                // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
                                update.qtyInCargoPlaces = (value / cargo.cargoPlaceValue).toFixed(2);
                            }
                            if (cargo.measureValue) {
                                // - Вес = [Количество упаковок] * [ТипГруза].[ВесУпаковки]
                                // если [ТипГруза].[ВесМеста] = 0 то не пересчитывать
                                update.cargoMass = (update.qtyInMeasure * cargo.measureWeight).toFixed(2);
                            } else if (0 === Number(cargo.measureWeight)) {
                                // если [ТипГруза].[ВесУпаковки] = 0, то
                                // Вес = [Количество мест] * [ТипГруза].[ВесМеста],
                                update.cargoMass = (update.qtyInCargoPlaces * cargo.cargoPlaceWeight).toFixed(2);
                            }
                            return update;
                        },

                    },
                    {
                        title:         'Вес, кг',
                        field:         'cargoMass',
                        // type:          'numeric',
                        object:        ColObjectSimpleData,
                        measureText:   '(кг)',
                        // editComponent: MTableTextField,
                        format: FieldFormat.FORMAT_NUMBER,
                        minValue: 0,
                        onRowChange:   (data, {field, value}) => {
                            const cargo = lookupCargo[asGUID(data.cargoType)];
                            if (!cargo) {
                                return data;
                            }
                            let update = {...data};
                            if (cargo.measureWeight) {
                                // 5. При изменении [Вес] считать:
                                // - Упаковок = [Вес] / [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
                                update.qtyInMeasure = (value / cargo.measureWeight).toFixed(2);
                            }
                            if (cargo.cargoPlaceWeight) {
                                // - Мест = [Вес] / [ТипГруза].[ВесМеста] // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
                                update.qtyInCargoPlaces = (value / cargo.cargoPlaceWeight).toFixed(2);
                            }
                            if (cargo.cargoPlaceValue) {
                                // - Объем = [Количество упаковок] * [ТипГруза].[ОбъемУпаковки]
                                // если [ТипГруза].[ОбъемМеста] = 0 то не пересчитывать
                                update.cargoValue = (update.qtyInMeasure * cargo.measureValue).toFixed(2);
                            } else if (0 === Number(cargo.measureValue)) {
                                // если [ТипГруза].[ОбъемУпаковки] = 0,
                                // то Вес = [Количество мест] * [ТипГруза].[ОбъемМеста],
                                update.cargoValue = update.qtyInCargoPlaces * cargo.measureValue;


                            }
                            return update;
                        },
                    },
                    {
                        title:         'ОКПД 2',
                        field:         'cargoOkpd2',
                        format: FieldFormat.FORMAT_INPUT,
                        object: ColObjectSimpleData
                    },
                ];
            },
        },
        // {
        //     render:    detailPanelRender2and1,
        //     colsSplit: 1,
        //     tooltip:   'Разгрузка',
        //     field:     'unloadCargo',
        //     icon:      () => <IconContextCount field={'unloadCargo'} icon={FormatIndentDecrease} />,
        //     // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
        //     onFormDataAction: (values, props) => {
        //         console.log('onFormDataAction nested2', {values, props})
        //         return values;
        //     },
        //     cols: async () => {
        //         const [lookupCargo, lookupMeasure]
        //                   = await Promise.all([
        //             ApiDataLoadLookup('/api/cargo_types', 'guid', false),
        //             ApiDataLoadLookup('/api/measures', 'guid', false),
        //         ]);

        //         const getMeasure = api => {
        //             const guid = api && api.substr(-36);
        //             return lookupMeasure[guid]
        //                 ? 'Упаковка: ' + lookupMeasure[guid].name
        //                 : guid;
        //         };
        //         //TODO: code duplicate - need optimize
        //         return [
        //             {
        //                 title:           'Груз',
        //                 field:           'cargoType',
        //                 freeSolo:        true,
        //                 freeSoloField:   'cargoTypeName',
        //                 api:             'cargo_types',
        //                 noSpinerLoading: true,
        //                 format:          FieldFormat.FORMAT_SELECT_API,
        //                 object:          ColObjectName,
        //                 onRowChange:   (data, {field, value}) => {
        //                     const cargo = lookupCargo[asGUID(data.cargoType)];
        //                     if (!cargo) {
        //                         return data;
        //                     }
        //                     let update = {...data};
        //                     if(cargo.okpd2){
        //                         update.cargoOkpd2 = cargo.okpd2
        //                     }
                        
        //                     return update;
        //                 },
                        
        //             },
        //             {
        //                 title:         'Упаковок',
        //                 field:         'qtyInMeasure',
        //                 // type:          'numeric',
        //                 format: FieldFormat.FORMAT_NUMBER,
        //                 minValue: 0,
        //                 // editComponent: MTableTextField,
        //                 object:        ColObjectSimpleData,
        //                 onRowChange:   (data, {field, value}) => {
        //                     const cargo = lookupCargo[asGUID(data.cargoType)];
        //                     if (!cargo) {
        //                         return data;
        //                     }
        //                     let update = {...data};
        //                     if (cargo.measureValue) {
        //                         // - Объем = [Количество в упаковках] * [ТипГруза].[ОбъемУпаковки] // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
        //                         update.cargoValue = (value * cargo.measureValue).toFixed(2);
        //                     }
        //                     if (cargo.measureWeight) {
        //                         // - Вес = [Количество в упаковках] * [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
        //                         update.cargoMass = (value * cargo.measureWeight).toFixed(2);
        //                     }
        //                     if (cargo.cargoPlaceValue) {
        //                         // - Мест = [ОБъем] / [ТипГруза].[ОбъемМеста] // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
        //                         update.qtyInCargoPlaces = (update.cargoValue / cargo.cargoPlaceValue).toFixed(2);
        //                     }
        //                     return update;
        //                 },
        //             },
        //             {
        //                 title:         'Мест',
        //                 field:         'qtyInCargoPlaces',
        //                 format: FieldFormat.FORMAT_NUMBER,
        //                 minValue: 0,
        //                 object:        ColObjectSimpleData,
        //                 onRowChange:   (data, {field, value}) => {
        //                     const cargo = lookupCargo[asGUID(data.cargoType)];
        //                     if (!cargo) {
        //                         return data;
        //                     }
        //                     let update = {...data};
        //                     if (cargo.cargoPlaceValue) {
        //                         // - Объем = [Количество мест] * [ТипГруза].[ОбъемМеста]
        //                         // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
        //                         update.cargoValue = (value * cargo.cargoPlaceValue).toFixed(2);
        //                     }
        //                     if (cargo.cargoPlaceWeight) {
        //                         // - Вес = [Количество мест] * [ТипГруза].[ВесМеста]
        //                         // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
        //                         update.cargoMass = (value * cargo.cargoPlaceWeight).toFixed(2);
        //                     }
        //                     if (cargo.measureValue) {
        //                         // - Упаковок = [ОБъем] / [ТипГруза].[ОбъемУпаковки]
        //                         // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
        //                         update.qtyInMeasure = (update.cargoValue / cargo.measureValue).toFixed(2);
        //                     }
        //                     return update;
        //                 },
        //             },
        //             {
        //                 title:         'Объем, м^3',
        //                 field:         'cargoValue',
        //                 // type:          'numeric',
        //                 object :       ColObjectSimpleData,
        //                 measureText:   '(м^3)',
        //                 format: FieldFormat.FORMAT_NUMBER,
        //                 minValue: 0,
        //                 onRowChange:   (data, {field, value}) => {
        //                     const cargo = lookupCargo[asGUID(data.cargoType)];
        //                     if (!cargo) {
        //                         return data;
        //                     }
        //                     let update = {...data};
        //                     if (cargo.measureValue) {
        //                         // 4. При изменении [Объем] считать:
        //                         // - Упаковок = [Объем] / [ТипГруза].[ОбъемУпаковки]
        //                         // если [ТипГруза].[ОбъемУпаковки] = 0, то не пересчитывать
        //                         update.qtyInMeasure = (value / cargo.measureValue).toFixed(2);
        //                     }
        //                     if (cargo.cargoPlaceValue) {
        //                         // - Мест = [Объем] / [ТипГруза].[ОбъемМеста]
        //                         // если [ТипГруза].[ОбъемМеста] = 0, то не пересчитывать
        //                         update.qtyInCargoPlaces = (value / cargo.cargoPlaceValue).toFixed(2);
        //                     }
        //                     if (cargo.measureValue) {
        //                         // - Вес = [Количество упаковок] * [ТипГруза].[ВесУпаковки]
        //                         // если [ТипГруза].[ВесМеста] = 0 то не пересчитывать
        //                         update.cargoMass = (update.qtyInMeasure * cargo.measureWeight).toFixed(2);
        //                     } else if (0 === Number(cargo.measureWeight)) {
        //                         // если [ТипГруза].[ВесУпаковки] = 0, то
        //                         // Вес = [Количество мест] * [ТипГруза].[ВесМеста],
        //                         update.cargoMass = (update.qtyInCargoPlaces * cargo.cargoPlaceWeight).toFixed(2);
        //                     }
        //                     return update;
        //                 },

        //             },
        //             {
        //                 title:         'Вес, кг',
        //                 field:         'cargoMass',
        //                 // type:          'numeric',
        //                 object:        ColObjectSimpleData,
        //                 measureText:   '(кг)',
        //                 format:        FieldFormat.FORMAT_NUMBER,
        //                 minValue: 0,
        //                 onRowChange:   (data, {field, value}) => {
        //                     const cargo = lookupCargo[asGUID(data.cargoType)];
        //                     if (!cargo) {
        //                         return data;
        //                     }
        //                     let update = {...data};
        //                     if (cargo.measureWeight) {
        //                         // 5. При изменении [Вес] считать:
        //                         // - Упаковок = [Вес] / [ТипГруза].[ВесУпаковки] // если [ТипГруза].[ВесУпаковки] = 0, то не пересчитывать
        //                         update.qtyInMeasure = (value / cargo.measureWeight).toFixed(2);
        //                     }
        //                     if (cargo.cargoPlaceWeight) {
        //                         // - Мест = [Вес] / [ТипГруза].[ВесМеста] // если [ТипГруза].[ВесМеста] = 0, то не пересчитывать
        //                         update.qtyInCargoPlaces = (value / cargo.cargoPlaceWeight).toFixed(2);
        //                     }
        //                     if (cargo.cargoPlaceValue) {
        //                         // - Объем = [Количество упаковок] * [ТипГруза].[ОбъемУпаковки]
        //                         // если [ТипГруза].[ОбъемМеста] = 0 то не пересчитывать
        //                         update.cargoValue = (update.qtyInMeasure * cargo.measureValue).toFixed(2);
        //                     } else if (0 === Number(cargo.measureValue)) {
        //                         // если [ТипГруза].[ОбъемУпаковки] = 0,
        //                         // то Вес = [Количество мест] * [ТипГруза].[ОбъемМеста],
        //                         update.cargoValue = (update.qtyInCargoPlaces * cargo.measureValue).toFixed(2);

        //                     }
        //                     return update;
        //                 },
        //             },
        //             {
        //                 title:         'ОКПД 2',
        //                 field:         'cargoOkpd2',
        //                 format: FieldFormat.FORMAT_INPUT,
        //             },
        //         ];
        //     },
        // },
        {
            render:  detailPanelRender2and1,
            field:   'services',
            tooltip: 'Услуги',
            initialValues: ()=>({
                // serviceCost: 1234555,
            }),
            onFormDataAction: (values, props) => {
                console.log('onFormDataAction nested routePlaces services', values, props)
                return values;
            },
            icon:      () => <IconContextCount field={'services'} icon={ServiceIcon} />,
            // filter: (row,rowData) => row.filter(i=>i.key_id === rowData.key_id),
            cols: () => (
                 [
                    {
                        title:  'Услуга',
                        field:  'service',
                        api:    'services',
                        format: FieldFormat.FORMAT_SELECT_API,
                        object: ColObjectName,
                    },
                    {
                        title: 'Стоимость',
                        field: 'serviceCost',
                        format: FieldFormat.FORMAT_NUMBER
                    },
                    
                    
                ]
            ),
        },
    ],
    cols:      async (props, other) => {
        console.log({props, other});
        // const [
        //           lookupFiasAddressTrees,
        //       ]
        //           = await Promise.all([
        //     ApiDataLoadLookup('/api/fias_address_trees', 'guid', false),
        // ]);

        //const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        //await sleep(2000); // need for correct draw table
        return [
            {
                title:         'Адрес',
                field:         'point',
                format: FieldFormat.FORMAT_FIAS_ADDRESS,
                api:           'fias_address_trees',
                object:         ColObjectName,
                // editComponent: MTableFiasAddress,
                // object: ColObjectName,
                // object: ColObjectNameOther,
                // render:        renderFiasAddress(lookupFiasAddressTrees, 'point', 'address'),
                // lookupObjects: lookupFiasAddressTrees,
            },
            {
                title:  'Дата прибытия',
                format: FieldFormat.FORMAT_DATETIME_LOCAL,
                field:  'dateFromText',
                // type:   'datetime',
                object: ColObjectShowDateTime,
                defaultSort : 'asc'

            },
            {
                title:   'Тип',
                format:  FieldFormat.FORMAT_SELECT,
                field:   'typePoint',
                options: [
                    {
                        id:   'погрузка',
                        name: 'Погрузка',
                    },
                    {
                        id:   'разгрузка',
                        name: 'Разгрузка',
                    },
                    // {
                    //     id:   'загрузка-погрузка',
                    //     name: 'Загрузка и погрузка',
                    // },
                ],
                object:  ColObjectOptionsName,
            },
            // {
            //     title:  'Дата убытия',
            //     format: FieldFormat.FORMAT_DATETIME_LOCAL,
            //     field:  'dateTo',
            //     // type:   'datetime',
            //     object: ColObjectShowDateTime,
            // },
            {
                title: 'Контрагент',
                // lookup: lookupCargoOwner,
                // render: rowData => rowData.cargoOwner
                //     && lookupCargoOwner[rowData.cargoOwner],
                api:       'legals',
                // apiFilter: () => (
                //     {'exists[contragent]': true,}

                // ),
                format:    FieldFormat.FORMAT_SELECT_API,
                object:    ColObjectNameOther,
                field:     'contragent',
                freeSolo:       true,
                freeSoloField:  'contragentName',
                freeSoloCanEdit: true,
                style: {minWidth: "17rem"},
            },
            {
                title:     'Контактное лицо',
                api:       'contact_people',
                apiFilter: (rowData) => (
                    {
                        legal: rowData && rowData.contragent,
                    }
                ),
                // lookup: lookupContact,
                format: FieldFormat.FORMAT_SELECT_API,
                object: ColObjectNameOther,
                field:  'contact',
                freeSolo:       true,
                freeSoloField:  'contactName',
                freeSoloCanEdit: true,
            },
            {
                title:          'Контактные телефоны',
                field:          'contactPhones',
                format:         FieldFormat.FORMAT_PHONE_CONTACTS,
            },
            {
                field:          'hidenContactPhones',
                format:         FieldFormat.FORMAT_NONE,
                hidden:   true,
            },
            {
                title:    'key_id',
                field:    'key_id',
                editable: 'never',
                hidden:   true,
                format:         FieldFormat.FORMAT_NONE,
            },
            {
                title: 'Услуги',
                field: "services",
                format: FieldFormat.FORMAT_NONE,
                hidden: true
            },
            {
                title: 'Грузы',
                field: "cargos",
                format: FieldFormat.FORMAT_NONE,
                hidden: true
            },
         

           
        ];
    },
}