import React from "react";
import {
    Badge,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TableCell,
    TableHead,
    Tooltip,
    Typography,
    Button,
    Tab,
    Grid,
    TextField
} from '@material-ui/core';

import {ErrorOutlined} from '@material-ui/icons';

import LoadStateShow from "components/LoadStateShow";
import useLoaderApi, {useLoaderApiList} from "components/useLoaderApi";
import {useChat} from "components/Chat/useChatRoom";
import useStyles from "../styles";
import {ChatBody} from "components/Chat/ChatBody";
import {BlockNewMessage} from "components/Chat/BlockNewMessage";
import {TabsHorizontal} from "components/Chat/TabsHorizontal";
import {TabsVertical} from "components/Chat/TabsVertical";
import ShowMessages from "components/Chat/ShowMessages";
import {withStyles} from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import asArray from "../libs/asArray";
import SelectApi from 'components/FieldFormat/SelectApi';
import {asGUID} from 'libs/asGUID';
import request from "superagent";
import {v1 as uuidv1} from 'uuid';
import {ChatContext} from "components/Chat/ChatContext";
import {FormDataContext} from "../FormDataContext";

const ChatWindowStyleBox = withStyles((theme) => ({
    root: {
        height:   "35vh",
        overflow: "auto",
        // background: "#f5f5f5"
    }
}))(Box)

const TabStyles = withStyles((theme) => ({
    root: {
        padding: "1rem",

    }
}))(Tab)
const StyleButtonAddNew = withStyles(() => ({
    root: {
        padding: '1rem'
    }
}))(Button)
const TabFixHeightStyles = withStyles((theme) => ({
    root: {
        height:       "70px",
        '&:hover':    {
            color:   '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color:   '#40a9ff',
            opacity: 1,
        },
        '&:focus':    {
            color:      '#40a9ff',
            background: '#b1e3f8',
            opacity:    1,
        },

    }
}))(TabStyles)


/**
 * Определяет что значение не превысило максимальное значение
 * Иначе сбрасывает
 * @return mixed
 * @param v
 * @param max
 * @param init
 */
function checkOverFlow(v, max, init = 0) {
    return v < max ? v : init;
}

function firstAndLast(array) {
    return [
        array[0],
        array[array.length - 1]
    ]
}

function ShowPointsFromTo({routePlaces}) {


    const [from, to] = firstAndLast(routePlaces);
    return <>
        <span>{from.point_name}</span>
        <span> {` -> `}</span>
        <span>{to.point_name}</span>
    </>;
}

ShowPointsFromTo.propTypes = {routePlaces: PropTypes.array};

const ShowPoints = ({guid, api}) => {
    const [item, loadState] = useLoaderApi(api, guid)
    return (
        <LoadStateShow state={loadState} error={<span>{JSON.stringify(item)} {guid}</span>}>
            <>
                {item && item.routePlaces && item.routePlaces.length
                    ?
                    <>
                        <span>{[...item.routePlaces].shift().point_name}</span>
                        <span> {` -> `}</span>
                        <span>{[...item.routePlaces].pop().point_name}</span>
                    </>
                    :
                    <></>}

            </>
        </LoadStateShow>
    )

}

const ShowNameGuid = ({guid, api}) => {
    const [item, loadState] = useLoaderApi(api, guid)
    return (
        <LoadStateShow state={loadState} error={<span>{JSON.stringify(item)} {guid}</span>}>
            <span>{item && item.name ? item.name : ''}</span>
        </LoadStateShow>
    )

}

const WorkingItem = ({data, id, type}) => {
    const [item, setItem] = React.useState(false);

    React.useEffect(() => {
        setItem(data.find(el => el.guid === id))
    }, [id])

//     carrier
// : 
// "a6dd591b-29b6-47ad-9405-60efc696ae16"
// docLinkedGuid
// : 
// "d973f800-7451-412b-9584-65df1347505d"
// docLinkedName
// : 
// "doc_request"
    if (!item) {
        return <></>
    }
    switch (type) {

        case 'title':
            return (
                <Box mb={1}>
                    <Typography variant="h6">
                        <span>{<ShowNameGuid guid={item.docLinkedGuid} api={`${item.docLinkedName}`}/>}</span>
                        <span> {` -> `}</span>
                        <span>{<ShowNameGuid guid={item.carrier} api={`legals`}/>}</span>
                    </Typography>
                </Box>
            )
        case 'points':
            return (
                <Typography variant="body2">
                    <ShowPoints guid={item.docLinkedGuid} api={`${item.docLinkedName}`}/>
                </Typography>
            )
        default:
            return (<></>)
    }
}


function AddChatMessage(props) {

    const {item, changeTabTabHorizontal, disabled, newMessage, children} = props;
    const {addNewChat} = React.useContext(ChatContext);

    const handlerClick = () => {
        const guid = uuidv1();
        const data = {
            data:          newMessage,
            carrier:       asGUID(item._carrierNewChatRoom),
            docLinkedName: 'doc_requests',
            docLinkedGuid: item.guid,
            name:          item.name
        }
        request
            .post(`/api/chat/${guid}`)
            .set('accept', 'application/json')
            .send(data)
            .then(result => {
                changeTabTabHorizontal(guid)
                addNewChat(
                    {
                        ...data,
                        isOpen:   true,
                        notRead:  0,
                        guid:     guid,
                        lastDate: result.body.createdAt
                    }
                );
            })

    }


    return <StyleButtonAddNew
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handlerClick}
        fullWidth
    >
        {children}
    </StyleButtonAddNew>;
}

AddChatMessage.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node
};
const getCustomOptionDisabled = (rooms, guid )=> option => !option.item.isTPValid || !!rooms.find(el => el.carrier === option.item.guid && el.docLinkedGuid === guid)

const textCustomDisabled = (rooms, guid) => (option) => {
    if (!option.item.isTPValid) {
        return <Box px={0.5}><ErrorOutlined/> (отсуствует на портале перевозчика)</Box>
    }
    if (!!rooms.find(el => el.carrier === option.guid && el.docLinkedGuid === guid)) {
        return <Box pl={0.5}> (чат комната уже создана) </Box>
    }
    return null
}

/**
 * TODO: Малозначащий класс. Избавиться при перыой возможности
 * @deprecated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CheckTabHorizontal = (props) => {
    const {state: {values}} = React.useContext(FormDataContext);
    const {tabHorizontal, item, changeTabTabHorizontal} = props
    const [rooms, loadState] = useChat()
    const text = {
        title:  "Выберете перевозчика с которым хотите начать общение",
        button: {
            init:         "Выберете перевозчика",
            notMessage:   "Введите сообщение",
            successfully: "Отправить"
        }
    }
    const [checkDisabled, setCheckDisabled] = React.useState(true)

    const [newMessage, setNewMessage] = React.useState('')

    const [textButton, setTextButton] = React.useState(text.button.init)

    React.useEffect(() => {
        if (item._carrierNewChatRoom) {

            if (newMessage) {
                setTextButton(text.button.successfully)
                setCheckDisabled(false)
                return
            }

            setTextButton(text.button.notMessage)
            setCheckDisabled(true)
        }
    }, [item, newMessage])


    if (tabHorizontal === "new") {

        return (
            <>
                <Typography variant="body2">
                    {text.title}
                </Typography>
                <Box p={1}>
                    <SelectApi
                        item={{
                            api:               "legals",
                            field:             "_carrierNewChatRoom",
                            title:             "Перевозчик",
                            apiFilter:         (rowData) => {
                                return {
                                    'exists[contragent]': true
                                }
                            },
                            getOptionDisabled: getCustomOptionDisabled(rooms, values.guid),
                            textDisabled:      textCustomDisabled(rooms, values.guid)
                        }}
                        parent={{}}
                    />
                </Box>
                <Box p={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <TextField variant="outlined"
                                       multiline
                                       disabled={!item._carrierNewChatRoom}
                                       placeholder={textButton}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       label="Сообщение перевозчику" size="large" value={newMessage || ''} fullWidth
                                       onChange={e => {
                                           setNewMessage(e.currentTarget.value)
                                       }}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <AddChatMessage
                                item={item}
                                disabled={checkDisabled}
                                changeTabTabHorizontal={changeTabTabHorizontal}
                                newMessage={newMessage}
                            >
                                {text.button.successfully}
                            </AddChatMessage>


                        </Grid>

                    </Grid>

                </Box>


            </>
        )
    }
    return (
        <>
            {props.children}
        </>
    )
}


export default function PageChat(props) {
    const {item} = props
    const [rooms, loadState] = useChat()
    const room = rooms.find(el => el.docLinkedGuid === item.guid)
    const [tabHorizontal, setTabHorizontal] = React.useState( room
            ?room.guid
            : 'new');
    const group = {
        secondGroup: 'carrier',
        api2:        'legals',
    }




    return (
        <LoadStateShow state={loadState} error={<>LogsShow ERROR {JSON.stringify(rooms)}</>}>
            <>
                {/* <Box mt={2}>
                    <WorkingItem data={rooms} id={tabHorizontal} type={'title'} />
                </Box> */}

                <Box mt={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TabsHorizontal
                                    rooms={asArray(rooms).filter(el => el.docLinkedGuid === item.guid)}
                                    group={group}
                                    tabHorizontal={tabHorizontal} setTabHorizontal={setTabHorizontal}
                                    // tabGroup={tabGroup}
                                    createNewRoom={true}
                                    // objectShow={objectShow}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={2}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box mb={1}>
                                <WorkingItem data={rooms} id={tabHorizontal} type={'points'}/>
                            </Box>
                            <ChatWindowStyleBox boxShadow={2}>
                                <CheckTabHorizontal item={item} tabHorizontal={tabHorizontal} changeTabTabHorizontal={setTabHorizontal}>
                                    <ShowMessages room={tabHorizontal}/>
                                </CheckTabHorizontal>
                            </ChatWindowStyleBox>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                tabHorizontal !== 'new'
                                    ? <BlockNewMessage room={tabHorizontal}/>
                                    : <></>
                            }
                        </Grid>
                    </Grid>
                </Box>

            </>
        </LoadStateShow>
    )

}