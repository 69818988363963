import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageService from 'page/PageService';
import {FieldFormat} from "./FieldFormat";
import {ColBoolean} from "../components/showItems";
import {ColFields, ColLog} from "../page/SyncTable";

export default {
    listTitle: 'sync',
    apiName:   'syncs',
    itemName:  'sync',
    canAdd:    false,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Услуги',
        path:      '/services',
        Component: PageService,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        // {field: 'id', title: 'ID', format: RequestForm.FORMAT_NONE},
        {field: 'name', title: 'Название'},
        {field: 'className', title: 'Имя класса', hidden: {xlDown: true}},
        {field: 'query', title: 'Имя для запроса', hidden: {xlDown: true}},
        {
            field: 'lastLog',
            title: 'Журнал обновления',
            object: ColLog,
            hidden: {xsDown: true},
        },
        {field: 'itemsName', title: 'Имя списка в результате', hidden: {xlDown: true}},
        {
            field: 'fromReplicator',
            title: 'Репликатор',
            type: 'int',
            hidden: {xsDown: true},
        },
        {
            field: 'fields',
            title: 'Поля синхронизации',
            object: ColFields,
            type: 'json',
            format: FieldFormat.FORMAT_TEXTAREA,
            hidden: {mdDown: true},
        },
        {
            field: 'limitPerSync',
            title: 'Лимит при синхронизации',
            type: 'int',
            hidden: {mdDown: true},
        },
        {
            field: 'enabled', title: 'включено',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            hidden: {mdDown: true},
        },
        {
            field: 'changeData',
            title: 'обратная',
            object: ColBoolean,
            format: FieldFormat.FORMAT_CHECKBOX,
            hidden: {mdDown: true},
        },
    ],
};
