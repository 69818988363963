import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        fontSize: '1rem',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    MuiTableContainer: {
        width: "100%",
        overflowX: "auto",
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'absolute',
        overflow: 'auto',
    },
    drawerBackdrop: {
        position: 'absolute',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
    },
    toolbar: theme.mixins.toolbar,
    overNested: {
        boxShadow: '0px 26px 26px -26px rgba(0,0,0,0.52) inset,' +
            '0px -26px 26px -26px rgba(0,0,0,0.52) inset',
    },
    nested: {
        paddingLeft: theme.spacing(4),
        // borderLeftStyle: 'solid',
        // borderLeftColor: theme.palette.action.selected,
        // borderLeftWidth: theme.spacing(2),

    },
    MuiPaper: {
        padding: theme.spacing(2),
    },


    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    grow: {
        flexGrow: 1,
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    ml_1: {
        marginLeft: '1rem',
    },
    avatar_text_link: {
        marginLeft: '58px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
export default useStyles;