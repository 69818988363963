import React from "react";


const CarbodyType = ({loading})=>{

    return (
        <svg width="20" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3143 1L1.0116 6.98887L10.8553 11.1391L29.1627 4.82171L19.3143 1Z" fill={loading.includes('Верхняя') ? "#C3EBA9" : "#ffffff00"} stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 7.0177V19.0329L10.7834 23.4805V11.0985L1 7.0177Z" fill={loading.includes('Задняя') ? "#C3EBA9" : "#ffffff00"} stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M29.218 7.22056L31.632 6.46277L34.6831 9.10159V14.2454L29.218 16.276" fill="#ffffff00" />
        <path d="M29.218 7.22056L31.632 6.46277L34.6831 9.10159V14.2454L29.218 16.276" stroke="#848484" />
        <path d="M29.1566 7.30566L31.5304 6.38136L29.1566 5.33362V7.30566Z" stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M29.1379 4.86609V16.3131L10.7153 23.4713V11.1427L29.1379 4.86609Z" fill={loading.includes('Боковая') ? "#C3EBA9" : "#ffffff00"} stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.0116 23.5672C18.1162 23.2359 19.0116 21.8481 19.0116 20.4674C19.0116 19.0866 18.1162 18.2359 17.0116 18.5672C15.907 18.8985 15.0116 20.2864 15.0116 21.6671C15.0116 23.0478 15.907 23.8985 17.0116 23.5672Z" fill="#ffffff00" stroke="#848484" />
        <path d="M30.0116 18.5672C31.1162 18.2359 32.0116 16.8481 32.0116 15.4674C32.0116 14.0866 31.1162 13.2359 30.0116 13.5672C28.907 13.8985 28.0116 15.2864 28.0116 16.6671C28.0116 18.0478 28.907 18.8985 30.0116 18.5672Z" fill="#ffffff00" stroke="#848484" />
      </svg>
    )
}

export {CarbodyType}
